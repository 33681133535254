import * as React from 'react';

import { SelectField } from '@cinuru/components';

import gql from 'graphql-tag';

import WithData from './WithData';

export default class BonusprogramSelectField extends React.Component {
	initialized = false;

	setInitialValue = (value) => {
		!this.initialized && this.props.onChange && this.props.onChange(value);
		this.initialized = true;
	};

	render = () => (
		<WithData
			query={gql`
				query {
					currentUser {
						id
						privileges {
							adminForBonusPrograms {
								id
								name
							}
						}
					}
				}
			`}
		>
			{({ currentUser }) => {
				let defaultValue = null;
				if (this.props.multi) {
					defaultValue = currentUser.privileges.adminForBonusPrograms.map(({ id }) => id);
				} else {
					//If our testcinema is part of the available cinemas, select that one.
					defaultValue = currentUser.privileges.adminForBonusPrograms
						.map((bp) => bp.id)
						.includes('Qm9udXNQcm9ncmFtOjEy')
						? 'Qm9udXNQcm9ncmFtOjEy'
						: currentUser.privileges.adminForBonusPrograms[0].id;
				}
				this.setInitialValue(defaultValue);

				return (
					<SelectField
						width={'100%'}
						label="Bonusprogramm wählen"
						multi={this.props.multi}
						items={currentUser.privileges.adminForBonusPrograms.map(({ id, name }) => ({
							value: id,
							label: name,
						}))}
						value={defaultValue}
						{...this.props}
					/>
				);
			}}
		</WithData>
	);
}
