export const campaignGroups = [
	{ type: 'FIXED', label: 'Einmalige Kampagne' },
	{ type: 'MOVIE', label: 'Wiederholende Kampagne' },
	// { type: 'USER', label: 'Wiederholend Nutzer Basiert' },
	// { type: 'USER_MOVIE', label: 'Wiederholend Nutzer Film Basiert' },
];

export const campaignTypes = [
	{
		group: 'FIXED',
		type: 'FIXED_DATE',
		active: true,
		label: 'Festes Datum',
		iconName: 'calendardate',
		fields: ['SENDING_TIME', 'SENDING_DATE', 'NEWS_ITEM'],
		filter: [
			'MOVIES_ON_WATCHLIST',
			'MOVIES_SEEN',
			'MOVIES_NOT_SEEN',
			'MOVIES_RATED_POSITIVELY',
			'MOVIES_RATED_NEGATIVELY',
			'BONUS_POINTS_GEQ',
			'BONUS_POINTS_LEQ',
			'VISIT_FREQUENCY',
		],
		defaultValues: {
			sendingTime: '12:00:00.000Z',
			channels: ['APP', 'PUSH'],
		},
	},
	{
		group: 'MOVIE',
		type: 'MOVIE_CINEMA_START',
		active: false,
		label: 'Startet im Kino',
		labelLong: 'Film Startet im Kino',
		iconName: 'filmStart',
		variables: [{ name: 'referencedMovie', label: 'Startender Film', type: 'Movie' }],
		fields: ['SEND_ON_DAY_X_BEFORE_TRIGGER', 'SENDING_TIME'],
		filter: [
			'MOVIES_ON_WATCHLIST',
			'MOVIES_SEEN',
			'MOVIES_NOT_SEEN',
			'MOVIES_RATED_POSITIVELY',
			'MOVIES_RATED_NEGATIVELY',
			'BONUS_POINTS_GEQ',
			'BONUS_POINTS_LEQ',
			'VISIT_FREQUENCY',
			'REFERENCED_MOVIE',
		],
		defaultValues: {
			sendOnDayXAfterTrigger: -3,
			sendingTime: '12:00:00.000Z',
			channels: ['PUSH'],
			userGroupFilters: [{ type: 'REFERENCED_MOVIE', value: { seen: false, onWatchlist: true } }],
			link: 'VARIABLE:referencedMovie',
		},
	},
	{
		group: 'MOVIE',
		type: 'MOVIE_CINEMA_LEAVE',
		active: true,
		label: 'Endet im Kino',
		labelLong: 'Endet im Kino',
		iconName: 'filmEnd',
		variables: [{ name: 'referencedMovie', label: 'Endender Film', type: 'Movie' }],
		fields: ['SEND_ON_DAY_X_BEFORE_TRIGGER', 'SENDING_TIME'],
		filter: [
			'MOVIES_ON_WATCHLIST',
			'MOVIES_SEEN',
			'MOVIES_NOT_SEEN',
			'MOVIES_RATED_POSITIVELY',
			'MOVIES_RATED_NEGATIVELY',
			'BONUS_POINTS_GEQ',
			'BONUS_POINTS_LEQ',
			'VISIT_FREQUENCY',
			'REFERENCED_MOVIE',
		],
		defaultValues: {
			sendOnDayXAfterTrigger: -3,
			sendingTime: '12:00:00.000Z',
			channels: ['PUSH'],
			userGroupFilters: [{ type: 'REFERENCED_MOVIE', value: { seen: false, onWatchlist: true } }],
			link: 'VARIABLE:referencedMovie',
		},
	},
	{
		group: 'USER',
		type: 'USER_BIRTHDAY',
		active: false,
		label: 'Geburstag',
		labelLong: 'Nutzer hat Geburtstag',
		iconName: 'birthdayCake',
		variables: [],
		fields: ['SEND_ON_DAY_X_BEFORE_TRIGGER', 'SENDING_TIME'],
		filter: [
			'MOVIES_ON_WATCHLIST',
			'MOVIES_SEEN',
			'MOVIES_NOT_SEEN',
			'MOVIES_RATED_POSITIVELY',
			'MOVIES_RATED_NEGATIVELY',
			'BONUS_POINTS_GEQ',
			'BONUS_POINTS_LEQ',
			'VISIT_FREQUENCY',
		],
		defaultValues: {
			sendOnDayXAfterTrigger: 0,
			sendingTime: '09:00:00.000Z',
			channels: ['APP', 'PUSH'],
			userGroupFilters: [
				{ type: 'VISIT_FREQUENCY', value: { visits: 2, intervalLengthDays: 365 } },
			],
		},
	},
	{
		group: 'USER',
		type: 'USER_CINEMA_LAST_VISIT',
		active: false,
		label: 'Wird Inaktiv',
		labelLong: 'Nutzer Wird Inaktiv',
		iconName: 'hourglass',
		variables: [],
		fields: ['SEND_ON_DAY_X_AFTER_TRIGGER', 'SENDING_TIME'],
		filter: [
			'MOVIES_ON_WATCHLIST',
			'MOVIES_SEEN',
			'MOVIES_NOT_SEEN',
			'MOVIES_RATED_POSITIVELY',
			'MOVIES_RATED_NEGATIVELY',
			'BONUS_POINTS_GEQ',
			'BONUS_POINTS_LEQ',
			'VISIT_FREQUENCY',
		],
		defaultValues: {
			sendOnDayXAfterTrigger: 1,
			sendingTime: '16:00:00.000Z',
			channels: ['PUSH'],
			userGroupFilters: [
				{ type: 'VISIT_FREQUENCY', value: { visits: 2, intervalLengthDays: 365 } },
			],
		},
	},
	{
		group: 'USER',
		type: 'USER_POINTS_REACHED',
		active: false,
		label: 'Punktestand',
		labelLong: 'Nutzer erreicht Punktestand',
		iconName: 'coin',
		variables: [],
		fields: ['SEND_EXACTLY_MINUTES_AFTER_TRIGGER'],
		filter: [
			'MOVIES_ON_WATCHLIST',
			'MOVIES_SEEN',
			'MOVIES_NOT_SEEN',
			'MOVIES_RATED_POSITIVELY',
			'MOVIES_RATED_NEGATIVELY',
			'BONUS_POINTS_GEQ',
			'BONUS_POINTS_LEQ',
			'VISIT_FREQUENCY',
		],
		defaultValues: {
			sendExactlyMinutesAfterTrigger: 0,
			channels: ['APP', 'PUSH'],
		},
	},
	{
		group: 'USER_MOVIE',
		type: 'USER_MOVIE_SEEN',
		active: false,
		label: 'Film gesehen',
		labelLong: 'Nutzer hat Film gesehen',
		iconName: 'ticket',
		variables: [{ name: 'referencedMovie', label: 'Gesehener Film', type: 'Movie' }],
		fields: ['SEND_EXACTLY_MINUTES_AFTER_TRIGGER'],
		filter: [
			'MOVIES_ON_WATCHLIST',
			'MOVIES_SEEN',
			'MOVIES_NOT_SEEN',
			'MOVIES_RATED_POSITIVELY',
			'MOVIES_RATED_NEGATIVELY',
			'BONUS_POINTS_GEQ',
			'BONUS_POINTS_LEQ',
			'VISIT_FREQUENCY',
		],
		defaultValues: {
			sendExactlyMinutesAfterTrigger: 15,
			channels: ['PUSH'],
		},
	},
];

export const usergroupFilterTypes = [
	{
		type: 'MOVIES_ON_WATCHLIST',
		name: 'Film auf der Merkliste Filter',
		input: [
			{
				label: 'Filme',
				hint: 'Mindestens einer dieser Filme wurde auf die Merkliste gesetzt',
				type: 'SELECT',
				multi: true,
				itemType: 'MOVIES',
			},
		],
		variables: [{ name: 'movieOnWatchlist', label: 'Film auf der Merkliste', type: 'Movie' }],
	},
	{
		type: 'MOVIES_SEEN',
		name: 'Film gesehen Filter',
		input: [
			{
				label: 'Filme',
				hint: 'Mindestens einer dieser Filme wurde gesehen',
				type: 'SELECT',
				multi: true,
				itemType: 'MOVIES',
			},
		],
		variables: [{ name: 'seenMovie', label: 'Gesehener Film', type: 'Movie' }],
	},
	{
		type: 'MOVIES_NOT_SEEN',
		name: 'Film nicht gesehen Filter',
		input: [
			{
				label: 'Filme',
				hint: 'Mindestens einer dieser Filme wurde nicht gesehen',
				type: 'SELECT',
				multi: true,
				itemType: 'MOVIES',
			},
		],
		variables: [{ name: 'notSeenMovie', label: 'Nicht gesehener Film', type: 'Movie' }],
	},
	{
		type: 'MOVIES_RATED_POSITIVELY',
		name: 'Film positiv bewertet Filter',
		input: [
			{
				label: 'Filme',
				hint: 'Mindestens einer dieser Filme wurde positive bewertet',
				type: 'SELECT',
				multi: true,
				itemType: 'MOVIES',
			},
		],
		variables: [{ name: 'positivelyRatedMovie', label: 'Positiv bewerteter Film', type: 'Movie' }],
	},
	{
		type: 'MOVIES_RATED_NEGATIVELY',
		name: 'Film negativ bewertet Filter',
		input: [
			{
				label: 'Filme',
				hint: 'Mindestens einer dieser Filme wurde negativ bewertet',
				type: 'SELECT',
				multi: true,
				itemType: 'MOVIES',
			},
		],
		variables: [{ name: 'negativelyRatedMovie', label: 'Negativ bewerteter Film', type: 'Movie' }],
	},
	{
		type: 'BONUS_POINTS_GEQ',
		name: 'Minimum Bonuspunkte Filter',
		input: [
			{
				label: 'Anzahl Bonuspunkte',
				hint: 'Mindestens dieser Bonuspunkte-Stand wurde bisher erreicht',
				type: 'INTEGER',
			},
		],
	},
	{
		type: 'BONUS_POINTS_LEQ',
		name: 'Maximum Bonuspunkte Filter',
		input: [
			{
				label: 'Anzahl Bonuspunkte',
				hint: 'Höchstens dieser Bonuspunkte-Stand wurde bisher erreicht',
				type: 'INTEGER',
			},
		],
	},
	{
		type: 'VISIT_FREQUENCY',
		name: 'Besuchshäufigkeit Filter',
		input: [
			{
				label: 'Anzahl Besuche',
				hint: 'Mindestens so oft wurde das Kino besucht',
				type: 'INTEGER',
				name: 'visits',
			},
			{
				label: 'Zeitraum in Tagen',
				hint: 'in den letzten X Tagen',
				type: 'INTEGER',
				name: 'intervalLengthDays',
			},
		],
	},
	{
		type: 'REFERENCED_MOVIE',
		name: '{referencedMovie} Filter',
		input: [
			{ label: 'Film bereits gesehen', type: 'BOOLEAN', name: 'seen' },
			{ label: 'Film noch nicht gesehen', type: 'BOOLEAN', name: 'seen', invertValue: true },
			{ label: 'Film positiv bewertet', type: 'BOOLEAN', name: 'ratedPositively' },
			{ label: 'Film negativ bewertet', type: 'BOOLEAN', name: 'ratedNegatively' },
			{ label: 'Film auf Merkliste', type: 'BOOLEAN', name: 'onWatchlist' },
		],
	},
];

export const variableTypeProps = {
	Movie: [
		{ label: 'Titel', name: 'title', inputLength: 20 },
		{ label: 'Start (Datum)', name: 'startDate', inputLength: 8 },
		{ label: 'Start (Wochentag)', name: 'startWeekDay', inputLength: 8 },
		{ label: 'Ende (Datum)', name: 'endDate', inputLength: 8 },
		{ label: 'Ende (Wochentag)', name: 'endWeekDay', inputLength: 8 },
	],
	User: [
		{ label: 'Name', name: 'name', inputLength: 7 },
		{ label: 'Ausgewähltes Kino', name: 'cinema', inputLength: 12 },
	],
};
