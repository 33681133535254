import * as React from 'react';
import { Box, Popper, IconButton } from '@mui/material';
import Info from '@mui/icons-material/Info';

import styledWeb from 'styled-components';

const StyledIconButton = styledWeb(IconButton)`
	padding: 0 1rem;
`;

const StyledPopper = styledWeb(Popper)`
	width: 44rem;
`;

const InfoPopper = ({ children }: { children?: string }): JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = React.useCallback(
		(event) => {
			setAnchorEl(anchorEl ? null : event.currentTarget);
		},
		[anchorEl]
	);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;

	const handleBackgroundColor = React.useCallback((props) => props.palette.common.white, []);

	return children ? (
		<>
			<StyledIconButton aria-label="delete" onClick={handleClick} size="small" color="primary">
				<Info />
			</StyledIconButton>
			<StyledPopper id={id} open={open} anchorEl={anchorEl}>
				<Box border={1} padding={1} bgcolor={handleBackgroundColor} fontSize="1.3rem">
					{children}
				</Box>
			</StyledPopper>
		</>
	) : (
		<></>
	);
};

export default React.memo(InfoPopper);
