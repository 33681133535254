import React from 'react';
import Box from '@mui/material/Box';
import { useWindowDimensions, usePxPerRemFactor } from '../utils/dimensions';

const FixedWrapper = ({
	children,
	bottomOffset = 0,
	width,
}: {
	children: JSX.Element;
	bottomOffset?: number;
	width?: number;
}): JSX.Element => {
	const pxPerRemFactor = usePxPerRemFactor();
	const { height: windowHeight } = useWindowDimensions();
	const windowHeightMinusBottomOffset = windowHeight - bottomOffset * pxPerRemFactor;

	return (
		<Box width={width}>
			<Box
				height={windowHeightMinusBottomOffset}
				position="fixed"
				// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
				sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
				width={width}
			>
				{children}
			</Box>
			{width ? null : (
				<Box visibility="hidden" height={windowHeightMinusBottomOffset} width={width}>
					{children}
				</Box>
			)}
		</Box>
	);
};

export default FixedWrapper;
