import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import type { ID } from '@cinuru/utils/types';

import {
	useAllTargetGroupsByB2BCustomers,
	createTargetGroup,
	deleteTargetGroups,
	createTargetGroupErrorDict,
} from '../../utils/targetGroup';
import { useCinemaIds } from '../../utils/cinema';
import { useUserPrivileges } from '../../utils/user';
import Dialog from '../../components/Dialog';
import TabTables from '../../components/TabTables';
import { Actions } from '../../components/Table';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import { IconName as ButtonIconName } from '../../components/Button';
import { IconName as IconButtonIconName } from '../../components/Button';
import { TargetGroup, updateTargetGroupsStatus } from '../../utils/targetGroup';

const columns = [
	{
		id: 1,
		label: 'Zielgruppe',
	},
	{
		id: 2,
		label: 'Zuletzt bearbeitet',
	},
	{
		id: 3,
		label: 'Status',
	},
	{
		id: 4,
		label: 'Nutzer',
		toolTip:
			'Die Anzahl der Nutzer wird nur zum Zeipunkt des Erstellens oder Editierens einer Zielgruppe berechnet. Um den aktuellen Wert zu erhalten, muss die Zielgruppe daher erneut gespeichert werden.',
	},
	{
		id: 5,
		label: 'Details',
	},
];

const statusDict = {
	NOT_IN_USE: 'Nicht verwendet',
	IN_USE: 'Verwendet',
	ARCHIVED: 'Archiviert',
};

type ActionType = 'DELETE' | 'ARCHIVE';

const tabFilters = [
	{ label: 'Alle', filterFunction: () => true },
	{ label: 'Verwendet', filterFunction: (rowItem) => rowItem.rawData.status === 'IN_USE' },
	{
		label: 'Nicht verwendet',
		filterFunction: (rowItem) => rowItem.rawData.status === 'NOT_IN_USE',
	},
	{ label: 'Archiviert', filterFunction: (rowItem) => rowItem.rawData.status === 'ARCHIVED' },
] as {
	label: string;
	filterFunction: (rowItem) => boolean;
}[];

const actions: Actions<ActionType> = {
	allActions: [
		{
			label: 'Löschen',
			type: 'DELETE',
		},
		{
			label: 'Archivieren',
			type: 'ARCHIVE',
		},
	],
	getPossibleActionTypes: (selectedRowIds, rowItems) => {
		const getActions = (itemStatus?: TargetGroup['status']): ActionType[] => {
			switch (itemStatus) {
				case 'IN_USE':
					return [];
				case 'NOT_IN_USE':
					return ['ARCHIVE', 'DELETE'];
				case 'ARCHIVED':
					return ['DELETE'];
				default:
					return [];
			}
		};

		const possibleItemActions = selectedRowIds?.map((rId) => {
			return getActions(rowItems?.find((rowItem) => rowItem.id === rId)?.rawData.status);
		});

		if (possibleItemActions?.length) {
			const result = possibleItemActions.reduce((acc: ActionType[], next: ActionType[]) => {
				const overlap: ActionType[] = [];
				acc.forEach((status) =>
					next.includes(status) ? overlap.push(status as ActionType) : null
				);
				return overlap;
			});
			return result;
		} else {
			return [];
		}
	},
};

const TargetGroups = (): JSX.Element | null => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = React.useState(false);

	const cinemaIds = useCinemaIds();
	const privileges = useUserPrivileges();
	const b2bCustomerCinemaIds = React.useMemo(
		() => privileges?.belongsToB2BCustomers?.map(({ id }) => id),
		[privileges?.belongsToB2BCustomers]
	);
	const targetGroups = useAllTargetGroupsByB2BCustomers(b2bCustomerCinemaIds);

	const handleDeleteTargetGroups = React.useCallback(
		async (ids) => {
			setLoading(true);
			const { success, error } = await deleteTargetGroups(ids);
			setLoading(false);
			if (success) {
				enqueueSnackbar('Gelöscht', { variant: 'success' });
			} else {
				enqueueSnackbar(
					createTargetGroupErrorDict[error] || createTargetGroupErrorDict['UNEXPECTED_ERROR'],
					{ variant: 'error' }
				);
			}
		},
		[enqueueSnackbar]
	);

	const handleUpdateTargetGroupsStatus = React.useCallback(
		async (ids: ID[], newStatus: TargetGroup['status']) => {
			setLoading(true);
			const { success } = await updateTargetGroupsStatus(ids, newStatus);
			setLoading(false);
			if (success) {
				enqueueSnackbar(newStatus === 'ARCHIVED' ? 'Archiviert' : 'Erfolg', { variant: 'success' });
			} else {
				enqueueSnackbar('Fehler', { variant: 'error' });
			}
		},
		[enqueueSnackbar]
	);

	const handleAction = React.useCallback(
		async (actionType: ActionType, ids: ID[]) => {
			console.log('(actionType: ActionType, ids: ID[]): ', actionType, ids);
			if (actionType === 'DELETE') {
				Dialog.render({
					title: 'Wirklich löschen',
					description: `Willst du diese ${
						ids.length === 1 ? 'Zielgruppe' : `${ids.length} Zielgruppen`
					} wirklich löschen?`,
					buttons: [
						{ id: '1', label: 'Ja', onClick: () => handleDeleteTargetGroups(ids) },
						{ id: '2', label: 'Abbrechen' },
					],
				});
			} else if (actionType === 'ARCHIVE') {
				handleUpdateTargetGroupsStatus(ids, 'ARCHIVED');
			}
		},
		[handleDeleteTargetGroups, handleUpdateTargetGroupsStatus]
	);

	const handleCreateTargetGroup = React.useCallback(async () => {
		setLoading(true);
		const { success, targetGroupId, error } = await createTargetGroup(cinemaIds!);
		setLoading(false);
		if (success) {
			enqueueSnackbar('Zielgruppe erstellt', { variant: 'success' });
			history.push(`/marketing/targetgroups/${targetGroupId}/edit`);
		} else {
			enqueueSnackbar(
				createTargetGroupErrorDict[error] || createTargetGroupErrorDict['UNEXPECTED_ERROR'],
				{ variant: 'error' }
			);
		}
	}, [cinemaIds, history, enqueueSnackbar]);

	const rows = React.useMemo(
		() =>
			targetGroups?.map((targetGroup) => {
				const { id, name, lastEditedDatetime, targetGroupSize, status } = targetGroup;
				return {
					id,
					data: [
						{ text: name },
						{ text: `${lastEditedDatetime}` },
						{ text: statusDict[status] },
						{ text: targetGroupSize?.toString() },
						{
							buttonLabel: 'Bearbeiten',
							onPress: () => history.push(`/marketing/targetgroups/${id}/edit`),
						},
					],
					rawData: targetGroup,
				};
			}),
		[history, targetGroups]
	);

	const buttons = React.useMemo(
		() => [
			{
				label: 'Zielgruppe erstellen',
				onClick: handleCreateTargetGroup,
				startIconName: 'Add' as ButtonIconName,
				loading,
				loadingText: 'Aktivieren...',
				collapsedIconName: 'Add' as IconButtonIconName,
			},
		],
		[handleCreateTargetGroup, loading]
	);

	const initialLoading = !cinemaIds || !rows;
	const isLoading = initialLoading || loading;

	return (
		<StickyHeaderWrapper label="Zielgruppen" buttons={buttons} isLoading={isLoading}>
			{initialLoading ? null : (
				<TabTables
					columns={columns}
					tabFilters={tabFilters}
					rows={rows}
					onHandleAction={handleAction}
					label="Zielgruppe"
					actions={actions}
				/>
			)}
		</StickyHeaderWrapper>
	);
};

export default TargetGroups;
