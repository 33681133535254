import React, { Component } from 'react';
import { get, set, omit } from 'lodash';
import { message } from '../../components/AntDReplacements';

import { Paragraph } from '@cinuru/components';

import { TextAreaSection } from './TextAreaSection';
import { AdressSection } from './AdressSection';
import { ContactsSection } from './ContactsSection';
import { HeaderImageSection } from './HeaderImageSection';
import { OpeningHoursSection } from './OpeningHoursSection';
import { SneakPreviewSection } from './SneakPreviewSection';
import { PricesSection } from './PricesSection';
import { GiftVouchersSection } from './GiftVouchersSection';
import { SpecialOffersSection } from './SpecialOffersSection';
import { AccessibilitySection } from './AccessibilitySection';
import { HeaderSection } from './HeaderSection';
import { AccessRestrictionSection } from './AccessRestrictionSection';

export class EditCinemaForm extends Component {
	constructor(props) {
		super(props);
		const cinema = { ...props.initialData };
		cinema.address = { ...cinema.address };
		cinema.address.coordinates = cinema.address.coordinates || {};
		cinema.contact = cinema.contact || {};
		cinema.openingHours = cinema.openingHours || [];
		cinema.sneaks = cinema.sneaks || [];
		cinema.prices = cinema.prices || [];
		cinema.giftVouchers = cinema.giftVouchers || [];
		cinema.specialOffers = cinema.specialOffers || [];
		cinema.accessibility = cinema.accessibility || {};
		cinema.currentInformation = cinema.currentInformation || '';
		this.state = { cinema };
	}

	updateCinemaProperty = (path, data) => {
		//Updates a property of the state.cinema.
		//Works for nested paths: e.g. updateCinemaProperty(['address','street'], "Neue Straße")
		//changes state.cinema.adress.street
		const cinema = { ...this.state.cinema };
		set(cinema, path, data);
		this.setState({ ...this.state, cinema });
	};
	addCinemaPropertyElement = (path, data) => {
		// Adds a new element to a cinema property array (e.g. to cinema.prices)
		const cinema = { ...this.state.cinema };
		const cinemaProperty = [...get(cinema, path), data];
		set(cinema, path, cinemaProperty);
		this.setState({ ...this.state, cinema });
	};
	removeCinemaPropertyElement = (path, key) => {
		// Removes an element from a cinema property array (e.g. from cinema.prices)
		const cinema = { ...this.state.cinema };
		const cinemaProperty = [...get(cinema, path)];
		cinemaProperty.splice(key, 1);
		set(cinema, path, cinemaProperty);
		this.setState({ ...this.state, cinema });
	};

	save = async () => {
		const stateData = { ...this.state.cinema };
		//Filter out the wrong __typenames (incoming is e.g. openingHoursDisplay, outgoing would have to be openingHoursDisplayInput)
		stateData.openingHoursDisplay = stateData.openingHoursDisplay.map((o) => omit(o, '__typename'));
		stateData.sneaksDisplay = stateData.sneaksDisplay.map((o) => omit(o, '__typename'));
		stateData.pricesDisplay = stateData.pricesDisplay.map((o) => omit(o, '__typename'));
		stateData.specialOffersDisplay = stateData.specialOffersDisplay.map((o) =>
			omit(o, '__typename')
		);
		stateData.giftVouchersDisplay = stateData.giftVouchersDisplay.map((o) => omit(o, '__typename'));
		try {
			const { data, error } = await this.props.saveCinema({ variables: stateData });
			if (!error && data.updateCinema.success) {
				message.success('Gespeichert.');
			} else {
				message.error('Fehler beim speichern.');
			}
		} catch (e) {
			console.log(e);
			message.error('Fehler beim speichern.');
		}
	};
	render = () => {
		const { cinema } = this.state;
		return (
			<Paragraph>
				<HeaderSection
					cinema={cinema}
					updateProperty={this.updateCinemaProperty}
					save={this.save}
				/>
				<AccessRestrictionSection
					selectedAccessRestrictedTo={cinema.accessRestrictedTo}
					update={(value) => this.updateCinemaProperty('accessRestrictedTo', value)}
				/>
				<TextAreaSection
					sectionName="Aktuelles"
					value={cinema.currentInformation}
					update={(value) => this.updateCinemaProperty('currentInformation', value)}
				/>
				<TextAreaSection
					sectionName="Über uns"
					value={cinema.specialAboutUs}
					update={(value) => this.updateCinemaProperty('specialAboutUs', value)}
				/>
				<TextAreaSection
					sectionName="Unsere Technik"
					value={cinema.technologies}
					update={(value) => this.updateCinemaProperty('technologies', value)}
				/>
				<TextAreaSection
					sectionName="Unsere Geschichte"
					value={cinema.history}
					update={(value) => this.updateCinemaProperty('history', value)}
				/>
				<TextAreaSection
					sectionName="Online Ticketing Basis URL"
					value={cinema.onlineTicketingBaseUrl}
					update={(value) => this.updateCinemaProperty('onlineTicketingBaseUrl', value)}
				/>
				<TextAreaSection
					sectionName="Impressum"
					value={cinema.imprint}
					update={(value) => this.updateCinemaProperty('imprint', value)}
				/>
				<hr />
				<AdressSection cinema={cinema} updateAddress={this.updateCinemaProperty} />
				<hr />
				<ContactsSection cinema={cinema} updateContact={this.updateCinemaProperty} />
				<hr />
				<HeaderImageSection
					headerImage={cinema.headerImage}
					updateImage={(url) => this.updateCinemaProperty('headerImage', url)}
				/>
				<hr />
				<OpeningHoursSection
					openingHours={cinema.openingHoursDisplay}
					updateOpeningHours={(path, val) =>
						this.updateCinemaProperty(['openingHoursDisplay', ...path], val)
					}
					addOpeningHours={(val) => this.addCinemaPropertyElement('openingHoursDisplay', val)}
					deleteOpeningHours={(key) => this.removeCinemaPropertyElement('openingHoursDisplay', key)}
				/>
				<hr />
				<SneakPreviewSection
					sneaks={cinema.sneaksDisplay}
					updateSneak={(path, val) => this.updateCinemaProperty(['sneaksDisplay', ...path], val)}
					addSneak={(val) => this.addCinemaPropertyElement('sneaksDisplay', val)}
					removeSneak={(key) => this.removeCinemaPropertyElement('sneaksDisplay', key)}
				/>
				<hr />
				<PricesSection
					prices={cinema.pricesDisplay}
					updatePrice={(idx, key, val) =>
						this.updateCinemaProperty(['pricesDisplay', idx, key], val)
					}
					addPrice={(val) => this.addCinemaPropertyElement('pricesDisplay', val)}
					removePrice={(key) => this.removeCinemaPropertyElement('pricesDisplay', key)}
				/>
				<hr />
				<GiftVouchersSection
					giftVouchers={cinema.giftVouchersDisplay}
					updateGiftVoucher={(idx, key, val) =>
						this.updateCinemaProperty(['giftVouchersDisplay', idx, key], val)
					}
					addGiftVoucher={(val) => this.addCinemaPropertyElement('giftVouchersDisplay', val)}
					removeGiftVoucher={(key) => this.removeCinemaPropertyElement('giftVouchersDisplay', key)}
				/>
				<hr />
				<SpecialOffersSection
					specialOffers={cinema.specialOffersDisplay}
					updateSpecialOffer={(idx, key, val) =>
						this.updateCinemaProperty(['specialOffersDisplay', idx, key], val)
					}
					addSpecialOffer={(val) => this.addCinemaPropertyElement('specialOffersDisplay', val)}
					removeSpecialOffer={(key) =>
						this.removeCinemaPropertyElement('specialOffersDisplay', key)
					}
				/>
				<hr />
				<AccessibilitySection cinema={cinema} updateAccessibility={this.updateCinemaProperty} />
			</Paragraph>
		);
	};
}
