import React, { useMemo, Ref } from 'react';
import styled, { useTheme } from 'styled-components';
import { Box } from '@mui/material';
import { Brand } from '@cinuru/utils/theme';

import RecommendationsPreview from './ContentTypes/Recommendations';
import ImagePreview from './ContentTypes/Image';
import FilmSeriesPreview from './ContentTypes/FilmSeries';
import FooterPreview from './ContentTypes/Footer';
import HeaderPreview from './ContentTypes/Header';
import ButtonPreview from './ContentTypes/Button';
import MarkdownTextPreview from './ContentTypes/MarkdownText';
import MarkdownTextWithImagePreview from './ContentTypes/MarkdownTextWithImage';
import ProgramPreview from './ContentTypes/Program';
import QRCodePreview from './ContentTypes/QRCode';
import SpacerPreview from './ContentTypes/Spacer';
import { Campaign } from '../../utils/campaign';
import { hexToRgba } from '../../utils/color';
import {
	EmailElement,
	FilmSeriesData,
	MarkdownTextWithImageData,
	ProgramData,
	RecommendationsData,
} from '../../utils/campaignEmailContent';

import IconButton from '../../components/IconButton';
import { useHoverInfo } from '../../utils/dimensions';

const DroppedCardWrapper = styled(Box)`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const EditBar = styled(Box)<{ $hovered: boolean; $small?: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	background-color: ${(p) =>
		p.$hovered ? hexToRgba(p.theme.customColors.accentBlue, 0.1) : 'none'};
	height: ${(p) => (p.$hovered ? '100%' : 0)};
	width: 100%;
	position: ${(p) => (p.$hovered && p.$small ? 'relative' : 'absolute')};
	overflow: visible;
	visibility: ${(p) => (p.$hovered ? 'visible' : 'hidden')};
	z-index: 100;
`;

const HoverWrapper = styled(Box)<{ $left: number; $top: number }>`
	position: fixed;
	left: ${(p) => p.$left}px;
	top: ${(p) => p.$top}px;
	transform: translate(-50%, -50%);
`;

export const DroppedCard = ({
	element,
	onEdit,
	index,
	onDragAndDrop,
	brand,
	isPreview,
	campaign,
	elements,
	mobileView,
}: {
	element: EmailElement;
	onEdit?: () => void;
	index: string;
	onDragAndDrop?: (val: boolean) => void;
	brand: Brand;
	isPreview?: boolean;
	campaign: Campaign;
	elements: EmailElement[];
	mobileView?: boolean;
}): JSX.Element => {
	const theme = useTheme();

	const handleDragStart = React.useCallback(
		(event) => {
			event.dataTransfer.setData(
				'text',
				JSON.stringify({ action: 'REORDER', currentIndex: index, key: element.key })
			);

			onDragAndDrop?.(true);
		},
		[element.key, index, onDragAndDrop]
	);
	const handleDragEnd = React.useCallback(() => {
		onDragAndDrop?.(false);
	}, [onDragAndDrop]);

	const { ref: hoverRef, isHovered, position } = useHoverInfo();

	const mobileViewElement = useMemo(() => ({ ...element, mobileView } as EmailElement), [
		element,
		mobileView,
	]);

	const innerComponent = useMemo(() => {
		switch (element.type) {
			case 'RECOMMENDATIONS':
				return (
					<RecommendationsPreview
						element={mobileViewElement as RecommendationsData}
						id={index}
						brand={brand}
						elements={elements}
						campaign={campaign}
					/>
				);
			case 'HEADER':
				return <HeaderPreview element={element} id={index} brand={brand} />;
			case 'FOOTER':
				return <FooterPreview element={element} id={index} brand={brand} />;
			case 'PROGRAM':
				return (
					<ProgramPreview
						element={mobileViewElement as ProgramData}
						id={index}
						brand={brand}
						campaign={campaign}
						elements={elements}
					/>
				);
			case 'SERIES':
				return (
					<FilmSeriesPreview
						element={mobileViewElement as FilmSeriesData}
						id={index}
						brand={brand}
						campaign={campaign}
						elements={elements}
					/>
				);
			case 'IMAGE':
				return <ImagePreview element={element} id={index} brand={brand} />;
			case 'SPACER':
				return <SpacerPreview element={element} id={index} brand={brand} />;
			case 'TEXT':
				return <MarkdownTextPreview element={element} id={index} brand={brand} />;
			case 'TEXTWITHIMAGE':
				return (
					<MarkdownTextWithImagePreview
						element={mobileViewElement as MarkdownTextWithImageData}
						id={index}
						brand={brand}
					/>
				);
			case 'BUTTON':
				return <ButtonPreview element={element} id={index} brand={brand} />;
			case 'QRCODE':
				return <QRCodePreview element={element} id={index} brand={brand} />;
			default:
				return null;
		}
	}, [element, mobileViewElement, index, brand, elements, campaign]);

	return (
		<DroppedCardWrapper
			draggable
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
			ref={hoverRef as Ref<unknown> | undefined}
		>
			{!isPreview ? (
				<EditBar
					$hovered={isHovered as boolean}
					$small={Boolean(
						element.type === 'SPACER' &&
							element.height &&
							Number(element.height?.split('px')[0]) < 40
					)}
				>
					<HoverWrapper $left={position.clientX} $top={position.clientY}>
						<IconButton
							iconName="EditRounded"
							color={theme.customColors.accentBlue}
							onClick={onEdit}
						/>
					</HoverWrapper>
				</EditBar>
			) : null}
			{innerComponent}
		</DroppedCardWrapper>
	);
};

export default DroppedCard;
