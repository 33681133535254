import React from 'react';
import { Box } from '@mui/material';
import { Brand } from '@cinuru/utils/theme';
import { SpacerProps } from '@cinuru/emails/src/components/DashboardEmailComponents/Spacer';

import ApiRenderedComponent from './ApiRenderedComponent';
import { ColorController } from '../utils/allFormControlComponents';
import { stripValueFromDimension, transformElement } from '../utils/emailConfigurationHelpers';
import { Wrapper } from '../utils/reusableStyledComponents';
import { SpacerData } from '../../../utils/campaignEmailContent';
import SizeController from './FormControls/SizeController';

const SpacerPreview = ({
	id,
	element,
	brand,
}: {
	id: string;
	element: SpacerData;
	brand: Brand;
}) => {
	const componentProps: SpacerProps = React.useMemo(
		() => ({
			...element,
			height: element.height ? `${stripValueFromDimension(element.height)}px` : undefined,
		}),
		[element]
	);

	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="Spacer"
			componentProps={componentProps}
		/>
	);
};

export const SpacerEditor = React.memo(
	({
		element,
		updateElement,
		brand,
		spacerHeight,
	}: {
		element: SpacerData;
		updateElement: (spacerData) => void;
		brand: Brand;
		spacerHeight?: string;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement(newElement as SpacerData);
			},
			[element, updateElement]
		);

		return (
			<Wrapper>
				<Wrapper className="wrapper">
					<SizeController
						type="HEIGHT"
						value={element?.height}
						onChange={handleChangeController}
						path="height"
						label="Höhe"
					/>
					<Box height={spacerHeight} />
					<ColorController
						value={element.backgroundColor}
						brand={brand}
						onChange={handleChangeController}
						path="backgroundColor"
						label="HintegrundFarbe"
						fallBackColor="background0"
					/>
				</Wrapper>
			</Wrapper>
		);
	}
);

export const SpacerDefaultParams: SpacerData = {
	type: 'SPACER',
	backgroundColor: '#00000000',
	index: 0,
	borderRadiusWrapper: { top: true, bottom: true },
};

export default React.memo(SpacerPreview);
