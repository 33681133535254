/* eslint-disable react-native/no-inline-styles */
// @flow
import * as React from 'react';
import styled, { withTheme } from 'styled-native-components';
import { Text, Platform, Linking } from 'react-native';

import parseMarkdown from './parser';

import WrappedImage from '../WrappedImage';
import Heading from '../Heading';
import Paragraph from '../Paragraph';

const Wrapper = styled.View`
	width: 100%;
	padding-top: 2rem;
	align-items: stretch;
`;

export const ParagraphWrapper = styled.View`
	padding: 0 ${(p) => p.sidePadding};
	margin-bottom: 1.5rem;
	margin-top: ${(p) => p.extraTopMargin};
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: ${(p) => (p.center ? 'center' : 'flex-start')};
	${(p) => (p.stretch ? 'width: 100%' : '')}
	${(p) =>
		p.size ? `font-size: ${p.theme.typography.paragraph(p.size).fontSize * p.theme.rem}px;` : ''}
`;
ParagraphWrapper.defaultProps = { extraTopMargin: '0', sidePadding: '0' };

export const BodyParagraph = ({ center, sidePadding, color, size, children }) => (
	<ParagraphWrapper center={center} sidePadding={sidePadding} size={size}>
		{children}
	</ParagraphWrapper>
);

export const SubHeadingParagraph = ({ center, sidePadding, children, size }) => (
	<ParagraphWrapper center={center} sidePadding={sidePadding} size={size}>
		{children}
	</ParagraphWrapper>
);

export const HeadingParagraph = ({ center, sidePadding, children, size }) => (
	<ParagraphWrapper center={center} sidePadding={sidePadding} size={size}>
		{children}
	</ParagraphWrapper>
);

const QuoteWrapper = styled(ParagraphWrapper)`
	border-color: $neutral3;
	border-left-width: 0.25rem;
	padding-left: 0.75rem;
`;

export const QuoteParagraph = ({ center, sidePadding, color, size, children }) => (
	<QuoteWrapper center={center} sidePadding={sidePadding}>
		<Paragraph color={color ? color : '$neutral2'} align={center && 'center'} size={size}>
			{children}
		</Paragraph>
	</QuoteWrapper>
);
const LineBreak = styled.View`
	width: 100%;
	height: 0px;
`;

export const ListWrapper = styled(ParagraphWrapper)`
	margin-left: 3rem;
	flex-direction: column;
	align-items: stretch;
`;

const { Provider: LiNumProvider, Consumer: LiNumConsumer } = React.createContext();
class LiNum {
	val = 0;
	next = () => ++this.val;
}

export const ListParagraph = ({ center, sidePadding, children }) => {
	return (
		<LiNumProvider value={new LiNum()}>
			<ListWrapper center={center} sidePadding={sidePadding}>
				{children}
			</ListWrapper>
		</LiNumProvider>
	);
};

const ListItemWrapper = styled.View`
	justify-content: ${(p) => (p.center ? 'center' : 'flex-start')};
`;

const ListIndicator = styled.View`
	position: absolute;
	left: ${(p) => -(p.bullet ? 2 : 3.5)}rem;
	width: ${(p) => (p.bullet ? 2 : 3.5)}rem;
	padding-right: 0.5rem;
`;

export const BulletListItem = ({ children, color, size, center }) => (
	<ListItemWrapper center={center}>
		<ListIndicator bullet>
			<Paragraph color={color} size={size}>
				•
			</Paragraph>
		</ListIndicator>
		<Paragraph color={color} size={size} align={center && 'center'}>
			{children}
		</Paragraph>
	</ListItemWrapper>
);

export const NumberedListItem = ({ children, color, size, center }) => (
	<ListItemWrapper center={center}>
		<ListIndicator>
			<Paragraph color={color} size={size} align="right">
				<LiNumConsumer>{(liNum) => `${liNum.next()}.`}</LiNumConsumer>
			</Paragraph>
		</ListIndicator>
		<Paragraph color={color} size={size} align={center && 'center'}>
			{children}
		</Paragraph>
	</ListItemWrapper>
);

const ImageWrapper = styled(ParagraphWrapper)`
	width: 100%;
	height: ${(p) => p.height || 30 * p.theme.rem}px;
	margin: 0 ${(p) => -(p.negativeMargin || 0)}rem;
	margin-top: 1.5rem;
	justify-content: flex-end;
`;

const Image = styled(WrappedImage)`
	position: absolute;
	width: 100%;
	height: 100%;
`;

const CaptionWrapper = styled.View`
	padding: 1rem 2rem;
	background-color: $overlayBackground;
`;
const LinkWrapper = styled.Text`
	text-decoration-line: underline;
	${(p) => (p.center ? `textAlign: center` : '')}
`;

export class ImageParagraph extends React.PureComponent<
	{ src: string, caption?: string, center?: boolean },
	{ width?: number, height?: number, aspectRatio?: number }
> {
	// measure width and resize image on load to have height auto
	layoutWidth = null;
	state = { width: null, height: null, aspectRatio: null };
	measureWidth = ({ nativeEvent: { layout } }) => {
		this.setState(({ aspectRatio }) => ({
			layoutWidth: layout.width,
			height: aspectRatio && layout.width / aspectRatio,
		}));
	};
	handleResize = ({ nativeEvent: { width, height } }) => {
		const aspectRatio = width / height;
		this.setState({ height: this.state.layoutWidth / aspectRatio, aspectRatio });
	};

	render = () => {
		const { src, caption, center, size } = this.props;
		return (
			<ImageWrapper onLayout={this.measureWidth} height={this.state.height}>
				<Image src={src} onLoad={this.handleResize} />
				{caption ? (
					<CaptionWrapper>
						<Paragraph size="s" align={center && 'center'} color="$overlayText">
							{caption}
						</Paragraph>
					</CaptionWrapper>
				) : null}
			</ImageWrapper>
		);
	};
}

const Border = styled.View`
	width: 100%;
	border-color: ${(p) => p.color || '$neutral0'};
	border-bottom-width: 1px;
	margin: 0 0 1.5rem;
`;

export const BorderParagraph = ({ color }: { color?: string }) => (
	<ParagraphWrapper extraTopMargin="1.5rem" stretch>
		<Border color={color} />
	</ParagraphWrapper>
);

export type Props = {
	children?: string,
	text?: string,
	markdown?: boolean,
	center?: boolean,
	sidePadding?: string,
	color?: string,
	onLinkPress?: (link: string) => void,
	size?: string,
};
class Article extends React.PureComponent<Props> {
	static defaultProps = { sidePadding: '2rem' };

	handleLinkPress = (link, e) => {
		// if function is provided use it
		if (this.props.onLinkPress) this.props.onLinkPress(link, e);
		else {
			// when relative link is provided and push function was added to global history object, use it
			if (window && window.history && window.history.push && !link.includes('http') && !e.metaKey) {
				e.preventDefault();
				window.history.push(link);
			}
			// if on native use linking module
			if (Platform.OS !== 'web') {
				// eslint-disable-next-line promise/prefer-await-to-then
				Linking.canOpenURL(link).then((supported) => supported && Linking.openURL(link));
			}
		}
	};

	renderMarkdownString = () => {
		const { center, size, sidePadding, color } = this.props;
		const string = this.props.children || this.props.text;
		// recursive rendering function for markdown syntax tree
		const renderMdNodes = (mdNodes, strong, em, h1, h2) =>
			mdNodes?.map(({ type, children, ...content }, i) => {
				const textStyles = {};
				if (strong) {
					Object.assign(textStyles, this.props.theme.typography.bold);
				}
				if (em) {
					Object.assign(textStyles, this.props.theme.typography.italic);
				}
				if (h1) {
					Object.assign(textStyles, this.props.theme.typography.heading('m'));
					textStyles.fontSize =
						this.props.theme.typography.heading('m').fontSize * this.props.theme.rem;
					textStyles.lineHeight =
						this.props.theme.typography.heading('m').lineHeight * this.props.theme.rem;

					delete textStyles.css;
				}
				if (h2) {
					Object.assign(textStyles, this.props.theme.typography.heading('s'));
					textStyles.fontSize =
						this.props.theme.typography.heading('s').fontSize * this.props.theme.rem;
					textStyles.lineHeight =
						this.props.theme.typography.heading('s').lineHeight * this.props.theme.rem;

					delete textStyles.css;
				}
				switch (type) {
					case 'text': {
						const words = content.text.split(' ');
						return (
							<React.Fragment key={i}>
								{words?.map((text, j) => (
									<Paragraph
										key={j}
										color={color}
										style={textStyles}
										align={center && 'center'}
										size={size}
									>
										<Text key={j} style={textStyles}>
											{text + (words.length === j + 1 ? '' : ' ')}
										</Text>
									</Paragraph>
								))}
							</React.Fragment>
						);
					}
					case 'code':
						//ignore code block formatting
						return renderMdNodes(children, strong, em, h1, h2);

					case 'strong':
						return renderMdNodes(children, true, em, h1, h2);

					case 'em':
						return renderMdNodes(children, strong, true, h1, h2);
					case 'link':
						return (
							<LinkWrapper
								key={i}
								// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
								onPress={(e) => this.handleLinkPress(content.href, e)}
								href={content.href}
								accessibilityRole="link"
								center={center}
							>
								{renderMdNodes(children, strong, em, h1, h2)}
							</LinkWrapper>
						);
					case 'hr': {
						return <BorderParagraph key={i} />;
					}
					case 'br': {
						return <LineBreak key={i} />;
					}
					case 'paragraph':
						return (
							<BodyParagraph
								key={i}
								center={center}
								sidePadding={sidePadding}
								color={color}
								size={size}
							>
								{renderMdNodes(children, strong, em, h1, h2)}
							</BodyParagraph>
						);
					case 'heading':
						// eslint-disable-next-line no-console
						if (content.depth > 2) console.warn('markdown parser only supports h1 and h2');
						return (
							<HeadingParagraph key={i} align={center && 'center'} sidePadding={sidePadding}>
								{renderMdNodes(children, strong, em, content.depth === 1, content.depth !== 1)}
							</HeadingParagraph>
						);
					case 'blockquote':
						return (
							<QuoteParagraph
								key={i}
								center={center}
								sidePadding={sidePadding}
								color={color}
								size={size}
							>
								{renderMdNodes(children, strong, em, h1, h2)}
							</QuoteParagraph>
						);
					case 'list':
						return (
							<ListParagraph key={i} center={center} sidePadding={sidePadding} size={size}>
								{renderMdNodes(children, strong, em, h1, h2)}
							</ListParagraph>
						);
					case 'list_item':
						return (
							<BulletListItem key={i} center={center} color={color} size={size}>
								{renderMdNodes(children, strong, em, h1, h2)}
							</BulletListItem>
						);
					case 'space':
						return null;
					case 'image':
						return (
							<ImageParagraph
								key={i}
								src={content.href}
								caption={content.text}
								center={center}
								size={size}
							/>
						);
					case 'image_block':
						return (
							<ImageParagraph
								key={i}
								src={content.href}
								caption={content.text}
								center={center}
								size={size}
							/>
						);
					default:
						return <Text key={i}>{JSON.stringify({ type, children, ...content }, null, 4)}</Text>;
				}
			});

		const markdownNodes = parseMarkdown(string);
		return renderMdNodes(markdownNodes, false, false, false, false);
	};

	render = () => {
		const { children, text, markdown, center, sidePadding, color, size, style } = this.props;
		return children || text ? (
			<Wrapper style={style}>
				{markdown ? (
					this.renderMarkdownString()
				) : (
					<ParagraphWrapper sidePadding={sidePadding}>
						<Paragraph color={color} align={center && 'center'} size={size}>
							{children || text}
						</Paragraph>
					</ParagraphWrapper>
				)}
			</Wrapper>
		) : null;
	};
}

export default withTheme(Article);
