/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-native-components';
import { useTheme } from 'styled-native-components';
import chroma from 'chroma-js';

const data2 = [
	{
		'id': 'Level 0',
		'Vor einer Woche': 33021,
		'Aktuell': 36001,
		'Vor einem Monat': 28021,
	},
	{
		'id': 'Level 1',
		'Vor einer Woche': 254123,
		'Aktuell': 264123,
		'Vor einem Monat': 224123,
	},
	{
		'id': 'Level 2',
		'Vor einer Woche': 114123,
		'Aktuell': 164123,
		'Vor einem Monat': 134123,
	},
	{
		'id': 'Level 3',
		'Aktuell': 104123,
		'Vor einer Woche': 101123,
		'Vor einem Monat': 70123,
	},
	{
		'id': 'Level 4',
		'Aktuell': 70123,
		'Vor einer Woche': 68111,
		'Vor einem Monat': 58123,
	},
	{
		'id': 'Level 5',
		'Aktuell': 33021,
		'Vor einer Woche': 29021,

		'Vor einem Monat': 15021,
	},
];
const Wrapper = styled.View`
	height: 100%;
	width: 100%;
`;
export const StatusLevelBarChart = () => {
	const theme = useTheme();
	const colorscale = chroma.scale(theme.colors.accentGradient0).colors(4);
	return (
		<Wrapper>
			<ResponsiveBar
				data={data2}
				groupMode="grouped"
				keys={['Vor einem Monat', 'Vor einer Woche', 'Aktuell']}
				margin={{ top: 10, right: 5, bottom: 50, left: 55 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={colorscale}
				borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 0,
					tickPadding: 0,
					tickRotation: 0,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Nutzer',
					legendPosition: 'middle',
					legendOffset: -45,
					format: (value) => `${Math.round(Number(value) / 1000).toLocaleString('de-DE')}k`,
					tickValues: 5,
				}}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom',
						direction: 'row',
						justify: false,
						translateY: 40,
						itemsSpacing: 2,
						itemWidth: 110,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 10,
					},
				]}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				animate={true}
				motionStiffness={90}
				motionDamping={15}
			/>
		</Wrapper>
	);
};
