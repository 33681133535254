import React from 'react';
import { Input } from '../../components/AntDReplacements';

const TextArea = Input.TextArea;

const styles = {
	wrapper: {
		marginTop: '20px',
		marginBottom: '40px',
	},
};

export const TextAreaSection = ({ sectionName, value, update }) => (
	<div style={styles.wrapper}>
		{sectionName}
		<TextArea value={value} autosize onChange={(event) => update(event.target.value)} />
	</div>
);
