import { isArray, isEqual } from 'lodash';
import Fuse from 'fuse.js';

const deaultOptions = {
	shouldSort: true,
	threshold: 0.3,
	location: 0,
	tokenize: true,
	matchAllTokens: true,
	findAllMatches: true,
	distance: 20,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	includeScore: true,
};

// this caches the last list of items for searches, until called with different items
const cache: {
	fuse?: Fuse<unknown, Fuse.IFuseOptions<unknown>>;
	items?: unknown[];
	options?: unknown;
} = {};

/**
 * execute a fuzzy search on an array of objects on specific keys of those objects
 * using fuse.js
 *
 * @param items - the list of items to search through
 * @param query - the search query string
 * @param keys - the names of the key(s) of each item to search through
 * @param options - further options, see fuse.js documentation for more info
 * @returns an array of objects of items that match the search and their scores
 */

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export default function fuzzySearch<D>(
	items: D[],
	query: string,
	keys: string | string[],
	options?: { [prop: string]: boolean | number },
	noDefaultOptions?: boolean
): { item: D; score?: number }[] {
	const mergedOptions = noDefaultOptions
		? { keys: isArray(keys) ? keys : [keys], ...options }
		: { keys: isArray(keys) ? keys : [keys], ...deaultOptions, ...options };
	if (!cache.fuse || cache.items !== items || !isEqual(cache.options, mergedOptions)) {
		cache.items = items;
		cache.options = mergedOptions;
		cache.fuse = new Fuse(items, mergedOptions);
	}
	return cache.fuse.search(query.trim());
}
