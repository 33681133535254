import * as React from 'react';
import styled from 'styled-native-components';

import { Icon, Paragraph } from '@cinuru/components';

const Wrapper = styled.View`
	background-color: $background1;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	margin: 1rem -0.5rem;
	padding: 1rem;
	border-width: 1px;
	border-color: $border0;
	elevation: 2;
`;

const RemoveIcon = styled(Icon).attrs({
	background: true,
	size: '2rem',
	name: 'close',
	color: '$neutral2',
})`
	position: absolute;
	right: ${(p) => p.theme.rem * 2.5}px;
	top: ${(p) => p.theme.rem * 1.5}px;
`;

export const INPUT_FIELD_MARGINS = [1];

const ElevatedCard = ({ title, onRemove, children }) => (
	<Wrapper>
		<Paragraph margin="0rem 2rem" bold>
			{title}
		</Paragraph>
		{onRemove ? <RemoveIcon onPress={onRemove} /> : null}
		{children}
	</Wrapper>
);

export default ElevatedCard;
