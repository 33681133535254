import * as React from 'react';
import { Box } from '@mui/material';
import type { BaseTextFieldProps } from '@mui/material/TextField';
import TextField from './TextField';

export type IntegerTextFieldRef = {
	validate: () => boolean;
};

const initStateValue = (defaultValue?: number, fallBackValue?: number): number | '' => {
	return defaultValue === 0 ? 0 : defaultValue || fallBackValue || '';
};

const IntegerTextField = React.forwardRef<
	IntegerTextFieldRef,
	{
		label?: string;
		defaultValue?: number;
		fallBackValue?: number;
		min?: number;
		max?: number;
		disabled?: boolean;
		onChange?: (value?: number) => void;
		m?: string;
		variant?: BaseTextFieldProps['variant'];
		flex?: boolean;
		minValue?: number;
		width?: string;
	}
>(
	(
		{
			label = 'Zahl',
			defaultValue,
			fallBackValue,
			min,
			max,
			disabled,
			onChange,
			m,
			variant,
			flex,
			// minValue,
			width,
		},
		ref
	): JSX.Element => {
		const [value, setValue] = React.useState<'' | number>(
			initStateValue(defaultValue, fallBackValue)
		);
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();

		const handleChange = React.useCallback(
			(newValue: string) => {
				console.log('newValue', newValue);
				setErrorMessage(undefined);
				if (newValue === '' || Number.isNaN(newValue)) {
					setValue('');
					onChange?.(undefined);
					return;
				}
				const integerNewValue = parseInt(newValue.toString().replace(',', '.'), 10);
				const minCorrectedValue =
					typeof min === 'number' ? Math.max(integerNewValue, min) : integerNewValue;
				const resultingValue =
					typeof max === 'number' ? Math.min(minCorrectedValue, max) : minCorrectedValue;
				setValue(resultingValue);
				onChange?.(resultingValue);
			},
			[max, min, onChange]
		);

		const handleValidate = React.useCallback(() => {
			let invalid = false;
			if (!disabled && !value && value !== 0) {
				invalid = true;
				setErrorMessage('Bitte wählen');
			}
			return invalid;
		}, [disabled, value]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		React.useEffect(() => {
			if (disabled) {
				setErrorMessage(undefined);
			}
		}, [disabled]);

		return (
			<Box m={m} flex={flex ? '1' : undefined} width={width}>
				<TextField
					disabled={disabled}
					fullWidth
					label={label}
					type="number"
					value={value}
					onChange={handleChange}
					variant={variant}
					error={Boolean(errorMessage)}
					helperText={errorMessage}
				></TextField>
			</Box>
		);
	}
);

export default React.memo(IntegerTextField);
