import * as React from 'react';

import { FormLabel, Box, Card } from '@mui/material';

// INFO: don't use this component, TODO: get rid of this component in favour of SectionWrapper2
const SectionWrapper = ({
	label,
	margin = '2rem 0 1rem 0',
	padding = '2rem',
	children,
}: {
	label?: string;
	margin?: string;
	padding?: string;
	children: JSX.Element;
}): JSX.Element => {
	return (
		<Box m={margin}>
			<Card variant="outlined">
				<Box p={padding}>
					<Box m="0 0 3rem">
						<FormLabel component="legend">{label}</FormLabel>
					</Box>
					{children}
				</Box>
			</Card>
		</Box>
	);
};

export default SectionWrapper;
