import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { ID } from '@cinuru/utils/types';

export const MovieInfoFragment = gql`
	fragment MovieInfoFragment on Movie {
		id
		title
		poster
		banner
	}
`;

export type Movie = {
	id: ID;
	title: string;
	poster: string;
	banner: string;
};

export const useAllMovies = (): undefined | Movie[] => {
	const { data } = useQuery(
		gql`
			query AllMovies {
				allMovies {
					...MovieInfoFragment
				}
			}
			${MovieInfoFragment}
		`,
		{ fetchPolicy: 'cache-first' }
	);

	return React.useMemo(() => data?.allMovies, [data?.allMovies]);
};

export const useSearchedMovies = (query?: string): undefined | Movie[] => {
	const { data, loading } = useQuery(
		gql`
			query SearchMovie($title: String!) {
				searchMovie(title: $title) {
					...MovieInfoFragment
				}
			}
			${MovieInfoFragment}
		`,
		{
			variables: {
				title: query,
			},
			skip: !query,
		}
	);
	return React.useMemo(() => (!query ? [] : loading ? undefined : data?.searchMovie), [
		data?.searchMovie,
		loading,
		query,
	]);
};

export const useMovie = (movieId: ID | undefined): Movie | undefined | null => {
	const { data, loading } = useQuery(
		gql`
			query MoviePoster($id: ID!) {
				movie(id: $id) {
					title
					poster
					banner
				}
			}
		`,
		{ skip: !movieId, variables: { id: movieId } }
	);
	return React.useMemo(() => (!movieId ? null : loading ? undefined : data?.movie), [
		data?.movie,
		loading,
		movieId,
	]);
};
