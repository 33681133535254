/** may need some refactoring, no native support */

// @flow
import * as React from 'react';
import {
	isSameMonth,
	isSameDay,
	addDays,
	addWeeks,
	addMonths,
	isBefore,
	isAfter,
	addYears,
	lastDayOfWeek,
	getDay,
	parseISO,
	format,
} from 'date-fns';
import styled from 'styled-native-components';

import TextField from './TextField';
import PortalTrigger from './PortalTrigger';
import DropDown from './DropDown';
import CalendarSheet from './CalendarSheet';
import Label from './Label';
import { formatDateInput, parseDateInput } from './DateField';

const TEXT_FIELD_MARGINS = [0];

const ShortcutList = styled.View`
	margin: 2rem;
	border-right-width: 1px;
	padding-right: 4rem;
	border-color: $border0;
	align-self: stretch;
	justify-content: space-between;
`;

const getShortcutColor = (p) => {
	if (!p.hovered) return 'transparent';
	const background = p.altBackground ? '$background0' : '$background1';
	return p.theme.colors.blend(
		0.2,
		p.theme.colors[background.substring(1)],
		p.theme.colors.neutral0
	);
};

const ShortcutWrapper = styled.TouchableOpacity`
	background-color: ${getShortcutColor};
	padding: 0 1rem;
	border-radius: ${(p) => p.theme.borderRadius[1]};
`;

class Shortcut extends React.PureComponent {
	state = { hovered: false };
	handleHover = () => this.setState({ hovered: true });
	handleUnHover = () => this.setState({ hovered: false });

	handlePress = () => this.props.onSelect && this.props.onSelect(this.props.value);
	isActiveShortcut = () =>
		isSameDay(this.props.activeValue.from, this.props.value.from) &&
		isSameDay(this.props.activeValue.to, this.props.value.to);

	render = () => (
		<ShortcutWrapper
			onPress={this.handlePress}
			onMouseEnter={this.handleHover}
			onMouseLeave={this.handleUnHover}
			hovered={this.state.hovered || this.isActiveShortcut()}
			altBackground={this.props.altBackground}
		>
			<Label size="s">{this.props.label}</Label>
		</ShortcutWrapper>
	);
}

const lastWedOfWeek = (date = new Date()) =>
	addDays(lastDayOfWeek(getDay(date) >= 3 ? date : addDays(date, -7)), -3);

// these disregard any timezones, which is intended for a purely date field
const decodePropsValue = (value?: string): Date => value && parseISO(value.split('T')[0]);
const encodeStateValue = (date?: Date): string => date && format(date, 'yyyy-MM-dd');

const formatDateRange = (value) =>
	value.from ? `${formatDateInput(value.from)} - ${formatDateInput(value.to)}` : '';

type Props = { value?: { from?: string, to?: string } };
type State = { selected: boolean, value?: { from: Date, to: Date } };
export default class DateRangeField extends React.PureComponent<Props, State> {
	state = {
		value: {
			from: decodePropsValue(this.props.value && this.props.value.from),
			to: decodePropsValue(this.props.value && this.props.value.to),
		},
		selected: false,
	};

	static defaultProps = {
		nMargins: [1, 0],
		altBackground: false,
		shortcuts: [
			// { label: 'Heute', value: { from: new Date(), to: new Date() } },
			// { label: 'Gestern', value: { from: addDays(new Date(), -1), to: addDays(new Date(), -1) } },
			{ label: 'Diese Woche', value: { from: addDays(lastWedOfWeek(), 1), to: new Date() } },
			{
				label: 'Letzte Woche',
				value: {
					from: addDays(lastWedOfWeek(addDays(new Date(), -7)), -6),
					to: lastWedOfWeek(addDays(new Date(), -7)),
				},
			},
			{ label: 'Letzte 7 Tage', value: { from: addDays(new Date(), -6), to: new Date() } },
			{ label: 'Letzter Monat', value: { from: addMonths(new Date(), -1), to: new Date() } },
			{ label: 'Letzte 3 Monate', value: { from: addMonths(new Date(), -3), to: new Date() } },
			{ label: 'Letzte 6 Monate', value: { from: addMonths(new Date(), -6), to: new Date() } },
			{ label: 'Letztes Jahr', value: { from: addYears(new Date(), -1), to: new Date() } },
			// { label: 'Letzte 2 Jahre', value: { from: addYears(new Date(), -2), to: new Date() } },
		],
	};

	active = null;
	inputRef = React.createRef();
	dateRef1 = React.createRef();
	dateRef2 = React.createRef();

	handleFocus = () =>
		this.setState({ selected: true }, () => this.props.onFocus && this.props.onFocus());
	handleBlur = () =>
		this.setState({ selected: false }, () => this.props.onBlur && this.props.onBlur());

	handleKeyPress = ({ nativeEvent: { key } }) => {
		const activeEmpty = !this.active;
		let active = this.active || new Date();
		if (key === 'ArrowRight') active = addDays(active, activeEmpty ? 0 : 1);
		if (key === 'ArrowLeft') active = addDays(active, activeEmpty ? 0 : -1);
		if (key === 'ArrowDown') active = addWeeks(active, activeEmpty ? 0 : 1);
		if (key === 'ArrowUp') active = addWeeks(active, activeEmpty ? 0 : -1);
		this.active = active;
		// update active selection
		const month1 = this.dateRef1.current.getMonth();
		if (!this.state.value.from || isSameMonth(month1, active) || isBefore(active, month1)) {
			this.dateRef1.current.setActive(active);
			this.dateRef2.current.setActive(active, true);
			this.dateRef2.current.setMonth(addMonths(active, 1));
		} else {
			this.dateRef1.current.setActive(active, true);
			this.dateRef2.current.setActive(active);
		}
		//  this.dateRef2.current.setActive(active);
		if (key === 'Enter' && !activeEmpty) {
			if (isSameMonth(month1, active)) {
				this.dateRef1.current.handleSelectDate(active);
			} else {
				this.dateRef2.current.handleSelectDate(active);
			}
		}
	};

	handleTextInput = (text) => {
		if (!text) this.handleSelectDate({});
		else {
			const [fromText, toText] = text.split('-');
			const from = parseDateInput(fromText);
			const to = parseDateInput(toText);
			if (from || to) this.handleSelectDate({ from, to });
			else this.setState({ error: 'Ungültiges Datum' });
		}
	};

	handleSelectDate = (value) => {
		// call onChange if input complete
		if (this.props.onChange && ((value.from && value.to) || (!value.from && !value.to))) {
			this.props.onChange({ from: encodeStateValue(value.from), to: encodeStateValue(value.to) });
		}
		// update text
		this.inputRef.current.setValue(formatDateRange(value));
		// update months
		this.dateRef2.current &&
			this.dateRef2.current.setMonth(
				value.to && !isSameMonth(value.to, value.from)
					? value.to
					: addMonths(value.from || new Date(), 1)
			);
		this.setState({ value });
		this.dateRef2.current && this.dateRef2.current.setValue(value);
		this.dateRef1.current && this.dateRef1.current.setValue(value);
		if (value === null || (value.from && value.to)) {
			this.handleBlur();
		}
	};

	handleSetActive = (active) => {
		this.active = active;
		this.dateRef1.current.setActive(active, true);
		this.dateRef2.current.setActive(active, true);
	};

	handleMonthChanged1 = (month) => {
		const nextMonth = addMonths(month, 1);
		if (isBefore(this.dateRef2.current.getMonth(), nextMonth)) {
			this.dateRef2.current.setMonth(nextMonth);
		}
	};
	handleMonthChanged2 = (month) => {
		const prevMonth = addMonths(month, -1);
		if (isAfter(this.dateRef1.current.getMonth(), prevMonth)) {
			this.dateRef1.current.setMonth(prevMonth);
		}
	};

	renderOverlay = () => {
		const { altBackground, futureOnly, shortcuts } = this.props;
		const { value } = this.state;
		const error = this.state.error || this.props.error;
		return (
			<DropDown
				focusline
				hasError={Boolean(error)}
				color={altBackground ? '$background0' : '$background1'}
				borderColor="$border0"
				maxHeight="42rem"
				altCenter
				flexFlow="row"
			>
				<ShortcutList>
					{shortcuts.map((shortcut) => (
						<Shortcut
							key={shortcut.label}
							label={shortcut.label}
							value={shortcut.value}
							activeValue={value}
							altBackground={altBackground}
							onSelect={this.handleSelectDate}
						/>
					))}
				</ShortcutList>
				<CalendarSheet
					rangeSelect
					ref={this.dateRef1}
					value={value}
					month={
						value.from && !isSameMonth(value.from, value.to)
							? value.from
							: addMonths(value.to || new Date(), -1)
					}
					onChange={this.handleSelectDate}
					altBackground={altBackground}
					futureOnly={futureOnly}
					onSetActive={this.handleSetActive}
					onMonthChange={this.handleMonthChanged1}
				/>
				<CalendarSheet
					rangeSelect
					ref={this.dateRef2}
					value={value}
					month={value.to || new Date()}
					onChange={this.handleSelectDate}
					altBackground={altBackground}
					futureOnly={futureOnly}
					onSetActive={this.handleSetActive}
					onMonthChange={this.handleMonthChanged2}
				/>
			</DropDown>
		);
	};

	render = () => {
		const error = this.state.error || this.props.error;
		const { nMargins, altBackground, flex, hint, ...other } = this.props;
		// eslint-disable-next-line no-unused-vars
		const { futureOnly, value: _, ...rest } = other;
		const { selected, value } = this.state;
		return (
			<PortalTrigger
				margin={nMargins.join('rem ') + 'rem'}
				flexBase={flex && '300rem'}
				active={selected}
				renderOverlay={this.renderOverlay}
				contentTopMargin={hint || error ? '-3.25rem' : '-0.25rem'}
				stretch
			>
				<TextField
					{...rest}
					width={'100%'}
					nMargins={TEXT_FIELD_MARGINS}
					ref={this.inputRef}
					altBackground={altBackground}
					value={formatDateRange(value)}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					selected={selected}
					hint={hint}
					autoCorrect={false}
					onKeyPress={this.handleKeyPress}
					onChange={this.handleTextInput}
					blurOnSubmit={false}
					inputChangeDebounce={500}
					error={error}
				/>
			</PortalTrigger>
		);
	};
}
