import * as React from 'react';
import { Tabs, Tab, Box, Paper } from '@mui/material';

import Table, { RowItem, ColumnItem, Actions } from './Table';

const TabWrapper = ({
	children,
	value,
	index,
}: {
	children: JSX.Element;
	value: number;
	index: number;
}): JSX.Element => {
	return (
		<div role="tabpanel" hidden={value !== index}>
			{value === index && (
				<Box p={3}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
};

type TabFilter = {
	label: string;
	filterFunction: (row: RowItem) => boolean;
};

type TabTableProps<ActionType extends string> = {
	rows: RowItem[];
	columns: ColumnItem[];
	tabFilters?: TabFilter[];
	onHandleAction;
	label: string;
	searchPath?: string;
	actions?: Actions<ActionType>;
};

const TabTables = <ActionType extends string>({
	rows,
	columns,
	tabFilters,
	onHandleAction,
	label,
	searchPath = 'data.text',
	actions,
}: TabTableProps<ActionType>): JSX.Element => {
	const [activePanel, setActivePanel] = React.useState(0);

	const handleChangeActivePanel = React.useCallback(
		(_, newValue) => {
			setActivePanel(newValue);
		},
		[setActivePanel]
	);

	const tabs = React.useMemo(
		() =>
			tabFilters
				? tabFilters.map(({ filterFunction }) => rows.filter((item) => filterFunction(item)))
				: rows,
		[rows, tabFilters]
	);

	return (
		<Paper>
			{tabFilters ? (
				<Tabs
					value={activePanel}
					onChange={handleChangeActivePanel}
					textColor="inherit"
					indicatorColor="primary"
				>
					{tabFilters?.map(({ label: tabFilterLabel }) => (
						<Tab key={tabFilterLabel} label={tabFilterLabel} />
					))}
				</Tabs>
			) : null}

			{tabFilters ? (
				tabs.map((arrayOfRows, index) => {
					const tabLabel = tabFilters[index]?.label;
					return (
						<TabWrapper key={tabLabel} value={activePanel} index={index}>
							<Table
								label={tabLabel}
								allRows={arrayOfRows}
								columns={columns}
								onHandleAction={onHandleAction}
								actions={actions}
							/>
						</TabWrapper>
					);
				})
			) : (
				<Table
					label={label}
					allRows={rows}
					columns={columns}
					onHandleAction={onHandleAction}
					searchPath={searchPath}
					actions={actions}
				/>
			)}
		</Paper>
	);
};

export default TabTables;
