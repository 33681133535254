import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { ID } from '@cinuru/utils/types';

export type MovieList = { id: ID; title?: string };

export const useAllMovieLists = (cinemaIds?: ID[]): MovieList[] | undefined | null => {
	const { data } = useQuery(
		gql`
			query MovieLists($ids: [ID!]!) {
				allCinemaFilmSeries(cinemaIds: $ids) {
					id
					title
				}
			}
		`,
		{
			variables: { ids: cinemaIds },
			skip: !cinemaIds,
		}
	);
	return React.useMemo(() => (!cinemaIds?.length ? null : data?.allCinemaFilmSeries), [
		cinemaIds?.length,
		data?.allCinemaFilmSeries,
	]);
};

export const useMovieList = (movieListId?: ID): MovieList | undefined | null => {
	const { data } = useQuery(
		gql`
			query MovieList($id: ID!) {
				movieList(id: $id) {
					id
					title
				}
			}
		`,
		{
			variables: { id: movieListId },
			skip: !movieListId,
		}
	);
	return React.useMemo(() => (!movieListId ? null : data?.movieList), [
		data?.movieList,
		movieListId,
	]);
};
