import React from 'react';
import { Popper as MuiPopper, Box, ClickAwayListener, Paper } from '@mui/material';
import uuid from 'uuid';
import { BoxProps } from '@mui/material/Box';

const PopperWrapper = ({
	renderPopperContent,
	children,
	anchorRef,
	...rest
}: {
	renderPopperContent: (props?: any) => JSX.Element;
	children: ({ onClick }: { onClick: () => void }) => JSX.Element;
	anchorRef: React.RefObject<any>;
	rest?: BoxProps;
}): JSX.Element => {
	const id = React.useMemo(() => uuid(), []);

	const [open, setOpen] = React.useState(false);

	const handleOpen = React.useCallback(() => {
		setOpen(true);
	}, []);

	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	return (
		<>
			{children({ onClick: handleOpen })}
			{open ? (
				<ClickAwayListener onClickAway={handleClose}>
					<MuiPopper id={id} open={open} anchorEl={anchorRef.current}>
						<Paper>
							<Box borderRadius="0.5rem" boxShadow={1} {...rest}>
								{renderPopperContent()}
							</Box>
						</Paper>
					</MuiPopper>
				</ClickAwayListener>
			) : null}
		</>
	);
};

export default React.memo(PopperWrapper);
