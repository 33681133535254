import React from 'react';
import TextField from './TextField';
import useTextFieldContoller from '../utils/useTextFieldController';

const SearchField = ({
	label = 'Suchen',
	onChange,
	m,
}: {
	label?: string;
	onChange?: (query: string) => void;
	m?: string;
}): JSX.Element => {
	const handleChange = React.useCallback(
		(value) => {
			onChange?.(value || '');
		},
		[onChange]
	);

	const { textInputProps } = useTextFieldContoller<string>({
		inputLabel: label,
		stateKey: '',
		onChange: handleChange,
	});

	return <TextField m={m} {...textInputProps} startIconName="Search" />;
};

export default SearchField;
