import React, { useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';

import EmailComponentControlPanel from './EmailComponentControlPanel';
import EmailComponentProviderPanel from './EmailComponentProviderPanel';
import {
	useElementsWithUpdatedProgram,
	useElementsWithUpdatedRecommendations,
} from './utils/updateTrueElements';

import { FooterDefaultParams } from './ContentTypes/Footer';
import { HeaderDefaultParams } from './ContentTypes/Header';

import { useCampaign } from '../../utils/campaign';
import {
	EmailElement,
	HeaderData,
	ProgramData,
	ProgramTimeFrame,
	saveCampaignEmail,
} from '../../utils/campaignEmailContent';
import StickyHeaderWrapper, {
	ButtonIconName,
	IconButtonIconName,
} from '../../components/StickyHeaderWrapper';
import EmailField from './EmailField';
import { useActiveBreakPoint } from '../../utils/theme';
import { useGetPixelFromRem } from '../../utils/dimensions';
import FixedWrapper from '../../components/FixedWrapper';

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const RowWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
`;

const errorDict = {
	NETWORK_ERROR: 'Netzwerkfehler',
	NO_CAMPAIGN_PRIVILEGES: 'Nicht autorisiert',
	INSUFFICIENT_CINEMA_PRIVILEGES: 'Nicht autorisiert',
	CAMPAIGN_NOT_FOUND: 'Kampagne existiert nicht',
	EDITING_STATUS_REQUIRED: 'Nur im Editieren-Modus möglich',
	UNEXPECTED_ERROR: 'Unerwarteter Fehler',
};

const EmailEditor = (): JSX.Element => {
	const client = useApolloClient();
	const { enqueueSnackbar } = useSnackbar();
	const { campaignId } = useParams();
	const campaign = useCampaign(campaignId);
	const brand = campaign?.language?.id?.split('de-')[1].toUpperCase();

	const [dragAndDropInProgress, setDragAndDropInProgress] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [mobileView, setMobileView] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [elements, setElements] = useState<EmailElement[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [isSending, setIsSending] = React.useState(false);
	const [programTimeFrame, setProgramTimeFrame] = React.useState(
		'NEXT_WEEK_ONLY' as ProgramTimeFrame
	);
	const [currentlyEditedElementKey, setCurrentlyEditedElementKey] = React.useState<
		string | undefined
	>();

	const editingElement = React.useMemo(
		() => elements.find((el) => el.key === currentlyEditedElementKey),
		[currentlyEditedElementKey, elements]
	);

	if (elements.length === 0 && brand) {
		setElements([
			{ ...HeaderDefaultParams, key: 'header' },
			{ ...FooterDefaultParams, key: 'footer' },
		]);
	}

	if (!dataLoaded && campaign?.emailContent) {
		setElements(campaign?.emailContent.elements);
		setDataLoaded(true);
	}

	const handleDragAndDrop = React.useCallback(
		(val: boolean) => {
			setTimeout(() => {
				setDragAndDropInProgress(val);
			}, 10);
		},
		[setDragAndDropInProgress]
	);

	const handleSave = React.useCallback(async () => {
		if (!loading) {
			setLoading(true);
			const elementsDeepCopy = cloneDeep(elements);
			elements.map((el, idx) => {
				if (el.type === 'HEADER' && el.textChangedByCustomer === false) {
					(elementsDeepCopy[idx] as HeaderData).children = undefined;
				}
				if (el.type === 'PROGRAM') {
					(elementsDeepCopy[idx] as ProgramData).fullProgramMovies = [];
				}
			});
			const { error } = await saveCampaignEmail({
				campaignId,
				emailContent: elementsDeepCopy,
			});
			if (!error) {
				enqueueSnackbar('Gespeichert', { variant: 'success' });
			} else {
				enqueueSnackbar(`${errorDict[error] || errorDict['UNEXPECTED_ERROR']}`, {
					variant: 'error',
				});
			}
			setLoading(false);
			setDataLoaded(false);
		}
	}, [loading, elements, campaignId, enqueueSnackbar]);

	//updateElements
	const elementsWithNewProgram = useElementsWithUpdatedProgram({
		elements,
		campaign,
		timeFrame: programTimeFrame,
	});

	const elementsWithUpdatedRecommendations = useElementsWithUpdatedRecommendations({
		elements: elementsWithNewProgram,
		campaign,
	});

	const handleSendTestEmail = React.useCallback(async () => {
		if (!isSending) {
			setIsSending(true);
			try {
				const typeConverter = {
					IMAGE: 'Image',
					HEADER: 'DashboardEmailHeader',
					FOOTER: 'DashboardEmailFooter',
					SPACER: 'Spacer',
					TEXT: 'MarkdownText',
					TEXTWITHIMAGE: 'MarkdownTextWithImage',
					PROGRAM: 'FullProgram',
					RECOMMENDATIONS: 'RecommendationSection',
					BUTTON: 'Button',
					QRCODE: 'QRCode',
				};

				const convertedElements = elementsWithUpdatedRecommendations.map((el: EmailElement) => {
					const { type, ...props } = cloneDeep(el);
					if (el.type === 'HEADER' && el.textChangedByCustomer === false) {
						(props as HeaderData).children = HeaderDefaultParams.children;
					}
					return { componentName: typeConverter[type], props };
				});

				//TO DO1: den Betreff der Campaign in der Datenbank speichern und hier mit als subject übergeben!
				//TO DO2: Überprüfen, warum JSON[] nicht via GraphQL übergeben werden kann!
				await client.mutate({
					mutation: gql`
						mutation SendTestEmail($brand: Brand!, $componentData: String!) {
							sendTestEmail(brand: $brand, componentData: $componentData) {
								success
							}
						}
					`,
					variables: {
						componentData: JSON.stringify(convertedElements),
						brand,
					},
				});
				// eslint-disable-next-line no-catch-all/no-catch-all
			} catch (e) {
				console.log('new Error trying to send TestEmail', e);
			}
			setIsSending(false);
		}
	}, [isSending, elementsWithUpdatedRecommendations, client, brand]);

	const handleSetMobile = React.useCallback(() => setMobileView(true), []);
	const handleSetDesktop = React.useCallback(() => setMobileView(false), []);

	const handleStartEditElement = React.useCallback(
		(el) => {
			setCurrentlyEditedElementKey(el.key);
			setIsEditing(true);
		},
		[setCurrentlyEditedElementKey, setIsEditing]
	);

	const buttons = React.useMemo(
		() => [
			{
				label: 'Mobile',
				onClick: handleSetMobile,
				startIconName: 'PhoneIphoneOutlined' as ButtonIconName,
				collapsedIconName: 'PhoneIphoneOutlined' as IconButtonIconName,
			},
			{
				label: 'Desktop',
				onClick: handleSetDesktop,
				startIconName: 'DesktopMacOutlined' as ButtonIconName,
				collapsedIconName: 'DesktopMacOutlined' as IconButtonIconName,
			},
			{
				label: 'Testen',
				onClick: handleSendTestEmail,
				startIconName: 'SendRounded' as ButtonIconName,
				loading,
				loadingText: 'Testen',
				collapsedIconName: 'SendRounded' as IconButtonIconName,
			},
			{
				label: 'Speichern',
				onClick: handleSave,
				startIconName: 'SaveOutlined' as ButtonIconName,
				loading,
				loadingText: 'Speichern',
				collapsedIconName: 'SaveOutlined' as IconButtonIconName,
			},
		],
		[handleSave, handleSendTestEmail, handleSetDesktop, handleSetMobile, loading]
	);

	const handleChangeElement = React.useCallback(
		(editedEmailElement: EmailElement) => {
			const allElementsWithEditedElement = cloneDeep(elements).map((el) =>
				el.key === editedEmailElement.key ? editedEmailElement : el
			);
			setElements(allElementsWithEditedElement);
			if (editedEmailElement.type.includes('PROGRAM')) {
				setProgramTimeFrame((editedEmailElement as ProgramData).timeFrame);
			}
		},
		[elements]
	);

	const handleDelete = React.useCallback(
		(deletedElement: EmailElement) => {
			if (currentlyEditedElementKey === deletedElement.key) {
				setIsEditing(false);
			}
			const updatedElement = elements.filter((el) => el.key !== deletedElement.key);
			setElements(updatedElement);
		},
		[currentlyEditedElementKey, elements]
	);

	const activeBreakPoint = useActiveBreakPoint();
	const panelWidthRem = { xl: 60, lg: 50, md: 40, sm: 30, xs: 30 }[activeBreakPoint];
	const margins = {
		xl: '0 0 0 2rem',
		lg: '0 0 0 2rem',
		md: '0 0 0 1em',
		sm: '0 0 0 1em',
		xs: '0 0 0 1em',
	}[activeBreakPoint];
	const panelWidthPx = useGetPixelFromRem(panelWidthRem);

	return (
		<StickyHeaderWrapper
			label="Email Editor"
			maxContentWidth="200rem"
			buttons={buttons}
			isLoading={!campaign}
		>
			{!campaign ? null : (
				<RowWrapper>
					<FixedWrapper bottomOffset={14} width={panelWidthPx}>
						{isEditing ? (
							<EmailComponentControlPanel
								element={editingElement}
								onChange={handleChangeElement}
								onChangeEditingInProgress={setIsEditing}
								brand={brand}
								onDelete={handleDelete}
							/>
						) : (
							<EmailComponentProviderPanel onDragAndDrop={handleDragAndDrop} />
						)}
					</FixedWrapper>

					<Column
						flex={mobileView ? undefined : '1'}
						width={mobileView ? 411 : '100%'}
						m={margins}
						boxShadow={2}
					>
						<EmailField
							dragAndDropInProgress={dragAndDropInProgress}
							onDragAndDrop={handleDragAndDrop}
							elements={elements}
							onChange={setElements}
							onClickElement={handleStartEditElement}
							brand={brand}
							campaign={campaign}
							mobileView={mobileView}
						/>
					</Column>
				</RowWrapper>
			)}
		</StickyHeaderWrapper>
	);
};

export default React.memo(EmailEditor);
