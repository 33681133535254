import * as React from 'react';
import styled from 'styled-native-components';
import { Link } from 'react-router-dom';

import { Icon, Heading } from '@cinuru/components';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 2rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	margin-top: 3rem;
	elevation: 2;
	flex-direction: row;
	align-items: center;
`;

const Chevron = styled(Icon).attrs({
	name: 'chevron',
	color: '$neutral2',
	size: '4rem',
	rotation: 90,
})`
	position: absolute;
	right: ${(p) => p.theme.rem * 4}px;
`;

const CampaignCard = ({ name, campaignType, id }) => (
	<Link to={`/marketing/campaigns/${encodeURIComponent(id)}/edit`}>
		<Wrapper>
			<Icon
				name={campaignType.iconName}
				size="4rem"
				margin="0rem 1rem 0rem 0rem"
				color="$neutral2"
			/>
			<Heading size="s" color="$neutral2">
				{name} Kampagne
			</Heading>
			<Chevron />
		</Wrapper>
	</Link>
);

export default CampaignCard;
