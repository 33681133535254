import * as React from 'react';
import { default as gql } from 'graphql-tag';
import { withApollo } from 'react-apollo';
import styled from 'styled-native-components';
import { ButtonTile, TextField, LoadingIndicator, Heading, Paragraph } from '@cinuru/components';

import CinemaSelectField from '../../components/CinemaSelectField';
import { showErrorDialog } from '../../services/report-error';

import { saveState } from './EditCampaign';
import { campaignTypes, campaignGroups } from './config';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
`;

const TileRow = styled.View`
	flex-flow: row wrap;
	margin: 0 -1.5rem;
`;

const TILE_MARGINS = [1];
const TEXT_FIELD_MARGINS = [2, -0.5];

class CampaignTypeButton extends React.PureComponent {
	handlePress = () => this.props.onPress && this.props.onPress(this.props.type);
	render = () => <ButtonTile {...this.props} onPress={this.handlePress} nMargins={TILE_MARGINS} />;
}

class CampaigningNew extends React.PureComponent {
	state = { name: '', cinemaIds: [], cinemaSelectError: null, createLoading: false };

	setName = (name) => this.setState({ name });
	setCinemaIds = (cinemaIds) => this.setState({ cinemaIds, cinemaSelectError: null });

	handleCreateCampaign = async (type) => {
		const { name, cinemaIds } = this.state;
		if (cinemaIds.length === 0) {
			this.setState({ cinemaSelectError: 'Mindestens ein Kino auswählen' });
		} else {
			this.setState({ createLoading: true });
			const { history, client } = this.props;
			const { data } = await client.mutate({
				mutation: gql`
					mutation CreateCampaign($name: String!, $type: CampaignType!, $cinemaIds: [ID!]!) {
						result: createCampaign(name: $name, type: $type, cinemaIds: $cinemaIds) {
							campaign {
								id
								type
								name
								userGroupFilter {
									id
								}
								userGroupFilterOnReferencedMovie {
									id
								}
							}
						}
					}
				`,
				variables: { name: name || 'Unbenannt', type, cinemaIds },
			});
			if (data.result && data.result.campaign) {
				const initialCampaignState = {
					name: data.result.campaign.name,
					id: data.result.campaign.id,
					userGroupFilterId: data.result.campaign.userGroupFilter.id,
					referencedMovieFilterId: data.result.campaign.userGroupFilterOnReferencedMovie.id,
					userGroupFilters: [],
					channels: [],
					status: 'EDITING',
					...campaignTypes.find((t) => t.type === type).defaultValues,
				};
				await saveState(initialCampaignState, this.props.client);
				this.setState({ createLoading: false });
				history.push(`/marketing/campaigns/${encodeURIComponent(data.result.campaign.id)}/edit`);
			} else {
				this.setState({ createLoading: false });
				showErrorDialog('Unerwarteter Fehler in create campaign');
			}
		}
	};

	render = () => (
		<Wrapper>
			<Heading size="l" margin="0rem 0rem 2rem 0rem">
				Neue Kampagne erstellen
			</Heading>
			<TextField
				value={this.state.name}
				onChange={this.setName}
				nMargins={TEXT_FIELD_MARGINS}
				label="Interne Kampagnen-Bezeichnung"
				width="100%"
			/>
			<CinemaSelectField
				onChange={this.setCinemaIds}
				error={this.state.cinemaSelectError}
				nMargins={TEXT_FIELD_MARGINS}
				multi
			/>
			<Paragraph margin="2rem 0rem 0rem 0rem">Art der Kampagne festlegen:</Paragraph>
			<Paragraph color="secondary" margin="1rem 0rem 2rem 0rem">
				Kampagnen-Nachrichten müssen immer auf einen spezifischen Film verlinken.
			</Paragraph>
			{this.state.createLoading ? (
				<LoadingIndicator />
			) : (
				campaignGroups.map(({ type, label }) => (
					<React.Fragment key={type}>
						<Heading size="xs" color="$neutral2">
							{label.toUpperCase()}
						</Heading>
						<TileRow>
							{campaignTypes
								.filter(({ group }) => group === type)
								.filter(({ active }) => active)
								.map((campaignType) => (
									<CampaignTypeButton
										key={campaignType.type}
										onPress={this.handleCreateCampaign}
										{...campaignType}
									/>
								))}
						</TileRow>
					</React.Fragment>
				))
			)}
		</Wrapper>
	);
}

export default withApollo(CampaigningNew);
