import * as React from 'react';
import styled, { useColorAttribute } from 'styled-native-components';
import { Linking } from 'react-native';

import Ripple from './Ripple';
import Icon from './Icon';
import LoadingIndicator from './LoadingIndicator';
import Label from './Label';
import Gradient from './Gradient';

import type { GradientName, IconName } from '@cinuru/utils/theme';
import type { GradientOrientation } from './Gradient';
import type { ID } from '@cinuru/utils/types';
import type { TouchableRef } from './Ripple';

const getBackgroundColor = (p): string => {
	if (p.hovered && !p.noHover) {
		return p.link
			? p.theme.colors.transparentize(0.9, p.textColor)
			: p.theme.colors.blend(0.2, p.color, p.textColor);
	} else {
		return p.link || p.outline ? 'transparent' : p.color;
	}
};

const Touchable = styled(Ripple).attrs<
	{
		id?: ID;
		onPressAction?: (id?: ID) => unknown;
		href?: string;
		loading?: boolean;
		disabled?: boolean;
		textColor?: string;
		extendTouchableArea?: number;
		nBorderRadius: number;
		size: 's' | 'm' | 'l';
		align?: 'left' | 'right' | 'stretch';
		margin: string;
		link?: boolean;
		flex?: boolean;
		stretch?: boolean;
		outline?: boolean;
		el: number;
		minWidth?: string;
		color: string;
	},
	{
		accessibilityRole: 'link' | 'button';
		onPress?: (e: unknown) => unknown;
		activeOpacity: number;
		rippleColor?: string;
		hitSlop?: { top: number; right: number; bottom: number; left: number };
	}
>((p) => ({
	accessibilityRole: p.href ? 'link' : 'button',
	onPress:
		p.disabled || p.loading
			? undefined
			: p.href
			? // @ts-ignore
			  (e) => (window && e && e.metaKey ? window.open(p.href, '_blank') : Linking.openURL(p.href))
			: () => p.onPressAction && p.onPressAction(p.id),
	activeOpacity: p.disabled ? 1 : 0.2,
	rippleColor: p.textColor,
	hitSlop: p.extendTouchableArea
		? {
				top: p.extendTouchableArea,
				right: p.extendTouchableArea,
				bottom: p.extendTouchableArea,
				left: p.extendTouchableArea,
		  }
		: undefined,
}))`
	height: ${(p) => ({ s: '3.5rem', m: '4rem', l: '6rem' }[p.size])};
	background-color: ${getBackgroundColor};
	border-radius: ${(p) => p.theme.borderRadius[p.nBorderRadius]};
	opacity: ${(p) => (p.disabled ? 0.35 : 1)};
	flex-direction: row;
	justify-content: ${(p) =>
		p.align === 'left' ? 'flex-start' : p.align === 'right' ? 'flex-end' : 'space-between'};
	align-items: center;
	margin: ${(p) => p.margin};
	elevation: ${(p) => (p.link ? 0 : p.el)};
	${(p) => (p.flex ? 'flex: 1' : '')};
	${(p) => (p.stretch ? 'align-self: stretch' : '')};
	border-width: ${(p) => (p.outline ? 1 : 0)}px;
	border-color: ${(p) => p.color};
	${(p) => (p.minWidth ? `min-width: ${p.minWidth}` : '')};
`;

const GradientBackground = styled(Gradient).attrs<
	{
		width: number;
		hovered?: boolean;
		gradient: GradientName;
		nBorderRadius: number;
	},
	{ colors: string[]; borderRadius: string }
>((p) => ({
	colors: p.theme.colors[p.gradient],
	borderRadius: p.theme.borderRadius[p.nBorderRadius],
}))`
	position: absolute;
	width: ${(p) => p.width}px;
	height: 100%;
	opacity: ${(p) => (p.hovered ? 0.7 : 1)};
	border-radius: ${(p) => p.theme.borderRadius[p.nBorderRadius]};
`;

const LabelWrapper = styled.View<{
	align?: 'left' | 'right' | 'stretch';
	size: 's' | 'm' | 'l';
	center?: boolean;
	chevron?: boolean;
}>`
	flex-direction: row;
	align-items: center;
	justify-content: ${(p) =>
		p.align === 'left' ? 'flex-start' : p.align === 'right' ? 'flex-end' : 'center'};
	${(p) => (p.center ? 'flex: 1' : '')};
	margin-left: ${(p) => ({ s: '1rem', m: '1.5rem', l: '2rem' }[p.size])};
	margin-right: ${(p) =>
		({
			s: p.chevron ? '2rem' : '1rem',
			m: p.chevron ? '3.5rem' : '1.5rem',
			l: p.chevron ? '4rem' : '2rem',
		}[p.size])};
`;

const Chevron = styled(Icon).attrs<{ marginRight: string }, { name: 'chevron' }>({
	name: 'chevron',
})`
	position: absolute;
	right: ${(p) => p.marginRight};
	transform: rotate(90deg);
`;

interface PressableWithId {
	id: ID;
	onPress?: (id: ID) => unknown;
}
interface PressableWithoutId {
	id?: undefined;
	onPress?: () => unknown;
}

export type Pressable = PressableWithId | PressableWithoutId;

export type ButtonProps = Pressable & {
	label?: string;
	flex?: boolean;
	stretch?: boolean;
	disabled?: boolean;
	uppercase?: boolean;
	size?: 's' | 'm' | 'l';
	link?: boolean;
	color?: string;
	gradient?: GradientName;
	gradientOrientation?: GradientOrientation;
	textColor?: string;
	loading?: boolean;
	elevation?: number;
	margin?: string;
	chevron?: boolean;
	center?: boolean;
	align?: 'left' | 'right' | 'stretch';
	iconName?: IconName;
	leftIconName?: IconName;
	href?: string;
	noHover?: boolean;
	underline?: boolean;
	outline?: boolean;
	extendTouchableArea?: number;
	minWidth?: string;
	testID?: string;
	light?: boolean;
};

export interface ButtonRef extends TouchableRef {}

const Button = React.forwardRef<ButtonRef, ButtonProps>(
	(
		{
			id,
			label,
			flex,
			stretch,
			disabled,
			uppercase,
			size = 'm',
			link,
			color = '$background1',
			gradient,
			gradientOrientation,
			textColor = '$neutral0',
			elevation = 2,
			loading,
			onPress,
			margin = '0.5rem',
			chevron,
			center,
			align = 'stretch',
			leftIconName,
			iconName,
			href,
			noHover,
			underline,
			outline,
			testID,
			light,
			extendTouchableArea,
			minWidth,
		},
		ref
	) => {
		const [hovered, setHovered] = React.useState(false);
		const handleMouseEnter = React.useCallback(() => setHovered(true), []);
		const handleMouseLeave = React.useCallback(() => setHovered(false), []);

		color = useColorAttribute(color);
		textColor = useColorAttribute(textColor);

		// width 100% doesn't work for the gradient background so we need to measure
		const [width, setWidth] = React.useState<number>();
		const handleSetWidth = React.useCallback(({ nativeEvent }) => {
			return setWidth(nativeEvent.layout.width);
		}, []);

		return (
			<Touchable
				onLayout={handleSetWidth}
				testID={testID}
				href={href}
				flex={flex}
				stretch={stretch}
				id={id}
				onPressAction={onPress}
				disabled={disabled}
				loading={loading}
				accessible
				accessibilityRole="button"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				el={elevation}
				link={link}
				margin={margin}
				color={color}
				textColor={textColor}
				size={size}
				align={align}
				hovered={hovered}
				noHover={noHover}
				outline={outline}
				extendTouchableArea={extendTouchableArea}
				minWidth={minWidth}
				nBorderRadius={{ s: 1, m: 2, l: 2 }[size]}
				ref={ref}
			>
				{gradient && width ? (
					<GradientBackground
						gradient={gradient}
						orientation={gradientOrientation}
						hovered={hovered}
						nBorderRadius={{ s: 1, m: 2, l: 2 }[size]}
						width={width}
					/>
				) : null}
				{leftIconName ? (
					<Icon
						name={leftIconName}
						color={textColor}
						margin="0 0 0 1.5rem"
						size={{ s: '2rem', m: '2.5rem', l: '3rem' }[size]}
					/>
				) : null}
				{label ? (
					<LabelWrapper align={align} center={center} chevron={chevron} size={size}>
						{loading ? (
							<LoadingIndicator
								margin="0 1rem 0 0"
								height={{ s: '3.5rem', m: '4rem', l: '4.5rem' }[size]}
								color={textColor}
							/>
						) : null}
						<Label
							color={textColor}
							center={center}
							size={{ s: link ? 's' : 'xs', m: 'm', l: 'l' }[size]}
							textDecoration={underline ? 'underline' : undefined}
							light={light || (link && underline)}
							numberOfLines={1}
							uppercase={uppercase}
						>
							{label}
						</Label>
					</LabelWrapper>
				) : null}
				{chevron ? (
					<Chevron
						size={{ s: '2rem', m: '2.5rem', l: '3rem' }[size]}
						marginRight={link ? '0' : { s: '1rem', m: '1rem', l: '2rem' }[size]}
						color={textColor}
					/>
				) : null}
				{iconName ? (
					<Icon
						name={iconName}
						color={textColor}
						margin={{ s: '0 1rem 0 0', m: label ? '0 1rem' : '0 0.5rem', l: '0 1rem' }[size]}
						size={{ s: '2rem', m: label ? '2.5rem' : '3rem', l: '3rem' }[size]}
					/>
				) : null}
			</Touchable>
		);
	}
);

export default React.memo(Button);
