import React from 'react';
import { Row, Col, Input } from '../../components/AntDReplacements';

const styles = {
	wrapper: {
		marginTop: '20px',
		marginBottom: '40px',
	},
};
//TODO: clean up this styles mess
export const HeaderImageSection = ({ headerImage, updateImage }) => (
	<div style={styles.wrapper}>
		<Row>
			<Col>Header Bild</Col>
		</Row>
		<Row style={{ margin: '10px 0' }}>
			<Col>
				<Input
					value={headerImage}
					placeholder="Url der Header Grafik"
					onChange={(event) => updateImage(event.target.value)}
				/>
			</Col>
		</Row>
	</div>
);
