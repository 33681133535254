import * as React from 'react';
import styled from 'styled-native-components';
import { groupBy, orderBy } from 'lodash';
import { Link } from 'react-router-dom';

import { Button, Paragraph, Heading } from '@cinuru/components';

import CampaignCard from './CampaignCard';
import { default as gql } from 'graphql-tag';
import WithData from '../../components/WithData';

import { campaignTypes } from './config';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
`;

const Section = styled.View`
	margin-top: 4rem;
`;

const groupByCinemas = (campaigns) => {
	const campaignsByCinemas = [];
	campaigns.forEach((campaign) => {
		const key = campaign.cinemas.map(({ id }) => id).join();
		const index = campaignsByCinemas.findIndex(({ cinemas }) => cinemas.key === key);
		if (index >= 0) {
			campaignsByCinemas[index].campaigns.push(campaign);
		} else {
			campaignsByCinemas.push({
				cinemas: { key, names: campaign.cinemas.map(({ name }) => name) },
				campaigns: [campaign],
			});
		}
	});
	return campaignsByCinemas;
};

class CampaigningHome extends React.Component {
	render = () => (
		<React.Fragment>
			<Wrapper>
				<Heading size="l" margin="0rem 0rem 4rem 0rem">
					Kampagnen
				</Heading>
				<Link to="/marketing/campaigns/new">
					<Button
						label="NEUE KAMPAGNE ERSTELLEN"
						gradient="accentGradient0"
						textColor="$background0"
						margin="0rem -0.5rem"
					/>
				</Link>
			</Wrapper>
			<WithData
				query={gql`
					query {
						currentUser {
							id
							privileges {
								adminForCinemas {
									id
									name
								}
							}
						}
					}
				`}
			>
				{({ currentUser }) => (
					<WithData
						query={gql`
							query Campaigns($cinemaIds: [ID!]!) {
								campaignsByCinemas(cinemaIds: $cinemaIds) {
									campaigns {
										id
										type
										name
										status
										cinemas {
											id
											name
										}
									}
								}
							}
						`}
						// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
						variables={{ cinemaIds: currentUser.privileges.adminForCinemas.map(({ id }) => id) }}
					>
						{({ campaignsByCinemas }) => {
							const cinemasGroupedCampaigns = groupByCinemas(campaignsByCinemas.campaigns);
							const renderCampaign = (campaign) => (
								<CampaignCard
									campaignType={campaignTypes.find(({ type }) => type === campaign.type)}
									key={campaign.id}
									{...campaign}
								/>
							);

							const renderCampaignsByStatus = (campaigns) =>
								orderBy(Object.entries(groupBy(campaigns, 'status')), [
									([status]) =>
										['ACTIVE', 'EDITING', 'SENT', 'FAILED', 'ARCHIVED'].findIndex(
											(s) => s === status
										),
								]).map(([status, cs]) => (
									<Section key={status}>
										<Paragraph
											// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
											margin="0rem 2rem -2rem"
											color="$neutral2"
											bold
										>
											{
												{
													ACTIVE: 'AKTIV',
													SENT: 'GESENDET',
													FAILED: 'FEHLGESCHLAGEN',
													EDITING: 'ENTWÜRFE',
													ARCHIVED: 'ARCHIVIERT',
												}[status]
											}
										</Paragraph>
										{cs.map(renderCampaign)}
									</Section>
								));

							switch (cinemasGroupedCampaigns.length) {
								case 0:
									return null;
								case 1:
									return renderCampaignsByStatus(cinemasGroupedCampaigns[0].campaigns);
								default:
									return cinemasGroupedCampaigns.map(({ cinemas, campaigns }) => (
										<Section key={cinemas.key}>
											<Paragraph
												// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
												margin="0rem 2rem -2rem"
												color="$neutral2"
												bold
											>
												{cinemas.names.join(', ').toUpperCase()}
											</Paragraph>
											{renderCampaignsByStatus(campaigns)}
										</Section>
									));
							}
						}}
					</WithData>
				)}
			</WithData>
		</React.Fragment>
	);
}

export default CampaigningHome;
