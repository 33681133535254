import React from 'react';
import { Box, Paper } from '@mui/material';
import { cloneDeep } from 'lodash';

import { ImageEditor } from './ContentTypes/Image';
import { SpacerEditor } from './ContentTypes/Spacer';
import { RecommendationsEditor } from './ContentTypes/Recommendations';
import { ProgramEditor } from './ContentTypes/Program';
import { MarkdownTextEditor } from './ContentTypes/MarkdownText';
import { MarkdownTextWithImageEditor } from './ContentTypes/MarkdownTextWithImage';
import { HeaderEditor } from './ContentTypes/Header';
import { FooterEditor } from './ContentTypes/Footer';
import { ButtonEditor } from './ContentTypes/Button';
import { QRCodeEditor } from './ContentTypes/QRCode';
import { FilmSeriesEditor } from './ContentTypes/FilmSeries';
import { EmailElement } from '../../utils/campaignEmailContent';
import { Brand } from 'utils/theme';
import IconButton from '../../components/IconButton';

export const typeConversionKeys = {
	HEADER: 'Kopfzeile',
	IMAGE: 'Bild',
	TEXT: 'Text',
	TEXTWITHIMAGE: 'Bild&Text',
	PROGRAM: 'Filmprogramm',
	RECOMMENDATIONS: 'Filmempfehlungen',
	SPACER: 'Trenner',
	FOOTER: 'Fußzeile',
	BUTTON: 'Button',
	QRCODE: 'QRCode',
	SERIES: 'Filmreihen',
};

const spacerHeight = '2rem';

const EditorComponent = React.memo(
	({
		element,
		onChange,
		brand,
	}: {
		element?: EmailElement;
		onChange: (element: EmailElement) => void;
		brand: Brand;
	}) => {
		const updateElement = React.useCallback(
			(updatedElement: EmailElement) => {
				const clonedUpdatetElement = cloneDeep(updatedElement);
				onChange(clonedUpdatetElement);
			},
			[onChange]
		);

		if (!element) {
			return null;
		}

		switch (element.type) {
			case 'HEADER':
				return (
					<HeaderEditor
						updateElement={updateElement}
						element={element}
						brand={brand}
						spacerHeight={spacerHeight}
					/>
				);
			case 'FOOTER':
				return <FooterEditor updateElement={updateElement} element={element} brand={brand} />;
			case 'IMAGE':
				return (
					<ImageEditor
						updateElement={updateElement}
						element={element}
						brand={brand}
						spacerHeight={spacerHeight}
					/>
				);
			case 'SPACER':
				return (
					<SpacerEditor
						updateElement={updateElement}
						element={element}
						brand={brand}
						spacerHeight={spacerHeight}
					/>
				);
			case 'TEXT':
				return (
					<MarkdownTextEditor
						updateElement={updateElement}
						element={element}
						brand={brand}
						spacerHeight={spacerHeight}
					/>
				);
			case 'TEXTWITHIMAGE':
				return (
					<MarkdownTextWithImageEditor
						updateElement={updateElement}
						element={element}
						brand={brand}
					/>
				);
			case 'RECOMMENDATIONS':
				return (
					<RecommendationsEditor updateElement={updateElement} element={element} brand={brand} />
				);
			case 'PROGRAM':
				return <ProgramEditor updateElement={updateElement} element={element} brand={brand} />;
			case 'SERIES':
				return <FilmSeriesEditor updateElement={updateElement} element={element} brand={brand} />;
			case 'BUTTON':
				return (
					<ButtonEditor
						updateElement={updateElement}
						element={element}
						brand={brand}
						spacerHeight={spacerHeight}
					/>
				);
			case 'QRCODE':
				return (
					<QRCodeEditor
						updateElement={updateElement}
						element={element}
						brand={brand}
						spacerHeight={spacerHeight}
					/>
				);
			default:
				return null;
		}
	}
);

const EmailComponentControlPanel = ({
	element,
	onChange,
	onDelete,
	brand,
	onChangeEditingInProgress,
}: {
	element?: EmailElement;
	onChange: (element: EmailElement) => void;
	onDelete: (element: EmailElement) => void;
	brand: Brand;
	onChangeEditingInProgress: (val: boolean) => void;
}) => {
	const handleEndEditing = React.useCallback(() => {
		onChangeEditingInProgress(false);
	}, [onChangeEditingInProgress]);

	const handleDelete = React.useCallback(() => {
		if (element) {
			onDelete(element);
		}
	}, [element, onDelete]);

	const isDeletable = element && !['HEADER', 'FOOTER'].includes(element.type);

	return (
		<Paper>
			<Box
				width="100%"
				display="flex"
				justifyContent={isDeletable ? 'space-between' : 'flex-end'}
				m="0 0 2rem 0"
			>
				{isDeletable ? (
					<IconButton iconName="DeleteOutline" onClick={handleDelete} m="1rem 0 0 1rem" />
				) : null}
				<IconButton iconName="CloseOutlined" onClick={handleEndEditing} m="1rem 1rem 0 0" />
			</Box>
			<Box width="100%" p="0 2rem 2rem">
				<EditorComponent element={element} onChange={onChange} brand={brand} />
			</Box>
		</Paper>
	);
};

export default React.memo(EmailComponentControlPanel);
