import React from 'react';
import EditorFieldWrapper from '../../../../components/EditorFieldWrapper';

const TextEditorController = ({
	id,
	value,
	onChange,
	label = 'Texteditor',
	width = '100%',
	path,
}: {
	id?: string;
	value?: string;
	onChange: (path: string, newValue: any) => void;
	label?: string;
	width?: string;
	path: string;
}): JSX.Element => {
	const handleChange = React.useCallback(
		(textValue) => {
			onChange(path, textValue);
		},
		[onChange, path]
	);

	return (
		<EditorFieldWrapper
			width={width}
			label={label}
			id={id}
			value={value || ''}
			onChange={handleChange}
		/>
	);
};

export default React.memo(TextEditorController);
