import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

export type CastOrCrewMember = {
	id: string;
	fullName: string;
};

export const useSearchedCastOrCrewMember = (query?: string): undefined | CastOrCrewMember[] => {
	const { data, loading } = useQuery(
		gql`
			query SearchCastOrCrewMember($fullName: String!) {
				searchCastOrCrewMember(fullName: $fullName) {
					id
					fullName
				}
			}
		`,
		{
			variables: {
				fullName: query,
			},
			skip: !query,
		}
	);

	return React.useMemo(() => (!query ? [] : loading ? undefined : data?.searchCastOrCrewMember), [
		data?.searchCastOrCrewMember,
		loading,
		query,
	]);
};
