/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import { DateRangeField, Heading, Paragraph } from '@cinuru/components';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-native-components';

import { addMonths, format } from 'date-fns';
import SplitCinemaSelectField from '../../../components/SplitCinemaSelectField';
import { useQuery } from 'react-apollo';
import { default as gql } from 'graphql-tag';
import styledWeb from 'styled-components';

const Page = styledWeb.div`
page-break-after: always;
`;

const Card = styled.View`
	background-color: $background0;
	width: 100%;
	min-width: 80rem;
	padding: 2rem 5rem;
	elevation: 2;
	margin-bottom: 2rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	align-items: center;
`;
const MainGraphWrapper = styled.View`
	height: 50rem;
	width: 100%;
`;
const MetricsWrapper = styled.View`
	min-width: 95rem;
`;
const CardHeading = styled(Heading)`
	text-align: center;
`;
const InfoParagraph = styled(Paragraph)`
	width: 80%;
`;
const Seperator = styled.View`
	background-color: $background1;
	width: 100%;
	height: 0.2rem;
	margin: 5rem;
	border-radius: 3rem;
`;

const FIELD_MARGINS = [0.5, 1];

const encodeDate = (date) => format(date, 'yyyy-MM-dd');
export const MovieStats = () => {
	const [dateRange, setDateRange] = useState({
		from: encodeDate(addMonths(new Date(), -1)),
		to: encodeDate(new Date()),
	});
	const [cinemaIds1, setCinemaIds1] = useState(['Q2luZW1hOjI1', 'Q2luZW1hOjIz', 'Q2luZW1hOjIx']);
	const cinemaNamesRes = useQuery(
		gql`
			{
				currentUser {
					id
					privileges {
						adminForCinemas {
							id
							name
						}
					}
				}
			}
		`,
		{ fetchPolicy: 'cache-first' }
	);
	const cinemaNameMap = new Map(
		cinemaNamesRes.data.currentUser.privileges.adminForCinemas.map((r) => [r.id, r.name])
	);
	const cinemaNames = cinemaIds1.map((id) => cinemaNameMap.get(id));

	return (
		<>
			<Page>
				<Card>
					<CardHeading>Auswahl</CardHeading>
					<MetricsWrapper>
						<DateRangeField
							nMargins={FIELD_MARGINS}
							onChange={setDateRange}
							value={dateRange}
							label="Zeitraum"
						/>
						<SplitCinemaSelectField
							onChange={setCinemaIds1}
							nMargins={[1]}
							multi
							value={cinemaIds1}
							label="Kinos "
						/>
					</MetricsWrapper>
				</Card>
			</Page>
			<Page>
				<Card>
					<CardHeading>Filmstatistiken</CardHeading>
				</Card>
			</Page>
		</>
	);
};
