import React from 'react';
import styled from 'styled-native-components';
import { useTheme } from 'styled-native-components';
import { ResponsiveLine } from '@nivo/line';
import chroma from 'chroma-js';
import { format, subDays } from 'date-fns';
import { range } from 'lodash';

/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */

const DiagramWrapper = styled.View`
	width: 20rem;
	height: 18rem;
`;
const Tooltip = styled.Text`
	background-color: white;
	font-size: 8pt;
`;
export const MiniLineChart = ({ data, yScaleMin = 0, yScaleMax = 'auto' }) => {
	const theme = useTheme();

	//[theme.colors.accent0]{ scheme: 'nivo' }
	return (
		<DiagramWrapper>
			<ResponsiveLine
				data={data}
				margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
				curve="linear"
				xScale={{ type: 'point' }}
				yScale={{ type: 'linear', min: yScaleMin, max: yScaleMax, stacked: true, reverse: false }}
				enableArea={true}
				enableGridX={false}
				enableGridY={false}
				enablePoints={false}
				animate={false}
				axisTop={null}
				axisRight={null}
				axisLeft={null}
				axisBottom={null}
				colors={[theme.colors.accent0]}
				useMesh={true}
				tooltip={(args) => {
					console.log(args);
					return (
						<Tooltip>{`${args.point.data.x}: ${Number(args.point.data.y).toLocaleString('de-De')} ${
							args.point.serieId
						}`}</Tooltip>
					);
				}}
				crosshairType="bottom"
			/>
		</DiagramWrapper>
	);
};
export const MiniLineChart2 = ({ data, yScaleMin = 0 }) => {
	const theme = useTheme();
	const colors = chroma.scale(theme.colors.accentGradient0).colors(4);
	//[theme.colors.accent0]{ scheme: 'nivo' }
	return (
		<DiagramWrapper>
			<ResponsiveLine
				data={data}
				margin={{ top: 10, right: 10, bottom: 40, left: 10 }}
				curve="linear"
				xScale={{ type: 'point' }}
				yScale={{ type: 'linear', min: yScaleMin, max: 'auto', stacked: false, reverse: false }}
				enableArea={true}
				enableGridX={false}
				enableGridY={false}
				enablePoints={false}
				animate={false}
				axisTop={null}
				axisRight={null}
				axisLeft={null}
				axisBottom={null}
				colors={colors}
				useMesh={true}
				tooltip={(args) => {
					return (
						<Tooltip>{`${args.point.data.x}: ${Number(args.point.data.y).toLocaleString('de-De')} ${
							args.point.serieId
						}`}</Tooltip>
					);
				}}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom',
						direction: 'column',
						justify: false,
						translateY: 40,
						itemsSpacing: 2,
						itemWidth: 80,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 10,
					},
				]}
				crosshairType="bottom"
			/>
		</DiagramWrapper>
	);
};

export const data = [
	{
		id: 'Neue Nutzer',
		data: [
			[8, 720],
			[7, 700],
			[6, 630],
			[5, 680],
			[4, 918],
			[3, 1020],
			[2, 804],
			[1, 880],
		].map(([day, numUsers]) => ({
			x: format(subDays(new Date(), day), 'dd.MM.yyyy'),
			y: numUsers,
		})),
	},
];
export const data2 = [
	{
		id: 'Neue Plus-Mitglieder',
		data: [
			[8, 620],
			[7, 560],
			[6, 530],
			[5, 580],
			[4, 818],
			[3, 920],
			[2, 804],
			[1, 780],
		].map(([day, numUsers]) => ({
			x: format(subDays(new Date(), day), 'dd.MM.yyyy'),
			y: numUsers,
		})),
	},
];
export const data4 = [
	{
		id: 'Gesammelt',
		data: range(8).map((day) => ({
			x: format(subDays(new Date(), 8 - day), 'dd.MM.yyyy'),
			y: Math.round(Math.random() * 2000) + Math.round(30000 / 7),
		})),
	},
	{
		id: 'Eingelöst',
		data: range(8).map((day) => ({
			x: format(subDays(new Date(), 8 - day), 'dd.MM.yyyy'),
			y: Math.round(Math.random() * 1000) + Math.round(17000 / 7),
		})),
	},
];
export const data5 = [
	{
		id: 'Statuspunkte',
		data: range(8).map((day) => ({
			x: format(subDays(new Date(), day), 'dd.MM.yyyy'),
			y: Math.round(Math.random() * 8000) + Math.round(380000 / 7),
		})),
	},
];
