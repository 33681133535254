import React from 'react';
import { times } from 'lodash';

import SearchSelectField, { SelectFieldItem } from '../../../../components/SearchSelectField';

type SelectControllerType =
	| 'FONT_FAMILY'
	| 'FONT_SIZE'
	| 'FONT_ALIGN'
	| 'IMAGE_ORIENTATION'
	| 'NUMBER_OF_MOVIES'
	| 'MARKDOWN_TYPE'
	| 'NUMBER_OF_WEEKS'
	| 'LIMIT_PROGRAM';

const optionsPerType: {
	[key in SelectControllerType]: { [key: string]: string | undefined } | number[];
} = {
	FONT_SIZE: {
		'- Voreinstellung -': undefined,
		'sehr klein': 'xs',
		'klein': 's',
		'mittel': 'm',
		'groß': 'l',
		'sehr groß': 'xl',
	},
	FONT_FAMILY: {
		'- Voreinstellung -': undefined,
		'Arial': 'Arial',
		'Verdana': 'Verdana',
		'Times New Roman': 'TimesNewRoman',
		'Georgia': 'Georgia',
		'Tahoma': 'Tahoma',
		'Lucida': 'Lucida',
	},
	FONT_ALIGN: {
		'- Voreinstellung -': undefined,
		'linksbündig': 'left',
		'zentriert': 'center',
		'rechtsbündig': 'right',
	},
	IMAGE_ORIENTATION: {
		links: 'left',
		rechts: 'right',
	},
	NUMBER_OF_MOVIES: [1, 2, 3],
	MARKDOWN_TYPE: {
		Überschrift: 'HEADING',
		Fließtext: 'PARAGRAPH',
	},
	NUMBER_OF_WEEKS: times(50).map((i) => i + 1),
	LIMIT_PROGRAM: {
		'Nur Filme der nächsten Woche anzeigen': 'NEXT_WEEK_ONLY',
		'Alle Filme anzeigen': 'ALL_UPCOMING_MOVIES',
	},
};

const typeLabelDict: { [key in SelectControllerType]: string } = {
	FONT_SIZE: 'Schriftgröße',
	FONT_FAMILY: 'Schriftart',
	FONT_ALIGN: 'Textausrichtung',
	IMAGE_ORIENTATION: 'Bildposition',
	NUMBER_OF_MOVIES: 'Anzahl der Filmempfehlungen',
	MARKDOWN_TYPE: 'Textart',
	NUMBER_OF_WEEKS: 'Zeitliche Begrenzung in Wochen',
	LIMIT_PROGRAM: 'Zeitliche Begrenzung für Programmvorschau',
};

const SelectController = ({
	children,
	value,
	onChange,
	path,
	type,
}: {
	children?: string;
	value?: string | number;
	onChange: (path: string, newValue: any) => void;
	path: string;
	type: SelectControllerType;
}): JSX.Element => {
	const handleChange = React.useCallback(
		(selectedItems: SelectFieldItem[]) => {
			onChange(path, selectedItems[0]?.value);
		},
		[onChange, path]
	);

	const allItems = React.useMemo(() => {
		const options = optionsPerType[type];
		if (Array.isArray(options)) {
			return options.map((item) => ({ label: item.toString(), value: item.toString() }));
		} else if (typeof options === 'object') {
			return Object.entries(options).map(([key, val]) => ({ label: key, value: val }));
		} else {
			return [];
		}
	}, [type]);

	const defaultItems = React.useMemo(() => allItems.filter(({ value: v }) => v === value), [
		allItems,
		value,
	]);

	return (
		<SearchSelectField
			label={children || typeLabelDict[type]}
			onChange={handleChange}
			variant="outlined"
			allItems={allItems}
			defaultItems={defaultItems}
			width="100%"
		/>
	);
};

export default React.memo(SelectController);
