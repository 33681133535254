import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import type { BoxProps as MuiBoxProps } from '@mui/material/Box';

interface LoadingIndicatorProps extends MuiBoxProps {
	m?: string;
	p?: string;
	width?: string;
	height?: string;
	onClick?: (val?: any) => any;
}

const LoadingIndicator = ({
	m,
	p,
	width = '100%',
	height = '100%',
	...rest
}: LoadingIndicatorProps): JSX.Element => {
	return (
		<Box
			m={m}
			p={p}
			width={width}
			height={height}
			display="flex"
			justifyContent="center"
			alignItems="center"
			{...rest}
		>
			<CircularProgress size={24} />
		</Box>
	);
};

export default React.memo(LoadingIndicator);
