import * as React from 'react';
import styledWeb from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Box } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

const StyledAccordionSummary = styledWeb(AccordionSummary)<{
	expanded: 'true' | 'false';
	color?: string;
}>((p) => ({
	borderBottomColor: p.theme.palette.grey[200],
	borderBottomStyle: 'solid',
	borderBottomWidth: p.expanded === 'true' ? 1 : 0,
}));

const Expandable = ({
	label,
	children,
	margin,
	padding,
	defaultExpanded = false,
	width,
	disabled,
}: {
	label: string;
	children: JSX.Element | JSX.Element[];
	margin?: string;
	padding?: string;
	defaultExpanded?: boolean;
	width?: string;
	disabled?: boolean;
}): JSX.Element => {
	const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);
	const handleToggleExpanded = React.useCallback(() => {
		setExpanded((b) => !b);
	}, []);

	return (
		<Box m={margin} width={width}>
			<Accordion expanded={expanded} onChange={handleToggleExpanded} disabled={disabled}>
				<StyledAccordionSummary
					expanded={expanded.toString() as 'true' | 'false'}
					expandIcon={<KeyboardArrowDown />}
				>
					<Typography>{label}</Typography>
				</StyledAccordionSummary>
				<Box component={AccordionDetails} padding={padding}>
					{children}
				</Box>
			</Accordion>
		</Box>
	);
};

export default Expandable;
