import React from 'react';
import styled from 'styled-components';
import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField';

const StyledInputLabel = styled(InputLabel)<{ $error?: boolean }>`
	color: ${(p) => (p.$error ? p.theme.palette.error.main : '')};
	background-color: ${(p) => p.theme.palette.common.white};
	padding: 0 0.5rem 0 0;
`;

const FormControlWrapper = ({
	disabled,
	errorMessage,
	variant,
	label,
	children,
	flex,
	m,
	onClick,
	width,
}: {
	disabled?: boolean;
	errorMessage?: string;
	variant?: TextFieldProps['variant'];
	label?: string;
	children?: JSX.Element | null;
	flex?: boolean;
	m?: string;
	onClick?: () => void;
	width?: string;
}) => {
	return (
		<Box flex={flex ? '1' : undefined} m={m} width={width}>
			<FormControl
				fullWidth
				variant={variant}
				error={Boolean(errorMessage)}
				disabled={disabled}
				onClick={onClick}
			>
				{label ? (
					<StyledInputLabel $error={Boolean(errorMessage)} variant={variant}>
						{label}
					</StyledInputLabel>
				) : null}
				{children}
				{errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
			</FormControl>
		</Box>
	);
};

export default React.memo(FormControlWrapper);
