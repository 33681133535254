import React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@mui/material';
import MuiButton from '@mui/material/Button';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';

import ChevronRight from '@mui/icons-material/ChevronRight';
import EditRounded from '@mui/icons-material/EditRounded';
import SendRounded from '@mui/icons-material/SendRounded';
import CancelScheduleSend from '@mui/icons-material/CancelScheduleSend';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import PhoneIphoneOutlined from '@mui/icons-material/PhoneIphoneOutlined';
import DesktopMacOutlined from '@mui/icons-material/DesktopMacOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import Search from '@mui/icons-material/Search';
import Info from '@mui/icons-material/Info';

export type IconName =
	| 'ChevronRight'
	| 'EditRounded'
	| 'SendRounded'
	| 'CancelScheduleSend'
	| 'SaveOutlined'
	| 'Add'
	| 'DeleteOutline'
	| 'PhoneIphoneOutlined'
	| 'DesktopMacOutlined'
	| 'CloseOutlined'
	| 'Search'
	| 'Info';
interface ButtonProps extends MuiButtonProps {
	label?: string;
	m?: string;
	p?: string;
	loading?: boolean;
	loadingVariant?: MuiButtonProps['variant'];
	loadingText?: string;
	id?: string;
	onClick?: (val?: any) => any;
	endIconName?: IconName;
	startIconName?: IconName;
}

const iconDict: { [key in IconName]: (props?: any) => JSX.Element } = {
	ChevronRight: (props) => <ChevronRight {...props} />,
	EditRounded: (props) => <EditRounded {...props} />,
	SendRounded: (props) => <SendRounded {...props} />,
	CancelScheduleSend: (props) => <CancelScheduleSend {...props} />,
	SaveOutlined: (props) => <SaveOutlined {...props} />,
	Add: (props) => <Add {...props} />,
	DeleteOutline: (props) => <DeleteOutline {...props} />,
	PhoneIphoneOutlined: (props) => <PhoneIphoneOutlined {...props} />,
	DesktopMacOutlined: (props) => <DesktopMacOutlined {...props} />,
	CloseOutlined: (props) => <CloseOutlined {...props} />,
	Search: (props) => <Search {...props} />,
	Info: (props) => <Info {...props} />,
};

const StyledCircularProgress = styled(CircularProgress)`
	color: ${(p) => p.theme.customColors.grey};
`;

const Absolute = styled(Box)`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	pointer-events: cursor;
`;

const Button = ({
	label,
	loading,
	loadingVariant,
	loadingText,
	m,
	p,
	variant,
	children,
	id,
	onClick,
	startIconName,
	endIconName,
	...rest
}: ButtonProps): JSX.Element => {
	const handleClick = React.useCallback(() => {
		onClick?.(id);
	}, [id, onClick]);

	return (
		<Box m={m} p={p} position="relative">
			<MuiButton
				variant={loading ? (loadingVariant ? loadingVariant : 'loadingButton') : variant}
				onClick={handleClick}
				endIcon={endIconName && iconDict[endIconName]?.()}
				startIcon={startIconName && iconDict[startIconName]?.()}
				{...rest}
			>
				{loading && loadingText ? loadingText : label || children}
			</MuiButton>
			{loading && !loadingText ? (
				<Absolute>
					<StyledCircularProgress size={24} />
				</Absolute>
			) : null}
		</Box>
	);
};

export default React.memo(Button);
