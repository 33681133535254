import React from 'react';
import { Box } from '@mui/material';
import { Prompt } from 'react-router';

import StickyHeaderBar from './StickyHeaderBar';
import { useDivDimensions } from '../utils/dimensions';
import { IconName as _ButtonIconName } from './Button';
import { IconName as _IconButtonIconName } from './Button';
import ProgressBox from '../components/ProgressBox';

export type ButtonIconName = _ButtonIconName;
export type IconButtonIconName = _IconButtonIconName;

const HEADER_HEIGHT = 10;

const StickyHeaderWrapper = ({
	label,
	buttons,
	children,
	maxContentWidth = '100rem',
	showWarningOnLeave,
	warningOnLeave = 'Änderungen werden nicht automatisch gespeichert! Fortfahren?',
	disabled,
	isLoading,
}: {
	label?: string;
	buttons: {
		label: string;
		icon?: JSX.Element;
		disabled?: boolean;
		onClick?: () => void;
		loading?: boolean;
		loadingText?: string;
		startIconName?: ButtonIconName;
		endIconName?: ButtonIconName;
		collapsedIconName: IconButtonIconName;
	}[];
	children: JSX.Element | null | (null | JSX.Element)[];
	maxContentWidth?: string;
	showWarningOnLeave?: boolean;
	warningOnLeave?: string;
	disabled?: boolean;
	isLoading?: boolean;
}): JSX.Element => {
	const containerRef = React.useRef(null);
	const containerDimensions = useDivDimensions(containerRef);
	return (
		<Box ref={containerRef} width="100%">
			{showWarningOnLeave ? <Prompt message={warningOnLeave} when={showWarningOnLeave} /> : null}
			<StickyHeaderBar
				label={label}
				buttons={buttons}
				containerDimensions={containerDimensions}
				height={HEADER_HEIGHT}
				additionalLeftWidth={16}
				collapsedContentBreakpoint={800}
				disabled={disabled || isLoading}
			/>
			<Box
				// maximum width but not wider than maxContentWidth, horizontally centered
				m={`${HEADER_HEIGHT}rem auto 0 auto`}
				maxWidth={maxContentWidth}
				flex="1"
			>
				{isLoading ? <ProgressBox /> : <>{children}</>}
			</Box>
		</Box>
	);
};

export default StickyHeaderWrapper;
