import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import type { BoxProps } from '@mui/material/Box';

type ObjectFit = 'fill' | 'cover' | 'contain' | 'none' | 'scale-down';
interface ExtendedBoxProps extends BoxProps {
	src?: string;
	navigateTo?: string;
	objectFit?: ObjectFit;
	onClick?: (id?: any) => void;
	id?: string;
}

const ContentWrapper = styled(Box)`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const PotentiallPointerBox = styled(Box)<{ $pointer?: boolean }>`
	display: flex;
	position: relative;
	:hover {
		cursor: ${(p) => (p.$pointer ? 'pointer' : 'default')};
	}
`;

const StyledImg = styled.img<{ $objectFit?: ObjectFit }>`
	object-fit: ${(p) => p.$objectFit || 'fill'};
`;

const ImageBox = ({
	src,
	navigateTo,
	onClick,
	children,
	width,
	height,
	objectFit,
	id,
	...rest
}: ExtendedBoxProps) => {
	const history = useHistory();

	const handleClick = React.useCallback(() => {
		if (navigateTo) {
			history.push(navigateTo as string);
		} else {
			onClick?.(id);
		}
	}, [history, id, navigateTo, onClick]);

	return (
		<PotentiallPointerBox
			$pointer={Boolean(onClick) || Boolean(navigateTo)}
			width={width || '100%'}
			height={height}
			onClick={handleClick}
			{...rest}
		>
			<StyledImg $objectFit={objectFit} src={src} width="100%" height={height ? '100%' : 'auto'} />
			<ContentWrapper>{children}</ContentWrapper>
		</PotentiallPointerBox>
	);
};

export default React.memo(ImageBox);
