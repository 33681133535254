import React from 'react';
import { Input, Checkbox, Button } from '../../components/AntDReplacements';

//TODO: Cleanup this styles mess
export const HeaderSection = ({ cinema, updateProperty, save }) => (
	<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
		<span style={{ display: 'flex', flexWrap: 'wrap' }}>
			<span
				style={{
					fontSize: '12pt',
					fontWeight: 'bold',
					marginRight: '10px',
				}}
			>
				Kinoname:
			</span>
			<Input
				style={{
					width: '18rem',
					fontSize: '12pt',
					fontWeight: 'bold',
				}}
				value={cinema.name}
				placeholder="Kinoname"
				onChange={(event) => updateProperty('name', event.target.value)}
			/>
		</span>
		<span style={{ marginLeft: '10px' }}>Id: {cinema.id}</span>
		<span>
			<Checkbox
				checked={cinema.hasBonusProgram}
				onChange={(event) => updateProperty('hasBonusProgram', event.target.checked)}
			>
				Bonussystem aktiv
			</Checkbox>
			<Button type="primary" onClick={() => save()}>
				Speichern
			</Button>
		</span>
	</div>
);
