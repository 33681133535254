/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';
import styled, { useTheme } from 'styled-native-components';

import { Heading, MoviePoster, Paragraph } from '@cinuru/components';
import { format, subMinutes } from 'date-fns';
import { useState, useEffect } from 'react';
import { range } from 'lodash';
import { Arrow } from './Arrow';
import { PosterRanking } from './PosterRanking';
import { StatusLevelBarChart } from './StatusLevelBarChart';
import { data, data2, data4, data5, MiniLineChart, MiniLineChart2 } from './MiniLineCharts';
import { DemographicsBarChart } from './MiniDemographicsBarChart';
import { GroupSizeBarChart } from './GroupSizeBarChart';

import SplitCinemaSelectField from '../../components/SplitCinemaSelectField';

const CARD_WIDTH = 40;
const CARD_MARGIN = 0.5;
const STATS_WRAPPER_MARGIN = 1;

const Card = styled.View`
	width: ${CARD_WIDTH}rem;
	height: 34rem;
	margin: ${CARD_MARGIN}rem;
	elevation: 2;
	background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : '$background0')};
	border-radius: ${(p) => p.theme.borderRadius[1]};
`;
const DoubleWidthCard = styled(Card)`
	width: 81rem;
`;

const Wrapper = styled.View`
	display: flex;
	flex-direction: column;
`;

const RowWrapper = styled.View`
	margin: 1rem ${CARD_MARGIN + STATS_WRAPPER_MARGIN}rem;
	background-color: $background0;
	border-radius: ${(p) => p.theme.borderRadius[1]};
	elevation: 2;
	width: ${(p) => p.width}px;
`;

const StatsWrapper = styled.View`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 ${STATS_WRAPPER_MARGIN}rem 1rem;
`;
const CinemaSelectFieldWrapper = styled.View`
	background-color: $background0;
	padding: 2rem;
	max-width: 163rem;

	border-radius: ${(p) => p.theme.borderRadius[1]};
	elevation: 2;
	margin: 0.5rem;
`;

const CardHeading = styled(Heading)`
	padding: 1rem 2rem;
	text-align: center;
	border-color: $border0;
	border-bottom-width: 0.5px;
	color: $neutral1;
`;
const CardContentWrapper = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 25rem;
`;
const KPIWrapper = styled.View`
	width: 18rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-left: 1rem;
`;
const AdditionalTicketsKPIWrapper = styled(KPIWrapper)`
	width: 23rem;
`;
const MoreWrapper = styled.View`
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	width: 100%;
	padding: 1rem 1rem 0 0;

	border-color: $border0;
	border-top-width: 0.5px;
`;

const StickerWrapper = styled.View`
	margin: 0.5rem 0 0.5rem 0;
	flex-direction: row;
	align-items: center;
	width: 100%;
`;
const StickerSectionWrapper = styled.View`
	flex-direction: column;
	padding: 0 1rem 0 1rem;
	height: 25rem;
	justify-content: center;
`;

const InitialsWrapper = styled.View`
	width: 5rem;
	height: 5rem;
	elevation: 2;
	align-items: center;
	justify-content: center;
	border-radius: 10000rem;
	background-color: $background1;
	margin: 0 1rem 0 1rem;
`;

const CityInitials = ({ text }) => {
	return (
		<InitialsWrapper>
			<Paragraph color="$neutral2" bold={true}>
				{text}
			</Paragraph>
		</InitialsWrapper>
	);
};

const Dashboard = () => {
	const theme = useTheme();
	const [fakeActiveUsers, setFakeActiveUsers] = useState(
		range(30).map(() => Math.round(Math.random() * 200) + 11100)
	);
	const data3 = [
		{
			id: 'Aktive Nutzer',
			data: fakeActiveUsers.map((val, idx) => ({
				x: format(subMinutes(new Date(), idx), 'HH:mm'),
				y: val,
			})),
		},
	];
	useEffect(() => {
		const interval = setInterval(() => {
			console.log('runInterval');
			const current = [...fakeActiveUsers];
			current.shift();
			current.push(Math.round(Math.random() * 400) + 11100);
			setFakeActiveUsers(current);
		}, 1000);
		return () => clearInterval(interval);
	}, [setFakeActiveUsers, fakeActiveUsers]);

	const [selectedCinemas, setSelectedCinemas] = React.useState(null);

	const handleChange = React.useCallback((selected) => {
		setSelectedCinemas(selected);
	}, []);

	// logic to dictate RowWrapperWidth based on statsWrapperWidth
	const [statsWrapperWidth, setStatsWrapperWidth] = React.useState(100);
	const [statsWrapperNeededWidth, setStatsWrapperNeededWidth] = React.useState(100);
	const handleStatsWrapperLayout = React.useCallback(({ nativeEvent: { layout: { width } } }) => {
		setStatsWrapperWidth(width);
	}, []);
	React.useEffect(() => {
		const cardWidthPx = CARD_WIDTH * theme.rem;
		const cardMarginsPx = CARD_MARGIN * theme.rem;
		const cardSizePx = cardWidthPx + 2 * cardMarginsPx;
		const cardsPerRow = Math.floor(statsWrapperWidth / cardSizePx);
		setStatsWrapperNeededWidth(cardsPerRow * cardSizePx - 2 * cardMarginsPx);
	}, [statsWrapperWidth, theme.rem]);

	return (
		<Wrapper>
			<RowWrapper width={statsWrapperNeededWidth}>
				<SplitCinemaSelectField onChange={handleChange} nMargins={[1]} multi />
			</RowWrapper>
			<StatsWrapper onLayout={handleStatsWrapperLayout}>
				<Card>
					<CardHeading size="xs">Aktive Nutzer</CardHeading>
					<CardContentWrapper>
						<MiniLineChart data={data3} yScaleMin={10500} yScaleMax={12500} />
						<KPIWrapper>
							<Heading color="$accent0">
								{Number(fakeActiveUsers[fakeActiveUsers.length - 1]).toLocaleString('de-De')}
							</Heading>
							<Paragraph>Nutzer</Paragraph>
							<Paragraph size="s">sind zur Zeit aktiv.</Paragraph>
						</KPIWrapper>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>
				<Card>
					<CardHeading size="xs">Neue Nutzer</CardHeading>
					<CardContentWrapper>
						<MiniLineChart data={data} />
						<KPIWrapper>
							<Heading color="$accent0">+7.304</Heading>
							<Paragraph>Nutzer</Paragraph>
							<Paragraph size="s">in den letzten 7 Tagen.</Paragraph>
							<Arrow percent={-30} />
						</KPIWrapper>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>
				<Card>
					<CardHeading size="xs">Neue Plus-Mitglieder</CardHeading>
					<CardContentWrapper>
						<MiniLineChart data={data2} />
						<KPIWrapper>
							<Heading color="$accent0">+6.184</Heading>
							<Paragraph>Plus-Mitglieder</Paragraph>
							<Paragraph size="s">in den letzten 7 Tagen.</Paragraph>
							<Arrow percent={12} />
						</KPIWrapper>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>

				<Card>
					<CardHeading size="xs">Merkliste</CardHeading>
					<CardContentWrapper>
						<PosterRanking
							movies={[
								{
									poster: 'https://static.cinuru.com/public/posterSmall/1523464641258.jpg',
									kpiValue: '83.468',
								},
								{
									poster: 'https://static.cinuru.com/public/posterSmall/1540585266999.jpg',
									kpiValue: '41.889',
								},
								{
									poster: 'https://static.cinuru.com/public/posterSmall/1557672914476.jpg',
									kpiValue: '3.268',
								},
							]}
							description="Vormerkungen"
						/>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>
				<Card>
					<CardHeading size="xs">Interaktionen</CardHeading>
					<CardContentWrapper>
						<PosterRanking
							movies={[
								{
									poster: 'https://static.cinuru.com/public/posterSmall/1575966651946.jpg',
									kpiValue: '487.998',
								},
								{
									poster: 'https://static.cinuru.com/public/posterSmall/1597667699497.jpg',
									kpiValue: '383.233',
								},
								{
									poster: 'https://static.cinuru.com/public/posterSmall/1540585266999.jpg',
									kpiValue: '310.112',
								},
							]}
							description="Interaktionen"
						/>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>

				<Card>
					<CardHeading size="xs">Ticketpunkte</CardHeading>
					<CardContentWrapper>
						<MiniLineChart2 data={data4} yScaleMin={0} />
						<KPIWrapper>
							<Heading color="$accent0">+38.461</Heading>
							<Paragraph>Punkte gesammelt</Paragraph>
							<Heading color="$accent0">-21.461</Heading>
							<Paragraph>Punkte eingelöst</Paragraph>
							<Paragraph size="s">in den letzten 7 Tagen.</Paragraph>
						</KPIWrapper>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>

				<Card>
					<CardHeading size="xs">Statuspunkte</CardHeading>
					<CardContentWrapper>
						<MiniLineChart data={data5} yScaleMin={4500} />
						<KPIWrapper>
							<Heading color="$accent0">+738.443</Heading>
							<Paragraph>Punkte gesammelt</Paragraph>
							<Paragraph size="s">in den letzten 7 Tagen.</Paragraph>
							<Arrow percent={+15} />
						</KPIWrapper>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>
				<Card>
					<CardHeading size="xs">Statuslevel</CardHeading>
					<CardContentWrapper>
						<StatusLevelBarChart />
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>
				<DoubleWidthCard>
					<CardHeading size="xs">Demografie</CardHeading>
					<CardContentWrapper>
						<DemographicsBarChart />
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</DoubleWidthCard>
				<Card>
					<CardHeading size="xs">Sticker</CardHeading>
					<CardContentWrapper>
						<StickerSectionWrapper>
							<StickerWrapper>
								<MoviePoster
									width="8rem"
									height="8rem"
									poster={
										'https://cdn-app.cineplex.de/app/cineplex/achievements/10-Nachtschicht-500x500.png'
									}
								/>
								<Paragraph size="s" width="13.5rem" style={{ paddingLeft: '2rem' }}>
									Nachtschicht
								</Paragraph>
								<Paragraph color="$accent0" width="5rem" style={{ textAlign: 'right' }}>
									2.318
								</Paragraph>
								<Paragraph size="s" style={{ paddingLeft: '0.5rem' }}>
									mal vergeben{' '}
								</Paragraph>
							</StickerWrapper>
							<StickerWrapper>
								<MoviePoster
									width="7rem"
									height="7rem"
									style={{ marginLeft: '0.5rem' }}
									poster={
										'https://cdn-app.cineplex.de/app/cineplex/achievements/0-welcome-500x500.png'
									}
								/>
								<Paragraph size="s" width="13.5rem" style={{ paddingLeft: '2.5rem' }}>
									Welcome
								</Paragraph>
								<Paragraph color="$accent0" width="5rem" style={{ textAlign: 'right' }}>
									1.966
								</Paragraph>
								<Paragraph size="s" style={{ paddingLeft: '0.5rem' }}>
									mal vergeben{' '}
								</Paragraph>
							</StickerWrapper>
							<StickerWrapper>
								<MoviePoster
									width="6rem"
									height="6rem"
									poster="https://cdn-app.cineplex.de/app/cineplex/achievements/7-StarWars-500x500.png"
									style={{ marginLeft: '1rem' }}
								/>
								<Paragraph size="s" width="13.5rem" style={{ paddingLeft: '3rem' }}>
									Star-Wars
								</Paragraph>
								<Paragraph color="$accent0" width="5rem" style={{ textAlign: 'right' }}>
									1.408
								</Paragraph>
								<Paragraph size="s" style={{ paddingLeft: '0.5rem' }}>
									mal vergeben{' '}
								</Paragraph>
							</StickerWrapper>
						</StickerSectionWrapper>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>
				<Card>
					<CardHeading size="xs">Aktivste Standorte</CardHeading>
					<CardContentWrapper>
						<StickerSectionWrapper>
							<StickerWrapper>
								<CityInitials text="AC" />
								<Paragraph size="s" width="15rem" style={{ paddingLeft: '2rem' }}>
									Aachen
								</Paragraph>
								<Paragraph color="$accent0" width="5rem" style={{ textAlign: 'right' }}>
									438
								</Paragraph>
								<Paragraph size="s" style={{ paddingLeft: '0.5rem' }}>
									neue Nutzer
								</Paragraph>
							</StickerWrapper>
							<StickerWrapper>
								<CityInitials text="B" />
								<Paragraph size="s" width="15rem" style={{ paddingLeft: '2rem' }}>
									Berlin Titania
								</Paragraph>
								<Paragraph color="$accent0" width="5rem" style={{ textAlign: 'right' }}>
									307
								</Paragraph>
								<Paragraph size="s" style={{ paddingLeft: '0.5rem' }}>
									neue Nutzer
								</Paragraph>
							</StickerWrapper>
							<StickerWrapper>
								<CityInitials text="MS" />
								<Paragraph size="s" width="15rem" style={{ paddingLeft: '2rem' }}>
									Münster
								</Paragraph>
								<Paragraph color="$accent0" width="5rem" style={{ textAlign: 'right' }}>
									240
								</Paragraph>
								<Paragraph size="s" style={{ paddingLeft: '0.5rem' }}>
									neue Nutzer
								</Paragraph>
							</StickerWrapper>
						</StickerSectionWrapper>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>
				<Card>
					<CardHeading size="xs">Gruppengrößen</CardHeading>
					<CardContentWrapper>
						<GroupSizeBarChart />
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</Card>
				<DoubleWidthCard>
					<CardHeading size="xs">Personalisierte Push-Nachrichten</CardHeading>
					<CardContentWrapper>
						<KPIWrapper>
							<Heading color="$accent0">+7.304</Heading>
							<Paragraph>Mehrverkäufe</Paragraph>
							<Paragraph size="s">
								in den letzten 7 Tagen, im Vergleich zur Kontrollgruppe.
							</Paragraph>
							<Arrow percent={+23} />
						</KPIWrapper>
						<PosterRanking
							width="30rem"
							movies={[
								{
									poster: 'https://static.cinuru.com/public/posterSmall/1523464641258.jpg',
									kpiValue: '83.468',
								},
								{
									poster: 'https://static.cinuru.com/public/posterSmall/1540585266999.jpg',
									kpiValue: '41.889',
								},
								{
									poster: 'https://static.cinuru.com/public/posterSmall/1557672914476.jpg',
									kpiValue: '3.268',
								},
							]}
							description="mal Empfohlen"
						/>
						<KPIWrapper>
							<Heading color="$accent0">303.304</Heading>
							<Paragraph>Versendete Nachrichten</Paragraph>
							<Paragraph size="s">in den letzten 7 Tagen.</Paragraph>
							<Arrow percent={+7} />
						</KPIWrapper>
					</CardContentWrapper>
					<MoreWrapper>
						<Paragraph size="s">&gt; Mehr Infos</Paragraph>
					</MoreWrapper>
				</DoubleWidthCard>
			</StatsWrapper>
		</Wrapper>
	);
};

export default Dashboard;
