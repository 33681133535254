import React from 'react';
import { Row, Col, Input, Checkbox } from '../../components/AntDReplacements';

const TextArea = Input.TextArea;
const styles = {
	wrapper: {
		marginTop: '20px',
		marginBottom: '40px',
	},
};
//TODO: Clean up this styles mess
export const AccessibilitySection = ({ cinema, updateAccessibility }) => (
	<div style={styles.wrapper}>
		<Row>
			<Col>Barierefreiheit</Col>
		</Row>
		<Row style={{ margin: '10px 0' }}>
			<Col span={7}>
				<Checkbox
					checked={cinema.barrierFree}
					onChange={(event) => updateAccessibility('barrierFree', event.target.checked)}
				>
					Barrierefrei
				</Checkbox>
				<TextArea
					value={cinema.barrierFreeText}
					rows={5}
					placeholder="Beschreibung..."
					onChange={(event) => updateAccessibility('barrierFreeText', event.target.value)}
				/>
			</Col>
			<Col span={7}>
				<Checkbox
					checked={cinema.hearingImpaired}
					onChange={(event) => updateAccessibility('hearingImpaired', event.target.checked)}
				>
					Geeignet für Gehörlose
				</Checkbox>
				<TextArea
					value={cinema.hearingImpairedText}
					rows={5}
					placeholder="Beschreibung..."
					onChange={(event) => updateAccessibility('hearingImpairedText', event.target.value)}
				/>
			</Col>
			<Col span={7}>
				<Checkbox
					checked={cinema.blind}
					onChange={(event) => updateAccessibility('blind', event.target.checked)}
				>
					Für Blinde geeignet
				</Checkbox>
				<TextArea
					value={cinema.blindText}
					rows={5}
					placeholder="Beschreibung..."
					onChange={(event) => updateAccessibility('blindText', event.target.value)}
				/>
			</Col>
		</Row>
	</div>
);
