/** old and needs a rewrite */

// @flow
import * as React from 'react';
import styled from 'styled-native-components';
import posed from 'react-native-pose';

import PortalTrigger from './PortalTrigger';
import Icon from './Icon';
import Paragraph from './Paragraph';

const Wrapper = styled.View`
	border-radius: 0.25rem;
	border-top-right-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
	border-width: 1px;
	border-color: $border0;
	overflow: hidden;
	margin: ${(p) => p.nMargins.join('rem ')}rem;
`;

const HeaderRow = styled.View`
	background-color: $accent0;
	flex-flow: row no-wrap;
	align-self: stretch;
	justify-content: space-evenly;
`;

const Row = styled.View`
	background-color: ${(p) => p.nColor};
	flex-flow: row no-wrap;
	align-self: stretch;
	justify-content: space-evenly;
`;

const FieldWrapper = styled(PortalTrigger)`
	flex: ${(p) =>
			p.flex === undefined ? 1 : typeof p.flex === 'number' ? p.flex : p.flex.join(' ')}
		${(p) => (p.nWidth || 0) * p.theme.rem}px;
	padding: 1rem;
	border-width: 1px;
	border-color: ${(p) => (p.hovered ? '$accent0' : 'transparent')};
	border-radius: 0.25rem;
	align-items: ${(p) =>
		p.align === 'left' ? 'flex-start' : p.align === 'right' ? 'flex-end' : p.align};
	${(p) =>
		p.hovered
			? `background-color: ${p.theme.colors.transparentize(0.85, p.theme.colors.accent0)};`
			: ''};
`;

const Border = styled.View`
	border-right-width: ${(p) => p.last || 1}px;
	border-right-color: ${(p) => p.nColor || '$border0'};
`;

const TooltipWrapper = styled(
	posed.View({
		enter: { opacity: 1 },
		exit: { opacity: 0 },
	})
)`
	background-color: ${(p) => p.theme.colors.transparentize(0.05, p.theme.colors.neutral0)};
	margin: 0.5rem 1rem;
	padding: 1rem 2rem;
	border-radius: 1rem;
	align-items: ${(p) =>
		p.align === 'left' ? 'flex-start' : p.align === 'right' ? 'flex-end' : p.align};
	elevation: 4;
`;

const InfoIcon = styled(Icon).attrs({
	name: 'infoBubble',
	color: '$neutral2',
	size: '1.5rem',
})`
	position: absolute;
	top: 0.5rem;
	right: 1rem;
`;

class Field extends React.Component {
	state = { active: false };

	handleMouseOver = () => !this.state.active && this.setState({ active: true });
	handleMouseLeave = () => this.setState({ active: false });

	renderOverlay = () => {
		const { tooltip, align } = this.props;
		return (
			<TooltipWrapper onMouseLeave={this.handleMouseLeave} align={align}>
				<Paragraph align={align} color="$background0">
					{tooltip}
				</Paragraph>
			</TooltipWrapper>
		);
	};

	render = () => {
		const {
			align,
			bold,
			value,
			tooltip,
			colLines,
			lastCol,
			nTextColor,
			nBorderColor,
			format,
		} = this.props;
		return (
			<>
				<FieldWrapper
					{...this.props}
					onMouseOver={this.handleMouseOver}
					onMouseLeave={this.handleMouseLeave}
					hovered={this.state.active}
					active={tooltip && this.state.active}
					renderOverlay={this.renderOverlay}
				>
					{typeof value === 'string' || typeof value === 'number' ? (
						<Paragraph bold={bold} align={align} color={nTextColor}>
							{format ? format(value) : value}
						</Paragraph>
					) : (
						value
					)}
					{tooltip ? <InfoIcon /> : null}
				</FieldWrapper>
				{colLines && !lastCol ? <Border nColor={nBorderColor} /> : null}
			</>
		);
	};
}

const getAlignment = (key, align, data) =>
	align || (!data[0] ? 'left' : typeof data[0][key] === 'number' ? 'right' : 'left');

const Table = ({
	nMargins,
	data,
	columns,
	colLines,
}: {
	nMargins: number[],
	data: { id: string, [key: string]: string | number }[],
	columns: {
		key: string,
		label: string,
		tooltip?: string,
		tooltipKey?: string,
		flex?: number | [number, number],
		nWidth?: number,
		align?: 'center' | 'left' | 'right',
		format: (string) => string,
	}[],
	colLines?: boolean,
}) => (
	<Wrapper nMargins={nMargins}>
		<HeaderRow>
			{
				// remove format
				// eslint-disable-next-line no-unused-vars
				columns.map(({ label, tooltip, key, align, format, ...options }, j) => (
					<Field
						key={key}
						{...options}
						nTextColor="$accentText0"
						align={getAlignment(key, align, data)}
						lastCol={j === columns.length - 1}
						colLines={colLines}
						value={label}
						tooltip={tooltip}
						bold
					/>
				))
			}
		</HeaderRow>
		{data.map((item, i) => (
			<Row key={item.id} nColor={i % 2 === 0 ? '$background1' : '$background0'}>
				{columns.map(({ key, tooltipKey, align, ...options }, j) => (
					<Field
						key={key}
						align={getAlignment(key, align, data)}
						{...options}
						lastCol={j === columns.length - 1}
						colLines={colLines}
						value={item[key]}
						tooltip={tooltipKey && item[tooltipKey]}
					/>
				))}
			</Row>
		))}
	</Wrapper>
);

Table.defaultProps = {
	nMargins: [0, 0, 1.5],
};

export default Table;
