import * as React from 'react';
import styled from 'styled-native-components';

import CinemaSelectField from '../../components/CinemaSelectField';
import LastMovies from './LastMovies';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	margin-bottom: 4rem;
	elevation: 2;
`;

const EditLastChances = () => {
	const [cinemaId, setCinemaId] = React.useState(null);

	return (
		<React.Fragment>
			<Wrapper>
				<CinemaSelectField onChange={setCinemaId} />
			</Wrapper>
			{cinemaId ? <LastMovies cinemaId={cinemaId} /> : null}
		</React.Fragment>
	);
};

export default EditLastChances;
