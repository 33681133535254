import * as React from 'react';
import { Box, Grid } from '@mui/material';
import type { BaseTextFieldProps } from '@mui/material/TextField';
import IntegerTextField, { IntegerTextFieldRef } from '../components/IntegerTextField';

export type IntegerRangeSelectFormRef = {
	validate: () => boolean;
};

const initStateValue = (defaultValue?: number, fallBackValue?: number): number | undefined => {
	return defaultValue === 0 ? 0 : defaultValue || fallBackValue || undefined;
};

const IntegerRangeSelectForm = React.forwardRef<
	IntegerRangeSelectFormRef,
	{
		fromLabel?: string;
		toLabel?: string;
		defaultFrom?: number;
		defaultTo?: number;
		fallbackFrom?: number;
		fallbackTo?: number;
		disabled?: boolean;
		onChange?: (
			value: [number, number] | [undefined, number] | [number, undefined] | [undefined, undefined]
		) => void;
		margin?: string;
		variant?: BaseTextFieldProps['variant'];
		flex?: boolean;
	}
>(
	(
		{
			fromLabel = 'Von',
			toLabel = 'Bis',
			defaultFrom,
			defaultTo,
			fallbackFrom,
			fallbackTo,
			disabled,
			onChange,
			margin,
			variant,
			flex,
		},
		ref
	): JSX.Element => {
		const [from, setFrom] = React.useState<number | undefined>(
			initStateValue(defaultFrom, fallbackFrom)
		);
		const [to, setTo] = React.useState<number | undefined>(initStateValue(defaultTo, fallbackTo));

		const handleChangeFrom = React.useCallback(
			(newValue?: number) => {
				setFrom(newValue);
				onChange?.([newValue, to]);
			},
			[onChange, to]
		);

		const handleChangeTo = React.useCallback(
			(newValue?: number) => {
				setTo(newValue);
				onChange?.([from, newValue]);
			},
			[from, onChange]
		);

		const fromTextFieldRef = React.useRef<IntegerTextFieldRef>(null);
		const toTextFieldRef = React.useRef<IntegerTextFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			const hasErrors = [fromTextFieldRef, toTextFieldRef]
				.map((r) => r.current?.validate())
				.some(Boolean);
			return hasErrors;
		}, []);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<Box m={margin} flex={flex ? '1' : undefined}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<IntegerTextField
							label={fromLabel}
							defaultValue={from}
							onChange={handleChangeFrom}
							variant={variant}
							disabled={disabled}
							ref={fromTextFieldRef}
						></IntegerTextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<IntegerTextField
							label={toLabel}
							defaultValue={to}
							onChange={handleChangeTo}
							variant={variant}
							disabled={disabled}
							ref={toTextFieldRef}
						></IntegerTextField>
					</Grid>
				</Grid>
			</Box>
		);
	}
);

export default React.memo(IntegerRangeSelectForm);
