import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { ChevronRight, ArrowBack } from '@mui/icons-material';

import DeviceFrame from './DeviceFrame';
import ProgressBox from '../../components/ProgressBox';

const Wrapper = styled(Box)`
	max-width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${(p) => p.theme.palette.grey[50]};
	height: 100%;
`;

const ArrowBackIcon = styled(ArrowBack)`
	color: ${(p) => p.theme.palette.common.white};
`;

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const AbsoluteBox = styled(Box)`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
`;

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
`;

const MovieButtonRow = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 6rem;
	max-width: 100%;
	border-radius: 1rem;
	overflow: hidden;
	margin-top: 3rem;
	padding-right: 1rem;
`;

const CustomAppViewPreviewer = ({
	messageTitle,
	teaser,
	message,
	banner,
	poster,
	linkLabel,
	loading,
	hasLink,
}: {
	messageTitle?: string;
	teaser?: string;
	message?: string;
	banner?: string;
	poster?: string;
	linkLabel?: string;
	loading?: boolean;
	hasLink: boolean;
}): JSX.Element | null => {
	const renderContent = React.useCallback(() => {
		return loading ? (
			<ProgressBox height="100%" />
		) : (
			<Wrapper boxShadow={3}>
				<Box width="100%" position="relative">
					<img src={banner} width="100%" height="auto" />
					<AbsoluteBox p="1rem">
						<ArrowBackIcon fontSize="large" />
					</AbsoluteBox>
				</Box>
				<Column p="1rem" alignSelf="stretch">
					<Row m="0 0 1rem">
						<Box
							flexShrink={0}
							height="20rem"
							width="13.3rem"
							borderRadius="0.5rem"
							overflow="hidden"
							m="0 1rem 0 0"
						>
							<img height="20rem" width="13.3rem" src={poster} height="100%" width="100%" />
						</Box>

						<Box flexDirection="column">
							<Typography variant="h5" fontWeight="bold" flexWrap="wrap">
								{messageTitle}
							</Typography>
							<Typography fontWeight="bold">{teaser}</Typography>
						</Box>
					</Row>
					<Typography>{message}</Typography>
					{hasLink && (
						<MovieButtonRow boxShadow={2}>
							<Row flex="1" height="100%" alignItems="center">
								<Row flex="1" justifyContent="center">
									<Typography fontWeight="bold">{linkLabel || ' '}</Typography>
								</Row>
							</Row>
							<ChevronRight />
						</MovieButtonRow>
					)}
				</Column>
			</Wrapper>
		);
	}, [banner, linkLabel, loading, message, messageTitle, poster, teaser, hasLink]);

	return <DeviceFrame margin="2rem 0 0 2rem" heightFactor={1} renderContent={renderContent} />;
};

export default CustomAppViewPreviewer;
