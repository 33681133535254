/** may need some refactoring, no native support */

// @flow
import * as React from 'react';
import styled from 'styled-native-components';

import Switch from './Switch';
import Paragraph from './Paragraph';

const Wrapper = styled.View`
	margin: ${(p) => p.nMargins.join('rem') + 'rem'};
	${(p) => (p.flex ? `flex: 1 0 30rem` : '')};
	min-width: 30rem;
	flex-direction: row;
	align-items: center;
`;

const SWITCH_MARGINS = [0, 1];

const SwitchField = ({ value, onChange, label, nMargins = [1, 0], flex }) => (
	<Wrapper nMargins={nMargins} flex={flex}>
		<Switch value={value} onValueChange={onChange} nMargins={SWITCH_MARGINS} />
		<Paragraph color="$neutral2" bold>
			{label}
		</Paragraph>
	</Wrapper>
);

export default SwitchField;
