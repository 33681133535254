import React from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Box } from '@mui/material';
import { Brand, createTheme } from '@cinuru/utils/theme';
import { ColorScheme } from '@cinuru/utils/theme/createColorScheme';
import { HexColorPicker } from 'react-colorful';

import TextField from './TextField';
import PopperWrapper from './PopperWrapper';

const getRelevantBrandThemeColors = (
	allBrandThemeColors: ColorScheme
): { name: string; value: string }[] => {
	const entries = Object.entries(allBrandThemeColors);
	const relevantBrandThemeColors = entries
		.filter(
			([name, value]) =>
				typeof value === 'string' &&
				['neutral', 'background', 'accent'].some((n) => name.includes(n))
		)
		.map(([name, value]) => ({ name, value }))
		.filter((item, index, array) => array.findIndex((i) => i.value === item.value) === index);
	return relevantBrandThemeColors;
};

const useRelevantBrandThemeColors = (brand: Brand): { name: string; value: string }[] => {
	const brandTheme = createTheme(brand, { darkMode: false });
	const allBrandThemeColors = brandTheme.colors;
	const relevantBrandThemeColors = React.useMemo(
		() => getRelevantBrandThemeColors(allBrandThemeColors),
		[allBrandThemeColors]
	);

	return relevantBrandThemeColors;
};

const COLOR_BOX_WIDTH = 2.5;

const ColorBox = styled(Box)`
	cursor: pointer;
	width: ${COLOR_BOX_WIDTH}rem;
	height: ${COLOR_BOX_WIDTH}rem;
`;

const ThemeColorsWrapper = styled(Box)`
	margin: 1rem 0 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: ${10 * COLOR_BOX_WIDTH}rem;
	border-radius: 0.5rem;
`;

const StyledColorPicker = styled(HexColorPicker)`
	.react-colorful__saturation-pointer {
		width: 2rem;
		height: 2rem;
		border-radius: 1rem;
	}
	.react-colorful__saturation-pointer,
	.react-colorful__hue-pointer {
		width: 2rem;
		height: 2rem;
		border-radius: 1rem;
	}
	.react-colorful__saturation,
	.react-colorful__hue {
		margin: 0.5rem 0;
		border-radius: 0.5rem;
		border-bottom: none;
	}
`;

const ColorPickerTextField = ({
	brand,
	defaultColor,
	fallBackColor,
	label = 'Farbe',
	onChange,
}: {
	brand: Brand;
	defaultColor?: string;
	fallBackColor?: string;
	label: string;
	onChange: (newValue?: string) => void;
}): JSX.Element => {
	const brandColors = useRelevantBrandThemeColors(brand);
	const allColors = React.useMemo(
		() => [...brandColors, { name: 'transparent', value: 'rgba(0,0,0,0)' }],
		[brandColors]
	);

	const [color, setColor] = React.useState(defaultColor || fallBackColor || '');

	const handleChangeColor = debounce(
		React.useCallback(
			(hexColor) => {
				const regEx = /^#([0-9a-f]{3}){1,2}$/i;
				const isValidHexColor = regEx.test(hexColor);
				if (isValidHexColor) {
					setColor(hexColor);
					onChange?.(hexColor);
				} else {
					// do nothign
				}
			},
			[onChange]
		),
		300
	);

	const anchorRef = React.useRef(null);

	const renderPopperContent = React.useCallback(() => {
		return (
			<Box display="flex" flexDirection="column" p="2rem">
				<StyledColorPicker color={color} onChange={handleChangeColor} />
				<ThemeColorsWrapper>
					{allColors.map(({ value, name }) => (
						<ColorBox
							boxShadow={1}
							key={value + name}
							bgcolor={value}
							// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
							onClick={() => handleChangeColor(value)}
						></ColorBox>
					))}
				</ThemeColorsWrapper>
			</Box>
		);
	}, [allColors, color, handleChangeColor]);

	return (
		<PopperWrapper renderPopperContent={renderPopperContent} anchorRef={anchorRef}>
			{({ onClick }) => (
				<TextField
					label={label}
					startIconName="ColorLens"
					startIconNameColor={color}
					onClickStartIcon={onClick}
					ref={anchorRef}
					value={color}
					onChange={handleChangeColor}
					width="100%"
				/>
			)}
		</PopperWrapper>
	);
};

export default React.memo(ColorPickerTextField);
