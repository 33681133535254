import * as React from 'react';

import {
	FormLabel,
	Grid,
	Box,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormHelperText,
} from '@mui/material';
import DateSelectField from './DateSelectField';
import type { DateSelectFieldRef } from './DateSelectField';
import TimeSelectField from './TimeSelectField';
import type { TimeSelectFieldRef } from './TimeSelectField';
import IntegerSelectField from './IntegerSelectField';
import type { IntegerSelectFieldRef } from './IntegerSelectField';
import TimeUnitSelectField from './TimeUnitSelectField';
import type { TimeUnit, TimeUnitSelectFieldRef } from './TimeUnitSelectField';
import { addDayToDateString } from '../utils/time';
import SectionWrapper from './SectionWrapper';

export type EventType = 'NEVER' | 'ON' | 'AFTER';

export type FreeIntervalSelectFormRef = {
	validate: () => boolean;
};

const FreeIntervalSelectForm = React.forwardRef<
	FreeIntervalSelectFormRef,
	{
		label: string;
		margin?: string;
		padding?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		disabled?: boolean;
		// date
		defaultStartDate?: string;
		onChangeStartDate?: (startDate?: string) => void;
		// time
		defaultStartTime?: string;
		onChangeStartTime?: (startTime?: string) => void;
		// interval
		defaultIntervalFactor?: number;
		onChangeIntervalFactor?: (value?: number) => void;
		defaultIntervalTimeUnit?: TimeUnit;
		onChangeIntervalTimeUnit?: (value?: TimeUnit) => void;
		// ending
		defaultIntervalEndType?: EventType;
		onChangeIntervalEndType?: (value?: EventType) => void;
		defaultNumberOfIntervals?: number;
		onChangeNumberOfIntervals?: (value?: number) => void;
		defaultEndDate?: string;
		onChangeEndDate?: (endDate?: string) => void;
	}
>(
	(
		{
			label,
			margin = '2rem 0 1rem 0',
			variant = 'standard',
			padding = '2rem',
			// date
			defaultStartDate,
			onChangeStartDate,
			// time
			defaultStartTime,
			onChangeStartTime,
			// interval
			defaultIntervalFactor,
			onChangeIntervalFactor,
			defaultIntervalTimeUnit,
			onChangeIntervalTimeUnit,
			// ending
			defaultIntervalEndType,
			onChangeIntervalEndType,
			defaultNumberOfIntervals,
			onChangeNumberOfIntervals,
			defaultEndDate,
			onChangeEndDate,
			disabled,
		},
		ref
	): JSX.Element => {
		const [startDate, setStartDate] = React.useState<string | undefined>(defaultStartDate);
		const [repititions, setRepitions] = React.useState<number | undefined>(
			defaultNumberOfIntervals
		);

		const [radioButtonValue, setRadioButtonValue] = React.useState<EventType | undefined>(
			defaultIntervalEndType
		);

		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();

		const handleRadioButtonChange = React.useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				setErrorMessage(undefined);
				const newValue = event?.target?.value as EventType;
				setRadioButtonValue(newValue);
				onChangeIntervalEndType && onChangeIntervalEndType(newValue);
			},
			[onChangeIntervalEndType]
		);

		const handleChangeStartDate = React.useCallback(
			(newValue?: string) => {
				setStartDate(newValue);
				onChangeStartDate?.(newValue);
			},
			[onChangeStartDate]
		);

		const handleChangeRepititions = React.useCallback(
			(newValue?: number) => {
				setRepitions(newValue);
				onChangeNumberOfIntervals?.(newValue);
			},
			[onChangeNumberOfIntervals]
		);

		const dateSelectFieldRef = React.useRef<DateSelectFieldRef>(null);

		const timeSelectFieldRef = React.useRef<TimeSelectFieldRef>(null);
		const integerSelectFieldRef = React.useRef<IntegerSelectFieldRef>(null);
		const timeUnitSelectFieldRef = React.useRef<TimeUnitSelectFieldRef>(null);
		const dateSelectFieldRef2 = React.useRef<DateSelectFieldRef>(null);
		const integerSelectFieldRef2 = React.useRef<IntegerSelectFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			if (!radioButtonValue) {
				setErrorMessage('Bitte wählen');
			}
			const invalid = [
				!radioButtonValue,
				...[
					dateSelectFieldRef,
					timeSelectFieldRef,
					integerSelectFieldRef,
					timeUnitSelectFieldRef,
					dateSelectFieldRef2,
					integerSelectFieldRef2,
				].map((r) => r.current?.validate()),
			].some(Boolean);
			return invalid;
		}, [radioButtonValue]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<SectionWrapper margin={margin} padding={padding} label={label}>
				<>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<DateSelectField
								label="Startdatum"
								defaultDate={defaultStartDate}
								onChange={handleChangeStartDate}
								variant={variant}
								flex
								ref={dateSelectFieldRef}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TimeSelectField
								label="Startzeit"
								onChange={onChangeStartTime}
								defaultValue={defaultStartTime}
								variant={variant}
								flex
								ref={timeSelectFieldRef}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<IntegerSelectField
								label="Alle"
								defaultValue={defaultIntervalFactor}
								onChange={onChangeIntervalFactor}
								variant={variant}
								ref={integerSelectFieldRef}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TimeUnitSelectField
								defaultValue={defaultIntervalTimeUnit}
								onChange={onChangeIntervalTimeUnit}
								variant={variant}
								ref={timeUnitSelectFieldRef}
								disabled={disabled}
							/>
						</Grid>
					</Grid>
					<Box m="6rem 0 0">
						<FormLabel component="legend">Endet</FormLabel>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Box display="flex" flex="1" height="100%" alignItems="center">
									<FormControl
										component="fieldset"
										error={Boolean(errorMessage)}
										disabled={disabled}
									>
										<RadioGroup row value={radioButtonValue} onChange={handleRadioButtonChange}>
											<FormControlLabel value="NEVER" control={<Radio />} label="Niemals" />
											<FormControlLabel value="ON" control={<Radio />} label="Am" />
											<FormControlLabel value="AFTER" control={<Radio />} label="Nach" />
										</RadioGroup>
										<FormHelperText>{errorMessage || null}</FormHelperText>
									</FormControl>
								</Box>
							</Grid>

							{radioButtonValue === 'ON' ? (
								<Grid item xs={12} sm={6}>
									<DateSelectField
										label="Enddatum"
										defaultDate={defaultEndDate}
										onChange={onChangeEndDate}
										variant={variant}
										flex
										minDate={addDayToDateString(startDate)}
										ref={dateSelectFieldRef2}
										disabled={disabled}
									/>
								</Grid>
							) : radioButtonValue === 'AFTER' ? (
								<Grid item xs={12} sm={6}>
									<IntegerSelectField
										label="Wiederholungen"
										defaultValue={repititions}
										onChange={handleChangeRepititions}
										variant={variant}
										ref={integerSelectFieldRef2}
										disabled={disabled}
									/>
								</Grid>
							) : null}
						</Grid>
					</Box>
				</>
			</SectionWrapper>
		);
	}
);

export default React.memo(FreeIntervalSelectForm);
