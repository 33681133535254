import * as React from 'react';
import { Animated, BackHandler, Platform, Keyboard, KeyboardAvoidingView } from 'react-native';
import styled from 'styled-native-components';
import shortid from 'shortid';

import PortalProvider from './PortalProvider';
import Heading from './Heading';
import Article from './Article';
import Button from './Button';
import TextField from './TextField';
import SelectField from './SelectField';
import Touchable from './Touchable';

import type { ID } from '@cinuru/utils/types';
import type { TouchableRef } from './Touchable';
import type { StringInputProps } from './TextField';
import type { SelectFieldProps } from './SelectField';
import type { ButtonRef, ButtonProps } from './Button';

const Wrapper = styled(Touchable).attrs({
	activeOpacity: 1,
})`
	${Platform.OS === 'web' ? 'cursor: auto' : ''};
	position: ${Platform.OS === 'web' ? 'fixed' : 'absolute'};
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: ${Platform.OS === 'android' ? '110' : '100'}vh;
`;
const Overlay = styled(Animated.View)`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: $overlayBackground;
`;

const ContentWrapper = styled(Touchable).attrs({
	activeOpacity: 1,
})`
	cursor: auto;
`;

export const DialogCard = styled(Touchable).attrs<
	{ color?: string; marginBottom?: string; transparentizeFactor?: number },
	{ activeOpacity: number; onPress: () => unknown }
>({
	activeOpacity: 1,
	onPress: Keyboard.dismiss,
})`
	width: ${Platform.OS === 'web' ? 50 : 43}rem;
	padding: 4rem;
	border-radius: ${(p) => p.theme.borderRadius[3]};
	background-color: ${(p) =>
		p.transparentizeFactor
			? p.theme.colors.transparentize(
					p.transparentizeFactor,
					p.color ? p.theme.colors[p.color.replace('$', '')] : p.theme.colors.background0
			  )
			: p.color || '$background0'};
	margin-bottom: ${(p) => p.marginBottom || (Platform.OS === 'web' ? '20%' : '5%')};
	elevation: 4;
	cursor: auto;
`;

const ButtonWrapper = styled.View`
	margin: 3rem -0.5rem 0;
	flex-flow: row wrap;
	justify-content: flex-end;
`;

const InputWrapper = styled.View`
	margin: 2rem 0;
	justify-content: center;
	align-items: stretch;
`;

const DialogButton = React.forwardRef<
	ButtonRef,
	{
		index: number;
		dismissPortal?: () => void;
		submitLoading?: boolean;
	} & ButtonProps
>(({ onPress, dismissPortal, submitLoading, testID, index, ...props }, ref) => {
	const [loading, setLoading] = React.useState(false);
	const handlePress = React.useCallback(
		async (id?: ID) => {
			if (onPress) {
				setLoading(true);
				await onPress(id);
				setLoading(false);
			}
			if (dismissPortal) dismissPortal();
		},
		[onPress, dismissPortal]
	);
	return (
		<Button
			margin="0rem 0.5rem"
			link
			{...props}
			onPress={handlePress}
			testID={testID || (onPress ? `DialogButton${index}` : 'DismissDialogButton')}
			loading={loading || submitLoading}
			ref={ref}
		/>
	);
});

const DialogInput = React.forwardRef<
	SelectField | TextField,
	(TextInputProps | SelectInputProps) & {
		index: number;
		onSetInputRef: (element: SelectField | TextField, index: number) => void;
		onSubmit: (index: number) => unknown;
	}
>(({ index, inputType, onSetInputRef, onSubmit, ...props }, ref) => {
	const handleSetRef = React.useCallback(
		(el) => {
			if (typeof ref === 'function') ref(el);
			if (ref && typeof ref === 'object') ref.current = el;
			onSetInputRef(el, index);
		},
		[index, onSetInputRef, ref]
	);
	const handleSubmit = React.useCallback(() => onSubmit(index), [index, onSubmit]);
	return inputType === 'SELECT' ? (
		// @ts-ignore typescript doesn't understand multi={false}
		<SelectField {...props} ref={handleSetRef} multi={false} onSubmit={handleSubmit} />
	) : (
		// @ts-ignore typescript doesn't understand valueType="string"
		<TextField valueType="string" {...props} ref={handleSetRef} onSubmit={handleSubmit} />
	);
});

interface TextInputProps extends Omit<StringInputProps, 'valueType'> {
	inputType?: 'TEXT';
	ref?: React.Ref<TextField>;
}

interface SelectInputProps extends Omit<SelectFieldProps, 'multi'> {
	inputType: 'SELECT';
	ref?: React.Ref<SelectField>;
}

export type DialogProps = {
	title?: string;
	description?: string;
	buttons?: ({ noDismiss?: boolean; ref?: React.Ref<ButtonRef> } & ButtonProps)[];
	inputs?: (TextInputProps | SelectInputProps)[];
	color?: string;
	marginBottom?: string;
	textColor?: string;
	renderContent?: (val: { dismissPortal: () => void }) => React.ReactNode;
	onUnmount?: () => void;
	animation?: 'TRANSLATE' | 'SCALE';
	noDismiss?: boolean;
	testID?: string;
	wrapperRef?: React.Ref<TouchableRef>;
	dismissPortal: () => void;
	transition: Animated.Value;
	renderHint?: () => JSX.Element;
	transparentizeFactor?: number;
};

const Dialog = ({
	title,
	description,
	buttons = [{ label: 'OK' }],
	renderHint,
	inputs,
	color,
	marginBottom,
	textColor,
	renderContent,
	dismissPortal,
	onUnmount,
	animation = 'TRANSLATE',
	noDismiss,
	testID = 'Dialog',
	wrapperRef,
	transition,
	transparentizeFactor,
}: DialogProps): JSX.Element => {
	// handle escape key presses on web
	React.useEffect(() => {
		if (window && window.addEventListener && window.removeEventListener) {
			const handleKeyPress = (e) => e.keyCode === 27 && dismissPortal();
			window.addEventListener('keydown', handleKeyPress);
			return () => window.removeEventListener('keydown', handleKeyPress);
		}
	}, [dismissPortal]);

	// Workaround for https://github.com/software-mansion/react-native-gesture-handler/issues/1255
	const containsSelectFieldOnAndroid = React.useMemo(
		() => inputs?.some((input) => input.inputType === 'SELECT') && Platform.OS === 'android',
		[inputs]
	);

	// call onUnmount function when unmounting
	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => onUnmount, []);

	// handle android back button on native
	React.useEffect(() => {
		if (BackHandler) {
			const handleBackPress = () => {
				!noDismiss && dismissPortal();
				return true;
			};
			BackHandler.addEventListener('hardwareBackPress', handleBackPress);
			return () => BackHandler.removeEventListener('hardwareBackPress', handleBackPress);
		}
	}, [dismissPortal, noDismiss]);

	const inputRefs = React.useRef<TextField[]>([]);
	const setInputRef = React.useCallback((el, i) => (inputRefs.current[i] = el), []);
	const [submitLoading, setSubmitLoading] = React.useState(false);
	const handleSubmit = React.useCallback(
		async (i: number) => {
			if (i < inputs!.length - 1) {
				inputRefs.current[i + 1].focus();
			} else {
				setSubmitLoading(true);
				await buttons[buttons.length - 1].onPress?.();
				setSubmitLoading(false);
				if (!noDismiss) dismissPortal();
			}
		},
		[inputs, buttons, noDismiss, dismissPortal]
	);

	const overlayAnimation = React.useMemo(() => {
		return { opacity: transition };
	}, [transition]);

	const cardAnimation = React.useMemo(() => {
		return {
			opacity: transition,
			transform: [
				{
					scale: transition.interpolate({
						inputRange: [0, 1],
						outputRange: [animation === 'SCALE' ? 0.5 : 1, 1],
					}),
				},
				{
					translateY: transition.interpolate({
						inputRange: [0, 1],
						outputRange: [animation === 'TRANSLATE' ? 300 : 0, 1],
					}),
				},
			],
		};
	}, [transition, animation]);

	return (
		<Wrapper
			onPress={noDismiss ? undefined : dismissPortal}
			testID={testID}
			ref={wrapperRef}
			discouragedTouchableOpacity={containsSelectFieldOnAndroid}
		>
			<Overlay style={overlayAnimation} />
			<Animated.View style={cardAnimation}>
				{renderContent ? (
					<ContentWrapper>{renderContent({ dismissPortal })}</ContentWrapper>
				) : (
					<KeyboardAvoidingView behavior={Platform.OS === 'android' ? 'padding' : 'position'}>
						<DialogCard
							color={color}
							marginBottom={marginBottom}
							discouragedTouchableOpacity={containsSelectFieldOnAndroid}
							transparentizeFactor={transparentizeFactor}
						>
							{title ? (
								<Heading size="s" margin={description ? '0 0 1.5rem' : '0'} color={textColor}>
									{title}
								</Heading>
							) : null}
							{description ? (
								<Article markdown color={textColor} sidePadding="0">
									{description}
								</Article>
							) : null}
							{inputs ? (
								<InputWrapper>
									{inputs.map((props, i) => (
										<DialogInput
											key={i}
											index={i}
											onSetInputRef={setInputRef}
											onSubmit={handleSubmit}
											returnKeyType={i < inputs.length - 1 ? 'next' : 'go'}
											{...props}
										/>
									))}
								</InputWrapper>
							) : null}
							{renderHint ? renderHint() : null}
							{buttons.length ? (
								<ButtonWrapper>
									{buttons.map((props, i) => (
										<DialogButton
											key={i}
											index={i}
											dismissPortal={(!noDismiss && !props.noDismiss && dismissPortal) || undefined}
											uppercase={props.uppercase !== undefined ? props.uppercase : true}
											textColor={props.link !== false ? textColor : '$background0'}
											color={props.link === false ? '$neutral0' : undefined}
											submitLoading={i === buttons.length - 1 && submitLoading}
											ref={props.ref}
											{...props}
										/>
									))}
								</ButtonWrapper>
							) : null}
						</DialogCard>
					</KeyboardAvoidingView>
				)}
			</Animated.View>
		</Wrapper>
	);
};

const dialogStack: string[] = [];
Dialog.render = (props: Omit<DialogProps, 'dismissPortal' | 'transition'>, id?: string): void => {
	if (!id) {
		id = shortid.generate();
		dialogStack.push(id);
	}
	PortalProvider.render(id, Dialog, props);
};
Dialog.unmount = (id?: string) => {
	if (!id) id = dialogStack.pop();
	if (id) PortalProvider.unmount(id);
};

export default Dialog;
