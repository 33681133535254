/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import { Paragraph } from '@cinuru/components';
import { ResponsiveLine } from '@nivo/line';
import React from 'react';

import { format, parse } from 'date-fns';
import { useTheme } from 'styled-native-components';
import chroma from 'chroma-js';
import styled from 'styled-native-components';
const Tooltip = styled.View`
	background-color: white;
	elevation: 2;
	border-radius: ${(p) => p.theme.borderRadius[1]};
	padding: 1rem;
	flex-direction: column;
`;
const TooltipCinemasWrapper = styled.View`
	flex-direction: row;
	align-items: center;
`;
const ColorSquare = styled.View`
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 0.3rem;
	background-color: ${(p) => p.color};
	margin: 0 0.5rem;
`;
export const MainGraph = ({
	data,
	ylabel = '',
	xlabel = '',
	cinemaNames,
	formatYAxisLabel = (value) => value,
}) => {
	const theme = useTheme();
	const colorscale = chroma.scale(theme.colors.accentGradient0).colors(3);
	return (
		<ResponsiveLine
			data={data}
			colors={colorscale}
			margin={{ top: 20, right: 20, bottom: 60, left: 80 }}
			xScale={{ type: 'point' }}
			yScale={{ type: 'linear', min: 0, max: 'auto' }}
			yFormat=" >-.2f"
			axisTop={null}
			axisRight={null}
			gridYValues={5}
			axisBottom={{
				orient: 'bottom',
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 45,
				legendOffset: 50,
				legendPosition: 'middle',
				format: (value) => value.substring(0, 6),
			}}
			animate={false}
			axisLeft={{
				orient: 'left',
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: ylabel,
				legendOffset: -40,
				legendPosition: 'middle',
				format: formatYAxisLabel,
			}}
			pointSize={0}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={0}
			pointBorderColor={{ from: 'serieColor' }}
			pointLabelYOffset={-12}
			useMesh={true}
			enableGridX={false}
			tooltip={(args) => {
				let names = cinemaNames[Number(args.point.serieId) - 1].join(', ');
				if (names.length > 50) {
					names = names.substring(0, 50) + ' ...';
				}
				return (
					<Tooltip>
						<TooltipCinemasWrapper>
							<ColorSquare color={args.point.serieColor} />
							<Paragraph size="s">{names}</Paragraph>
						</TooltipCinemasWrapper>
						<Paragraph>
							{format(parse(args.point.data.x, 'dd.MM.yyyy', new Date()), 'eee')}
							{', '}
							{`${args.point.data.x} : ${formatYAxisLabel(Number(args.point.data.y))} ${ylabel}`}
						</Paragraph>
					</Tooltip>
				);
			}}
			legends={[
				{
					data: data.map((item, index) => {
						let names = cinemaNames[index].join(', ');
						if (names.length > 43) {
							names = names.substring(0, 43) + ' ...';
						}
						return {
							id: item.id,
							color: colorscale[index],
							label: names,
						};
					}),
					anchor: 'bottom',
					direction: 'row',
					justify: false,
					translateX: 0,
					translateY: 65,
					itemsSpacing: 0,
					itemDirection: 'left-to-right',
					itemWidth: 270,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: 'circle',
					symbolBorderColor: 'rgba(0, 0, 0, .5)',
					effects: [
						{
							on: 'hover',
							style: {
								itemBackground: 'rgba(0, 0, 0, .03)',
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	);
};
