import * as React from 'react';
import { DatePicker as DatePickerMUI } from '@mui/lab';
import { TextField } from '@mui/material';
import { stringToDate, dateToString } from '../utils/time';
import FormControlWrapper from './FormControlWrapper';

export type DateSelectFieldRef = {
	validate: () => boolean;
};

const DateSelectField = React.forwardRef<
	DateSelectFieldRef,
	{
		label?: string;
		margin?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		defaultDate?: string;
		onChange?: (date?: string) => void;
		minDate?: string;
		flex?: boolean;
		disabled?: boolean;
	}
>(
	(
		{ label, margin, variant = 'standard', defaultDate, onChange, minDate, flex, disabled },
		ref
	): JSX.Element => {
		const [date, setDate] = React.useState<Date | undefined>(stringToDate(defaultDate));
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();
		const handleChange = React.useCallback(
			(newDate: Date | undefined | null) => {
				const isDate = Object.prototype.toString.call(newDate) === '[object Date]';
				// @ts-ignore
				const isInvalid = !isDate || (isDate && isNaN(newDate));
				setErrorMessage(undefined);
				setDate(isInvalid ? undefined : (newDate as Date));
				const dateString = dateToString(isInvalid ? undefined : (newDate as Date));
				onChange?.(dateString);
			},
			[onChange]
		);

		const renderInput = React.useCallback(
			(props) => {
				// const value = props?.inputProps?.value;
				// if (value) {
				// 	const splittedValue = value.split('.');
				// 	const [dayPart, monthPart, yearPart] = [
				// 		splittedValue[0],
				// 		splittedValue[1],
				// 		splittedValue[2],
				// 	];
				// 	if (yearPart) {
				// 		const zerosToAdd = 4 - yearPart.length;
				// 		let formattedYearPart = yearPart;
				// 		for (let i = 0; i < zerosToAdd; i++) {
				// 			formattedYearPart = '0' + formattedYearPart;
				// 		}
				// 		const formattedValue = [dayPart, monthPart, formattedYearPart].join('.');
				// 		console.log('formattedValue: ', formattedValue);
				// 		props.inputProps.value = formattedValue;
				// 		console.log('props after: ', props);
				// 	}
				// }
				return <TextField {...props} fullWidth variant={variant} error={Boolean(errorMessage)} />;
			},
			[errorMessage, variant]
		);

		React.useEffect(() => {
			if ((minDate && !date) || (minDate && minDate > dateToString(date!)!)) {
				handleChange(stringToDate(minDate));
			}
		}, [date, defaultDate, handleChange, minDate]);

		const handleValidate = React.useCallback(() => {
			let error = false;
			if (!date) {
				setErrorMessage('Bitte wählen');
				error = true;
			} else {
				error = false;
			}
			return error;
		}, [date]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<FormControlWrapper
				flex={flex}
				m={margin}
				errorMessage={errorMessage}
				variant={variant}
				disabled={disabled}
			>
				<DatePickerMUI
					label={label}
					value={date || null}
					onChange={handleChange}
					inputFormat="dd.MM.yyyy"
					renderInput={renderInput}
					mask="__.__.____"
					minDate={minDate ? (stringToDate(minDate) as Date) : undefined}
					disabled={disabled}
				/>
			</FormControlWrapper>
		);
	}
);

export default React.memo(DateSelectField);
