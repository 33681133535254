import React from 'react';
import TextField from '../../../../components/TextField';

type TextControllerType = 'LINK' | 'HEADER' | 'ALT';

const typeLabelDict: { [key in TextControllerType]: string } = {
	LINK: 'Link/Url',
	HEADER: 'Texteditor',
	ALT: 'Titel des Bildes',
};

const TextController = ({
	children,
	value,
	onChange,
	path,
	type,
	toolTip,
}: {
	children?: string;
	value?: string;
	onChange: (path: string, newValue: any) => void;
	path: string;
	type: TextControllerType;
	toolTip?: string;
}): JSX.Element => {
	const handleChange = React.useCallback(
		(newValue?: string) => {
			onChange(path, newValue);
		},
		[onChange, path]
	);

	return (
		<TextField
			label={children || typeLabelDict[type]}
			onChange={handleChange}
			value={value || ''}
			width="100%"
			toolTip={toolTip}
		/>
	);
};

export default React.memo(TextController);
