import * as React from 'react';

import { Divider as MuiDivider, Box } from '@mui/material';

const Divider = ({ m, p }: { m?: string; p?: string }): JSX.Element => {
	return (
		<Box m={m} p={p}>
			<MuiDivider />
		</Box>
	);
};

export default React.memo(Divider);
