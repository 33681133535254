import * as React from 'react';
import styled from 'styled-components';
import { Box, Switch } from '@mui/material';

import Txt from '../../../components/Txt';

import {
	ParentStatus,
	BonusProgramMemberStatus,
	LongTermBenefitsStatus,
} from '../../../utils/targetGroup';
import ActiveWrapper from './ActiveWrapper';
import { ClusterPropName, ClusterProps } from '../TargetGroupCluster';

export const StyledSwitch = styled(Switch)<{ $disabled: number }>`
	& span.MuiSwitch-thumb {
		color: ${(props) =>
			props.disabled ? props.theme.palette.grey[200] : props.theme.palette.primary.main};
	}
	& span.Mui-disabled > span.MuiSwitch-thumb {
		color: ${(props) =>
			props.disabled ? props.theme.palette.grey[200] : props.theme.palette.primary.main};
	}
	& span.MuiSwitch-track {
		background-color: ${(props) =>
			props.disabled ? props.theme.palette.common.black : props.theme.palette.primary.main};
	}
`;

type StatusType = BonusProgramMemberStatus | ParentStatus | LongTermBenefitsStatus;

type StatusOption =
	| 'parentStatusFilter'
	| 'bonusProgramMemberStatusFilter'
	| 'longTermBenefitsReceivedFilter';

const optionLabelsAndDescriptions: {
	[key in StatusOption]: {
		label: string;
		firstOption: { label: string; value: StatusType };
		secondOption: { label: string; value: StatusType };
	};
} = {
	parentStatusFilter: {
		label: 'Empfänger müssen folgenden Elternstatus haben:',
		firstOption: {
			label: 'Nur Empfänger mit Kindern',
			value: 'PARENTS_ONLY' as const,
		},
		secondOption: {
			label: 'Nur Empfänger ohne Kinder',
			value: 'NON_PARENTS_ONLY' as const,
		},
	},
	bonusProgramMemberStatusFilter: {
		label: 'Empfänger müssen folgenden Teilnehmerstatus besitzen:',
		firstOption: {
			label: 'müssen Bonusprogrammmitglieder sein',
			value: 'BONUSPROGRAM_MEMBERS_ONLY' as const,
		},
		secondOption: {
			label: 'dürfen keine Bonusprogrammmitglieder sein',
			value: 'NON_BONUSPROGRAM_MEMBERS_ONLY' as const,
		},
	},
	longTermBenefitsReceivedFilter: {
		label: 'Empfänger müssen einen Longtermbenefit erhalten:',
		firstOption: {
			label: 'Nein',
			value: 'NO_LONGTERMBENEFITS_RECEIVED_ONLY' as const,
		},
		secondOption: {
			label: 'Ja',
			value: 'LONGTERMBENEFITS_RECEIVED_ONLY' as const,
		},
	},
};

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const SwitchFilter = ({
	defaultStatus,
	active,
	showFilter,
	onChange,
	clusterPropName,
}: {
	defaultStatus: StatusType;
	active?: boolean;
	showFilter: boolean;
	onChange: ({ clusterPropName, clusterPropValue }: ClusterProps) => void;
	clusterPropName: ClusterPropName;
}): JSX.Element | null => {
	const { label, firstOption, secondOption } = optionLabelsAndDescriptions[clusterPropName];

	const [firstOptionSelected, setFirstOptionSelected] = React.useState<boolean>(
		defaultStatus !== secondOption.value
	);

	const handleToggleActivity = React.useCallback(() => {
		onChange({
			clusterPropName,
			clusterPropValue: {
				active: !active,
			},
		});
	}, [onChange, clusterPropName, active]);

	const disabled = !active;

	const handleChangeStatus = React.useCallback(() => {
		setFirstOptionSelected(!firstOptionSelected);
		onChange({
			clusterPropName,
			clusterPropValue: {
				status: !firstOptionSelected ? firstOption.value : secondOption.value,
			},
		});
	}, [clusterPropName, firstOption.value, firstOptionSelected, onChange, secondOption.value]);

	return showFilter ? (
		<ActiveWrapper
			label={label}
			active={active}
			onHandleToggleActivity={handleToggleActivity}
			p="2rem"
		>
			<Row>
				<Txt disabled={disabled} variant="body2">
					{firstOption.label}
				</Txt>
				<StyledSwitch
					disabled={disabled}
					$disabled={disabled ? 1 : 0}
					color="primary"
					checked={!firstOptionSelected}
					onChange={handleChangeStatus}
					size="small"
				/>
				<Txt disabled={disabled} variant="body2">
					{secondOption.label}
				</Txt>
			</Row>
		</ActiveWrapper>
	) : null;
};

export default React.memo(SwitchFilter);
