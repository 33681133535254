import { Path } from 'path-parser';

export type Param = number | string | boolean | number[] | string[] | boolean[];
export type Params = { [name: string]: Param };

// path cache
const paths: { [path: string]: Path } = {};
const getPathObject = (path: string) => {
	if (!(path in paths)) paths[path] = Path.createPath(path);
	return paths[path];
};
const matchPath = (path: string, link: string) => getPathObject(path).test(link);

/* extract the route from the full url: https://cinuru.com/bla/blub/2 => /bla/blub/2 */
const getPathFromURL = (url: string): string => {
	// if the given url doesn’t contain a scheme, return it directly
	if (!url.includes('://')) return url;
	// split off the scheme like 'example://' or 'http://'
	const [scheme, path] = url.split('://');
	// if the scheme was 'http' or 'https' split of the host like 'www.example.com'
	if (scheme === 'http' || scheme === 'https') {
		const [, ...parts] = path.split('/');
		return `/${parts.join('/')}`;
	} else return `/${path}`;
};

/**
 * test wether a given url matches the given path and if so return the parsed parameters
 *
 * @param url - the incoming url, e.g. `https://cinuru.com/bla/2?q=test` or `/bla/2?q=test`
 * @param path - a path defintion, e.g. `/bla/:id?q`
 * @returns the parsed query and path parameters, e.g. `{ id: 2, q: 'test' }`
 */
export const match = (url: string, path: string): Params | undefined => {
	const link = getPathFromURL(url);
	const [basePath, pathQuery] = path.split('?');
	const [baseLink] = link.split('?');
	if (pathQuery) {
		// if a query is defined it is optional, so we check against both path and basePath
		return matchPath(path, link) || matchPath(basePath, link) || undefined;
	}
	// if a query is included that is not defined in the path we don't care check link and baseLink
	return matchPath(path, link) || matchPath(path, baseLink) || undefined;
};

/**
 * generate a link based on the given path with the given parameters
 *
 * @param path - a path definition, e.g. `/bla/:id?q`
 * @param params - a params object for the path, e.g. `{ id: 2, q: 'test' }`
 * @returns the generated link, e.g. `/bla/2?q=test`
 */
export const generate = (path: string, params: Params): string => getPathObject(path).build(params);
