import { get } from 'lodash';

type ObjectOrString = string | { [name: string]: ObjectOrString };

/**
 * resolves a string that contains variable references like `watch {movie.title} now` with
 * the values of a given object `{ movie: { title: 'Finding Nemo' } }` yielding a string
 * like `watch Finding Nemo now`
 *
 * @param string - a string that contains variable declarations of the form {a.b.c}
 * @param variableValues - a nested object containing the values for the variables
 * @returns a string with the variables resolved to the provided values
 */
export default function resolveVariables(string: string, variableValues: ObjectOrString): string {
	const variables = string.match(/\{[^}]*\}/g);
	return variables
		? variables.reduce(
				(output, variable) =>
					output.replace(variable, get(variableValues, variable.replace(/[{}]/g, ''), '')),
				string
		  )
		: string;
}
