import React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';

import ChevronRight from '@mui/icons-material/ChevronRight';
import EditRounded from '@mui/icons-material/EditRounded';
import SendRounded from '@mui/icons-material/SendRounded';
import CancelScheduleSend from '@mui/icons-material/CancelScheduleSend';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Search from '@mui/icons-material/Search';
import PhoneIphoneOutlined from '@mui/icons-material/PhoneIphoneOutlined';
import DesktopMacOutlined from '@mui/icons-material/DesktopMacOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import Info from '@mui/icons-material/Info';
import ColorLensOutlined from '@mui/icons-material/ColorLensOutlined';
import ColorLens from '@mui/icons-material/ColorLens';

export type IconName =
	| 'ChevronRight'
	| 'EditRounded'
	| 'SendRounded'
	| 'CancelScheduleSend'
	| 'SaveOutlined'
	| 'Add'
	| 'DeleteOutline'
	| 'Search'
	| 'PhoneIphoneOutlined'
	| 'DesktopMacOutlined'
	| 'CloseOutlined'
	| 'Info'
	| 'ColorLensOutlined'
	| 'ColorLens';

const iconDict: { [key in IconName]: (props?: any) => JSX.Element } = {
	ChevronRight: (props) => <ChevronRight {...props} />,
	EditRounded: (props) => <EditRounded {...props} />,
	SendRounded: (props) => <SendRounded {...props} />,
	CancelScheduleSend: (props) => <CancelScheduleSend {...props} />,
	SaveOutlined: (props) => <SaveOutlined {...props} />,
	Add: (props) => <Add {...props} />,
	DeleteOutline: (props) => <DeleteOutline {...props} />,
	Search: (props) => <Search {...props} />,
	PhoneIphoneOutlined: (props) => <PhoneIphoneOutlined {...props} />,
	DesktopMacOutlined: (props) => <DesktopMacOutlined {...props} />,
	CloseOutlined: (props) => <CloseOutlined {...props} />,
	Info: (props) => <Info {...props} />,
	ColorLensOutlined: (props) => <ColorLensOutlined {...props} />,
	ColorLens: (props) => <ColorLens {...props} />,
};

const StyledIconButton = styled(MuiIconButton)<{ $color?: string }>`
	color: ${(p) => p.$color};
`;

const IconButton = ({
	id,
	m,
	p,
	loading,
	color,
	onClick,
	iconName,
	...rest
}: {
	id?: string;
	m?: string;
	p?: string;
	loading?: boolean;
	color?: string;
	onClick?: (val?: any) => any;
	iconName: IconName;
} & Omit<MuiIconButtonProps, 'color'>): JSX.Element => {
	const handleClick = React.useCallback(() => {
		onClick?.(id);
	}, [id, onClick]);

	return (
		<Box m={m} p={p} position="relative">
			<StyledIconButton $color={color} onClick={handleClick} {...rest}>
				{loading ? (
					// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
					<CircularProgress size={24} sx={{ color }} />
				) : iconDict[iconName] ? (
					iconDict[iconName]()
				) : null}
			</StyledIconButton>
		</Box>
	);
};

export default React.memo(IconButton);
