// @flow
import * as React from 'react';
import styled from 'styled-native-components';
import { Text } from 'react-native';

import PortalTrigger from '../PortalTrigger';
import Touchable from '../Touchable';
import Icon from '../Icon';
import { ParagraphInput } from '../Paragraph';

const LinkInputWrapper = styled(Touchable).attrs((p) => ({
	noCursor: true,
	onClick: (e) => {
		e.stopPropagation();
		p.onPress && p.onPress(e);
	},
}))`
	width: 40rem;
	margin-left: ${(p) => -20 * p.theme.rem + p.anchorWidth / 2}px;
	background-color: $neutral3;
	elevation: 2;
	padding: 1rem 1.5rem;
	border-radius: 0.5rem;
	flex-direction: row;
	justify-content: space-between;
`;

const Triangle = styled.View`
	position: absolute;
	top: -0.5rem;
	left: 20rem;
	width: 0;
	height: 0;
	border-color: transparent;
	border-left-width: 0.5rem;
	border-right-width: 0.5rem;
	border-bottom-width: 0.5rem;
	border-bottom-color: $neutral3;
`;

class LinkInput extends React.PureComponent {
	inputRef = React.createRef();
	// focus on startup, even if underlying editor forces selection first
	componentDidMount = () => setTimeout(() => this.inputRef.current.focus(), 100);

	// dismiss on hitting escape
	handleKeyPress = (e) =>
		['Enter', 'Escape'].includes(e.nativeEvent.key) && this.props.onHandleClose();

	render = () => {
		const { anchorWidth, onRemoveLink, url, onChangeUrl } = this.props;
		return (
			<LinkInputWrapper anchorWidth={anchorWidth} onPress={this.focus}>
				<Triangle />
				<ParagraphInput
					ref={this.inputRef}
					value={url}
					onChangeText={onChangeUrl}
					onKeyPress={this.handleKeyPress}
					autoFocus
					color="$backgroun0"
					flex
				/>
				<Icon size="2.5rem" name="unlink" color="$background0" onPress={onRemoveLink} />
			</LinkInputWrapper>
		);
	};
}

// eslint-disable-next-line no-unused-vars
const LinkText = React.memo(styled(({ active, ...props }) => <Text {...props} />)`
	text-decoration: underline;
	color: ${(p) =>
		p.active
			? p.theme.colors.blend(0.1, p.theme.colors.accent0, p.theme.colors.neutral0)
			: 'inherit'};
	text-decoration-color: ${(p) =>
		p.active
			? p.theme.colors.blend(0.1, p.theme.colors.accent0, p.theme.colors.neutral0)
			: 'inherit'};
`);

export default class EditorLink extends React.PureComponent {
	state = {
		active: false,
		anchorWidth: null,
		url: this.props.contentState.getEntity(this.props.entityKey).getData().url || '',
	};

	componentDidMount = () => !this.state.url && this.handleOpen();

	handleOpen = () =>
		this.setState({ active: true }, () => this.props.onOpenPopup && this.props.onOpenPopup());
	handleClose = () =>
		this.setState({ active: false }, () => {
			this.props.onEditEntity &&
				this.props.onEditEntity(this.props.entityKey, { url: this.state.url });
			this.props.onClosePopup && this.props.onClosePopup();
		});

	handlePressLink = (e) =>
		e.metaKey
			? window.open(e.target.parentElement.parentElement.getAttribute('href'), '_blank')
			: this.handleOpen();

	handleMeasureWidth = ({ nativeEvent: { layout } }) =>
		this.setState({ anchorWidth: layout.width });

	handleInput = (url) => this.setState({ url });

	handleRemove = () =>
		this.setState({ active: false }, () => {
			this.props.onClosePopup && this.props.onClosePopup();
			this.props.onRemoveEntity && this.props.onRemoveEntity();
		});

	renderOverlay = () => (
		<LinkInput
			onHandleClose={this.handleClose}
			anchorWidth={this.state.anchorWidth}
			onRemoveLink={this.handleRemove}
			url={this.state.url}
			onChangeUrl={this.handleInput}
		/>
	);

	render = () => (
		<PortalTrigger
			active={this.state.active}
			renderOverlay={this.renderOverlay}
			allowOverflow
			onClose={this.handleClose}
			preventEventBubbling
		>
			<LinkText
				href={this.props.contentState.getEntity(this.props.entityKey).getData().url}
				accessibilityRole="link"
				onPress={this.handlePressLink}
				active={this.state.active}
				onLayout={this.handleMeasureWidth}
			>
				{this.props.children}
			</LinkText>
		</PortalTrigger>
	);
}
