import * as React from 'react';

import { SelectField } from '@cinuru/components';
import { default as gql } from 'graphql-tag';

import WithData from './WithData';

const CinemaSelectField = ({ multi, onChange, ...selectProps }) => {
	const initialized = React.useRef(false);
	return (
		<WithData
			query={gql`
				query {
					currentUser {
						id
						privileges {
							adminForCinemas {
								id
								name
							}
						}
					}
				}
			`}
			fetchPolicy="cache-and-network"
		>
			{({ currentUser }) => {
				const userCinemaIds = currentUser.privileges.adminForCinemas.map(({ id }) => id);
				const defaultValue = multi
					? userCinemaIds
					: userCinemaIds.length === 1
					? userCinemaIds[0]
					: null;

				// set default cinema selection
				if (!initialized.current) {
					if (onChange && defaultValue) onChange(defaultValue);
					initialized.current = true;
				}

				const items = currentUser.privileges.adminForCinemas.map(({ id, name }) => ({
					value: id,
					label: name,
				}));

				return (
					<SelectField
						width="100%"
						label={`Kino${multi ? 's' : ''} wählen`}
						multi={multi}
						onChange={onChange}
						items={items}
						value={defaultValue}
						{...selectProps}
					/>
				);
			}}
		</WithData>
	);
};

export default CinemaSelectField;
