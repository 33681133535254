import * as React from 'react';
import { StyleSheet } from 'react-native';
import styled, { useTheme, useColorAttribute } from 'styled-native-components';

import Icon from './Icon';
import Touchable from './Touchable';

import type { ViewStyle } from 'react-native';

const Wrapper = styled(Touchable).attrs<
	{ nWidth: number; active?: boolean },
	{
		activeOpacity: number;
		opacity: number;
		hitSlop: { top: number; right: number; bottom: number; left: number };
	}
>((p) => ({
	activeOpacity: p.active ? 0.6 : 1,
	opacity: p.active ? 1 : 0.6,
	hitSlop: { top: 5, right: 5, bottom: 5, left: 5 },
}))`
	padding: 0 ${(p) => p.nWidth * 0.4}rem ${(p) => p.nWidth * 0.4}rem;
`;

// the transparent background genereates warnings about ineficency that are real but cannot be avoided wihtout a completely different implementation
// https://github.com/facebook/react-native/commit/e4c53c28aea7e067e48f5c8c0100c7cafc031b06
const Shape = styled.View<{
	nWidth: number;
	color: string;
	shadowColor: string;
	noShadow?: boolean;
}>`
	height: ${(p) => p.nWidth * 1.4}rem;
	border-color: ${(p) => p.color};
	border-left-width: ${(p) => p.nWidth / 2}rem;
	border-right-width: ${(p) => p.nWidth / 2}rem;
	border-bottom-width: ${(p) => p.nWidth * 0.2}rem;
	border-bottom-color: transparent;
	background-color: transparent;
	shadow-color: ${(p) => p.shadowColor};
	shadow-offset: 0 5px;
	shadow-radius: 2.5px;
	shadow-opacity: ${(p) => (p.noShadow ? 0 : 0.3)};
`;

/**
 * this fills in a hairlineWidth line that is not filled by the above shape on actual devices
 */
const ShapeLineFix = styled.View<{ nWidth: number; color: string }>`
	height: ${(p) => p.nWidth * 1.2}rem;
	width: ${StyleSheet.hairlineWidth}px;
	left: -${StyleSheet.hairlineWidth / 2}px;
	background-color: ${(p) => p.color};
	position: absolute;
`;

const BookmarkIconWrapper = styled.View<{ nWidth: number }>`
	position: absolute;
	top: 0;
	left: ${(p) => -p.nWidth * 0.4}rem;
	height: ${(p) => p.nWidth + 1}rem;
	width: ${(p) => p.nWidth}rem;
	justify-content: center;
`;

const Bookmark = ({
	active,
	rating,
	interest,
	onPress,
	noShadow,
	color,
	iconColor,
	size = 'm',
	style,
}: {
	active?: boolean;
	rating?: number;
	interest?: number;
	onPress?: () => void;
	noShadow?: boolean;
	size?: 's' | 'm' | 'l';
	color?: string;
	iconColor?: string;
	style?: ViewStyle;
}) => {
	const nWidth = { s: 2, m: 2.5, l: 3 }[size];
	const theme = useTheme();
	const foregroundColor = useColorAttribute(
		iconColor || (theme.brand === 'CINURU' ? '$accent1' : '$accent0')
	);
	const lightForeground = theme.colors.isLight(foregroundColor);
	const shadowColor = lightForeground ? 'white' : 'black';
	const backgroundColor =
		color ||
		(lightForeground
			? theme.colors.isLight(theme.colors.background0)
				? theme.colors.neutral0
				: theme.colors.background0
			: theme.colors.overlayText);
	return (
		<Wrapper style={style} active={active} onPress={onPress} key={String(active)} nWidth={nWidth}>
			<Shape nWidth={nWidth} noShadow={noShadow} color={backgroundColor} shadowColor={shadowColor}>
				<ShapeLineFix nWidth={nWidth} color={backgroundColor} />
				<BookmarkIconWrapper nWidth={nWidth}>
					<Icon
						name={
							active
								? rating
									? (`fatStar${rating}` as
											| 'fatStar1'
											| 'fatStar2'
											| 'fatStar3'
											| 'fatStar4'
											| 'fatStar5')
									: interest && interest > 0
									? 'fatCheckmark'
									: 'fatBlocked'
								: 'fatPlus'
						}
						size={`${nWidth - 0.5}rem`}
						color={active ? iconColor : shadowColor}
						gradient={
							active && !iconColor
								? theme.brand === 'CINURU'
									? 'accentGradient1'
									: 'accentGradient0'
								: undefined
						}
					/>
				</BookmarkIconWrapper>
			</Shape>
		</Wrapper>
	);
};

export default React.memo(Bookmark);
