import * as React from 'react';
import { SelectField } from '@cinuru/components';
import { default as gql } from 'graphql-tag';
import { withApollo } from 'react-apollo';

const styles = { minWidth: '300px' };
class UserSelectField extends React.Component {
	executeSearch = async (query) => {
		const { data } = await this.props.client.query({
			query: gql`
				query searchUsers($queryText: String!) {
					users: searchUsers(queryText: $queryText) {
						id
						name
						email
					}
				}
			`,
			variables: { queryText: query },
		});
		return data.users.map((u) => ({ label: u.email, value: u.id }));
	};
	render = () => {
		const { ...otherProps } = this.props;
		return (
			<SelectField
				label={`Nutzer suchen`}
				multi={false}
				executeSearch={this.executeSearch}
				style={styles}
				{...otherProps}
			/>
		);
	};
}
export default withApollo(UserSelectField);
