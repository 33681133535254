type FontWeight =
	| undefined
	| 'normal'
	| 'bold'
	| '100'
	| '200'
	| '300'
	| '400'
	| '500'
	| '600'
	| '700'
	| '800'
	| '900';

type FontStyle = undefined | 'normal' | 'italic';

const getFontStyle = ({
	os,
	weight,
	family,
	italic,
	condensed,
}: {
	os: 'ios' | 'android' | 'web';
	family: 'ProximaNova' | 'System';
	weight?: FontWeight;
	italic?: boolean;
	condensed?: boolean;
}): {
	fontFamily: string;
	fontWeight: FontWeight;
	fontStyle: FontStyle;
} => {
	if (condensed && italic) throw new Error('condensed italic is not supported');
	const nWeight = !weight || weight === 'normal' ? 400 : weight === 'bold' ? 700 : Number(weight);

	if (nWeight < 400 || nWeight > 900) throw new Error('weight must be at between 400 and 900');
	switch (family) {
		case 'ProximaNova': {
			if (os === 'web') throw new Error('ProximaNova is not supported on web');
			if (condensed) throw new Error('condensed is not supporter for proxima nova');
			if (italic && nWeight > 500) {
				throw new Error('ProximaNova italic only supports regular weight');
			}
			switch (true) {
				case nWeight <= 500:
					return {
						fontFamily: italic ? 'ProximaNova-RegularIt' : 'ProximaNova-Regular',
						fontWeight: undefined,
						fontStyle: italic && os !== 'android' ? 'italic' : undefined,
					};
				case nWeight <= 600:
					return {
						fontFamily: 'ProximaNova-Regular',
						fontWeight: os === 'android' ? undefined : '600',
						fontStyle: undefined,
					};
				case nWeight <= 700:
					return {
						fontFamily: 'ProximaNova-Bold',
						fontWeight: os === 'android' ? undefined : '700',
						fontStyle: undefined,
					};
				case nWeight <= 900:
					return {
						fontFamily: 'ProximaNova-Extrabold',
						fontWeight: os === 'android' ? undefined : '800',
						fontStyle: undefined,
					};
				default:
					throw new Error(`unknown weight '${weight}'`);
			}
		}
		case 'System': {
			if (condensed) {
				if (weight !== 'bold') throw new Error(`condensed font only supported in bold weight`);
				return {
					fontWeight: 'bold',
					fontStyle: undefined,
					fontFamily: {
						web: 'inherit',
						ios: 'SFCashTextCondensed-Semibold',
						android: 'sans-serif-condensed',
					}[os],
				};
			}
			const fontStyle = italic ? 'italic' : undefined;
			switch (os) {
				case 'android':
					switch (true) {
						case nWeight <= 400:
							return { fontFamily: 'sans-serif', fontWeight: undefined, fontStyle };
						case nWeight <= 500:
							return { fontFamily: 'sans-serif-medium', fontWeight: undefined, fontStyle };
						case nWeight <= 700:
							return { fontFamily: 'sans-serif', fontWeight: 'bold', fontStyle };
						case nWeight <= 900:
							return { fontFamily: 'sans-serif-medium', fontWeight: 'bold', fontStyle };
						default:
							throw new Error(`unknown weight '${weight}'`);
					}
				case 'ios':
					return { fontFamily: 'System', fontWeight: weight, fontStyle };
				case 'web':
					return { fontFamily: 'inherit', fontWeight: weight, fontStyle };
				default:
					throw new Error(`unknown operating system '${os}'`);
			}
		}
		default:
			throw new Error(`unknown font family '${family}'`);
	}
};

type TypographyStyle = {
	fontFamily: string;
	fontWeight: FontWeight;
	fontStyle: FontStyle;
	fontSize: number;
	lineHeight: number;
};
type FontSizes = [number, number, number, number, number, number, number, number];
export interface TypographyStyles {
	/** the 8 step font size scale */
	fontSizes: FontSizes;
	/** italic inline font style object used in Article */
	italic: { fontFamily: string; fontWeight: FontWeight; fontStyle: FontStyle };
	/** bold inline font style object used in Article */
	bold: { fontFamily: string; fontWeight: FontWeight; fontStyle: FontStyle };
	/**
	 * get font styles for paragraph (main longform) text
	 *
	 * @param size - the size of the text
	 * @param bold - whether to render the text in bold or not
	 * @returns font style object
	 */
	paragraph: (
		size: 's' | 'm' | 'l' | 'xl' | 'xs',
		bold?: boolean
	) => TypographyStyle & { css: string };
	/**
	 * get font styles for labels (UI copy on interactive elements)
	 *
	 * @param size - the size of the text
	 * @param light - whether to not render bold text
	 * @returns font style object
	 */
	label: (
		size: 'xs' | 's' | 'm' | 'l' | 'xl',
		light?: boolean
	) => TypographyStyle & { css: string };
	/**
	 * get font styles for headings
	 *
	 * @param size - the size of the text
	 * @param condensed - whether to use a condensed font style when available for this brand
	 * @returns font style object
	 */
	heading: (
		size: 'xs' | 's' | 'm' | 'l' | 'xl',
		condensed?: boolean
	) => TypographyStyle & { css: string };
}

/** Generates typography theme functions mainly for use in Pragraph, Heading, Label, Article */
const createTypographyStyles = ({
	os,
	rem,
	font,
}: {
	os: 'web' | 'ios' | 'android';
	rem: number;
	font?: 'ProximaNova';
}): TypographyStyles => {
	const smallFont = font === 'ProximaNova';
	// careful here when adding new sizes to the fontSizes array, 1) the mapping of all getXStyle functions and 2) all calls to theme.typography.fontSizes[index] must be updated accordingly
	const fontSizes = [8, 11, 13, 15, 17, 19, 23, 31, 39].map(
		(s) => (smallFont ? s + 1 : s) / rem
	) as FontSizes;

	const getParagraphStyle = (
		size: 's' | 'm' | 'l' | 'xl' | 'xs',
		bold?: boolean
	): TypographyStyle => ({
		fontSize: fontSizes[{ xs: 1, s: 2, m: 3, l: 5, xl: 6 }[size]],
		lineHeight: size === 's' || size === 'xs' ? 2 : 3,
		...getFontStyle({ os, family: font || 'System', weight: bold ? 'bold' : undefined }),
	});

	const getLabelStyle = (
		size: 'xs' | 's' | 'm' | 'l' | 'xl',
		light?: boolean
	): TypographyStyle => ({
		fontSize: fontSizes[{ xs: 1, s: 2, m: 3, l: 4, xl: 5 }[size]],
		lineHeight: { xs: smallFont ? 1.7 : 1.5, s: 2, m: 2.5, l: 2.5, xl: 3 }[size],
		...getFontStyle({
			os,
			family: font || 'System',
			weight: {
				xs: light ? '500' : !font && os === 'android' ? '800' : '700',
				s: font ? (light ? '500' : '700') : light ? '600' : '800',
				m: font ? (light ? '600' : '700') : light ? '600' : '800',
				l: font ? (light ? '500' : '700') : light ? '600' : '800',
				xl: font ? (light ? '500' : '700') : light ? '400' : '600',
			}[size] as FontWeight,
		}),
	});

	const getHeadingStyle = (
		size: 'xs' | 's' | 'm' | 'l' | 'xl',
		condensed?: boolean
	): TypographyStyle => {
		if (condensed && size === 'xl') throw new Error('condensed style not supported for size xl');
		return {
			fontSize:
				fontSizes[{ xs: smallFont ? 4 : 3, s: 5, m: 6, l: 7, xl: 8 }[size]] *
				(condensed && !font ? (size === 'xs' ? 1.1 : size === 's' ? 1.05 : 1) : 1),
			lineHeight: {
				xs: condensed && !font ? 2.25 : 2.5,
				s: 3,
				m: smallFont ? 3.5 : 4,
				l: smallFont ? 4 : 4.5,
				xl: smallFont ? 6 : 8,
			}[size],
			...getFontStyle({
				os,
				family: font || 'System',
				condensed: condensed && !font,
				weight:
					condensed && !font
						? 'bold'
						: ({
								xs: '800',
								s: font && condensed ? '700' : '800',
								m: (font || os === 'android') && !condensed ? '800' : '700',
								l: '900',
								xl: '900',
						  }[size] as FontWeight),
			}),
		};
	};

	const getCssShorthand = ({
		fontFamily,
		fontWeight,
		fontStyle,
		fontSize,
		lineHeight,
	}: TypographyStyle): string =>
		[fontStyle, fontWeight, fontSize + 'rem/' + lineHeight + 'rem', fontFamily]
			.filter(Boolean)
			.join(' ');

	return {
		fontSizes,

		bold: getFontStyle({ os, family: font || 'System', weight: 'bold' }),
		italic: getFontStyle({ os, family: font || 'System', italic: true }),

		paragraph: (...args) => {
			const style = getParagraphStyle(...args);
			const css = getCssShorthand(style);
			return { ...style, css };
		},
		label: (...args) => {
			const style = getLabelStyle(...args);
			const css = getCssShorthand(style);
			return { ...style, css };
		},
		heading: (...args) => {
			const style = getHeadingStyle(...args);
			const css = getCssShorthand(style);
			return { ...style, css };
		},
	};
};

export default createTypographyStyles;
