import { ButtonProps } from '@cinuru/emails/src/components/DashboardEmailComponents/Button';
import { FilmSeriesProps } from '@cinuru/emails/src/components/DashboardEmailComponents/FilmSeries';
import { DashboardEmailFooterProps as FooterProps } from '@cinuru/emails/src/components/DashboardEmailComponents/DashboardEmailFooter';
import { DashboardEmailHeaderProps as HeaderProps } from '@cinuru/emails/src/components/DashboardEmailComponents/DashboardEmailHeader';
import { ImageProps } from '@cinuru/emails/src/components/DashboardEmailComponents/Image';
import { MarkdownTextProps } from '@cinuru/emails/src/components/DashboardEmailComponents/MarkdownText';
import { FullProgramProps as ProgramProps } from '@cinuru/emails/src/components/DashboardEmailComponents/FullProgram';
import { MarkdownTextWithImageProps } from '@cinuru/emails/src/components/DashboardEmailComponents/MarkdownTextWithImage';
import { QRCodeProps } from '@cinuru/emails/src/components/DashboardEmailComponents/QRCode';
import { RecommendationSectionProps as RecommendationsProps } from '@cinuru/emails/src/components/DashboardEmailComponents/RecommendationSection';
import { Campaign } from './campaign';
import { SpacerProps } from '@cinuru/emails/src/components/DashboardEmailComponents/Spacer';
import { ColorName } from '@cinuru/utils/theme';

import client from '../apollo';
import gql from 'graphql-tag';
import { campaignDataFragment } from '../utils/campaign';
import { ID } from '@cinuru/utils/types';

type ComponentBorderRadius = { bottom: boolean; top: boolean };

export type SpacerData = {
	type: 'SPACER';
	height?: string;
	backgroundColor?: string | ColorName | 'transparent';
	index?: number;
	key?: string;
	borderRadiusWrapper?: ComponentBorderRadius;
};

export interface RecommendationsData extends RecommendationsProps {
	type: 'RECOMMENDATIONS';
	index?: number;
	key?: string;
	borderRadiusWrapper?: ComponentBorderRadius;
}

export interface QRCodeData extends QRCodeProps {
	type: 'QRCODE';
	index?: number;
	key?: string;
	borderRadiusWrapper?: ComponentBorderRadius;
}

export type ProgramTimeFrame = 'NEXT_WEEK_ONLY' | 'ALL_UPCOMING_MOVIES';

export interface ProgramData extends ProgramProps {
	type: 'PROGRAM';
	index?: number;
	key?: string;
	borderRadiusWrapper?: ComponentBorderRadius;
	campaign?: Campaign;
	selectedCampaignCinemas?: Campaign['cinemas'];
	timeFrame: ProgramTimeFrame;
}

export interface MarkdownTextWithImageData extends MarkdownTextWithImageProps {
	type: 'TEXTWITHIMAGE';
	index?: number;
	key?: string;
	borderRadiusWrapper?: ComponentBorderRadius;
}

export interface MarkdownTextData extends MarkdownTextProps {
	type: 'TEXT';
	index?: number;
	key?: string;
	borderRadiusWrapper?: ComponentBorderRadius;
}

export interface ImageData extends ImageProps {
	type: 'IMAGE';
	index?: number;
	key?: string;
	borderRadiusWrapper?: ComponentBorderRadius;
}

export interface HeaderData extends HeaderProps {
	type: 'HEADER';
	textChangedByCustomer: boolean;
	index?: number;
	key?: string;
}

export interface FooterData extends FooterProps {
	type: 'FOOTER';
	index?: number;
	key?: string;
}

export interface FilmSeriesData extends FilmSeriesProps {
	type: 'SERIES';
	index?: number;
	key?: string;
	borderRadiusWrapper?: ComponentBorderRadius;
	campaign?: Campaign;

	selectedCampaignCinemas?: Campaign['cinemas'];
	selectedFilmSeries?: string[];
	timeFrame: number;
}

export interface ButtonData extends ButtonProps {
	type: 'BUTTON';
	index?: number;
	key?: string;
	borderRadiusWrapper?: ComponentBorderRadius;
}

export type EmailElement =
	| RecommendationsData
	| ButtonData
	| FilmSeriesData
	| FooterData
	| HeaderData
	| ImageData
	| MarkdownTextData
	| MarkdownTextWithImageData
	| ProgramData
	| QRCodeData
	| SpacerData;

export type GenericObjectType = { [key: string]: any };

export type ObjectType =
	| RecommendationsProps
	| FilmSeriesProps
	| FooterProps
	| HeaderProps
	| ImageProps
	| ButtonProps
	| MarkdownTextProps
	| MarkdownTextWithImageProps
	| ProgramProps
	| QRCodeProps
	| SpacerProps;

export type EmailEditorComponentType =
	| 'RECOMMENDATIONS'
	| 'PROGRAM'
	| 'TEXT'
	| 'IMAGE'
	| 'TEXTWITHIMAGE'
	| 'BUTTON'
	| 'QRCODE'
	| 'SPACER'
	| 'SERIES';

export type SaveCampaignEmailError =
	| 'NETWORK_ERROR'
	| 'NO_CAMPAIGN_PRIVILEGES'
	| 'INSUFFICIENT_CINEMA_PRIVILEGES'
	| 'CAMPAIGN_NOT_FOUND'
	| 'EDITING_STATUS_REQUIRED';

export const saveCampaignEmail = async ({
	campaignId,
	emailContent,
}: {
	campaignId: ID;
	emailContent: EmailElement[];
}): Promise<{ success: true; error: null } | { success: false; error: SaveCampaignEmailError }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation SaveCampaignEmail($id: ID!, $emailContent: Json!) {
					editCampaignEmail(id: $id, emailContent: $emailContent) {
						campaign {
							...campaignDataFragment
						}
					}
				}
				${campaignDataFragment}
			`,
			variables: { id: campaignId, emailContent: { elements: emailContent } },
			errorPolicy: 'all',
		});
		if (errors) {
			const error = errors[0]!.message as SaveCampaignEmailError;
			return { success: false, error: error };
		} else return { success: true, error: null };
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' as SaveCampaignEmailError };
		else throw e;
	}
};
