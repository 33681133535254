/** may need some refactoring */

// @flow
import * as React from 'react';
import { FlatList } from 'react-native';
import { sortBy, memoize, isArray } from 'lodash';
import styled, { ThemeContext } from 'styled-native-components';

import Paragraph from './Paragraph';
import Icon from './Icon';
import TextField from './TextField';
import WrappedImage from './WrappedImage';
import LoadingIndicator from './LoadingIndicator';
import PortalTrigger from './PortalTrigger';
import DropDown from './DropDown';
import type { BaseFieldProps } from './BaseField';

import fuzzySearch from '@cinuru/utils/fuzzySearch';
import { Label } from '@cinuru/components';
import { IconName } from '@cinuru/utils/theme';

const FilterList = styled.View`
	margin: 2rem;
	padding-right: 4rem;
	align-self: stretch;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

const getShortcutColor = (p) => {
	if (!p.hovered) return 'transparent';
	const background = p.altBackground ? '$background0' : '$background1';
	return p.theme.colors.blend(
		0.2,
		p.theme.colors[background.substring(1)],
		p.theme.colors.neutral0
	);
};

const FilterWrapper = styled.TouchableOpacity`
	background-color: ${getShortcutColor};
	padding: 0 1rem;
	border-radius: ${(p) => p.theme.borderRadius[1]};
	margin: 1rem 0rem;
`;

const FilterItem = ({ onSelect, value, altBackground, label }) => {
	const [hovered, setHovered] = React.useState(false);
	const handleHover = React.useCallback(() => setHovered(true), []);
	const handleUnHover = React.useCallback(() => setHovered(false), []);
	const handlePress = React.useCallback(() => onSelect && onSelect({ label, value }), [
		label,
		onSelect,
		value,
	]);
	return (
		<FilterWrapper
			onPress={handlePress}
			onMouseEnter={handleHover} // TODO: is onMouseEnter actually working?
			onMouseLeave={handleUnHover}
			hovered={hovered}
			altBackground={altBackground}
		>
			<Label size="s">{label}</Label>
		</FilterWrapper>
	);
};

const LoadingWrapper = styled.View`
	position: absolute;
	width: 100%;
	height: 100%;
`;

const DropDownList = styled.FlatList`
	align-self: stretch;
`;

const getItemColor = (p) => {
	let background =
		p.theme.colors[p.selected ? 'border0' : p.altBackground ? 'background0' : 'background1'];
	if (p.active) background = p.theme.colors.blend(0.15, background, p.theme.colors.accent0);
	return background;
};

const ItemWrapper = styled.TouchableOpacity<{ selected?: boolean; active?: boolean }>`
	padding: 0.5rem 1.5rem;
	flex-direction: row;
	background-color: ${getItemColor};
`;

const Thumbnail = styled(WrappedImage).attrs({
	resizeMode: 'contain',
})`
	height: 3rem;
	width: 2rem;
	border-radius: ${(p) => p.theme.borderRadius[0]};
	margin-right: 1.5rem;
`;

const Checkmark = styled(Icon).attrs({
	name: 'checkmark',
	size: '2rem',
	margin: '0.5rem 0rem',
})`
	position: absolute;
	right: 2.5rem;
`;

type ItemType = {
	value: string;
	label: string;
	iconName?: IconName;
	image?: string;
	selected?: boolean;
};

interface ItemClassType extends ItemType {
	value: string;
	label: string;
	iconName?: IconName;
	image?: string;
	active: boolean;
	selected?: boolean;
	index: number;
	altBackground?: boolean;
	onHover: (index: number) => void;
	onPress: (args: ItemType) => void;
}

class Item extends React.PureComponent<ItemClassType> {
	handlePress = () => {
		const { onPress, value, label, iconName, image, selected } = this.props;
		if (onPress) onPress({ value, label, iconName, image, selected });
	};

	handleHover = () => this.props.onHover && this.props.onHover(this.props.index);

	render = () => {
		const { label, iconName, image, active, selected, altBackground } = this.props;
		return (
			<ItemWrapper
				selected={selected}
				active={active}
				onPress={this.handlePress}
				onMouseEnter={this.handleHover} // TODO: onMouseEnter does not seem to exist on TouchableOpacity
				altBackground={altBackground}
			>
				{image ? <Thumbnail src={image} /> : null}
				{iconName ? (
					<Icon
						color={selected ? '$neutral0' : '$neutral2'}
						size="2rem"
						margin="0.5rem 1rem 0.5rem 0rem"
						name={iconName}
					/>
				) : null}
				<Paragraph color={selected ? '$neutral0' : '$neutral2'} bold={selected}>
					{label}
				</Paragraph>
				{selected ? <Checkmark color={selected ? '$neutral0' : '$neutral2'} /> : null}
			</ItemWrapper>
		);
	};
}

const TEXT_FIELD_MARGINS = [0];

const getValueFromProps = (props) => {
	const defaultValue = props.value || props.defaultValue;
	if (!defaultValue) return [];
	if (props.executeSearch) {
		return defaultValue;
	} else {
		return props.items.filter(({ value }) =>
			isArray(defaultValue) ? defaultValue.includes(value) : defaultValue === value
		);
	}
};

type CommonProps = BaseFieldProps & {
	searchable?: boolean;
	executeSearch?: (query: string) => ItemType[];
};

type Filter = {
	label: string;
	value: string;
	filterFunction: (value: ItemType, index: number, array: ItemType[]) => false | true;
};

interface MutliSelectProps extends CommonProps {
	multi: true;
	width?: string;
	items: ItemType[];
	value?: string[];
	defaultValue?: string[];
	onChange?: (value: string[]) => unknown;
	onFocus?: (value?: string[]) => unknown;
	onBlur?: (value?: string[]) => unknown;
	autoclose?: boolean;
	filters?: Filter[];
	defaultFilterValue?: string;
	tagWidth?: number;
	cutTags?: boolean;
}
interface SingleSelectProps extends CommonProps {
	multi?: false;
	width?: string;
	items: ItemType[];
	value?: string;
	defaultValue?: string;
	onChange?: (value: string) => unknown;
	onFocus?: (value?: string) => unknown;
	onBlur?: (value?: string) => unknown;
	autoclose?: boolean;
	filters?: Filter[];
	defaultFilterValue?: string;
	tagWidth?: number;
	cutTags?: boolean;
}

export type SelectFieldProps = MutliSelectProps | SingleSelectProps;

type State = {
	selected: boolean;
	value: ItemType[] | [{ key: string; label: string }];
	activeIndex: number;
	query: string;
	searchedItems: ItemType[];
	singleTagItem: null | string;
};
class SelectField extends React.PureComponent<SelectFieldProps, State> {
	static defaultProps = { nMargins: [1, 0] };
	state = {
		selected: false,
		value: getValueFromProps(this.props),
		activeIndex: -1,
		query: '',
		searchedItems: [],
		singleTagItem: null,
	};

	inputRef = React.createRef<TextField>();
	listRef = React.createRef<FlatList>();

	getFilteredItems = memoize(
		(query, items) =>
			query && query.trim
				? fuzzySearch(items, query.trim(), 'label').map(({ item }) => item)
				: sortBy(items, [(i) => i.label.toLowerCase()]),
		(query) => (query && query.trim ? query.trim() : '')
	);

	getItems = (query = ''): ItemType[] => {
		if (this.props.executeSearch) {
			// if we do the search on the server, we do not filter here
			return this.state.searchedItems;
		} else {
			return query ? this.getFilteredItems(query, this.props.items) : this.props.items;
		}
	};

	handleFocus = (): void =>
		this.setState({ selected: true }, () => this.props.onFocus && this.props.onFocus());
	handleBlur = (): void =>
		this.setState({ selected: false }, () => this.props.onBlur && this.props.onBlur());

	handleSearchInput = async (query: string): Promise<void> => {
		const { executeSearch, multi, searchable } = this.props;
		// if query changed and searchable update search
		if (this.state.query !== query && (executeSearch || searchable || multi)) {
			const searchedItems = executeSearch ? (await executeSearch(query)) || [] : [];
			this.setState(
				{ query, activeIndex: -1, searchedItems },
				// remove previous value if new query is entered and is not multi select
				() => !executeSearch && query && !this.props.multi && this.handleChange([])
			);
		}
	};

	handleTagsInput = (value: [{ key: string; label: string }]): void => this.handleChange(value);

	handleChange = (
		value: ItemType[] | [{ key: string; label: string }],
		selectedFilter?: { label: string; value: string }
	): void => {
		const { multi, searchable, onChange, autoclose } = this.props;
		const newValue = this.getItems().find((item: ItemType) => item.label === value); // TODO: seems like this line will never even run
		this.setState(
			{ value: value && isArray(value) ? value : newValue ? [newValue] : [] },
			() => autoclose && this.handleBlur()
		);
		if (value !== undefined && this.inputRef.current) {
			this.inputRef.current.setValue(
				multi || searchable
					? selectedFilter
						? [{ label: selectedFilter.label, value: selectedFilter.value }]
						: value
					: (value && value[0] && value[0].label) || ''
			);
		}
		if (onChange) onChange(multi ? value.map((v) => v.value) : value && value[0] && value[0].value);
	};

	handleKeyPress = ({ nativeEvent: { key } }: { nativeEvent: { key: any } }): void => {
		const { multi } = this.props;
		if (key === 'Enter' && this.state.activeIndex >= 0) {
			const filteredItems = this.getItems(this.state.query);
			this.handleItemPress(filteredItems[this.state.activeIndex]);
			if (this.inputRef.current?.blur && !multi) this.inputRef.current.blur();
		}
		this.setState((state) => {
			const filteredItems = this.getItems(state.query);
			if (key === 'ArrowDown') {
				const activeIndex =
					state.activeIndex >= filteredItems.length - 1 ? 0 : state.activeIndex + 1;
				this.listRef.current?.scrollToIndex({ index: activeIndex, viewPosition: 0.55 });
				return { activeIndex };
			}
			if (key === 'ArrowUp') {
				const activeIndex =
					state.activeIndex <= 0 ? filteredItems.length - 1 : state.activeIndex - 1;
				this.listRef.current?.scrollToIndex({ index: activeIndex, viewPosition: 0.55 });
				return { activeIndex };
			}
			return {};
		});
		if (key === 'Tab' && this.inputRef.current?.blur) {
			this.inputRef.current.blur();
		}
	};

	handleItemHovered = (index: number): void => this.setState({ activeIndex: index });

	handleItemPress = (item: ItemType): void => {
		// reset singleTagItem on a single item press
		if (this.props.filters) this.setState({ singleTagItem: null }); // TODO: seems state.singleTagItem can be omitted altogether as we do not use this value anywhere
		if (!this.props.multi) this.inputRef.current?.blur();
		if (this.state.value.findIndex(({ value }) => value === item.value) >= 0) {
			// already selected, remove item
			this.handleChange(
				this.props.multi ? this.state.value.filter(({ value }) => value !== item.value) : []
			);
		} else {
			// not selected yet, select item
			this.handleChange(this.props.multi ? [...this.state.value, item] : [item]);
			this.inputRef.current?.clearText();
		}
	};

	static contextType = ThemeContext;
	getItemLayout = (_, index: number): { length: number; offset: number; index: number } => ({
		length: this.context.rem * 4,
		offset: this.context.rem * 4 * index,
		index,
	});

	keyExtractor = ({ value }) => value;

	renderDropDownItem = ({ item, index }: { item: ItemType; index: number }): JSX.Element => (
		<Item
			{...item}
			onPress={this.handleItemPress}
			onHover={this.handleItemHovered}
			index={index}
			active={this.state.activeIndex === index}
			selected={this.state.value.findIndex(({ value }) => value === item.value) >= 0}
			altBackground={this.props.altBackground}
		/>
	);

	handleSelectFilter = (selectedFilter?: Filter): void => {
		if (selectedFilter) {
			const filterFunction = this.props.filters?.find(({ value }) => value === selectedFilter.value)
				?.filterFunction;
			if (filterFunction) {
				this.handleChange(this.props.items.filter(filterFunction), selectedFilter);
			}
		}
	};

	componentDidMount = (): void => {
		if (this.props.filters && this.props.defaultFilterValue) {
			const defaultFilter = this.props.filters.find(
				({ value }) => value === this.props.defaultFilterValue
			);
			this.handleSelectFilter(defaultFilter);
		}
	};

	renderOverlay = (): JSX.Element => {
		const { altBackground, error } = this.props;
		const { query } = this.state;
		return (
			<DropDown
				focusline
				hasError={Boolean(error)}
				color={altBackground ? '$background0' : '$background1'}
				borderColor="$border0"
				maxHeight="40rem"
				flexFlow={this.props.filters ? 'row' : null}
			>
				{this.props.filters ? null : (
					<LoadingWrapper>
						<LoadingIndicator />
					</LoadingWrapper>
				)}
				{this.props.filters ? (
					<FilterList>
						{this.props.filters.map((shortcut) => (
							<FilterItem
								key={shortcut.label}
								label={shortcut.label}
								value={shortcut.value}
								altBackground={'green'}
								onSelect={this.handleSelectFilter}
							/>
						))}
					</FilterList>
				) : null}
				<DropDownList
					ref={this.listRef}
					data={this.getItems(query)}
					keyExtractor={this.keyExtractor}
					initialNumToRender={10}
					renderItem={this.renderDropDownItem}
					getItemLayout={this.getItemLayout}
				/>
			</DropDown>
		);
	};

	render = (): JSX.Element => {
		const { nMargins, altBackground, hint, searchable, error, multi, ...props } = this.props;
		// eslint-disable-next-line no-unused-vars
		const { width, ...rest } = props;
		//to prevent onChange being fired twice (CinemaSelectField)
		delete rest.onChange;

		const { selected, value } = this.state;
		const tagInput = multi || searchable;
		return (
			<PortalTrigger
				margin={nMargins?.join('rem ') + 'rem'}
				active={selected}
				renderOverlay={this.renderOverlay}
				contentTopMargin={hint || error ? '-3.25rem' : '-0.25rem'}
				stretch
			>
				<TextField
					{...rest}
					nMargins={TEXT_FIELD_MARGINS}
					ref={this.inputRef}
					altBackground={altBackground}
					value={tagInput ? value : (value[0] && value[0].label) || ''}
					valueIcon={!tagInput && value[0] && value[0].iconName}
					valueImage={!tagInput && value[0] && value[0].image}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					selected={selected}
					hint={hint}
					error={error}
					autoCorrect={false}
					onKeyPress={this.handleKeyPress}
					blurOnSubmit={false}
					onChangeText={this.handleSearchInput}
					onChangeTags={this.handleTagsInput}
					valueType={tagInput ? 'tags' : 'string'}
					hideTagRemove={searchable && !multi}
					maxTags={multi ? undefined : 2}
					editOnBackspace={false}
					tagSeparator={undefined}
					width={width}
					tagWidth={this.props.tagWidth}
					cutTags={this.props.cutTags}
				/>
			</PortalTrigger>
		);
	};
}

export default SelectField;
