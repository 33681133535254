import React from 'react';

export const removeTypeNames = (objectWithTypeNames: { [key: string]: unknown }) => {
	const cleaned = JSON.parse(
		JSON.stringify(objectWithTypeNames, (name, val) => {
			if (name === '__typename') {
				delete val[name];
			} else {
				return val;
			}
		})
	);

	return cleaned;
};

export const useInitializeStateWithDefault = <T extends any>(
	defaultValue: T,
	finishedInitializing?: boolean
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] => {
	const [state, setState] = React.useState<T | undefined>(undefined);

	React.useEffect(() => {
		if (finishedInitializing ? finishedInitializing : defaultValue !== undefined) {
			setState(defaultValue);
		}
	}, [finishedInitializing, defaultValue]);

	const result = React.useMemo(() => [state, setState], [state]);
	return result as [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>];
};
