import * as React from 'react';

import { SelectField } from '@cinuru/components';
import { default as gql } from 'graphql-tag';

import WithData from './WithData';

const CinemaSelectField = ({ multi, onChange, value, defaultFilterValue, ...selectProps }) => {
	const filters = React.useMemo(
		() => [
			{ label: 'Alle', value: 'Alle', filterFunction: () => true },
			{ label: 'Keine', value: 'Keine', filterFunction: () => false },
			{
				label: 'Millionenstädte',
				value: 'Millionenstädte',
				filterFunction: (item) => item.isMillionCity,
			},
			{ label: 'Großstädte', value: 'Großstädte', filterFunction: (item) => item.isBigCity },
			{ label: 'Kleinstädte', value: 'Kleinstädte', filterFunction: (item) => item.isSmallCity },
			{ label: 'Arthouse', value: 'Kleinstädte', filterFunction: (item) => item.isSmallCity },
			{ label: 'Multiplex', value: 'Kleinstädte', filterFunction: (item) => item.isBigCity },
		],
		[]
	);

	return (
		<WithData
			query={gql`
				query {
					currentUser {
						id
						privileges {
							adminForCinemas {
								id
								name
								city
							}
						}
					}
				}
			`}
			fetchPolicy="cache-first"
		>
			{({ currentUser }) => {
				const items = currentUser.privileges.adminForCinemas
					.map(({ id, name, city }) => {
						const isMillionCity = ['Berlin', 'Hamburg', 'München', 'Köln'].reduce(
							(a, b) => a || b.includes(city),
							false
						);
						const isBigCity = [
							'Frankfurt am Main',
							'Stuttgart',
							'Düsseldorf',
							'Dortmund',
							'Essen',
							'Leipzig',
							'Bremen',
							'Dresden',
							'Hannover',
						].reduce((a, b) => a || b.includes(city), false);

						return {
							value: id,
							label: name,
							isMillionCity,
							isBigCity,
							isSmallCity: !isBigCity && !isMillionCity,
						};
					})
					.sort((a, b) => a.label > b.label);

				return (
					<SelectField
						width="100%"
						label={`Kino${multi ? 's' : ''} wählen`}
						multi={multi}
						onChange={onChange}
						items={items}
						value={value}
						tagWidth={16}
						cutTags={5}
						filters={filters}
						defaultFilterValue={value ? null : defaultFilterValue ? defaultFilterValue : 'Alle'}
						{...selectProps}
					/>
				);
			}}
		</WithData>
	);
};

export default CinemaSelectField;
