import React from 'react';
import styled from 'styled-native-components';

import { Article, Heading, Paragraph } from '@cinuru/components';

import { default as gql } from 'graphql-tag';

import WithData from '../components/WithData';

const ContentCard = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
`;

const CinemaCard = styled.View`
	background-color: $background1;
	padding: 2rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	margin-top: 3rem;
	elevation: 2;
`;
const DESCRIPTION_TEXT = `
Die sogenannten Authentifizierungs-Token funktionieren für alle POS-Versionen und -Geräte eines Kinos. Der Einsatz mehrerer Tokens für verschiedene Bereiche (Ticketkasse, Concession etc.) ermöglicht eine Unterschiedung in den Statistiken. 

Bei Bedarf an weiteren Tokens wenden Sie sich gerne an unseren [Support](mailto:support@cinuru.com).


[Download-Link für Windows POS-Software](https://static.cinuru.com/download/cinurupos)`;

export default class PosTokens extends React.Component {
	render = () => (
		<ContentCard>
			<Heading size="l">CinuruPOS Zugangsdaten</Heading>
			{/* prettier-ignore */}
			<Article markdown>
				{DESCRIPTION_TEXT}
			</Article>
			<WithData
				query={gql`
					{
						currentUser {
							id
							privileges {
								adminForCinemas {
									id
									name
									posTokens
								}
							}
						}
					}
				`}
			>
				{({ currentUser }) => (
					<React.Fragment>
						{currentUser.privileges.adminForCinemas.map((cinema) => (
							<CinemaCard key={cinema.id}>
								<Heading>{cinema.name}</Heading>
								{cinema.posTokens.map((token, index) => (
									<Paragraph key={token}>
										Token {index + 1}: {token}
									</Paragraph>
								))}
							</CinemaCard>
						))}
					</React.Fragment>
				)}
			</WithData>
		</ContentCard>
	);
}
