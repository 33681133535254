import React from 'react';
import { useQuery } from 'react-apollo';
import cookies from 'js-cookie';
import gql from 'graphql-tag';

import { FILE_SERVER_ENDPOINT } from '../consts';
import client from '../apollo';
import type { ID } from '@cinuru/utils/types';

export type Image = { id: ID; url: string; datetime?: Date; b2bCustomerCinemaId?: ID };

type SaveImageError = 'NETWORK_ERROR' | 'UNAUTHORIZED' | 'UNEXPECTED_ERROR';

const AllImagesQuery = gql`
	query AllImages {
		allImages {
			id
			datetime
			url
		}
	}
`;

const saveImagePath = async ({
	filePath,
}: {
	filePath: string;
}): Promise<
	| {
			error: SaveImageError;
			success: false;
			uploadedImage: undefined;
	  }
	| {
			error: null;
			success: true;
			uploadedImage: Image;
	  }
> => {
	try {
		const { errors, data } = await client.mutate({
			mutation: gql`
				mutation SaveImagePath($filePath: String!) {
					saveImagePath(filePath: $filePath) {
						success
						uploadedImage {
							id
							url
							datetime
						}
					}
				}
			`,
			variables: { filePath },
			refetchQueries: ['AllImages'],
		});

		if (errors) {
			const error = errors[0].message as SaveImageError;
			return { success: false, error, uploadedImage: undefined };
		} else {
			const uploadedImage = data.saveImagePath.uploadedImage;
			return { success: true, error: null, uploadedImage };
		}
	} catch (e: any) {
		if (e.networkError)
			return {
				success: false,
				error: 'NETWORK_ERROR',
				uploadedImage: undefined,
			};
		else throw e;
	}
};

export const uploadImageToFileServer = async ({
	file,
}: {
	file: any;
}): Promise<
	| {
			error: SaveImageError;
			success: false;
			uploadedImage: undefined;
	  }
	| {
			error: null;
			success: true;
			uploadedImage: Image;
	  }
> => {
	const fileServerToken = cookies.get('_fileServerToken');
	const formData = new FormData();
	formData.append('customerImages', file);

	const res = await fetch(`${FILE_SERVER_ENDPOINT}/upload-campaign-gallery-image`, {
		method: 'POST',
		body: formData,
		headers: { fileservertoken: fileServerToken },
	});
	const filePath = await res.json();
	return await saveImagePath(filePath);
};

type DeleteImageError =
	| 'NETWORK_ERROR'
	| 'UNAUTHORIZED'
	| 'IMAGE_DOES_NOT_EXIST'
	| 'IMAGE_ID_DUPLICATION'
	| 'INVALID_REQUEST';

export const deleteImage = async ({
	id,
}: {
	id: ID;
}): Promise<
	| { error: DeleteImageError; success: false }
	| {
			error: null;
			success: true;
	  }
> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation DeleteImage($id: ID!) {
					deleteImage(id: $id) {
						success
					}
				}
			`,
			variables: { id },
			update: () => {
				const oldState = client.readQuery({
					query: AllImagesQuery,
				});
				const newState = {
					...oldState,
					allImages: oldState.allImages.filter((image) => image.id !== id),
				};
				client.writeQuery({ query: AllImagesQuery, data: newState });
			},
		});

		if (errors) {
			const error = errors[0].message as DeleteImageError;
			return { success: false, error };
		} else {
			return { success: true, error: null };
		}
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

export const useUploadedImages = ():
	| undefined
	| {
			id: ID;
			url: string;
			datetime: Date;
	  }[] => {
	const { data } = useQuery(
		gql`
			query AllImages {
				allImages {
					id
					datetime
					url
				}
			}
		`,
		{}
	);
	return React.useMemo(() => data?.allImages, [data?.allImages]);
};
