import React from 'react';
import ColorPickerTextField from '../../../../components/ColorPickerTextField';
import { Brand } from '@cinuru/utils/theme';

const ColorController = ({
	label,
	value,
	brand,
	onChange,
	path,
	fallBackColor,
}: {
	label: string;
	value?: string;
	brand: Brand;
	onChange: (path: string, newValue: any) => void;
	path: string;
	fallBackColor?: string;
}): JSX.Element => {
	const handleChange = React.useCallback(
		(newColor) => {
			onChange(path, newColor);
		},
		[onChange, path]
	);

	return (
		<ColorPickerTextField
			label={label}
			onChange={handleChange}
			defaultColor={value}
			fallBackColor={fallBackColor}
			brand={brand}
		/>
	);
};

export default React.memo(ColorController);
