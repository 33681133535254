/** may need some refactoring */

// @flow
import * as React from 'react';
import { de as locale } from 'date-fns/locale';
import { times } from 'lodash';
import {
	startOfYear,
	addMonths,
	isSameDay,
	isFuture,
	addDays,
	addWeeks,
	format,
	parseISO,
} from 'date-fns';

import TextField from './TextField';
import PortalTrigger from './PortalTrigger';
import DropDown from './DropDown';
import CalendarSheet from './CalendarSheet';

const TEXT_FIELD_MARGINS = [0];

const months = times(12).map((i) => addMonths(startOfYear(new Date()), i));
const monthNames = months.map((m) => format(m, 'MMM', { locale }).toLowerCase());
const fullMonthNames = months.map((m) => format(m, 'MMMM', { locale }).toLowerCase());

const parseMonth = (string: string): number => {
	if (!Number.isNaN(Number(string))) return Number(string);
	return (
		monthNames.findIndex((name) => name === string.trim()) + 1 ||
		fullMonthNames.findIndex((name) => name === string.trim()) + 1 ||
		monthNames.findIndex((name) => name === `${string.trim()}.`) + 1
	);
};

export const parseDateInput = (string: string = ''): ?Date => {
	string = string.toLowerCase();
	if (!string || string.length <= 6) return null;
	const parts = string.split(/[.-\s]/).filter((d) => d);
	if (parts.length !== 3) return null;
	if (parts[0].length === 4) {
		const year = Number(parts[0]);
		const month = parseMonth(parts[1]);
		const day = Number(parts[2]);
		return !Number.isNaN(year) && month <= 12 && day <= 31 ? new Date(year, month - 1, day) : null;
	} else if ([2, 4].includes(parts[2].length)) {
		const year = Number((parts[2].length === 2 ? '20' : '') + parts[2]);
		const month = parseMonth(parts[1]);
		const day = Number(parts[0]);
		return !Number.isNaN(year) && month <= 12 && day <= 31 ? new Date(year, month - 1, day) : null;
	} else return null;
};

export const formatDateInput = (date?: Date): string =>
	date ? format(date, 'd. MMM yyyy', { locale }) : '';

// these disregard any timezones, which is intended for a purely date field
const decodePropsValue = (value?: string): Date => value && parseISO(value.split('T')[0]);
const encodeStateValue = (date?: Date): string => date && format(date, 'yyyy-MM-dd');

type Props = { value?: string };
type State = { selected: boolean, value?: Date };
export default class DateField extends React.PureComponent<Props, State> {
	state = { selected: false, value: decodePropsValue(this.props.value) };

	static defaultProps = {
		nMargins: [1, 0],
		altBackground: false,
		errorMessages: {
			INVALID_DATE: 'Ungültiges Datum',
			DATE_NOT_IN_FUTURE: 'Datum muss in Zukunft liegen',
		},
	};

	active = null;
	inputRef = React.createRef();
	dateRef = React.createRef();

	handleFocus = () => {
		// if CalenderSheet is already mounted, don't call setState
		if (this.dateRef.current) {
			this.inputRef.current.blur();
		} else {
			this.setState({ selected: true }, () => this.props.onFocus && this.props.onFocus());
		}
	};

	handleBlur = () => {
		this.setState({ selected: false }, () => this.props.onBlur && this.props.onBlur());
	};

	handleKeyPress = ({ nativeEvent: { key } }) => {
		const activeEmpty = !this.active;
		let active = this.active || new Date();
		if (key === 'ArrowRight') active = addDays(active, activeEmpty ? 0 : 1);
		if (key === 'ArrowLeft') active = addDays(active, activeEmpty ? 0 : -1);
		if (key === 'ArrowDown') active = addWeeks(active, activeEmpty ? 0 : 1);
		if (key === 'ArrowUp') active = addWeeks(active, activeEmpty ? 0 : -1);
		this.active = active;
		this.dateRef.current.setActive(active);
		if (key === 'Enter' && !activeEmpty) {
			this.handleSelectDate(active);
			if (this.inputRef.current.blur) this.inputRef.current.blur();
		}
	};

	handleTextInput = (text) => {
		this.setState({ error: null });
		if (!text) this.handleSelectDate(null);
		else {
			const date = parseDateInput(text);
			if (date) this.handleSelectDate(date);
			else this.setState({ error: this.props.errorMessages.INVALID_DATE });
		}
	};

	handleSelectDate = (value) => {
		if (this.props.onChange) this.props.onChange(encodeStateValue(value));
		this.inputRef.current.setValue(formatDateInput(value));
		this.dateRef.current && this.dateRef.current.setMonth(value);
		this.dateRef.current && this.dateRef.current.setValue(value);
		if (!value || !this.props.futureOnly || isSameDay(value, new Date()) || isFuture(value)) {
			this.setState({ value, error: null });
			this.handleBlur();
		} else {
			this.setState({ value, error: this.props.errorMessages.DATE_NOT_IN_FUTURE });
		}
	};

	handleSetActive = (active) => {
		this.active = active;
	};

	renderOverlay = () => {
		const { altBackground, futureOnly, errorMessages } = this.props;
		const error = this.state.error || this.props.error;
		return (
			<DropDown
				focusline
				hasError={Boolean(error)}
				color={altBackground ? '$background0' : '$background1'}
				borderColor="$border0"
				maxHeight="42rem"
				center
			>
				<CalendarSheet
					ref={this.dateRef}
					value={this.state.value}
					onChange={this.handleSelectDate}
					altBackground={altBackground}
					futureOnly={futureOnly}
					onSetActive={this.handleSetActive}
				/>
			</DropDown>
		);
	};

	render = () => {
		const error = this.state.error || this.props.error;
		const { nMargins, altBackground, flex, hint, ...other } = this.props;
		// eslint-disable-next-line no-unused-vars
		const { futureOnly, value: _, ...rest } = other;
		const { selected, value } = this.state;
		return (
			<PortalTrigger
				margin={nMargins.join('rem ') + 'rem'}
				flexBase={flex && '30rem'}
				active={selected}
				renderOverlay={this.renderOverlay}
				contentTopMargin={hint || error ? '-3.25rem' : '-0.25rem'}
				stretch
			>
				<TextField
					{...rest}
					width={'100%'}
					nMargins={TEXT_FIELD_MARGINS}
					ref={this.inputRef}
					altBackground={altBackground}
					value={formatDateInput(value)}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					selected={selected}
					hint={hint}
					autoCorrect={false}
					onKeyPress={this.handleKeyPress}
					onChange={this.handleTextInput}
					blurOnSubmit={false}
					inputChangeDebounce={500}
					error={error}
				/>
			</PortalTrigger>
		);
	};
}
