import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { CircularProgress, Box } from '@mui/material';
import { Brand } from '@cinuru/utils/theme';

import type { RecommendationSectionProps as RecommendationsProps } from '@cinuru/emails/src/components/DashboardEmailComponents/RecommendationSection';
import type { FullProgramProps as ProgramProps } from '@cinuru/emails/src/components/DashboardEmailComponents/FullProgram';
import type { MarkdownTextProps } from '@cinuru/emails/src/components/DashboardEmailComponents/MarkdownText';
import type { ImageProps } from '@cinuru/emails/src/components/DashboardEmailComponents/Image';
import type { MarkdownTextWithImageProps } from '@cinuru/emails/src/components/DashboardEmailComponents/MarkdownTextWithImage';
import type { ButtonProps } from '@cinuru/emails/src/components/DashboardEmailComponents/Button';
import type { QRCodeProps } from '@cinuru/emails/src/components/DashboardEmailComponents/QRCode';
import type { SpacerProps } from '@cinuru/emails/src/components/DashboardEmailComponents/Spacer';
import type { FilmSeriesProps } from '@cinuru/emails/src/components/DashboardEmailComponents/FilmSeries';

import { useDivDimensions } from '../../../utils/dimensions';

type ComponentProps =
	| RecommendationsProps
	| ProgramProps
	| MarkdownTextProps
	| ImageProps
	| MarkdownTextWithImageProps
	| ButtonProps
	| QRCodeProps
	| SpacerProps
	| FilmSeriesProps;

const useEmailComponentHtml = ({
	id,
	componentName,
	componentProps,
	brand,
}: {
	id: string;
	componentName: string;
	componentProps: ComponentProps;
	brand?: Brand;
}): string | undefined => {
	const { data, loading } = useQuery(
		gql`
			query EmailComponent(
				$id: ID!
				$componentName: EmailComponentName!
				$componentProps: Json!
				$brand: Brand!
			) {
				emailComponent(
					identifier: $id
					componentName: $componentName
					componentProps: $componentProps
					brand: $brand
				) {
					id
					html
				}
			}
		`,
		{
			variables: {
				id,
				componentName,
				brand,
				componentProps,
			},
			fetchPolicy: 'network-only',
		}
	);
	return loading ? undefined : data?.emailComponent?.html;
};

const ApiRenderedComponent = ({
	id,
	componentName,
	componentProps,
	brand,
}: {
	id: string;
	componentName: string;
	componentProps: ComponentProps;
	brand?: Brand;
}) => {
	const emailComponentHtml = useEmailComponentHtml({
		id,
		componentName,
		componentProps,
		brand,
	});

	const innerHtml = React.useMemo(
		() => (emailComponentHtml ? { __html: emailComponentHtml } : undefined),
		[emailComponentHtml]
	);

	const ref = React.useRef(null);
	const divDimensions = useDivDimensions(ref, [emailComponentHtml]);

	const heightHistoryRef = React.useRef<number[]>([]);

	React.useEffect(() => {
		const currentHeight = divDimensions.height;
		const lastHeight = heightHistoryRef?.current?.[heightHistoryRef.current?.length - 1];
		if (currentHeight !== lastHeight) {
			heightHistoryRef.current.push(currentHeight);
		}
	}, [divDimensions]);

	const lastHeight = heightHistoryRef?.current?.[heightHistoryRef.current?.length - 1];

	return (
		<>
			{emailComponentHtml ? (
				<Box width="100%" dangerouslySetInnerHTML={innerHtml} ref={ref} />
			) : (
				<Box p="2rem" height={lastHeight || undefined}>
					<Box
						width="100%"
						height="100%"
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<CircularProgress size={24} />
					</Box>
				</Box>
			)}
		</>
	);
};

export default React.memo(ApiRenderedComponent);
