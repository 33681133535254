import React from 'react';
import ContentWrapper from '../../components/ContentWrapper';
import PushMessageEditor from './PushMessageEditor';
import PushLinkEditor from './PushLinkEditor';
import { CampaignLink } from '../../utils/campaign';
import { Language } from '../../utils/notification';

import { ID } from '@cinuru/utils/types';

export type PushEditorRef = {
	validate: () => boolean;
	hasNewsItem: () => boolean;
	saveNewsItem?: () => Promise<boolean>;
};

type ImageKey =
	| 'APP_VIEW_MOVIE_DETAIL'
	| 'CUSTOM_APP_VIEW_POSTER'
	| 'CUSTOM_APP_VIEW_BANNER'
	| 'MESSAGE_IMAGE';

const PushEditorSection = React.forwardRef<
	PushEditorRef,
	{
		variables?: { name: string; label: string; inputLength: number }[];
		defaultMessageTitle?: string;
		defaultMessage?: string;
		defaultMessageImage?: string;
		defaultLink?: CampaignLink;
		onChange: (key: string, value: unknown) => void;
		variant?: 'outlined' | 'filled' | 'standard';
		onSaveCampaign?: (campaignLink?: CampaignLink) => void;
		bonusProgramIds?: ID[];
		disabled?: boolean;
		language: Language;
		campaignCinemaIds: ID[];
	}
>(
	(
		{
			variables,
			defaultMessageTitle,
			defaultMessage,
			defaultMessageImage,
			defaultLink,
			onChange,
			variant = 'outlined',
			onSaveCampaign,
			bonusProgramIds,
			disabled,
			language,
			campaignCinemaIds,
		},
		ref
	): JSX.Element => {
		const [changedLink, setChangedLink] = React.useState<CampaignLink | undefined>(undefined);

		const handleChangeLink = React.useCallback(
			(newLink?: string | null) => {
				setChangedLink(newLink ? (newLink as CampaignLink) : undefined);
				onChange('link', newLink);
			},
			[onChange]
		);

		const PushNotificationRef = React.useRef(null);

		return (
			<ContentWrapper>
				<PushMessageEditor
					defaultMessageTitle={defaultMessageTitle}
					defaultMessage={defaultMessage}
					defaultMessageImage={defaultMessageImage}
					onChange={onChange}
					ref={PushNotificationRef}
					variant={variant}
					variables={variables}
				/>
				<PushLinkEditor
					defaultLink={defaultLink}
					onChange={handleChangeLink}
					hasNewsItem
					onSaveCampaign={onSaveCampaign}
					campaignCinemaIds={campaignCinemaIds}
				/>

				{/* <SendTestNotificationButton
					title={messageTitle || undefined}
					body={messageBody || undefined}
					linkType={linkType}
					linkValue={linkValue}
					imageUrl={currentImage}
					language={language}
					campaignCinemaIds={campaignCinemaIds}
					onSaveNewsItemAndValidate={handleSaveNewsItemAndValidate}
				/> */}
			</ContentWrapper>
		);
	}
);

export default PushEditorSection;
