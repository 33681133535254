import { addDays, differenceInDays, differenceInWeeks, format } from 'date-fns';
import { range } from 'lodash';

const cinemaStrengths = new Map();
const getCinemaStrength = (cinemaId) => {
	if (!cinemaStrengths.has(cinemaId)) {
		cinemaStrengths.set(cinemaId, Math.random());
	}
	return cinemaStrengths.get(cinemaId);
};

export const generateData = (
	from,
	to,
	mean,
	random,
	cinemaIds1,
	cinemaIds2,
	cinemaIds3,
	relative
) => {
	const data = [];
	const start = new Date(from);
	const end = new Date(to);
	const strength1 =
		cinemaIds1.reduce((acc, curr) => acc + getCinemaStrength(curr), 0) /
		(relative && cinemaIds1.length > 0 ? cinemaIds1.length : 1);
	const strength2 =
		cinemaIds2.reduce((acc, curr) => acc + getCinemaStrength(curr), 0) /
		(relative && cinemaIds2.length > 0 ? cinemaIds2.length : 1);
	const strength3 =
		cinemaIds3.reduce((acc, curr) => acc + getCinemaStrength(curr), 0) /
		(relative && cinemaIds3.length > 0 ? cinemaIds3.length : 1);

	const diffInDays = differenceInDays(end, start) + 1;
	let r = range(diffInDays),
		interval = 1;
	if (diffInDays > 61) {
		r = range(differenceInWeeks(end, start) + 1);
		interval = 7;
	}
	if (strength1 > 0) {
		data.push({
			id: '1',
			data: [],
		});
	}
	if (strength2 > 0) {
		data.push({
			id: '2',
			data: [],
		});
	}
	if (strength3 > 0) {
		data.push({
			id: '3',
			data: [],
		});
	}

	r.map((d) => {
		let seasonalityModifier = 1;
		if (interval === 1) {
			switch (addDays(start, d * interval).getDay()) {
				case 1:
					seasonalityModifier = 0.7;
					break;
				case 2:
					seasonalityModifier = 0.75;
					break;
				case 3:
					seasonalityModifier = 0.8;
					break;
				case 4:
					seasonalityModifier = 1.2;
					break;
				case 5:
					seasonalityModifier = 1.5;
					break;
				case 6:
					seasonalityModifier = 2;
					break;
				case 0:
					seasonalityModifier = 1.8;
					break;
				default:
					seasonalityModifier = 1;
					break;
			}
		} else {
			switch (addDays(start, d * interval).getMonth()) {
				case 0:
					seasonalityModifier = 1.8;
					break;
				case 1:
					seasonalityModifier = 1.8;
					break;
				case 2:
					seasonalityModifier = 1.4;
					break;
				case 3:
					seasonalityModifier = 1.2;
					break;
				case 4:
					seasonalityModifier = 1;
					break;
				case 5:
					seasonalityModifier = 0.9;
					break;
				case 6:
					seasonalityModifier = 0.8;
					break;
				case 7:
					seasonalityModifier = 0.7;
					break;
				case 8:
					seasonalityModifier = 0.9;
					break;
				case 9:
					seasonalityModifier = 1.1;
					break;
				case 10:
					seasonalityModifier = 1.4;
					break;
				case 11:
					seasonalityModifier = 2;
					break;

				default:
					seasonalityModifier = 1;
					break;
			}
		}

		const rand = (mean + Math.round(Math.random() * random)) * seasonalityModifier;
		const x = format(addDays(start, d * interval), 'dd.MM.yyyy');
		let arrIndex = 0;
		if (strength1 > 0) {
			data[arrIndex].data.push({
				x,
				y: Math.round(strength1 * rand + Math.round(Math.random() * random)) * interval,
			});
			arrIndex++;
		}
		if (strength2 > 0) {
			data[arrIndex].data.push({
				x,
				y: Math.round(strength2 * rand + Math.round(Math.random() * random)) * interval,
			});
			arrIndex++;
		}
		if (strength3 > 0) {
			data[arrIndex].data.push({
				x,
				y: Math.round(strength3 * rand + Math.round(Math.random() * random)) * interval,
			});
			arrIndex++;
		}
	});
	return data;
};
