/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';

import { ResponsiveBar } from '@nivo/bar';

import { BoxLegendSvg } from '@nivo/legends';
import styled from 'styled-native-components';
import { useTheme } from 'styled-native-components';
import chroma from 'chroma-js';
import { Paragraph } from '@cinuru/components';
import { range } from 'lodash';

const Wrapper = styled.View`
	height: 100%;
	width: 100%;
`;
const Tooltip = styled.View`
	flex-direction: column;
`;
const TooltipCinemasWrapper = styled.View`
	flex-direction: row;
	align-items: center;
`;
const ColorSquare = styled.View`
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 0.3rem;
	background-color: ${(p) => p.color};
	margin: 0 0.5rem;
`;
const BarLegend = ({ height, legends, width }) => (
	<React.Fragment>
		{legends.map((legend) => (
			<BoxLegendSvg
				key={JSON.stringify(legend.data.map(({ id }) => id))}
				{...legend}
				containerHeight={height}
				containerWidth={width}
			/>
		))}
	</React.Fragment>
);

const CompBarChart = ({ cinemaNames, ylabel = '', data, formatYAxisLabel = (value) => value }) => {
	const theme = useTheme();
	const colorscale = chroma.scale(theme.colors.accentGradient0).colors(3);

	return (
		<Wrapper>
			<ResponsiveBar
				data={data}
				groupMode="grouped"
				keys={['1', '2', '3']}
				margin={{ top: 25, right: 30, bottom: 150, left: 65 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={colorscale}
				borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 0,
					tickPadding: 0,
					tickRotation: 0,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Nutzer',
					legendPosition: 'middle',
					legendOffset: -45,
					format: formatYAxisLabel,
					tickValues: 5,
				}}
				legends={[
					{
						data: range(3).map((index) => {
							console.log(index);
							let names = cinemaNames[index].join(', ');
							if (names.length > 43) {
								names = names.substring(0, 43) + ' ...';
							}
							return {
								id: names,
								color: colorscale[index],
								label: names,
							};
						}),
						dataFrom: 'indexes',
						anchor: 'bottom',
						direction: 'row',
						justify: false,
						translateY: 80,
						translateX: 0,
						itemsSpacing: 2,
						itemWidth: 270,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 10,
					},
				]}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelFormat={formatYAxisLabel}
				labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				animate={true}
				motionStiffness={90}
				motionDamping={15}
				tooltip={(args) => {
					let names = cinemaNames[Number(args.id) - 1].join(', ');
					if (names.length > 50) {
						names = names.substring(0, 50) + ' ...';
					}
					return (
						<Tooltip>
							<TooltipCinemasWrapper>
								<ColorSquare color={args.color} />
								<Paragraph size="s">{names}</Paragraph>
							</TooltipCinemasWrapper>
							<Paragraph>{`${args.indexValue} : ${args.value} ${ylabel}`}</Paragraph>
						</Tooltip>
					);
				}}
				layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
			/>
		</Wrapper>
	);
};

const demographicsData = [
	{
		'id': '<18',
		'1': 53001,
		'2': 57455,
		'3': 44044,
	},
	{
		'id': '18-25',
		'1': 73001,
		'2': 77455,
		'3': 64044,
	},
	{
		'id': '25-35',
		'1': 93001,
		'2': 97455,
		'3': 84044,
	},
	{
		'id': '35-49',
		'1': 73001,
		'2': 77455,
		'3': 54044,
	},
	{
		'id': '49-65',
		'1': 53001,
		'2': 57455,
		'3': 44044,
	},
	{
		'id': '65+',
		'1': 33001,
		'2': 27455,
		'3': 31044,
	},
];
export const DemographicsBarChart = (args) => <CompBarChart data={demographicsData} {...args} />;

const visitFrequencyData = [
	{
		'id': '1 Besuch/Jahr oder weniger',
		'1': 17,
		'2': 13,
		'3': 15,
	},
	{
		'id': '2-4 Besuche/Jahr',
		'1': 27,
		'2': 29,
		'3': 30,
	},
	{
		'id': '5-9 Besuche/Jahr',
		'1': 33,
		'2': 36,
		'3': 27,
	},
	{
		'id': '10-20 Besuche/Jahr',
		'1': 16,
		'2': 14,
		'3': 13,
	},
	{
		'id': '20-50 Besuche/Jahr',
		'1': 6,
		'2': 7,
		'3': 11,
	},
	{
		'id': '51+ Besuche/Jahr',
		'1': 1,
		'2': 1,
		'3': 4,
	},
];
const plusVisitFrequencyData = [
	{
		'id': '1 Besuch/Jahr oder weniger',
		'1': 2,
		'2': 3,
		'3': 1,
	},
	{
		'id': '2-4 Besuche/Jahr',
		'1': 15,
		'2': 9,
		'3': 8,
	},
	{
		'id': '5-9 Besuche/Jahr',
		'1': 43,
		'2': 46,
		'3': 42,
	},
	{
		'id': '10-20 Besuche/Jahr',
		'1': 26,
		'2': 28,
		'3': 27,
	},
	{
		'id': '20-50 Besuche/Jahr',
		'1': 11,
		'2': 11,
		'3': 15,
	},
	{
		'id': '51+ Besuche/Jahr',
		'1': 3,
		'2': 3,
		'3': 7,
	},
];
export const VisitFrequencyBarChart = (args) => (
	<CompBarChart data={visitFrequencyData} {...args} />
);
export const PlusVisitFrequencyBarChart = (args) => (
	<CompBarChart data={plusVisitFrequencyData} {...args} />
);
