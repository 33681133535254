import * as React from 'react';
import { snakeCase, camelCase, upperFirst } from 'lodash';
import { withApollo } from 'react-apollo';
import { HotKeys } from 'react-hotkeys';

import { Dialog } from '@cinuru/components';
import { default as gql } from 'graphql-tag';

import WithData from '../../components/WithData';
import CampaignActivationDialog from './CampaignActivationDialog';

import { campaignTypes, usergroupFilterTypes } from './config';
import EditCampaignRender from './EditCampaignRender';

const getCampaignTypeConfig = (type) => campaignTypes.find((c) => c.type === type);
const getFilterTypeConfig = (type) => usergroupFilterTypes.find((f) => f.type === type);
const isIdInputFilterType = (type) => getFilterTypeConfig(type).input[0].itemType === 'MOVIES';
const isNestedInputFilterType = (type) => getFilterTypeConfig(type).input[0].name;

const loadState = ({
	userGroupFilter,
	userGroupFilterOnReferencedMovie: {
		// eslint-disable-next-line no-unused-vars
		__typename,
		id: referencedMovieFilterId,
		...referencedMovieFilterValues
	},
	type: campaignType,
	...rest
}) => ({
	...rest,
	type: campaignType,
	userGroupFilterId: userGroupFilter && userGroupFilter.id,
	referencedMovieFilterId,
	userGroupFilters: Object.entries({
		...userGroupFilter,
		referencedMovie: Object.values(referencedMovieFilterValues).some((v) => v !== null)
			? { ...referencedMovieFilterValues }
			: null,
	})
		.map(([type, value]) => ({
			type: snakeCase(type).toUpperCase(),
			value,
		}))
		.filter(
			({ type, value }) =>
				getFilterTypeConfig(type) &&
				value !== null &&
				getCampaignTypeConfig(campaignType).filter.includes(type)
		)
		.map(({ type, value }) => ({
			type,
			value: isIdInputFilterType(type) ? value.map(({ id }) => id) : value,
		})),
});

export const saveState = async (
	{ userGroupFilterId, userGroupFilters, referencedMovieFilterId, status, ...rest },
	client
) => {
	const generateNestedInput = (type, value) =>
		Object.assign(
			...Object.entries(value).map(([name, innerValue]) =>
				getFilterTypeConfig(type).input.findIndex((i) => i.name === name) >= 0
					? { [camelCase(type) + upperFirst(name)]: innerValue }
					: {}
			)
		);
	const userGroupFilterValues = Object.assign(
		{},
		...userGroupFilters
			.filter(({ type }) => type !== 'REFERENCED_MOVIE')
			.map(({ type, value }) =>
				isNestedInputFilterType(type)
					? generateNestedInput(type, value)
					: { [camelCase(type) + (isIdInputFilterType(type) ? 'Ids' : '')]: value }
			)
	);
	const userGroupFilterQuery = {
		id: userGroupFilterId,
		...userGroupFilterValues,
	};
	const referencedMovieFilter = userGroupFilters.find(({ type }) => type === 'REFERENCED_MOVIE');
	const userGroupFilterOnReferencedMovie = {
		id: referencedMovieFilterId,
		...(referencedMovieFilter
			? referencedMovieFilter.value
			: { onWatchlist: null, seen: null, ratedPositively: null, ratedNegatively: null }),
	};
	const variables = {
		userGroupFilter: userGroupFilterQuery,
		userGroupFilterOnReferencedMovie,
		status: status || 'EDITING',
		...rest,
	};
	const { data } = await client.mutate({
		mutation: gql`
			mutation EditCampaign(
				$id: ID!
				$name: String!
				$channels: [Channel!]!
				$message: String
				$messageTitle: String
				$link: String
				$sendingTime: Time
				$sendingDate: Date
				$sendOnDayXAfterTrigger: Int
				$sendExactlyMinutesAfterTrigger: Int
				$userGroupFilter: UserGroupFilterInput!
				$userGroupFilterOnReferencedMovie: UserGroupFilterOnReferencedMovieInput!
				$status: CampaignStatus!
			) {
				editCampaign(
					id: $id
					name: $name
					channels: $channels
					message: $message
					messageTitle: $messageTitle
					link: $link
					sendingTime: $sendingTime
					sendingDate: $sendingDate
					sendOnDayXAfterTrigger: $sendOnDayXAfterTrigger
					sendExactlyMinutesAfterTrigger: $sendExactlyMinutesAfterTrigger
					status: $status
				) {
					campaign {
						id
					}
				}
				editUserGroupFilter(userGroupFilter: $userGroupFilter) {
					userGroupFilter {
						id
					}
				}
				editUserGroupFilterOnReferencedMovie(
					userGroupFilterOnReferencedMovie: $userGroupFilterOnReferencedMovie
				) {
					userGroupFilterOnReferencedMovie {
						id
					}
				}
			}
		`,
		variables,
	});
	return data;
};

class EditCampaign extends React.PureComponent {
	state = { campaign: loadState(this.props.campaign), saveLoading: false };

	ref = React.createRef();
	keyMap = { save: ['command+s', 'ctrl+s'] };
	keyHandlers = {
		save: (e) => {
			e.preventDefault();
			this.handleSave();
		},
	};

	constructor(props) {
		super(props);
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.campaign !== prevProps.campaign) {
			this.setCampaignState(loadState(this.props.campaign));
		}
	};

	setCampaignState = (newCampaignState, callback) =>
		this.setState(
			(state) => ({
				// saveLoading: true,
				campaign: {
					...state.campaign,
					...(typeof newCampaignState === 'function'
						? newCampaignState(state.campaign)
						: newCampaignState),
				},
			}),
			() => callback && callback()
		);

	setName = (name) => this.setCampaignState({ name, nameError: null });
	setMessage = (message) => this.setCampaignState({ message, messageError: null });
	setMessageTitle = (messageTitle) =>
		this.setCampaignState({ messageTitle, messageTitleError: null });
	setChannels = (channels) => this.setCampaignState({ channels, channelsError: null });
	setLink = (link) => this.setCampaignState({ link, linkError: null });
	setSendingDate = (sendingDate) => this.setCampaignState({ sendingDate, sendingDateError: null });
	setSendingTime = (sendingTime) => this.setCampaignState({ sendingTime, sendingTimeError: null });
	setSendOnDayXAfterTrigger = (sendOnDayXAfterTrigger) =>
		this.setCampaignState({ sendOnDayXAfterTrigger, sendOnDayXAfterTriggerError: null });
	setSendOnDayXBeforeTrigger = (sendOnDayXBeforeTrigger) =>
		this.setCampaignState({
			sendOnDayXAfterTrigger: sendOnDayXBeforeTrigger !== null ? -sendOnDayXBeforeTrigger : null,
			sendOnDayXAfterTriggerError: null,
		});
	setSendExactlyMinutesAfterTrigger = (sendExactlyMinutesAfterTrigger) =>
		this.setCampaignState({
			sendExactlyMinutesAfterTrigger,
			sendExactlyMinutesAfterTriggerError: null,
		});

	setUserGroupFilterValue = ({ type, value, name }) =>
		this.setCampaignState(({ userGroupFilters }) => {
			const newUserGroupFilters = [...userGroupFilters];
			const filterIndex = userGroupFilters.findIndex((filter) => filter.type === type);
			newUserGroupFilters[filterIndex].value = name
				? { ...(userGroupFilters[filterIndex].value || {}), [name]: value }
				: value;
			return { userGroupFilters: newUserGroupFilters };
		});

	removeUserGroupFilter = (type) =>
		this.setCampaignState(({ userGroupFilters }) => ({
			userGroupFilters: userGroupFilters.filter((filter) => filter.type !== type),
		}));

	addUserGroupFilter = (type = 'moviesOnWatchlist') =>
		this.setCampaignState(({ userGroupFilters }) => ({
			userGroupFilters: [...userGroupFilters, { type }],
		}));

	handleSave = async () => {
		this.setState({ saveLoading: true });
		// we must ensure that the news item is saved first and that the news item id is set
		await this.ref.current.saveLinkedNewsItem();
		await saveState(this.state.campaign, this.props.client);
		this.setState({ saveLoading: false });
		await this.props.client.query({
			query: gql`
				query EstimateSize($id: ID!) {
					size: estimateCampaignSize(campaignId: $id) {
						userGroupSize
						exampleReferenceMovie {
							userGroupSize
							movie {
								title
							}
						}
					}
				}
			`,
			variables: { id: this.state.campaign.id },
			fetchPolicy: 'network-only',
		});
	};

	handleDelete = () => {
		Dialog.render({
			title: 'Kampagne löschen',
			description: 'Soll diese Kampagne wirklich gelöscht werden?',
			buttons: [
				{ label: 'ABBRECHEN' },
				{
					label: 'LÖSCHEN',
					color: '$error',
					textColor: '$background0',
					link: false,
					onPress: async () => {
						const { data } = await this.props.client.mutate({
							mutation: gql`
								mutation DeleteCampaigns($ids: ID!) {
									deleteCampaigns(ids: $ids) {
										success
									}
								}
							`,
							variables: { ids: [this.state.campaign.id] },
						});
						if (data && data.deleteCampaigns && data.deleteCampaigns.success) {
							this.props.history.push('/marketing/campaigns');
						}
					},
				},
			],
		});
	};

	handleSetStatusEditing = async () => {
		const { data } = await this.props.client.mutate({
			mutation: gql`
				mutation SetCampaignEditable($id: ID!) {
					setCampaignStatus(id: $id, status: EDITING) {
						campaign {
							id
						}
					}
				}
			`,
			variables: { id: this.state.campaign.id },
		});
		if (data && data.setCampaignStatus && data.setCampaignStatus.campaign) {
			this.setCampaignState({ status: 'EDITING' });
		}
	};
	handleActivation = async () => {
		const {
			name,
			sendingDate,
			sendOnDayXAfterTrigger,
			sendingTime,
			sendExactlyMinutesAfterTrigger,
			channels,
			messageTitle,
			message,
			link,
		} = this.state.campaign;
		const campaignType = getCampaignTypeConfig(this.state.campaign.type);
		let valid = true;
		if (!(name || '').trim()) {
			valid = false;
			this.setCampaignState({ nameError: 'Bitte ausfüllen' });
		}
		if (!channels || channels.length === 0) {
			valid = false;
			this.setCampaignState({ channelsError: 'Bitte auswählen' });
		}
		if (!(messageTitle || '').trim()) {
			valid = false;
			this.setCampaignState({ messageTitleError: 'Bitte ausfüllen' });
		}
		if (!(message || '').trim()) {
			valid = false;
			this.setCampaignState({ messageError: 'Bitte ausfüllen' });
		}
		if (!link) {
			valid = false;
			this.setCampaignState({ linkError: 'Bitte auswählen' });
		}
		if (link && !(await this.ref.current.validateLink())) {
			valid = false;
		}
		if (campaignType.fields.includes('SENDING_DATE') && !sendingDate) {
			valid = false;
			this.setCampaignState({ sendingDateError: 'Bitte auswählen' });
		}
		if (
			(campaignType.fields.includes('SEND_ON_DAY_X_BEFORE_TRIGGER') ||
				campaignType.fields.includes('SEND_ON_DAY_X_AFTER_TRIGGER')) &&
			sendOnDayXAfterTrigger === null
		) {
			valid = false;
			this.setCampaignState({ sendOnDayXAfterTriggerError: 'Bitte ausfüllen' });
		}
		if (campaignType.fields.includes('SENDING_TIME') && !sendingTime) {
			valid = false;
			this.setCampaignState({ sendingTimeError: 'Bitte auswählen' });
		}
		if (
			campaignType.fields.includes('SEND_EXACTLY_MINUTES_AFTER_TRIGGER') &&
			sendExactlyMinutesAfterTrigger === null
		) {
			valid = false;
			this.setCampaignState({ sendExactlyMinutesAfterTriggerError: 'Bitte auswählen' });
		}
		// Show error alert or confirm validation
		if (valid) {
			const { data } = await this.props.client.query({
				query: gql`
					query EstimateSize($id: ID!) {
						size: estimateCampaignSize(campaignId: $id) {
							userGroupSize
						}
					}
				`,
				variables: { id: this.state.campaign.id },
			});
			CampaignActivationDialog.show({
				campaignName: this.state.campaign.name,
				userGroupSize: data.size.userGroupSize,
				onConfirm: () =>
					this.setState(
						(state) => ({ campaign: { ...state.campaign, status: 'ACTIVE' } }),
						this.handleSave
					),
			});
		} else {
			Dialog.render({
				title: 'Angaben sind unvollständig!',
				buttons: [
					{
						label: 'ZURÜCK',
						link: false,
						color: 'neutral0',
						textColor: '$background0',
					},
				],
			});
		}
	};

	render = () => (
		<HotKeys keyMap={this.keyMap} handlers={this.keyHandlers}>
			<EditCampaignRender
				{...this.state.campaign}
				ref={this.ref}
				campaignType={getCampaignTypeConfig(this.state.campaign.type)}
				saveLoading={this.state.saveLoading}
				onSetName={this.setName}
				onSetMessage={this.setMessage}
				onSetMessageTitle={this.setMessageTitle}
				onSetChannels={this.setChannels}
				onSetLink={this.setLink}
				onSetSendingDate={this.setSendingDate}
				onSetSendingTime={this.setSendingTime}
				onSetSendOnDayXAfterTrigger={this.setSendOnDayXAfterTrigger}
				onSetSendOnDayXBeforeTrigger={this.setSendOnDayXBeforeTrigger}
				onSetSendExactlyMinutesAfterTrigger={this.setSendExactlyMinutesAfterTrigger}
				onAddUserGroupFilter={this.addUserGroupFilter}
				onRemoveUserGroupFilter={this.removeUserGroupFilter}
				onSetUserGroupFilterValue={this.setUserGroupFilterValue}
				onActivate={this.handleActivation}
				onDelete={this.handleDelete}
				onSave={this.handleSave}
				onMakeEditable={this.handleSetStatusEditing}
			/>
		</HotKeys>
	);
}

export default withApollo((props) => (
	<WithData
		fetchPolicy="network-only"
		query={gql`
			query Campaign($id: ID!) {
				campaign(id: $id) {
					id
					type
					name
					channels
					message
					messageTitle
					link
					status
					sendingTime
					sendingDate
					sendOnDayXAfterTrigger
					sendExactlyMinutesAfterTrigger
					userGroupFilter {
						id
						moviesOnWatchlist {
							id
							title
							poster
						}
						moviesSeen {
							id
							title
							poster
						}
						moviesNotSeen {
							id
							title
							poster
						}
						moviesRatedPositively {
							id
							title
							poster
						}
						moviesRatedNegatively {
							id
							title
							poster
						}
						bonusPointsGeq
						bonusPointsLeq
						visitFrequency {
							id
							visits
							intervalLengthDays
						}
					}
					userGroupFilterOnReferencedMovie {
						id
						onWatchlist
						seen
						ratedPositively
						ratedNegatively
					}
				}
			}
		`}
		variables={{ id: decodeURIComponent(props.match.params.id) }}
	>
		{({ campaign }) => <EditCampaign campaign={campaign} {...props} />}
	</WithData>
));
