import { differenceInDays, parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-native-components';

import { withApollo } from 'react-apollo';
import { default as gql } from 'graphql-tag';
import { format } from 'date-fns';

import { Button, Icon, Heading, Paragraph } from '@cinuru/components';

import { WithData } from '../../components/WithData';
import { Wrapper, Content, Row } from './LocalComponents';

const MovieCard = styled.View`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: $background1;
	border-radius: ${(p) => p.theme.borderRadius[1]};
	margin: 1rem 0;
	justify-content: flex-start;
	overflow: hidden;
	elevation: 1;
`;

const MoviePoster = styled.View`
	height: ${(p) => p.width * 1.5}px;
	width: ${(p) => p.width}px;
	margin-right: 2rem;
	background-image: url(${(p) => p.poster});
	background-color: ${(p) => (p.poster ? 'none' : '$neutral2')}
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;

const MovieInfos = styled.View`
	display: flex;
	flex-direction: column;
	flex: 10;
	margin-right: ${(p) => p.theme.rem}px;
`;

const MovieActions = styled.View`
	width: 100px;
	height: 70px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const ShowMovies = ({ cinemaId, client, openAddDialog, openEditDialog }) => {
	const deleteMovie = React.useCallback(
		async (id) => {
			await client.mutate({
				mutation: gql`
					mutation deleteSoonInCinemaItem($id: ID!) {
						deleteSoonInCinemaItem(id: $id) {
							success
						}
					}
				`,
				variables: {
					id,
				},
				refetchQueries: ['soonInCinemaItems'],
			});
		},
		[client]
	);

	return (
		<Wrapper>
			<Content>
				<Row>
					<Heading>Vorschau</Heading>
				</Row>
				<Row>
					<Paragraph color="$neutral2">{`Filme erscheinen im Entdecken-Tab der App in der Kategorie 'Bald im Kino'`}</Paragraph>
				</Row>
				<Row>
					<Button
						leftIconName="plus"
						label="FILM HINZUFÜGEN"
						gradient="accentGradient0"
						textColor="$background0"
						/* eslint-disable-next-line */
						margin="1rem 1rem 1rem 0rem"
						onPress={openAddDialog}
					/>
				</Row>
				<WithData
					query={gql`
						query soonInCinemaItems($cinemaId: ID!) {
							soonInCinemaItems(cinemaId: $cinemaId) {
								id
								startDate
								movieTitle
								movie {
									id
									title
									poster
								}
							}
						}
					`}
					// eslint-disable-next-line
					variables={{ cinemaId }}
				>
					{({ soonInCinemaItems }) => {
						if (soonInCinemaItems.length === 0) {
							return (
								<MovieCard>
									<Paragraph>Derzeit sind keine Filme vorhanden</Paragraph>
								</MovieCard>
							);
						}
						// don't show soonInCinemaItems that have a startdate that is 7 days or longer ago
						soonInCinemaItems = soonInCinemaItems.filter(
							(item) => differenceInDays(parseISO(item.startDate), new Date()) > -7
						);
						return soonInCinemaItems.map((item) => (
							<MovieCard key={item.id}>
								<MoviePoster poster={item.movie ? item.movie.poster : null} width={70} />
								<MovieInfos>
									<Paragraph bold>{item.movie ? item.movie.title : item.movieTitle}</Paragraph>
									<Paragraph>
										{item.startDate && format(parseISO(item.startDate), 'dd.MM.yyyy')}
									</Paragraph>
								</MovieInfos>
								<MovieActions>
									<Icon
										nSize={3}
										name="pen"
										margin="0 2rem 0 0"
										// eslint-disable-next-line
										onPress={() => openEditDialog(item)}
									/>
									<Icon
										nSize={3}
										name="trash"
										// eslint-disable-next-line
										onPress={() => deleteMovie(item.id)}
									/>
								</MovieActions>
							</MovieCard>
						));
					}}
				</WithData>
			</Content>
		</Wrapper>
	);
};

export default withApollo(ShowMovies);
