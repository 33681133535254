import React from 'react';
import styled from 'styled-components';
import templateData from '../json/templateData.json';

import { Brand } from '@cinuru/utils/theme';
import { FullProgramProps as ProgramProps } from '@cinuru/emails/src/components/DashboardEmailComponents/FullProgram';

import {
	stripValueFromDimensionObject,
	transformElement,
} from '../utils/emailConfigurationHelpers';

import ApiRenderedComponent from './ApiRenderedComponent';
import {
	ColorController,
	CheckBoxController,
	SelectController,
	CinemasController,
	TextEditorController,
} from '../utils/allFormControlComponents';
import { useElementsWithUpdatedProgram } from '../utils/updateTrueElements';
import { Campaign } from '../../../utils/campaign';
import { EmailElement } from '../../../utils/campaignEmailContent';

import SizeController from './FormControls/SizeController';
import ExpandableContents from '../../../components/ExpandableContents';

const { fullProgramTemplateMovies } = templateData;

interface ProgramData extends ProgramProps {
	type: 'PROGRAM';
	index?: any;
	key?: any;
	borderRadiusWrapper?: any;
	campaign?: any;
	selectedCampaignCinemas?: any;
	timeFrame: 'NEXT_WEEK_ONLY' | 'ALL_UPCOMING_MOVIES';
}

const Wrapper = styled.div`
	display: flex;
	width: 100%;
`;

export const ProgramPreview = ({
	id,
	element,
	campaign,
	brand,
	elements,
}: {
	id: string;
	element: ProgramData;
	campaign: Campaign;
	brand: Brand;
	elements: EmailElement[];
}): JSX.Element => {
	const elementWithNewProgram = useElementsWithUpdatedProgram({
		elements,
		campaign,
		selectedCampaignCinemas: element.selectedCampaignCinemas,
		timeFrame: element.timeFrame,
	}).filter((el) => el.key === element.key)[0] as ProgramData;

	const componentProps: ProgramProps = React.useMemo(
		() => ({
			...element,
			movieConfiguration: stripValueFromDimensionObject(
				element.movieConfiguration
			) as ProgramProps['movieConfiguration'],
			fullProgramMovies: elementWithNewProgram.fullProgramMovies,
		}),
		[element, elementWithNewProgram.fullProgramMovies]
	);

	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="FullProgram"
			componentProps={componentProps}
		/>
	);
};

export const ProgramEditor = React.memo(
	({
		element,
		updateElement,
		brand,
	}: {
		element: ProgramData;
		updateElement: (programData) => void;
		brand: Brand;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement(newElement as ProgramData);
			},
			[element, updateElement]
		);

		const contents = React.useMemo(
			() => [
				{
					label: 'Allgemein',
					items: [
						<CinemasController
							onChange={handleChangeController}
							path="selectedCampaignCinemas"
							defaultIds={element.selectedCampaignCinemas}
							key="1"
						/>,
						<CheckBoxController
							type="PROGRAM_DETAILS"
							value={element.details}
							onChange={handleChangeController}
							path="details"
							key="2"
						/>,
						<SelectController
							type="LIMIT_PROGRAM"
							value={element.timeFrame}
							onChange={handleChangeController}
							path="timeFrame"
							key="3"
						/>,
						<ColorController
							value={element.backgroundColor}
							brand={brand}
							onChange={handleChangeController}
							path="backgroundColor"
							label="Hintergrundfarbe"
							fallBackColor="background0"
							key="4"
						/>,
					],
				},
				{
					label: 'Hauptüberschrift und -beschreibung',
					items: [
						<TextEditorController
							id={`program_header_${element.key}`}
							value={element.description?.text || ''}
							onChange={handleChangeController}
							path="description/text"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.description?.family}
							onChange={handleChangeController}
							path="description/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.description?.size}
							onChange={handleChangeController}
							path="description/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.description?.align}
							onChange={handleChangeController}
							path="description/align"
							key="4"
						/>,
						<ColorController
							value={element.description?.color}
							brand={brand}
							onChange={handleChangeController}
							path="description/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
					],
				},
				{
					label: 'Filmplakat',
					items: [
						<SizeController
							type="WIDTH"
							value={element.movieConfiguration?.poster?.width}
							onChange={handleChangeController}
							path="movieConfiguration/poster/width"
							key="1"
						/>,
						<SizeController
							type="BORDER_RADIUS"
							value={element.movieConfiguration?.poster?.borderRadius}
							onChange={handleChangeController}
							path="movieConfiguration/poster/borderRadius"
							key="2"
						/>,
						<CheckBoxController
							type="SHADOW"
							value={element.movieConfiguration?.poster?.shadow}
							onChange={handleChangeController}
							path="movieConfiguration/poster/shadow"
							key="3"
						/>,
					],
				},
				{
					label: 'Filmtitel',
					items: [
						<SelectController
							type="MARKDOWN_TYPE"
							value={element.movieConfiguration?.movieTitle?.textType}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/textType"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.movieConfiguration?.movieTitle?.family}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.movieConfiguration?.movieTitle?.size}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.movieConfiguration?.movieTitle?.align}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/align"
							key="4"
						/>,
						<ColorController
							value={element.movieConfiguration?.movieTitle?.color}
							brand={brand}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
						<CheckBoxController
							type="BOLDNESS"
							value={element.movieConfiguration?.movieTitle?.bold}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/bold"
							key="6"
						/>,
					],
				},
				{
					label: 'Weitere Filminfos',
					items: [
						<SelectController
							type="MARKDOWN_TYPE"
							value={element.movieConfiguration?.movieData?.textType}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/textType"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.movieConfiguration?.movieData?.family}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.movieConfiguration?.movieData?.size}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.movieConfiguration?.movieData?.align}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/align"
							key="4"
						/>,
						<ColorController
							value={element.movieConfiguration?.movieData?.color}
							brand={brand}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
						<CheckBoxController
							type="BOLDNESS"
							value={element.movieConfiguration?.movieData?.bold}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/bold"
							key="6"
						/>,
					],
				},
				{
					label: 'Filmsynopsis',
					items: [
						<SelectController
							type="MARKDOWN_TYPE"
							value={element.movieConfiguration?.movieSynopsis?.textType}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/textType"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.movieConfiguration?.movieSynopsis?.family}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.movieConfiguration?.movieSynopsis?.size}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.movieConfiguration?.movieSynopsis?.align}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/align"
							key="4"
						/>,
						<ColorController
							value={element.movieConfiguration?.movieSynopsis?.color}
							brand={brand}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
						<CheckBoxController
							type="BOLDNESS"
							value={element.movieConfiguration?.movieSynopsis?.bold}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/bold"
							key="6"
						/>,
					],
				},
			],
			[
				element.description?.align,
				element.description?.color,
				element.description?.family,
				element.description?.size,
				element.description?.text,
				element.details,
				element.key,
				element.movieConfiguration?.movieData?.align,
				element.movieConfiguration?.movieData?.bold,
				element.movieConfiguration?.movieData?.color,
				element.movieConfiguration?.movieData?.family,
				element.movieConfiguration?.movieData?.size,
				element.movieConfiguration?.movieData?.textType,
				element.movieConfiguration?.movieSynopsis?.align,
				element.movieConfiguration?.movieSynopsis?.bold,
				element.movieConfiguration?.movieSynopsis?.color,
				element.movieConfiguration?.movieSynopsis?.family,
				element.movieConfiguration?.movieSynopsis?.size,
				element.movieConfiguration?.movieSynopsis?.textType,
				element.movieConfiguration?.movieTitle?.align,
				element.movieConfiguration?.movieTitle?.bold,
				element.movieConfiguration?.movieTitle?.color,
				element.movieConfiguration?.movieTitle?.family,
				element.movieConfiguration?.movieTitle?.size,
				element.movieConfiguration?.movieTitle?.textType,
				element.movieConfiguration?.poster?.borderRadius,
				element.movieConfiguration?.poster?.shadow,
				element.movieConfiguration?.poster?.width,
				element.selectedCampaignCinemas,
				element.timeFrame,
				handleChangeController,
				brand,
				element.backgroundColor,
			]
		);

		return (
			<Wrapper>
				<ExpandableContents
					width="100%"
					contents={contents}
					itemMargin="2rem 0 1rem"
					expandableMargin="1rem 0"
				/>
			</Wrapper>
		);
	}
);

export const ProgramDefaultParams: ProgramData = {
	type: 'PROGRAM',
	timeFrame: 'NEXT_WEEK_ONLY',
	fullProgramMovies: fullProgramTemplateMovies,
	details: true,
	description: {
		textType: 'PARAGRAPH',
		text: '# Das gesamte Kinoprogramm:',
	},
	movieConfiguration: {
		poster: { shadow: true },
		movieTitle: {
			textType: 'PARAGRAPH',
			color: 'accent0',
			bold: true,
		},
		movieData: {
			textType: 'PARAGRAPH',
			bold: true,
		},
		movieSynopsis: {
			textType: 'PARAGRAPH',
		},
	},
	index: 0,
	borderRadiusWrapper: { top: true, bottom: true },
};

export default React.memo(ProgramPreview);
