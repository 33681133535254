import * as React from 'react';
import { Tabs as MuiTabs, Tab, Box } from '@mui/material';

const TabWrapper = ({
	children,
	value,
	index,
}: {
	children: JSX.Element;
	value: number;
	index: number;
}): JSX.Element => {
	return (
		<Box width="100%" hidden={value !== index}>
			{value === index && <Box>{children}</Box>}
		</Box>
	);
};

export type TabItems = { label: string; renderComponent: () => JSX.Element }[];

const Tabs = ({ tabs }: { tabs: TabItems }): JSX.Element => {
	const [activePanel, setActivePanel] = React.useState(0);

	const handleChangeActivePanel = React.useCallback(
		(_, newValue) => {
			setActivePanel(newValue);
		},
		[setActivePanel]
	);

	return (
		<Box width="100%">
			<MuiTabs
				value={activePanel}
				onChange={handleChangeActivePanel}
				textColor="inherit"
				indicatorColor="primary"
			>
				{tabs?.map(({ label: tabFilterLabel }) => (
					<Tab key={tabFilterLabel} label={tabFilterLabel} />
				))}
			</MuiTabs>

			{tabs.map(({ label, renderComponent }, index) => {
				return (
					<TabWrapper key={label} value={activePanel} index={index}>
						{renderComponent()}
					</TabWrapper>
				);
			})}
		</Box>
	);
};

export default Tabs;
