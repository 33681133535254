import * as React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import Expandable from './Expandable';
import { BoxProps } from '@mui/material/Box';

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const ExpandableContents = ({
	contents,
	itemMargin,
	expandableMargin,
	m,
	width,
}: {
	contents: { label: string; items: JSX.Element[] }[];
	itemMargin?: string;
	expandableMargin?: string;
} & BoxProps): JSX.Element => {
	return (
		<Wrapper width={width} m={m}>
			{contents.map(({ label, items }) => (
				<Expandable key={label} label={label} margin={expandableMargin}>
					{items.map((item, index) => (
						<Box key={index} m={itemMargin}>
							{item}
						</Box>
					))}
				</Expandable>
			))}
		</Wrapper>
	);
};

export default ExpandableContents;
