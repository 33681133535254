import React from 'react';
import { Row, Col, Input, Button } from '../../components/AntDReplacements';

const TextArea = Input.TextArea;
const styles = {
	wrapper: {
		marginTop: '20px',
		marginBottom: '40px',
	},
};
//TODO: Cleanup this styles mess
export const PricesSection = ({ prices, updatePrice, addPrice, removePrice }) => (
	<div style={styles.wrapper}>
		<Row>
			<Col>Preise</Col>
		</Row>
		<Row style={{ margin: '10px 0' }}>
			{Object.entries(prices).map(([key, { description, title, price }]) => (
				<Col span={7} key={key} style={{ marginBottom: '10px', marginLeft: '10px' }}>
					<Input
						value={title}
						placeholder="Titel..."
						onChange={(event) => updatePrice(key, 'title', event.target.value)}
					/>
					<Input
						value={price}
						placeholder="Preis..."
						onChange={(event) => updatePrice(key, 'price', event.target.value)}
					/>
					<TextArea
						value={description}
						placeholder="Beschreibung..."
						onChange={(event) => updatePrice(key, 'description', event.target.value)}
					/>
					<Button type="danger" onClick={() => removePrice(key)}>
						Löschen
					</Button>
				</Col>
			))}
		</Row>
		<Row>
			<Col>
				<Button type="dashed" icon="plus-circle-o" onClick={() => addPrice({})} />
			</Col>
		</Row>
	</div>
);
