import React from 'react';

import { Box, CircularProgress } from '@mui/material';

const LoadingBox = ({ height = '20rem' }: { height?: string }): JSX.Element | null => {
	return (
		<Box
			width="100%"
			height={height}
			display="flex"
			flexDirection="row"
			justifyContent="center"
			alignItems="center"
		>
			<CircularProgress />
		</Box>
	);
};

export default LoadingBox;
