import * as React from 'react';
import { Query } from 'react-apollo';
import { isEmpty } from 'lodash';

import { LoadingIndicator, Paragraph } from '@cinuru/components';

export const WithData = ({ children, errorComponent, ...props }) => (
	<Query {...props}>
		{({ data, error, options, loading }) =>
			error ? (
				errorComponent || <Paragraph>{`Error!: ${error}`}</Paragraph>
			) : isEmpty(data) ? (
				<LoadingIndicator />
			) : (
				children(data, options, loading)
			)
		}
	</Query>
);

WithData.defaultProps = {
	fetchPolicy: 'cache-and-network',
};

export { withApollo, ApolloConsumer } from 'react-apollo';
export default WithData;
