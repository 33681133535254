import * as React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { ID } from '@cinuru/utils/types';

import { TargetGroupFiltersAvailability } from './EditTargetGroup';
import { Cluster, Options } from '../../utils/targetGroup';
import DistanceFilter, { DistanceFilterRef } from './Components/DistanceFilter';
import SliderFilter, { TimeUnit } from './Components/SliderFilter';
import TimePeriodFilter, { TimePeriodFilteRef } from './Components/TimePeriodFilter';
import SwitchFilter from './Components/SwitchFilter';
import SearchSelectFilter, {
	SelectFieldItem,
	GeneralSearchFilterRef,
} from './Components/SearchSelectFilter';
import ValueRangeFilter, { ValueRangeFilterRef } from './Components/ValueRangeFilter';
import Expandable from '../../components/Expandable';
import TextField from '../../components/TextField';
import IconButton from '../../components/IconButton';

import useTextFieldContoller from '../../utils/useTextFieldController';

const genderDict = {
	MALE: 'männlich',
	FEMALE: 'weiblich',
	DIVERSE: 'divers',
};
const groupSizeKeys = {
	LARGEGROUP: 'Gruppenorganisator',
	PAIRS: 'Pärchenkäufer',
	SINGLE: 'Einzelgänger',
};

const expandableProps = {
	margin: '0 0 2rem 0',
	padding: '5rem 3rem 0 0',
};

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export type ClusterPropName =
	| 'ageFilter'
	| 'gendersFilter'
	| 'movieAttributesAffinityFilter'
	| 'SliderFilter'
	| 'parentStatusFilter'
	| 'bonusProgramMemberStatusFilter'
	| 'longTermBenefitsReceivedFilter'
	| 'distanceFilter'
	| 'castOrCrewMemberAffinityFilter'
	| 'genreAffinityFilter'
	| 'movieAffinityFilter'
	| 'moviesSeenFilter'
	| 'moviesNotSeenFilter'
	| 'moviesOnWatchListFilter'
	| 'vouchersReceivedFilter'
	| 'vouchersRedeemedFilter'
	| 'stickersReceivedFilter'
	| 'bonusPointsFilter'
	| 'statusPointsFilter'
	| 'statusLevelFilter'
	| 'noCinemaVisitSinceFilter'
	| 'cinemaVisitSinceFilter'
	| 'averageGroupSizesFilter'
	| 'name'
	| 'averageCinemaVisitsFilter';

const gendersOptions: SelectFieldItem[] = [
	{ label: 'männlich', value: 'MALE' },
	{ label: 'weiblich', value: 'FEMALE' },
	{ label: 'divers', value: 'DIVERSE' },
];

const averageGroupSizeOptions: SelectFieldItem[] = [
	{ label: 'Einzelgänger', value: 'SINGLE' },
	{ label: 'Pärchenkäufer', value: 'PAIRS' },
	{ label: 'Gruppenorganisator', value: 'LARGEGROUP' },
];

export interface ClusterProps {
	clusterPropName: ClusterPropName;
	clusterPropValue: string | { [key: string]: unknown };
	transformItemListValueToListOfIds?: boolean;
}

const enabledTimeUnits: TimeUnit[] = ['WEEK', 'MONTH', 'YEAR'];

export type TargetGroupClusterRef = {
	validate: () => boolean;
};

const TargetGroupCluster = React.forwardRef<
	TargetGroupClusterRef,
	{
		cluster: Cluster;
		onChange: ({
			clusterId,
			clusterPropName,
			clusterPropValue,
		}: {
			clusterId: ID;
			clusterPropName: ClusterPropName;
			clusterPropValue: string | { [key: string]: unknown };
		}) => void;
		removeCluster: (clusterId: ID) => void;
		selectionOptions?: Options;
		targetGroupFiltersAvailability: TargetGroupFiltersAvailability;
		changedWithoutSaving: boolean;
		disabled?: boolean;
	}
>(
	(
		{
			cluster,
			onChange,
			removeCluster,
			selectionOptions = { genres: [], vouchers: [], stickers: [], movieAttributes: [] },
			targetGroupFiltersAvailability,
			changedWithoutSaving,
			disabled,
		},
		ref
	): JSX.Element => {
		const { textInputProps: clusterNameProps } = useTextFieldContoller({
			defaultValue: cluster.name,
			inputLabel: 'Cluster-Titel',
			stateKey: '',
			onChange: (value) => {
				onChange({
					clusterId: cluster.id,
					clusterPropName: 'name',
					clusterPropValue: value!,
				});
			},
		});

		const handleChangeClusterProps = React.useCallback(
			({ clusterPropName, clusterPropValue, transformItemListValueToListOfIds }: ClusterProps) => {
				const key =
					transformItemListValueToListOfIds && clusterPropValue && Object.keys(clusterPropValue)[0];
				const newValue =
					transformItemListValueToListOfIds && key && key !== 'active' && clusterPropValue[key]
						? {
								[key]: clusterPropValue[key].map((v) => v.id),
						  }
						: clusterPropValue;
				onChange({ clusterId: cluster.id, clusterPropName, clusterPropValue: newValue });
			},
			[cluster.id, onChange]
		);

		const handleChangeActivity = React.useCallback(
			(clusterPropName: ClusterPropName, active: boolean) => {
				handleChangeClusterProps({
					clusterPropName,
					clusterPropValue: {
						active,
					},
				});
			},
			[handleChangeClusterProps]
		);

		const handleChange = React.useCallback(
			(clusterPropName: ClusterPropName, key: string, items: SelectFieldItem[]) => {
				const transformedItems = items.map((item) => item.value);
				handleChangeClusterProps({
					clusterPropName,
					clusterPropValue: {
						[key]: transformedItems,
					},
				});
			},
			[handleChangeClusterProps]
		);

		const useHandleChangeClusterPropertyAtKey = (key) => {
			return React.useCallback(
				(clusterPropName, items) => {
					handleChange(clusterPropName, key, items);
				},
				[key]
			);
		};

		const handleChangeGenres = useHandleChangeClusterPropertyAtKey('genres');
		const handleChangeGroupSizes = useHandleChangeClusterPropertyAtKey('sizes');
		const handleChangeStickers = useHandleChangeClusterPropertyAtKey('stickers');
		const handleChangeVouchers = useHandleChangeClusterPropertyAtKey('vouchers');
		const handleChangeCastOrCrewMember = useHandleChangeClusterPropertyAtKey('castOrCrewMembers');
		const handleChangeMovies = useHandleChangeClusterPropertyAtKey('movies');
		const handleChangeGenders = useHandleChangeClusterPropertyAtKey('genders');

		const handleChangeMovieAttributes = React.useCallback(
			(clusterPropName: ClusterPropName, items: SelectFieldItem[]) => {
				const transformedItems = items.map((item) => ({ id: item.value, label: item.label }));
				handleChangeClusterProps({
					clusterPropName,
					clusterPropValue: {
						movieAttributes: transformedItems,
					},
				});
			},
			[handleChangeClusterProps]
		);

		const handleRemoveCluster = React.useCallback(() => {
			removeCluster(cluster.id);
		}, [cluster.id, removeCluster]);

		const movieAffinityFilteRef = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef2 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef3 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef4 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef5 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef6 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef7 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef8 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef9 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef10 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef11 = React.useRef<GeneralSearchFilterRef>(null);
		const generalSearchFilterRef12 = React.useRef<GeneralSearchFilterRef>(null);
		const distanceFilterRef = React.useRef<DistanceFilterRef>(null);
		const bonusPointsFilterRef = React.useRef<ValueRangeFilterRef>(null);
		const statusPointsFilterRef = React.useRef<ValueRangeFilterRef>(null);
		const statusLevelFilterRef = React.useRef<ValueRangeFilterRef>(null);
		const noCinemaVisitsFilterRef = React.useRef<TimePeriodFilteRef>(null);
		const cinemaVisitSinceFilterRef = React.useRef<TimePeriodFilteRef>(null);

		const handleValidate = React.useCallback(() => {
			const errors = [
				movieAffinityFilteRef,
				generalSearchFilterRef2,
				generalSearchFilterRef3,
				generalSearchFilterRef4,
				generalSearchFilterRef5,
				generalSearchFilterRef6,
				generalSearchFilterRef7,
				generalSearchFilterRef8,
				generalSearchFilterRef9,
				generalSearchFilterRef10,
				generalSearchFilterRef11,
				generalSearchFilterRef12,
				distanceFilterRef,
				bonusPointsFilterRef,
				statusPointsFilterRef,
				statusLevelFilterRef,
				noCinemaVisitsFilterRef,
				cinemaVisitSinceFilterRef,
			].map((r) => r.current?.validate());
			const invalid = errors.some(Boolean);
			return invalid;
		}, []);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<>
				<Row justifyContent="space-between" m="2rem 0">
					<TextField
						m="0 1rem 0 0"
						flex
						variant="outlined"
						{...clusterNameProps}
						disabled={disabled}
					/>
					<TextField
						m="0 1rem 0 0"
						label="Größe des Clusters"
						variant="outlined"
						value={changedWithoutSaving ? '?' : cluster.clusterSize || ''}
						width="20rem"
						disabled
						helperText={changedWithoutSaving ? 'für Berechnung bitte speichern' : ''}
					/>
					<IconButton
						iconName="DeleteOutline"
						m="1rem"
						onClick={handleRemoveCluster}
						disabled={disabled}
					>
						<DeleteIcon />
					</IconButton>
				</Row>

				<Expandable label="Demografie" {...expandableProps} disabled={disabled}>
					<SliderFilter
						label="Empfänger müssen folgender Altersspanne angehören:"
						active={cluster.ageFilter.active}
						showFilter={targetGroupFiltersAvailability.ageFilter}
						onChange={handleChangeClusterProps}
						clusterPropName="ageFilter"
						hideTimeUnit
						defaultMin={cluster.ageFilter.minAge}
						defaultMax={cluster.ageFilter.maxAge}
						minMark={0}
						maxMark={100}
						markStep={10}
						fallbackMin={16}
						fallbackMax={45}
					/>
					<SearchSelectFilter
						active={cluster.gendersFilter.active}
						defaultItems={cluster.gendersFilter.genders.map((g) => ({
							value: g,
							label: genderDict[g],
						}))}
						allItems={gendersOptions}
						showFilter={targetGroupFiltersAvailability.gendersFilter}
						onChange={handleChangeGenders}
						clusterPropName="gendersFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger können folgendem Geschlecht angehören"
						ref={generalSearchFilterRef10}
					/>
					<SwitchFilter
						active={cluster.parentStatusFilter.active}
						defaultStatus={cluster.parentStatusFilter.status}
						showFilter={targetGroupFiltersAvailability.parentStatusFilter}
						onChange={handleChangeClusterProps}
						clusterPropName="parentStatusFilter"
					/>
					<DistanceFilter
						active={cluster.distanceFilter.active}
						defaultZipCode={cluster.distanceFilter.zipCode}
						defaultDistanceInKm={cluster.distanceFilter.distanceInKm}
						showFilter={targetGroupFiltersAvailability.distanceFilter}
						onChange={handleChangeClusterProps}
						clusterPropName="distanceFilter"
						ref={distanceFilterRef}
					/>
				</Expandable>
				<Expandable label="Filme" {...expandableProps} disabled={disabled}>
					<SearchSelectFilter
						active={cluster.movieAffinityFilter.active}
						defaultItems={cluster.movieAffinityFilter.movies.map(({ id, title }) => ({
							value: id as string,
							label: title,
						}))}
						showFilter={targetGroupFiltersAvailability.movieAffinityFilter}
						onChange={handleChangeMovies}
						clusterPropName="movieAffinityFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger mit einer wahrscheinlichen Vorliebe für mindestens einen der folgenden Filme:"
						type="MOVIE"
						ref={movieAffinityFilteRef}
					/>
					<SearchSelectFilter
						active={cluster.genreAffinityFilter.active}
						defaultItems={cluster.genreAffinityFilter.genres.map((genre) => ({
							value: genre,
							label: genre.charAt(0) + genre.substring(1).toLowerCase(),
						}))}
						allItems={selectionOptions.genres.map((g) => ({
							value: g.id as string,
							label: g.name,
						}))}
						showFilter={targetGroupFiltersAvailability.genreAffinityFilter}
						onChange={handleChangeGenres}
						clusterPropName="genreAffinityFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger mit einer wahrscheinlichen Vorliebe für mindestens eines der folgenden Genres:"
						ref={generalSearchFilterRef2}
					/>

					<SearchSelectFilter
						active={cluster.castOrCrewMemberAffinityFilter.active}
						defaultItems={cluster.castOrCrewMemberAffinityFilter.castOrCrewMembers.map(
							({ id, fullName }) => ({ value: id as string, label: fullName })
						)}
						showFilter={targetGroupFiltersAvailability.castOrCrewMemberAffinityFilter}
						onChange={handleChangeCastOrCrewMember}
						clusterPropName="castOrCrewMemberAffinityFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger mit einer wahrscheinlichen Vorliebe für mindestens einen der folgenden Regisseure oder Schauspieler:"
						type="CAST_OR_CREW_MEMBER"
						ref={generalSearchFilterRef3}
					/>
					<SearchSelectFilter
						active={cluster.movieAttributesAffinityFilter.active}
						defaultItems={cluster.movieAttributesAffinityFilter.movieAttributes.map(
							({ id, label }) => ({ value: id as string, label })
						)}
						allItems={selectionOptions.movieAttributes.map(({ id, label }) => ({
							value: id as string,
							label,
						}))}
						showFilter={targetGroupFiltersAvailability.movieAttributesAffinityFilter}
						onChange={handleChangeMovieAttributes}
						clusterPropName="movieAttributesAffinityFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger mit einer wahrscheinlichen Vorliebe für folgende Filmeigenschaften:"
						ref={generalSearchFilterRef11}
					/>
					<SearchSelectFilter
						active={cluster.moviesSeenFilter.active}
						defaultItems={cluster.moviesSeenFilter.movies.map(({ id, title }) => ({
							value: id as string,
							label: title,
						}))}
						showFilter={targetGroupFiltersAvailability.moviesSeenFilter}
						onChange={handleChangeMovies}
						clusterPropName="moviesSeenFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger müssen zudem folgende Filme gesehen haben:"
						type="MOVIE"
						ref={generalSearchFilterRef4}
					/>
					<SearchSelectFilter
						active={cluster.moviesNotSeenFilter.active}
						defaultItems={cluster.moviesNotSeenFilter.movies.map(({ id, title }) => ({
							value: id as string,
							label: title,
						}))}
						showFilter={targetGroupFiltersAvailability.moviesNotSeenFilter}
						onChange={handleChangeMovies}
						clusterPropName="moviesNotSeenFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger dürfen zudem folgende Filme noch nicht gesehen haben:"
						type="MOVIE"
						ref={generalSearchFilterRef5}
					/>
					<SearchSelectFilter
						active={cluster.moviesOnWatchListFilter.active}
						defaultItems={cluster.moviesOnWatchListFilter.movies.map(({ id, title }) => ({
							value: id as string,
							label: title,
						}))}
						showFilter={targetGroupFiltersAvailability.moviesOnWatchListFilter}
						onChange={handleChangeMovies}
						clusterPropName="moviesOnWatchListFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger müssen zudem folgende Filme auf ihrer Merkliste haben:"
						type="MOVIE"
						ref={generalSearchFilterRef6}
					/>
				</Expandable>
				<Expandable label="Bonusprogram" {...expandableProps} disabled={disabled}>
					<SwitchFilter
						active={cluster.bonusProgramMemberStatusFilter.active}
						defaultStatus={cluster.bonusProgramMemberStatusFilter.status}
						showFilter={targetGroupFiltersAvailability.bonusProgramMemberStatusFilter}
						onChange={handleChangeClusterProps}
						clusterPropName="bonusProgramMemberStatusFilter"
					/>
					<ValueRangeFilter
						showFilter={targetGroupFiltersAvailability.bonusPointsFilter}
						active={cluster.bonusPointsFilter.active}
						defaultMinimum={cluster.bonusPointsFilter.minBonusPoints}
						defaultMaximum={cluster.bonusPointsFilter.maxBonusPoints}
						onChange={handleChangeClusterProps}
						clusterPropName="bonusPointsFilter"
						ref={bonusPointsFilterRef}
					/>
					<ValueRangeFilter
						showFilter={targetGroupFiltersAvailability.statusPointsFilter}
						active={cluster.statusPointsFilter.active}
						defaultMinimum={cluster.statusPointsFilter.minStatusPoints}
						defaultMaximum={cluster.statusPointsFilter.maxStatusPoints}
						onChange={handleChangeClusterProps}
						clusterPropName="statusPointsFilter"
						ref={statusPointsFilterRef}
					/>
					<ValueRangeFilter
						showFilter={targetGroupFiltersAvailability.statusLevelFilter}
						active={cluster.statusLevelFilter.active}
						defaultMinimum={cluster.statusLevelFilter.minStatusLevel}
						defaultMaximum={cluster.statusLevelFilter.maxStatusLevel}
						onChange={handleChangeClusterProps}
						clusterPropName="statusLevelFilter"
						ref={statusLevelFilterRef}
					/>

					<SearchSelectFilter
						active={cluster.vouchersReceivedFilter.active}
						defaultItems={cluster.vouchersReceivedFilter.vouchers.map(({ id, title }) => ({
							value: id as string,
							label: title,
						}))}
						allItems={selectionOptions.vouchers.map(({ id, title }) => ({
							value: id as string,
							label: title || '',
						}))}
						showFilter={targetGroupFiltersAvailability.vouchersReceivedFilter}
						onChange={handleChangeVouchers}
						clusterPropName="vouchersReceivedFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger müssen folgende Gutscheine erhalten haben:"
						ref={generalSearchFilterRef7}
					/>

					<SearchSelectFilter
						active={cluster.vouchersRedeemedFilter.active}
						defaultItems={cluster.vouchersRedeemedFilter.vouchers.map(({ id, title }) => ({
							value: id as string,
							label: title,
						}))}
						allItems={selectionOptions.vouchers.map(({ id, title }) => ({
							value: id as string,
							label: title || '',
						}))}
						showFilter={targetGroupFiltersAvailability.vouchersRedeemedFilter}
						onChange={handleChangeVouchers}
						clusterPropName="vouchersRedeemedFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger müssen folgende Gutscheine eingelöst haben:"
						ref={generalSearchFilterRef8}
					/>
					<SearchSelectFilter
						active={cluster.stickersReceivedFilter.active}
						defaultItems={cluster.stickersReceivedFilter.stickers.map(
							({ id, title, description }) => ({
								value: id as string,
								label: `${title}${description ? ': "' + description + '"' : ''}`,
							})
						)}
						allItems={selectionOptions.stickers.map(({ id, title }) => ({
							value: id as string,
							label: title,
						}))}
						showFilter={targetGroupFiltersAvailability.stickersReceivedFilter}
						onChange={handleChangeStickers}
						clusterPropName="stickersReceivedFilter"
						onChangeActivity={handleChangeActivity}
						label="Empfänger müssen folgende Sticker erhalten haben:"
						ref={generalSearchFilterRef9}
					/>
					<SwitchFilter
						showFilter={targetGroupFiltersAvailability.longTermBenefitsReceivedFilter}
						active={cluster.longTermBenefitsReceivedFilter.active}
						defaultStatus={cluster.longTermBenefitsReceivedFilter.status}
						onChange={handleChangeClusterProps}
						clusterPropName="longTermBenefitsReceivedFilter"
					/>
				</Expandable>
				<Expandable label="Besuchsverhalten" {...expandableProps} disabled={disabled}>
					<SliderFilter
						label="Nur Empfänger, die durchschnittlich so oft, wie festgelegt, ein Kino besuchen, werden berücksichtigt:"
						showFilter={targetGroupFiltersAvailability.averageCinemaVisitsFilter}
						active={cluster.averageCinemaVisitsFilter.active}
						defaultTimePeriod={cluster.averageCinemaVisitsFilter.timePeriod}
						onChange={handleChangeClusterProps}
						clusterPropName="averageCinemaVisitsFilter"
						enabledTimeUnits={enabledTimeUnits}
						defaultMin={cluster.averageCinemaVisitsFilter.minVisits}
						defaultMax={cluster.averageCinemaVisitsFilter.maxVisits}
						minMark={0}
						maxMark={20}
						markStep={5}
						fallbackMin={1}
						fallbackMax={5}
					/>
					<TimePeriodFilter
						label="Nutzer dürfen im folgenden Zeitraum nicht im Kino gewesen sein:"
						showFilter={targetGroupFiltersAvailability.noCinemaVisitSinceFilter}
						active={cluster.noCinemaVisitSinceFilter.active}
						defaultTimePeriod={cluster.noCinemaVisitSinceFilter.timePeriod}
						defaultAmount={cluster.noCinemaVisitSinceFilter.amount}
						onChange={handleChangeClusterProps}
						clusterPropName="noCinemaVisitSinceFilter"
						ref={noCinemaVisitsFilterRef}
					/>
					<TimePeriodFilter
						label="Nutzer müssen im folgenden Zeitraum im Kino gewesen sein:"
						showFilter={targetGroupFiltersAvailability.cinemaVisitSinceFilter}
						active={cluster.cinemaVisitSinceFilter.active}
						defaultTimePeriod={cluster.cinemaVisitSinceFilter.timePeriod}
						defaultAmount={cluster.cinemaVisitSinceFilter.amount}
						onChange={handleChangeClusterProps}
						clusterPropName="cinemaVisitSinceFilter"
						ref={cinemaVisitSinceFilterRef}
					/>
					<SearchSelectFilter
						active={cluster.averageGroupSizesFilter.active}
						defaultItems={cluster.averageGroupSizesFilter.sizes.map((size) => ({
							value: size,
							label: groupSizeKeys[size],
						}))}
						allItems={averageGroupSizeOptions}
						showFilter={targetGroupFiltersAvailability.averageGroupSizesFilter}
						onChange={handleChangeGroupSizes}
						clusterPropName="averageGroupSizesFilter"
						onChangeActivity={handleChangeActivity}
						label="Welche Kaufeigenschaften dürfen die Empfänger der Zielgruppe haben"
						ref={generalSearchFilterRef12}
					/>
				</Expandable>
			</>
		);
	}
);

export default TargetGroupCluster;
