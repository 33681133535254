import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import uuid from 'uuid/v4';
import { useSnackbar } from 'notistack';
import { times } from 'lodash';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { Button, Box } from '@mui/material';

import { ID } from 'utils/types';
import TargetGroupCluster, { TargetGroupClusterRef } from './TargetGroupCluster';
import Txt from '../../components/Txt';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import ContentWrapper from '../../components/ContentWrapper';
import TextField from '../../components/TextField';
import Dialog from '../../components/Dialog';
import useTextFieldContoller from '../../utils/useTextFieldController';
import {
	Cluster,
	saveTargetGroup,
	updateTargetGroupsStatus,
	useStaticTargetGroupFilters,
	useTargetGroup,
} from '../../utils/targetGroup';

import { removeTypeNames } from '../../utils/helpers';
import { IconName as ButtonIconName } from '../../components/Button';
import { IconName as IconButtonIconName } from '../../components/Button';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

type ClusterDictionary = { [key: string]: Cluster };

interface ExtendedTargetGroupClusterRef extends TargetGroupClusterRef {
	current: any;
}

const template: Cluster = {
	id: uuid(),
	name: 'Cluster',
	position: 0,
	createdDatetime: new Date(),
	lastEditedDatetime: new Date(),
	targetGroupId: '',
	ageFilter: {
		active: false,
		minAge: 16,
		maxAge: 55,
	},
	gendersFilter: {
		active: false,
		genders: ['MALE', 'FEMALE', 'DIVERSE'],
	},
	parentStatusFilter: {
		active: false,
		status: 'PARENTS_ONLY',
	},
	longTermBenefitsReceivedFilter: {
		active: false,
		status: 'LONGTERMBENEFITS_RECEIVED_ONLY',
	},
	distanceFilter: {
		active: false,
		zipCode: 12345,
		distanceInKm: 0,
	},
	movieAffinityFilter: {
		active: false,
		movies: [],
	},
	genreAffinityFilter: {
		active: false,
		genres: [],
	},
	castOrCrewMemberAffinityFilter: {
		active: false,
		castOrCrewMembers: [],
	},
	movieAttributesAffinityFilter: {
		active: false,
		movieAttributes: [],
	},
	moviesSeenFilter: {
		active: false,
		movies: [],
	},
	moviesNotSeenFilter: {
		active: false,
		movies: [],
	},
	moviesOnWatchListFilter: {
		active: false,
		movies: [],
	},
	bonusProgramMemberStatusFilter: {
		active: false,
		status: 'BONUSPROGRAM_MEMBERS_ONLY',
	},
	bonusPointsFilter: {
		active: false,
		minBonusPoints: 0,
		maxBonusPoints: undefined,
	},
	statusPointsFilter: {
		active: false,
		minStatusPoints: 0,
		maxStatusPoints: undefined,
	},
	statusLevelFilter: {
		active: false,
		minStatusLevel: 0,
		maxStatusLevel: undefined,
	},
	vouchersReceivedFilter: {
		active: false,
		vouchers: [],
	},
	vouchersRedeemedFilter: {
		active: false,
		vouchers: [],
	},
	stickersReceivedFilter: {
		active: false,
		stickers: [],
	},
	averageCinemaVisitsFilter: {
		active: false,
		minVisits: 0,
		maxVisits: undefined,
		timePeriod: 'WEEK',
	},
	noCinemaVisitSinceFilter: {
		active: false,
		amount: undefined, // TODO: this needs validation on the Frontend
		timePeriod: 'WEEK',
	},
	cinemaVisitSinceFilter: {
		active: false,
		amount: undefined,
		timePeriod: 'WEEK',
	},
	averageGroupSizesFilter: {
		active: false,
		sizes: [],
	},
};

type TargetGroupFilterName =
	| 'ageFilter'
	| 'gendersFilter'
	| 'parentStatusFilter'
	| 'longTermBenefitsReceivedFilter'
	| 'distanceFilter'
	| 'movieAffinityFilter'
	| 'genreAffinityFilter'
	| 'castOrCrewMemberAffinityFilter'
	| 'movieAttributesAffinityFilter'
	| 'moviesSeenFilter'
	| 'moviesNotSeenFilter'
	| 'moviesOnWatchListFilter'
	| 'bonusProgramMemberStatusFilter'
	| 'bonusPointsFilter'
	| 'statusPointsFilter'
	| 'statusLevelFilter'
	| 'vouchersReceivedFilter'
	| 'vouchersRedeemedFilter'
	| 'stickersReceivedFilter'
	| 'averageCinemaVisitsFilter'
	| 'noCinemaVisitSinceFilter'
	| 'cinemaVisitSinceFilter'
	| 'averageGroupSizesFilter';

export type TargetGroupFiltersAvailability = { [key in TargetGroupFilterName]: boolean };

const defaultFilterDisplaySettings: TargetGroupFiltersAvailability = {
	ageFilter: true,
	gendersFilter: true,
	parentStatusFilter: true,
	longTermBenefitsReceivedFilter: true,
	distanceFilter: true,
	movieAffinityFilter: true,
	genreAffinityFilter: true,
	castOrCrewMemberAffinityFilter: true,
	movieAttributesAffinityFilter: true,
	moviesSeenFilter: true,
	moviesNotSeenFilter: true,
	moviesOnWatchListFilter: true,
	bonusProgramMemberStatusFilter: true,
	bonusPointsFilter: true,
	statusPointsFilter: true,
	statusLevelFilter: true,
	vouchersReceivedFilter: true,
	vouchersRedeemedFilter: true,
	stickersReceivedFilter: true,
	averageCinemaVisitsFilter: true,
	noCinemaVisitSinceFilter: true,
	cinemaVisitSinceFilter: true,
	averageGroupSizesFilter: true,
};

const useManyRefs = <T extends unknown>(numberOfRefs): T[] => {
	return React.useMemo(() => {
		const arrayOfRefs = times(numberOfRefs).map(() => React.createRef());
		return arrayOfRefs as T[];
	}, [numberOfRefs]);
};

const transformClusterDictionary = (clustersDictionary: ClusterDictionary): Cluster[] => {
	const arrayOfClusters = Object.keys(clustersDictionary).map((key) => {
		return {
			...clustersDictionary[key],
			lastEditedDatetime: new Date(),
		};
	}) as Cluster[];

	const arrayOfClustersWithOnlyIdReferences = arrayOfClusters.map((cluster) => {
		const modifiedCluster = JSON.parse(JSON.stringify(cluster));
		const assignedFiltersPerProperty = [
			{
				propertyName: 'movies',
				filter: [
					'movieAffinityFilter',
					'moviesNotSeenFilter',
					'moviesSeenFilter',
					'moviesOnWatchListFilter',
				],
			},
			{
				propertyName: 'vouchers',
				filter: ['vouchersReceivedFilter', 'vouchersRedeemedFilter'],
			},
			{ propertyName: 'stickers', filter: ['stickersReceivedFilter'] },
			{ propertyName: 'castOrCrewMembers', filter: ['castOrCrewMemberAffinityFilter'] },
		];
		assignedFiltersPerProperty.map((p) => {
			p.filter.map((f) => {
				modifiedCluster[f][p.propertyName] = cluster[f][p.propertyName].map((v) => v.id || v);
			});
		});
		return modifiedCluster;
	});
	return arrayOfClustersWithOnlyIdReferences;
};

const errorDict = {
	UNAUTHORIZED: 'Nutzer ist nicht authorisiert',
	INVALID_TARGET_GROUP_ID: 'Zielgruppe existiert nicht oder ist ungültig',
	NETWORK_ERROR: 'Netzwerkfehler',
};

const createNewClustersDictionary = (
	clusterTemplate: Cluster,
	targetGroupId: ID
): ClusterDictionary => {
	const newId = clusterTemplate.id;
	const newName = 'Neuer Cluster';
	return {
		[newId]: ({ ...clusterTemplate, name: newName, targetGroupId } as unknown) as Cluster,
	};
};

const EditTargetGroup = (): JSX.Element | null => {
	const { targetGroupId }: { targetGroupId: string } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const targetGroup = useTargetGroup(targetGroupId);
	//query genres, movieAttributes, vouchers and stickers and make them available to selectionFilters
	const selectionOptions = useStaticTargetGroupFilters({ targetGroupId });
	//necessary to create InfoPopper about GroupSize-Number/Stats that might be out of date:
	const [changedClusterIds, setChangedClusterIds] = React.useState<ID[]>([]);
	const [loading, setLoading] = React.useState(false);

	const {
		textInputProps: targetGroupNameProps,
		newStateValue: targetGroupName,
	} = useTextFieldContoller({
		defaultValue: targetGroup?.name,
		inputLabel: 'Kampagnen-Titel',
		stateKey: 'campaignName',
		finishedInitializing: targetGroup !== undefined,
	});

	// copy targetGroupClusters into local state
	const [clustersDictionary, setClustersDictionary] = React.useState<ClusterDictionary>({});
	React.useEffect(() => {
		const targetGroupHasClusters =
			targetGroup?.cluster && Object.keys(targetGroup.cluster).length > 0;
		if (targetGroupHasClusters) {
			setClustersDictionary(targetGroup.cluster);
		} else {
			const defaultClusterDictionary = createNewClustersDictionary(template, targetGroupId);
			setClustersDictionary(defaultClusterDictionary);
		}
	}, [setClustersDictionary, targetGroup, targetGroup?.cluster, targetGroupId]);

	//add, remove, change cluster
	const handleAddCluster = React.useCallback(() => {
		const clusterId = uuid() as ID;
		const objectValues = Object.values(clustersDictionary);
		const objectValuesLength = objectValues.length;
		const lastClusterPosition = objectValues?.[objectValuesLength - 1]?.position;
		const newClusterPosition = lastClusterPosition !== undefined ? lastClusterPosition + 1 : 0;
		setClustersDictionary((b) => ({
			...b,
			[clusterId]: {
				...template,
				name: `Neuer Cluster ${newClusterPosition}`,
				position: newClusterPosition,
				id: clusterId,
				targetGroupId,
			},
		}));
		setChangedClusterIds((oldState: ID[]) => {
			return [...new Set([...oldState, clusterId])] as ID[];
		});
	}, [setClustersDictionary, clustersDictionary, targetGroupId, setChangedClusterIds]);

	const handlePerformClusterDeletion = React.useCallback(
		(clusterId: ID) => {
			const newClusters = { ...clustersDictionary };
			delete newClusters[clusterId];

			setClustersDictionary(newClusters);
			setChangedClusterIds((oldState: ID[]) => {
				return [...new Set([...oldState, clusterId])];
			});
		},
		[clustersDictionary]
	);

	const handleDeleteCluster = React.useCallback(
		(clusterId: ID) => {
			Dialog.render({
				title: 'Löschen?',
				description: 'Wollen Sie den Cluster wirklich löschen',
				buttons: [
					{ id: '1', label: 'Ja', onClick: () => handlePerformClusterDeletion(clusterId) },
					{ id: '2', label: 'Nein' },
				],
			});
		},
		[handlePerformClusterDeletion]
	);

	const handleChangeCluster = React.useCallback(
		({
			clusterId,
			clusterPropName,
			clusterPropValue,
		}: {
			clusterId: ID;
			clusterPropName: string;
			clusterPropValue: string | { [key: string]: any };
		}) => {
			setClustersDictionary((oldState) => ({
				...oldState,
				[clusterId!]: {
					...oldState[clusterId],
					[clusterPropName]:
						typeof clusterPropValue === 'object'
							? { ...oldState[clusterId][clusterPropName], ...clusterPropValue }
							: clusterPropValue,
				},
			}));
			if (clusterPropName !== 'name') {
				setChangedClusterIds((oldState: ID[]) => {
					return [...new Set([...oldState, clusterId])];
				});
			}
		},
		[setClustersDictionary, setChangedClusterIds]
	);

	const allClusterRefs = useManyRefs<ExtendedTargetGroupClusterRef>(
		Object.entries(clustersDictionary).length
	);
	const handleValidate = React.useCallback(() => {
		const errors = allClusterRefs.map((ref) => ref.current?.validate?.());
		const invalid = errors.some(Boolean);
		if (invalid) {
			enqueueSnackbar('Es es fehlen Einträge', { variant: 'warning' });
		}
		return invalid;
	}, [allClusterRefs, enqueueSnackbar]);

	const handleSave = React.useCallback(async () => {
		if (!loading) {
			const invalid = handleValidate();
			if (invalid) return;
			setLoading(true);
			const clusterDictionaryWithoutTypeNames = removeTypeNames(clustersDictionary);
			// we only save the ids of some data in the cluster e.g. movies or castOrCrewMembers
			const arrayOfClustersWithOnlyIdReferences = transformClusterDictionary(
				clusterDictionaryWithoutTypeNames
			);
			const { success, error } = await saveTargetGroup({
				id: targetGroup!.id,
				name: targetGroupName!,
				clusters: arrayOfClustersWithOnlyIdReferences,
			});
			setLoading(false);
			if (!success) {
				enqueueSnackbar(errorDict[error], { variant: 'error' });
			} else {
				enqueueSnackbar('Gespeichert', { variant: 'success' });
			}
			setChangedClusterIds([]);
		}
	}, [loading, handleValidate, clustersDictionary, targetGroup, targetGroupName, enqueueSnackbar]);

	//adapt the "showFilter"-properties of all filters, based on customer-settings queried from DB
	const targetGroupFiltersAvailability = React.useMemo(() => {
		const updatedFilterDisplaySettings = { ...defaultFilterDisplaySettings };
		if (targetGroup && targetGroup.b2bCustomerCinemas[0]) {
			targetGroup.b2bCustomerCinemas[0].targetGroupFilterConfig.map((f) => {
				updatedFilterDisplaySettings[f.name] = f.showFilter;
			});
		}
		return updatedFilterDisplaySettings;
	}, [targetGroup]);

	const buttons = React.useMemo(
		() => [
			{
				label: 'Zielgruppe speichern',
				onClick: handleSave,
				loading,
				loadingText: 'Speichern...',
				startIconName: 'SaveOutlined' as ButtonIconName,
				disabled: loading,
				collapsedIconName: 'SaveOutlined' as IconButtonIconName,
			},
		],
		[handleSave, loading]
	);

	const clusters = React.useMemo(() => {
		const isEmptyObject = Object.keys(clustersDictionary).length === 0;
		return isEmptyObject ? null : (Object.values(clustersDictionary) as Cluster[]);
	}, [clustersDictionary]);

	const handleSetCampaignStateToEditing = React.useCallback(async () => {
		setLoading(true);
		const { success } = await updateTargetGroupsStatus([targetGroup!.id], 'NOT_IN_USE');
		setLoading(false);
		if (success) {
			enqueueSnackbar('Zielgruppe wurde entarchviert', { variant: 'success' });
		} else {
			enqueueSnackbar('Fehler beim Entarchivieren.', { variant: 'error' });
		}
	}, [enqueueSnackbar, targetGroup]);

	React.useEffect(() => {
		if (targetGroup?.status === 'ARCHIVED') {
			handleSetCampaignStateToEditing();
		}
	}, [handleSetCampaignStateToEditing, targetGroup?.status]);

	const isLoading = !targetGroup || loading;

	return (
		<StickyHeaderWrapper
			label="Zielgruppe bearbeiten"
			buttons={buttons}
			showWarningOnLeave={changedClusterIds.length > 0}
			isLoading={!targetGroup}
		>
			{!targetGroup ? null : (
				<>
					<ContentWrapper>
						<Txt disabled={isLoading} variant="h6">
							Kanalübergreifende Parameter
						</Txt>
						<Row justifyContent="space-between" m="2rem 0">
							<TextField
								disabled={isLoading}
								m="0 1rem 0 0"
								flex
								variant="outlined"
								{...targetGroupNameProps}
							/>
							<TextField
								m="0 1rem 0 0"
								label="Größe der Zielgruppe"
								variant="outlined"
								value={changedClusterIds.length > 0 ? '?' : targetGroup.targetGroupSize}
								width="20rem"
								disabled
								helperText={changedClusterIds.length > 0 ? 'für Berechnung bitte speichern' : ''}
							/>
						</Row>
					</ContentWrapper>
					<Txt variant="h6" marginTop="2rem" disabled={isLoading}>
						Teilgruppen
					</Txt>
					{clusters?.map((cluster, index) => {
						return (
							<ContentWrapper key={cluster.id}>
								<TargetGroupCluster
									disabled={isLoading}
									key={cluster.id}
									cluster={cluster}
									onChange={handleChangeCluster}
									removeCluster={handleDeleteCluster}
									selectionOptions={selectionOptions}
									targetGroupFiltersAvailability={targetGroupFiltersAvailability}
									changedWithoutSaving={changedClusterIds.includes(cluster.id)}
									ref={allClusterRefs[index]}
								/>
							</ContentWrapper>
						);
					}) || null}
					<Button
						fullWidth
						startIcon={<PersonAdd />}
						variant="contained"
						color="primary"
						onClick={handleAddCluster}
						disabled={isLoading}
					>
						Cluster hinzufügen und Zielgruppe erweitern
					</Button>
				</>
			)}
		</StickyHeaderWrapper>
	);
};

export default EditTargetGroup;
