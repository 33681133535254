import React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@mui/material';

const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const ProgressBox = ({
	height = '8rem',
	width = '100%',
}: {
	height?: string;
	width?: string;
}): JSX.Element => {
	return (
		<Wrapper height={height} width={width}>
			<CircularProgress size={24} />
		</Wrapper>
	);
};

export default ProgressBox;
