import React from 'react';
import styled from 'styled-components';
import { cloneDeep, isEqual } from 'lodash';
import uuid from 'uuid/v4';
import { Typography, Box } from '@mui/material';
import { Brand } from '@cinuru/utils/theme';

import DroppedCard from './DroppedCard';
import { Campaign } from '../../utils/campaign';
import { EmailElement } from '../../utils/campaignEmailContent';

const DropZoneWrapper = styled(Box)<{ $height: string }>`
	display: flex;
	width: 100%;
	height: ${(p) => p.$height};
	justify-content: center;
	align-items: center;
`;

const DropZone = ({ index, height, handleDrop }) => {
	const handleDragOver = React.useCallback((event) => {
		event.preventDefault();
	}, []);
	const innerHandleDrop = React.useCallback(
		(event) => {
			event.preventDefault();
			const dt = event.dataTransfer.getData('text');
			if (dt) {
				const data = JSON.parse(dt);
				handleDrop(index, data);
			}
		},
		[handleDrop, index]
	);
	return (
		<DropZoneWrapper
			id={index}
			$height={height}
			onDragOver={handleDragOver}
			onDrop={innerHandleDrop}
		>
			<Typography align="center">{'Platziere hier Elemente per Drag & Drop.'}</Typography>
		</DropZoneWrapper>
	);
};

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const EmailField = ({
	dragAndDropInProgress,
	onDragAndDrop,
	elements,
	onClickElement,
	brand,
	campaign,
	mobileView,
	onChange,
}: {
	onClickElement: (el: EmailElement) => void;
	onDragAndDrop: (val: boolean) => void;
	brand: Brand;
	campaign: Campaign;
	elements: EmailElement[];
	mobileView: boolean;
	dragAndDropInProgress: boolean;
	onChange: (el: EmailElement[]) => void;
}): JSX.Element => {
	const handleDrop = React.useCallback(
		(position, data) => {
			console.log('handleDrop', position, data);
			if (data.action === 'ADD') {
				const newElements = [...elements];
				const clonedDefaultValues = cloneDeep(data.defaultValues);

				newElements.splice(position, 0, {
					type: data.itemType,
					key: uuid(),
					...(clonedDefaultValues || {}),
					campaign,
				});

				onChange(newElements);
			}
			if (data.action === 'REORDER') {
				const newElements = elements.filter((el) => el.key !== data.key);
				const oldPosition = data.currentIndex;
				let newPosition = position;
				if (oldPosition <= position) {
					newPosition--;
				}
				newElements.splice(newPosition, 0, elements[oldPosition]);
				onChange(newElements);
			}
		},
		[onChange, elements, campaign]
	);

	let elementsWithAdjustedBorderRadius = elements;

	React.useEffect(() => {
		onChange(elementsWithAdjustedBorderRadius);
	}, [elementsWithAdjustedBorderRadius, onChange]);

	const setEmptyOpjectForFooterAndHeader = React.useCallback(() => <></>, []);

	const handleClickElement = React.useCallback(
		(el) => () => {
			onClickElement(el);
		},
		[onClickElement]
	);

	return (
		<Column width="100%">
			{elements.map((el, idx) => {
				const previousBackgroundColor = elements[idx - 1]?.backgroundColor;
				const nextBackgroundColor = elements[idx + 1]?.backgroundColor;

				const borderRadius = { top: true, bottom: true };
				if (
					idx === 0 ||
					(idx > 0 &&
						previousBackgroundColor !== 'transparent' &&
						previousBackgroundColor !== '#00000000')
				) {
					borderRadius.top = false;
				}
				if (nextBackgroundColor !== 'transparent' && nextBackgroundColor !== '#00000000') {
					borderRadius.bottom = false;
				}

				if (!isEqual(el['borderRadiusWrapper'], borderRadius)) {
					const clonedElements = cloneDeep(elements);
					//add new borderRadius to cloned elements
					clonedElements[idx]['borderRadiusWrapper'] = borderRadius;
					elementsWithAdjustedBorderRadius = clonedElements;
				}

				return (
					<React.Fragment key={`Wrapper${el.key}`}>
						<DroppedCard
							key={el.key}
							index={idx.toString()}
							element={el}
							onDragAndDrop={
								el.type !== 'FOOTER' && el.type !== 'HEADER'
									? onDragAndDrop
									: setEmptyOpjectForFooterAndHeader
							}
							onEdit={handleClickElement(el)}
							brand={brand}
							campaign={campaign}
							elements={elements}
							mobileView={mobileView}
						/>

						{dragAndDropInProgress && el.type !== 'FOOTER' && (
							<DropZone
								key={`Dropzone${idx}`}
								index={idx + 1}
								height="40px"
								handleDrop={handleDrop}
							/>
						)}
					</React.Fragment>
				);
			})}
		</Column>
	);
};

export default React.memo(EmailField);
