import * as React from 'react';
import { ActivityIndicator } from 'react-native';
import styled, { useColorAttribute } from 'styled-native-components';

const Wrapper = styled.View`
	justify-content: center;
	align-items: center;
	margin: ${(p) => p.margin};
	${(p) => (p.height ? `height: ${p.height};` : `flex: 1; min-height: 10rem;`)};
`;

const LoadingIndicator = ({
	size = 'small',
	color = '$neutral0',
	height,
	margin = '0',
}: {
	size?: 'small' | 'large';
	color?: string;
	height?: string;
	margin?: string;
}) => {
	const colorValue = useColorAttribute(color);
	return (
		<Wrapper height={height} margin={margin}>
			<ActivityIndicator color={colorValue} size={size} />
		</Wrapper>
	);
};

export default React.memo(LoadingIndicator);
