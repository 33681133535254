/** may need some refactoring */

// @flow
import * as React from 'react';
import styled from 'styled-native-components';

import Gradient from './Gradient';
import Ripple from './Ripple';
import Icon from './Icon';
import Paragraph from './Paragraph';

const Wrapper = styled(Ripple).attrs((p) => ({
	disabled: !p.onPress,
	rippleColor: '$background0',
}))`
	border-radius: 1rem;
	margin: ${(p) => p.nMargins.join('rem ')}rem;
	width: ${(p) => p.nWidth}rem;
	height: 10rem;
	overflow: hidden;
	cursor: ${(p) => (p.onPress ? 'pointer' : 'auto')};
	elevation: ${(p) => p.el};
`;

const Background = styled(Gradient).attrs((p) => ({
	colors: p.theme.colors.accentGradient0,
	orientation: 'DIAGDOWN',
}))`
	padding: 1rem;
	align-items: center;
`;

// const InfoBubble = styled(Icon).attrs({
// 	size: '1.5rem',
// 	name: 'infoBubble',
// 	color: '$background0',
// })`
// 	position: absolute;
// 	top: 0.5rem;
// 	right: 0.5rem;
// `;

const ButtonTile = ({ iconName, label, nMargins, nWidth, onPress, elevation }) => (
	<Wrapper onPress={onPress} nWidth={nWidth} el={elevation} nMargins={nMargins}>
		<Background>
			<Icon name={iconName} size="5rem" color="$background0" />
			<Paragraph align="center" flex bold color="$background0">
				{label}
			</Paragraph>
		</Background>
		{/*<InfoBubble />*/}
	</Wrapper>
);

ButtonTile.defaultProps = { nWidth: 25, elevation: 2 };

export default ButtonTile;
