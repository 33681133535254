/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-native-components';
import { useTheme } from 'styled-native-components';
import chroma from 'chroma-js';

const data2 = [
	{
		'id': '1',
		'App-Nutzer': 9123,
		'Plus-Mitglieder': 7123,
	},
	{
		'id': '2',
		'App-Nutzer': 14224,
		'Plus-Mitglieder': 11899,
	},
	{
		'id': '3-4',
		'App-Nutzer': 8123,
		'Plus-Mitglieder': 6123,
	},

	{
		'id': '5+',
		'App-Nutzer': 4443,
		'Plus-Mitglieder': 4001,
	},
];
const Wrapper = styled.View`
	height: 100%;
	width: 100%;
`;
export const GroupSizeBarChart = () => {
	const theme = useTheme();
	const colorscale = chroma.scale(theme.colors.accentGradient0).colors(4);
	return (
		<Wrapper>
			<ResponsiveBar
				data={data2}
				groupMode="grouped"
				keys={['App-Nutzer', 'Plus-Mitglieder']}
				margin={{ top: 10, right: 5, bottom: 50, left: 55 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={colorscale}
				borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 0,
					tickPadding: 0,
					tickRotation: 0,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Nutzer',
					legendPosition: 'middle',
					legendOffset: -45,
					format: (value) => `${Math.round(Number(value) / 1000).toLocaleString('de-DE')}k`,
					tickValues: 5,
				}}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom',
						direction: 'row',
						justify: false,
						translateY: 40,
						itemsSpacing: 2,
						itemWidth: 110,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 10,
					},
				]}
				enableLabel={false}
				animate={true}
				motionStiffness={90}
				motionDamping={15}
			/>
		</Wrapper>
	);
};
