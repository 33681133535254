import styled from 'styled-native-components';

export const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
	margin-bottom: 4rem;
	min-width: 500px;
`;

export const Content = styled.View`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
`;

export const Row = styled.View`
	width: 100%;
	padding: 1rem 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${(p) => (p.right ? 'flex-end' : 'flex-start')};
`;
