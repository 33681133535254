/** 
 React native posed should be removed from this, take inspiration from the PortalProvider on how to handle transitions.
*/

// @flow
import * as React from 'react';
import { createPortal } from 'react-dom';
import styled, { useLengthAttribute } from 'styled-native-components';
import posed, { Transition } from 'react-native-pose';

import shallowEqual from 'shallowequal';
import PortalTriggerNative from './PortalTrigger.js';

const Wrapper = styled.View`
	margin: ${(p) => p.margin};
	${(p) => (p.stretch ? 'align-self: stretch' : '')};
	${(p) => (p.flexBase ? `flex: 1 0 ${p.flexBase}` : '')};
`;

const OverlayWrapper = styled.TouchableOpacity.attrs((p) => ({
	pointerEvents: p.onPress ? 'auto' : 'box-none',
	activeOpacity: 1,
}))`
	position: absolute;
	width: ${(p) => p.measures.windowWidth}px;
	height: ${(p) => p.measures.windowHeight}px;
	cursor: auto;
`;

const CONTENT_PADDING = 10;
const getTop = (p) => p.measures.top + p.measures.height + p.topMargin;
const OverlayContentWrapper = styled.View`
	position: absolute;
	top: ${getTop}px;
	left: ${(p) => p.measures.left - CONTENT_PADDING}px;
	width: ${(p) => p.measures.width + 2 * CONTENT_PADDING}px;
	/* height: ${(p) => p.measures.windowHeight - getTop(p)}px; */
	padding: 0 ${CONTENT_PADDING}px;
	${(p) => (p.allowOverflow ? '' : 'overflow: hidden')};
`;

const PortalWrapper = posed.View({ enter: {}, exit: {} });

const PortalContent = ({ contentTopMargin, allowOverflow, children, measures, onClose }) => {
	const [topMargin] = useLengthAttribute(contentTopMargin);
	return measures ? (
		<OverlayWrapper measures={measures} onPress={onClose}>
			<OverlayContentWrapper
				measures={measures}
				topMargin={topMargin}
				allowOverflow={allowOverflow}
			>
				{children}
			</OverlayContentWrapper>
		</OverlayWrapper>
	) : null;
};

const Portal = ({ rootId, children }) => createPortal(children, document.getElementById(rootId));

type Props = {
	active: boolean,
	onClose: () => any,
	children: React.Node,
	renderOverlay: () => React.Node,
	renderTrigger: () => React.Node,
	contentTopMargin: string,
	margin?: string,
	stretch?: boolean,
	flexBase?: string,
	rootId?: string,
};
type State = {
	measures?: {
		width: number,
		height: number,
		windowHeight: number,
		windowWidth: number,
	},
};
class PortalTriggerDom extends React.Component<Props, State> {
	static defaultProps = { rootId: 'web-portal-target', margin: '0', contentTopMargin: '0' };
	state = { measures: null };
	wrapperRef = React.createRef();

	shouldComponentUpdate = (nextProps, nextState) =>
		!shallowEqual(nextProps, this.props) ||
		(!shallowEqual(nextState.measures, this.state.measures) && Boolean(nextProps.active));

	componentDidUpdate = () => {
		const windowHeight = document.getElementById(this.props.rootId).offsetHeight;
		const windowWidth = document.getElementById(this.props.rootId).offsetWidth;
		this.wrapperRef.current.measure((x, y, width, height, left, top) => {
			top += window.scrollY;
			left += window.scrollX;
			this.setState({ measures: { width, height, top, left, windowHeight, windowWidth } });
		});
	};

	render = () => {
		const {
			children,
			margin,
			flexBase,
			rootId,
			renderTrigger,
			active,
			stretch,
			renderOverlay,
			onClose,
			contentTopMargin,
			allowOverflow,
			...props
		} = this.props;
		return (
			<Wrapper
				ref={this.wrapperRef}
				margin={margin}
				flexBase={flexBase}
				stretch={stretch}
				onLayout={this.componentDidUpdate}
				{...props}
			>
				{children || (renderTrigger && renderTrigger())}
				<Transition>
					{active && renderOverlay && (
						<PortalWrapper key={1}>
							<Portal rootId={rootId}>
								<PortalContent
									contentTopMargin={contentTopMargin}
									allowOverflow={allowOverflow}
									measures={this.state.measures}
									onClose={onClose}
								>
									{renderOverlay()}
								</PortalContent>
							</Portal>
						</PortalWrapper>
					)}
				</Transition>
			</Wrapper>
		);
	};
}

export default ({ preventEventBubbling, ...props }) =>
	preventEventBubbling ? <PortalTriggerNative {...props} /> : <PortalTriggerDom {...props} />;
