import * as React from 'react';

import { Button, TextField, DateField, Heading } from '@cinuru/components';

import { default as gql } from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { Wrapper, Content, Row } from './LocalComponents';
import MovieSelectField from './MovieSelectField';

const { useState } = React;

const AddMovie = ({ dismissPortal, cinemaId, client }) => {
	const [startDate, setStartDate] = useState({ value: null, error: false });
	const [movie, setMovie] = useState({ movieTitle: null, movieId: null, error: null });
	const [loading, setLoading] = React.useState(false);

	const handleDateSelect = React.useCallback((value) => {
		setStartDate({
			value,
			error: false,
		});
	}, []);

	const handleMovieId = React.useCallback((movieId) => {
		setMovie({
			movieTitle: null,
			movieId,
			error: false,
		});
	}, []);

	const handleMovieTitle = React.useCallback((movieTitle) => {
		setMovie({
			movieTitle,
			movieId: null,
			error: false,
		});
	}, []);

	const validateStartDate = React.useCallback((value) => {
		setStartDate((before) => ({ ...before, error: !value ? 'Datum fehlt' : false }));
		return value ? true : false;
	}, []);

	const validateMovie = React.useCallback((movie) => {
		setMovie((before) => ({
			...before,
			error: movie.movieTitle || movie.movieId ? false : 'Film fehlt',
		}));
		return movie.movieTitle || movie.movieId ? true : false;
	}, []);

	const addMovie = React.useCallback(async () => {
		const validStartDate = validateStartDate(startDate.value);
		const validMovie = validateMovie(movie);
		if (!validStartDate || !validMovie) {
			return;
		}
		setLoading(true);
		const { data } = await client.mutate({
			mutation: gql`
				mutation createSoonInCinemaItem(
					$cinemaId: ID!
					$movieTitle: String
					$movieId: ID
					$startDate: Date
				) {
					createSoonInCinemaItem(
						cinemaId: $cinemaId
						movieTitle: $movieTitle
						movieId: $movieId
						startDate: $startDate
					) {
						success
					}
				}
			`,
			variables: {
				cinemaId,
				startDate: startDate.value,
				movieTitle: movie.movieTitle,
				movieId: movie.movieId,
			},
			refetchQueries: ['soonInCinemaItems'],
		});
		if (data.createSoonInCinemaItem.success) {
			setLoading(false);
			dismissPortal();
		}
	}, [cinemaId, client, dismissPortal, movie, startDate.value, validateMovie, validateStartDate]);

	return (
		<Wrapper>
			<Content>
				<Row>
					<Heading>Film hinzufügen</Heading>
				</Row>
				<DateField
					width={'100%'}
					error={startDate.error}
					onChange={handleDateSelect}
					label="Startdatum"
					hint="Datum, an welchem der Film im Kino starten wird"
					futureOnly
				/>

				<MovieSelectField
					error={movie.error}
					disabled={movie.movieTitle ? true : false}
					onChange={handleMovieId}
				/>

				<Row>
					<TextField
						error={movie.error}
						disabled={movie.movieId ? true : false}
						value={movie.movieTitle}
						onChange={handleMovieTitle}
						hint="Wenn der Film noch nicht bei Cinuru angelegt ist"
						label="Filmtitel manuell eingeben"
						width="100%"
					/>
				</Row>
				<Row right>
					<Button label="ABBRECHEN" link onPress={dismissPortal} />
					<Button
						label="HINZUFÜGEN"
						gradient="accentGradient0"
						textColor="$background0"
						onPress={addMovie}
						loading={loading}
					/>
				</Row>
			</Content>
		</Wrapper>
	);
};

export default withApollo(AddMovie);
