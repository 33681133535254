import React from 'react';
import { Typography, FormGroup, FormControlLabel, Checkbox, Box } from '@mui/material';
import SectionWrapper from './SectionWrapper';

const CheckboxSection = ({
	label,
	options,
	defaultValue,
	onChange,
	margin,
	padding,
	wrap,
	row,
	disabled,
}: {
	label?: string;
	options: { label: string; value: string | boolean }[];
	defaultValue?: string | boolean;
	onChange: (optionValue: string | boolean) => void;
	margin?: string;
	padding?: string;
	wrap?: boolean;
	row?: boolean;
	disabled?: boolean;
}): JSX.Element => {
	const [selected, setSelected] = React.useState(defaultValue);

	const handleSelect = React.useCallback(
		(optionValue) => {
			setSelected(optionValue);
			onChange && onChange(optionValue);
		},
		[onChange]
	);

	const DynamicWrapper = React.useCallback(
		({ children }) =>
			wrap ? (
				<SectionWrapper margin={margin} padding={padding} label={label}>
					<>{children}</>
				</SectionWrapper>
			) : label ? (
				<Box m={margin}>
					<Typography>{label}</Typography>
					<FormGroup row={row}>{children}</FormGroup>
				</Box>
			) : (
				<Box m={margin}>
					<FormGroup row={row}>{children}</FormGroup>
				</Box>
			),
		[label, margin, padding, row, wrap]
	);

	return (
		<DynamicWrapper>
			{options.map((option, index) => (
				<FormControlLabel
					disabled={disabled}
					key={index}
					control={
						<Checkbox
							checked={selected === option.value}
							// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
							onChange={() => handleSelect(option.value)}
						/>
					}
					label={option.label}
				/>
			))}
		</DynamicWrapper>
	);
};

export default CheckboxSection;
