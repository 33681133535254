// @flow
import * as React from 'react';
import { Image } from 'react-native';
import styled from 'styled-native-components';

import Icon from './Icon';

const MissingWrapper = styled.View`
	justify-content: center;
	align-items: center;
	background-color: $border0;
	padding: 1rem;
`;

type ResizeMode = 'contain' | 'cover' | 'stretch' | 'center';
type Props = { src?: string, style?: any, resizeMode: ResizeMode, tiny?: boolean };
type State = { source?: { uri: string }, retries: number };
class WrappedImage extends React.PureComponent<Props, State> {
	static defaultProps = { resizeMode: 'cover', tiny: false };

	constructor(props: Props) {
		super(props);
		if (props.src) {
			this.state = { source: { uri: props.src }, retries: 0 };
		} else {
			// eslint-disable-next-line no-console
			console.warn('missing src prop for image');
			this.state = { source: undefined };
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (state.source && props.src !== state.source.uri) {
			if (props.src) {
				return { source: { uri: props.src } };
			} else {
				// eslint-disable-next-line no-console
				console.warn('missing src prop for image');
				return { source: undefined };
			}
		}
		return null;
	}

	componentDidMount = () => {
		if (this.props.onLoad) {
			Image.getSize(this.props.src, (width, height) =>
				this.props.onLoad({ nativeEvent: { width, height } })
			);
		}
	};

	render() {
		const { style, resizeMode, tiny } = this.props;
		const { source } = this.state;
		return source ? (
			<Image style={style} resizeMode={resizeMode} source={source} />
		) : (
			<MissingWrapper style={style}>
				<Icon name="missingPicture" color="$neutral3" size={tiny ? '2rem' : '3rem'} />
			</MissingWrapper>
		);
	}
}

export default WrappedImage;
