import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Typography, Box } from '@mui/material';
import Button from './Button';
import { IconName as _ButtonIconName } from './Button';
import { IconName as _IconButtonIconName } from './IconButton';
import IconButton from './IconButton';
import { HEADER_BAR_ZINDEX } from '../utils/constants';

export type ButtonIconName = _ButtonIconName;
export type IconButtonIconName = _IconButtonIconName;

const Background = styled(Box)`
	height: 100%;
	width: 100%;
	padding: 0 1rem;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Shadow = styled(Box)`
	width: 100%;
	height: 100%;
	position: absolute;
`;

const FixedPositionWrapper = styled(Box)<{
	left: number;
	width: number;
	top: number;
	height: number;
	zIndex?: number;
}>`
	width: ${(p) => p.width}px;
	height: ${(p) => p.height}rem;
	position: fixed;
	${(p) => `top: ${p.top}px;`};
	${(p) => `left: ${p.left}px;`};
	z-index: ${(p) => p.zIndex || 0};
`;

const StyledTypography = styled(Typography)`
	color: ${(p) => p.theme.palette.common.white};
`;

const StickyHeaderBar = ({
	containerDimensions,
	height,
	label,
	buttons,
	additionalLeftWidth = 0,
	additionalRightWidth = 0,
	collapsedContentBreakpoint = 700,
	disabled,
}: {
	containerDimensions: { top: number; left: number; width: number };
	height: number;
	label?: string;
	buttons: {
		label: string;
		icon?: JSX.Element;
		disabled?: boolean;
		onClick?: () => void;
		loading?: boolean;
		loadingText?: string;
		startIconName?: ButtonIconName;
		endIconName?: ButtonIconName;
		collapsedIconName: IconButtonIconName;
	}[];
	additionalLeftWidth?: number;
	additionalRightWidth?: number;
	collapsedContentBreakpoint?: number;
	disabled?: boolean;
}): JSX.Element => {
	const theme = useTheme();

	const hasCollapsedLayout = containerDimensions.width < collapsedContentBreakpoint;
	return (
		<Box>
			<FixedPositionWrapper
				left={containerDimensions.left - additionalLeftWidth}
				width={containerDimensions.width + additionalLeftWidth + additionalRightWidth}
				top={0}
				height={height}
				zIndex={HEADER_BAR_ZINDEX}
			>
				<Background bgcolor={theme.customColors.accentBlue}>
					<Box
						// border="1px solid red"
						m="0 1rem"
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
						width="100%"
					>
						<StyledTypography variant="h6">{label}</StyledTypography>
						<Box display="flex" flexDirection="row">
							{buttons.map(
								({
									label: buttonLabel,
									onClick,
									disabled: buttonDisabled,
									loading,
									loadingText,
									startIconName,
									endIconName,
									collapsedIconName,
								}) =>
									hasCollapsedLayout ? (
										<IconButton
											key={buttonLabel}
											m="0 0 0 1rem"
											size="small"
											color={theme.customColors.white}
											disabled={loading || disabled || buttonDisabled}
											onClick={onClick}
											loading={loading}
											iconName={collapsedIconName}
										/>
									) : (
										<Button
											key={buttonLabel}
											m="0 0 0 1rem"
											variant="textButton"
											startIconName={startIconName}
											endIconName={endIconName}
											disabled={loading || disabled || buttonDisabled}
											onClick={onClick}
											loading={loading}
											loadingText={loadingText || '...'}
											loadingVariant="disabledTextButton"
										>
											{buttonLabel}
										</Button>
									)
							)}
						</Box>
					</Box>
				</Background>
			</FixedPositionWrapper>

			<FixedPositionWrapper
				left={containerDimensions.left - additionalLeftWidth}
				width={containerDimensions.width + additionalLeftWidth}
				top={0}
				height={height}
				zIndex={2}
			>
				<Shadow boxShadow={3} />
			</FixedPositionWrapper>
		</Box>
	);
};

export default StickyHeaderBar;
