import * as React from 'react';
import { Animated, Platform } from 'react-native';
import styled, { useTheme } from 'styled-native-components';

import type { LayoutChangeEvent } from 'react-native';

import Icon from './Icon';
import Ripple from './Ripple';
import Paragraph from './Paragraph';
import Label from './Label';
import { IconName } from '@cinuru/utils/theme';

const Wrapper = styled.View<{
	margin: string;
	width?: string;
	flex?: boolean;
	stretch?: boolean;
	disabled?: boolean;
}>`
	margin: ${(p) => p.margin};
	${(p) => (p.flex ? `flex: 1 0 ${p.width}` : '')};
	${(p) => (!p.flex && p.width ? `width: ${p.width}` : '')};
	${(p) => (p.stretch ? 'align-self: stretch' : '')};
	opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`;

const InputWrapper = styled(Ripple).attrs<
	{
		altBackground?: boolean;
		borderRadius?: string;
		borderWidth?: string;
	},
	{ accesible: boolean; rippleColor: string }
>({
	accesible: false,
	rippleColor: '$neutral3',
})`
	cursor: text;
	align-self: stretch;
	background-color: ${(p) => (p.altBackground ? '$background0' : '$background1')};
	border-radius: ${(p) =>
		p.borderRadius ||
		[
			p.theme.borderRadius[2],
			p.theme.borderRadius[2],
			p.theme.borderRadius[0],
			p.theme.borderRadius[0],
		].join(' ')};
	border: ${(p) => `${p.borderWidth || '1px'} solid $border0`};
	flex-flow: row wrap;
	align-items: center;
`;

const ContentWrapper = styled.View<{
	hasIcon?: boolean;
	iconRightAligned?: boolean;
	hasLabel?: boolean;
	align: 'flex-end' | 'flex-start' | 'center';
	hasChildren: boolean;
	padding?: number;
}>`
	flex: 1;
	flex-flow: row wrap;
	align-items: center;
	padding-right: ${(p) =>
		p.padding !== undefined ? p.padding : p.hasIcon && p.iconRightAligned ? 7 : 1.5}rem;
	padding-left: ${(p) =>
		p.padding !== undefined ? p.padding : p.hasIcon && !p.iconRightAligned ? 7 : 1.5}rem;
	padding-top: ${(p) => (p.padding !== undefined ? p.padding : p.hasLabel ? 2 : 0) + 0.5}rem;
	padding-bottom: 0.5rem;
	justify-content: ${(p) => p.align};
	${(p) => (p.hasChildren ? '' : 'min-height: 6.5rem')};
`;

const AnimatedLabel = ({
	expanded,
	children,
	hasIcon,
	iconRightAligned,
	selected,
	error,
	color,
}) => {
	const animation = React.useRef(new Animated.Value(expanded ? 1 : 0));
	React.useEffect(() => {
		Animated.timing(animation.current, {
			toValue: expanded ? 1 : 0,
			duration: 150,
			useNativeDriver: Platform.OS !== 'web',
		}).start();
	}, [expanded]);
	const theme = useTheme();
	const scaleFactor = theme.typography.fontSizes[2] / theme.typography.fontSizes[3];
	const [width, setWidth] = React.useState(0);
	const style = React.useMemo(
		() => ({
			position: 'absolute',
			top: 1.75 * theme.rem,
			left: (hasIcon && !iconRightAligned ? 7 : 1.5) * theme.rem,
			transform: [
				{
					translateY: animation.current.interpolate({
						inputRange: [0, 1],
						outputRange: [0, -1.5 * theme.rem],
					}),
				},
				{
					translateX: animation.current.interpolate({
						inputRange: [0, 1],
						outputRange: [0, (scaleFactor * width - width) / 2],
					}),
				},
				{
					scale: animation.current.interpolate({
						inputRange: [0, 1],
						outputRange: [1, scaleFactor],
					}),
				},
			],
		}),
		[hasIcon, iconRightAligned, theme.rem, scaleFactor, width]
	);
	return (
		// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
		<Animated.View style={style} onLayout={({ nativeEvent }) => setWidth(nativeEvent.layout.width)}>
			<Label color={selected ? (error ? '$error' : color) : '$neutral2'}>{children}</Label>
		</Animated.View>
	);
};

const InputIcon = styled(Icon).attrs<
	{ iconRightAligned?: boolean },
	{ color: string; size: string }
>({
	color: '$neutral2',
	size: '3rem',
})`
	position: absolute;
	top: 0;
	padding: 1.5rem;
	width: 6rem;
	height: 100%;
	${(p) => (p.iconRightAligned ? 'right: 0' : 'left: 0')};
	${(p) => (p.iconRightAligned ? '' : 'border-right-width: 1px')};
	border-color: $border0;
`;

const ClearIcon = styled(Icon).attrs({
	name: 'close',
	size: '2rem',
	color: '$neutral2',
	accessible: false,
})`
	cursor: pointer;
`;

// in case the android version of Picker gets rendered within Basefield, there will be a chevron icon at the same position where the ClearIcon appears
// therefore we add a background to the ClearIcon to hide the chevron icon underneath it
const HideAndroidPickerIconOverlay = styled.View<{
	altBackground?: boolean;
}>`
	position: absolute;
	right: 1rem;
	top: 3rem;
	background-color: ${(p) => (p.altBackground ? '$background0' : '$background1')};
`;

const VisibilityIcon = styled(Icon).attrs<
	{ visibilityHidden?: boolean },
	{ name: 'visible' | 'hidden'; size: string; color: string; accessible: boolean }
>((p) => ({
	name: p.visibilityHidden ? 'visible' : 'hidden',
	size: '3rem',
	color: '$neutral2',
	accessible: false,
}))`
	position: absolute;
	right: 1rem;
	top: 2.5rem;
	cursor: pointer;
`;

const FocusOutline = styled(Animated.View)<{
	error?: string;
	color: string;
	borderRadiusVal?: string;
}>`
	position: absolute;
	border-color: ${(p) => (p.error ? '$error' : p.color)};
	width: 100%;
	height: 100%;
	border-width: 2px;
	border-radius: ${(p) =>
		p.borderRadiusVal ||
		[
			p.theme.borderRadius[2],
			p.theme.borderRadius[2],
			p.theme.borderRadius[0],
			p.theme.borderRadius[0],
		].join(' ')};
`;

const FocusUnderline = styled(Animated.View)<{ error?: string; color: string }>`
	position: absolute;
	background-color: ${(p) => (p.error ? '$error' : p.color)};
	width: 100%;
	height: 2px;
	bottom: 0;
	left: 0;
	border-radius: ${(p) => p.theme.borderRadius[0]};
`;

const HintWrapper = styled.View`
	padding: 0 1.5rem;
	flex-direction: row;
	justify-content: space-between;
`;

export type BaseFieldProps = {
	noClear?: boolean;
	multiline?: boolean;
	disabled?: boolean;
	label?: string;
	margin?: string;
	nMargins?: number[];
	iconName?: IconName;
	iconRightAligned?: boolean;
	altBackground?: boolean;
	flex?: boolean;
	stretch?: boolean;
	characterLimit?: number;
	hint?: string;
	width?: string;
	borderRadius?: string;
	activeColor?: string;
	focusStyle?: 'underline' | 'outline' | 'disabled';
	onLayout?: (e: LayoutChangeEvent) => unknown;
	testID?: string;
	hintTestID?: string;
	errorTestID?: string;
	error?: string;
};

const BaseField = ({
	label,
	nMargins,
	disabled,
	margin,
	noClear,
	iconName,
	iconRightAligned,
	altBackground,
	flex,
	multiline,
	characterLimit,
	hint,
	error,
	inputLength,
	textValue,
	selected,
	onClear,
	onToggleVisibility,
	onPress,
	onClick,
	children,
	hasContent,
	toggleVisibility,
	visibilityHidden,
	width = '30rem',
	borderRadius,
	activeColor,
	onLayout,
	align = 'flex-start',
	focusStyle = 'underline',
	testID,
	errorTestID,
	hintTestID,
	stretch,
	borderWidth,
	padding,
}: BaseFieldProps & {
	inputLength?: number;
	textValue?: string;
	selected?: boolean;
	tagSeparator?: string;
	maxTags?: number;
	editOnBackspace?: boolean;
	onChange?: () => unknown;
	inputChangeDebounce?: number;
	toggleVisibility?: boolean;
	visibilityHidden?: boolean;
	onClear?: () => void;
	onToggleVisibility?: () => void;
	align?: 'flex-end' | 'flex-start' | 'center';
	onPress?: () => unknown;
	onClick?: () => unknown;
	children?: React.ReactNode;
	hasContent?: boolean;
	borderWidth?: string;
	padding?: number;
}) => {
	const theme = useTheme();
	const labelColor =
		activeColor ||
		(!theme.colors.darkMode && theme.colors.isLight(theme.colors.accent0)
			? '$neutral0'
			: '$accent0');

	const animation = React.useRef(new Animated.Value(selected ? 1 : 0));
	React.useEffect(() => {
		Animated.timing(animation.current, {
			toValue: selected ? 1 : 0,
			duration: 150,
			useNativeDriver: Platform.OS !== 'web',
		}).start();
	}, [selected]);
	const selectionStyle = React.useMemo(() => ({ opacity: animation.current }), []);
	return (
		<Wrapper
			margin={margin || (nMargins && nMargins.join('rem ') + 'rem') || '0'}
			flex={flex}
			stretch={stretch}
			width={width}
			onLayout={onLayout}
			disabled={disabled}
			pointerEvents={disabled ? 'none' : 'auto'}
		>
			<InputWrapper
				altBackground={altBackground}
				borderWidth={borderWidth}
				onPress={onPress}
				onClick={onClick}
				multiline={multiline}
				borderRadius={borderRadius}
				testID={testID}
			>
				{label ? (
					<AnimatedLabel
						selected={selected}
						error={error || (characterLimit && inputLength! > characterLimit)}
						expanded={selected || hasContent || inputLength! > 0}
						iconRightAligned={iconRightAligned}
						hasIcon={Boolean(iconName)}
						color={labelColor}
					>
						{label}
					</AnimatedLabel>
				) : null}
				<ContentWrapper
					iconRightAligned={iconRightAligned}
					hasIcon={Boolean(iconName)}
					align={align}
					hasLabel={Boolean(label)}
					hasChildren={Boolean(children)}
					padding={padding}
				>
					{children}
				</ContentWrapper>
				{iconName ? <InputIcon name={iconName} iconRightAligned={iconRightAligned} /> : null}
				{(inputLength! > 0 || textValue || hasContent) && !(iconName && iconRightAligned) ? (
					toggleVisibility ? (
						<VisibilityIcon visibilityHidden={visibilityHidden} onPress={onToggleVisibility} />
					) : !noClear ? (
						<HideAndroidPickerIconOverlay altBackground={altBackground}>
							<ClearIcon onPress={onClear} />
						</HideAndroidPickerIconOverlay>
					) : null
				) : null}
				{focusStyle === 'underline' ? (
					<FocusUnderline
						error={error || inputLength! > characterLimit! ? 'e' : undefined}
						color={activeColor || '$accent0'}
						style={selectionStyle}
						pointerEvents="box-none"
					/>
				) : focusStyle === 'disabled' ? null : (
					<FocusOutline
						error={error || inputLength! > characterLimit! ? 'e' : undefined}
						color={activeColor || '$accent0'}
						borderRadiusVal={borderRadius}
						style={selectionStyle}
						pointerEvents="box-none"
					/>
				)}
			</InputWrapper>
			{(error && error.trim()) || hint || characterLimit ? (
				<HintWrapper>
					{error && error.trim() ? (
						<Paragraph size="s" color={error && '$error'} testID={errorTestID}>
							{error.toUpperCase()}
						</Paragraph>
					) : hint ? (
						<Paragraph size="s" color={error && '$error'} testID={hintTestID}>
							{hint.toUpperCase()}
						</Paragraph>
					) : null}
					{characterLimit ? (
						<Paragraph size="s" color={inputLength! > characterLimit ? '$error' : undefined}>
							{inputLength} / {characterLimit}
						</Paragraph>
					) : null}
				</HintWrapper>
			) : null}
		</Wrapper>
	);
};

export default React.memo(BaseField);
