import React, { useState } from 'react';

import { Button, TextField, DateField, Heading, Paragraph } from '@cinuru/components';

import { default as gql } from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { Wrapper, Content, Row } from './LocalComponents';

const EditMovie = ({ item, dismissPortal, client }) => {
	const [id] = useState(item.id);
	const [movieTitle, setMovieTitle] = useState({ value: item.movieTitle, error: null });
	const [startDate, setStartDate] = useState({ value: item.startDate, error: null });
	const [loading, setLoading] = useState(false);

	const handleDateSelect = React.useCallback((value) => {
		setStartDate({
			value,
			error: false,
		});
	}, []);

	const handleMovieTitle = React.useCallback((value) => {
		setMovieTitle((before) => ({ ...before, value, error: false }));
	}, []);

	const validateStartDate = React.useCallback((value) => {
		setStartDate((before) => ({ ...before, error: !value ? 'Datum fehlt' : false }));
		return value ? true : false;
	}, []);

	const validateMovieTitle = React.useCallback((value) => {
		setMovieTitle((before) => ({ ...before, error: !value ? 'Film fehlt' : false }));
		return !value ? false : true;
	}, []);

	const editMovie = React.useCallback(async () => {
		const validStartDate = validateStartDate(startDate.value);
		const validMovieTitle = validateMovieTitle(movieTitle.value);

		if (!validStartDate) return;
		if (item.movieTitle && !validMovieTitle) return;
		setLoading(true);
		const { data } = await client.mutate({
			mutation: gql`
				mutation updateSoonInCinemaItem($id: ID!, $movieTitle: String, $startDate: Date) {
					updateSoonInCinemaItem(id: $id, movieTitle: $movieTitle, startDate: $startDate) {
						success
					}
				}
			`,
			variables: {
				id,
				startDate: startDate.value,
				movieTitle: movieTitle.value,
			},

			refetchQueries: ['soonInCinemaItems'],
		});
		if (data.updateSoonInCinemaItem.success) {
			setLoading(false);
			dismissPortal();
		}
	}, [
		client,
		dismissPortal,
		id,
		item.movieTitle,
		movieTitle.value,
		startDate.value,
		validateMovieTitle,
		validateStartDate,
	]);

	return (
		<Wrapper>
			<Content>
				<Row>
					<Heading>Film bearbeiten</Heading>
				</Row>
				<Row>
					<Paragraph>{item.movieTitle ? item.movieTitle : item.movie.title}</Paragraph>
				</Row>
				<DateField
					value={startDate.value}
					error={startDate.error}
					onChange={handleDateSelect}
					label="Startdatum"
					hint="In der App angezeigter Starttermin"
					futureOnly
				/>

				{item.movieTitle ? (
					<Row>
						<TextField
							error={movieTitle.error}
							value={movieTitle.value}
							onChange={handleMovieTitle}
							label="Filmtitel manuell eingeben"
							hint="Wenn der Film noch nicht bei Cinuru angelegt ist"
							flex
						/>
					</Row>
				) : null}
				<Row right>
					<Button label="ABBRECHEN" link onPress={dismissPortal} />
					<Button
						label="SPEICHERN"
						gradient="accentGradient0"
						textColor="$background0"
						onPress={editMovie}
						loading={loading}
					/>
				</Row>
			</Content>
		</Wrapper>
	);
};

export default withApollo(EditMovie);
