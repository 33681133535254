import * as React from 'react';
import { View } from 'react-native';
import { useTheme, useStyle } from 'styled-native-components';
import Svg, { Defs, LinearGradient, RadialGradient, Stop, Rect } from 'svgs';

import type { ViewStyle, LayoutChangeEvent, LayoutRectangle } from 'react-native';
import type { GradientName } from '@cinuru/utils/theme';

const orientations = {
	HORIZONTAL: { x1: '0%', y1: '0%', x2: '0%', y2: '100%' },
	VERTICAL: { x1: '0%', y1: '0%', x2: '100%', y2: '0%' },
	DIAGUP: { x1: '0%', y1: '100%', x2: '100%', y2: '0%' },
	DIAGDOWN: { x1: '0%', y1: '0%', x2: '100%', y2: '100%' },
};

export type GradientOrientation = 'HORIZONTAL' | 'VERTICAL' | 'DIAGUP' | 'DIAGDOWN' | 'RADIAL';

export const GradientDef = ({
	id,
	colors,
	opacities,
	orientation,
}: {
	id: string;
	colors: string[];
	opacities?: number[];
	orientation?: GradientOrientation;
}): JSX.Element => {
	const theme = useTheme();
	orientation = orientation || (theme.brand === 'CINURU' ? 'DIAGDOWN' : 'HORIZONTAL');
	const Gradient = orientation === 'RADIAL' ? RadialGradient : LinearGradient;
	return (
		<Defs>
			<Gradient id={`grad${id}`} {...orientations[orientation]}>
				{colors.map((col, i) => (
					<Stop
						key={i}
						offset={colors.length === 1 ? '0%' : Math.round((i / (colors.length - 1)) * 100) + '%'}
						stopColor={col}
						stopOpacity={opacities ? opacities[i] : 1}
					/>
				))}
			</Gradient>
		</Defs>
	);
};

let gradIdCounter = 0;
const Gradient = ({
	colors,
	gradient,
	opacities,
	color,
	orientation,
	borderRadius,
	style,
	children,
}: {
	color?: string;
	colors?: string[];
	gradient?: GradientName;
	opacities?: number[];
	orientation?: GradientOrientation;
	borderRadius?: string;
	children?: React.ReactNode;
	style?: ViewStyle;
}) => {
	const gradId = React.useRef(String(gradIdCounter++)).current;
	const theme = useTheme();
	const gradientColors = React.useMemo(() => {
		if (colors) {
			return colors;
		} else if (gradient) {
			return theme.colors[gradient];
		} else if (color) {
			const colorStringColors = color.split(',');
			// if comma separated color is passed, parse it into array of colors
			if (colorStringColors.length > 1) return colorStringColors;
			// otherwise return a single color value resolving a theme variable if present
			else return color.includes('$') ? [theme.colors[color.replace('$', '')]] : [color];
		} else {
			return [theme.colors.neutral3, theme.colors.neutral2];
		}
	}, [color, colors, theme, gradient]);

	const [layout, setLayout] = React.useState<LayoutRectangle | undefined>();
	const handleLayout = React.useCallback(({ nativeEvent }: LayoutChangeEvent) => {
		setLayout(nativeEvent.layout);
	}, []);

	const backgroundStyle = useStyle(`
		position: absolute;
		width: ${layout ? layout.width + 'px' : '100%'};
		height: ${layout ? layout.height + 'px' : '100%'};
		top: 0;
		left: 0;
		z-index: -1;
		overflow: hidden;
		overflow: hidden;
		padding: 0;
		margin: 0;
		background-color: transparent;
		opacity: 1;
		elevation: 0;
		border-width: 0;
		${borderRadius ? `border-radius: ${borderRadius}` : ''};
	`);
	const memodBackgroundElement = React.useMemo(
		() =>
			// if only one color is given, don't generate a gradient
			gradientColors.length === 1 ? (
				// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
				<View style={[style, backgroundStyle, { backgroundColor: gradientColors[0] }]} />
			) : (
				// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
				<View style={[style, backgroundStyle]}>
					<Svg width="100%" height="100%">
						<GradientDef
							id={gradId}
							colors={gradientColors}
							opacities={opacities}
							orientation={orientation}
						/>
						<Rect x="0" y="0" width="100%" height="100%" fill={`url(#grad${gradId})`} />
					</Svg>
				</View>
			),
		[style, backgroundStyle, gradId, gradientColors, opacities, orientation]
	);

	return (
		<View style={style} onLayout={handleLayout} key={`${layout?.width}|${layout?.height}`}>
			{memodBackgroundElement}
			{children}
		</View>
	);
};

export default React.memo(Gradient);
