import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { ID } from '@cinuru/utils/types';

import SearchSelectField, { SelectFieldItem } from '../../../../components/SearchSelectField';

const useAllCinemaFilmSeries = (cinemaIds?: ID[]): { id: ID; title?: string }[] | undefined => {
	const { data } = useQuery(
		gql`
			query MovieLists($ids: [ID!]!) {
				allCinemaFilmSeries(cinemaIds: $ids) {
					id
					title
				}
			}
		`,
		{
			variables: { ids: cinemaIds },
			skip: !cinemaIds,
		}
	);
	return React.useMemo(() => data?.allCinemaFilmSeries, [data?.allCinemaFilmSeries]);
};

const modifySelectedFilmSeries = (s: string[]): string[] => {
	return s.length > 1 && s[0] === 'ALL'
		? s.filter((s) => s !== 'ALL')
		: s.length > 1 && s[s.length - 1] === 'ALL'
		? ['ALL']
		: s.length === 0
		? ['ALL']
		: s;
};

const FilmSeriesController = ({
	children,
	value,
	onChange,
	path,
}: {
	children?: string;
	value: { selectedFilmSeries?: string[]; campaign: any };
	onChange: (path: string, newValue: any) => void;
	path: string;
}): JSX.Element => {
	const { campaign, selectedFilmSeries } = value;
	const campaignCinemaIds = campaign?.cinemas?.map((c) => c.id);
	const allCinemaFilmSeries = useAllCinemaFilmSeries(campaignCinemaIds);
	const allFilmSeries = React.useMemo(
		() => [{ id: 'ALL', title: 'Alle Filmreihen' }, ...(allCinemaFilmSeries || [])],
		[allCinemaFilmSeries]
	);

	const handleChange = React.useCallback(
		(selectedItems: SelectFieldItem[]) => {
			const values = selectedItems.map(({ value }) => value || '');
			const modifiedFilmSeries = modifySelectedFilmSeries(values);
			onChange(path, modifiedFilmSeries.length ? modifiedFilmSeries : undefined);
		},
		[onChange, path]
	);

	const allItems = React.useMemo(
		() => allFilmSeries.map(({ id, title }) => ({ label: title || '', value: id as string })),
		[allFilmSeries]
	);

	const defaultItems = React.useMemo(
		() =>
			selectedFilmSeries
				? modifySelectedFilmSeries(selectedFilmSeries).map((v) => ({
						value: v,
						label: allItems.find((item) => item.value === v)?.label || '',
				  }))
				: [{ value: 'ALL', label: 'Alle Filmreihen' }],
		[allItems, selectedFilmSeries]
	);

	return (
		<SearchSelectField
			width="100%"
			label={children || 'Filmreihenauswahl'}
			onChange={handleChange}
			allItems={allItems}
			defaultItems={defaultItems}
			multi
		/>
	);
};

export default React.memo(FilmSeriesController);
