import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { Box, Modal as MUIModal } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

import Txt from './Txt';
import Button from './Button';

import PortalProvider from './PortalProvider';

type DialogProps = {
	title?: string;
	description?: string;
	onClose?: () => void;
	renderContent?: (val: { dismissPortal: () => void }) => JSX.Element;
	dismissPortal: () => void;
	buttons?: { id: string; label: string; onClick?: () => void; variant?: ButtonProps['variant'] }[];
	width?: string;
	backgroundColor?: string;
	positionedAtTop?: boolean;
	wrapperStyle?: { [key: string]: string | number };
	isLocked?: boolean;
};

const StyledModal = styled(MUIModal)`
	overflow: auto;
`;

const AbsoluteWrapper = styled(Box)`
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100%;
	padding: 3rem;
	display: flex;
	flex-direction: column;
	position: absolute;
`;

const ScrollCenterWrapper = styled(Box)`
	margin: auto;
`;

const Dialog = ({
	renderContent,
	title,
	description,
	onClose,
	dismissPortal,
	buttons,
	isLocked,
}: DialogProps): JSX.Element => {
	const handleClose = React.useCallback(() => {
		onClose?.();
		dismissPortal();
	}, [dismissPortal, onClose]);

	const handleClick = React.useCallback(
		(id: string) => {
			buttons?.find((button) => button.id === id)?.onClick?.();
			dismissPortal();
		},
		[buttons, dismissPortal]
	);

	const handleStopPropagation = React.useCallback((event) => {
		event.stopPropagation();
	}, []);

	return (
		<Box>
			<StyledModal open={true} onClose={isLocked ? undefined : handleClose}>
				<AbsoluteWrapper onClick={isLocked ? undefined : handleClose}>
					<ScrollCenterWrapper
						onClick={handleStopPropagation} // don't remove this
					>
						<Box
							bgcolor="background.paper"
							borderRadius="1rem"
							padding={renderContent ? undefined : '3rem'}
							boxShadow="24"
						>
							{renderContent ? (
								renderContent({ dismissPortal: handleClose })
							) : (
								<>
									<Txt m="0 0 1rem 0" variant="h6">
										{title}
									</Txt>
									<Txt>{description}</Txt>
								</>
							)}

							{buttons && buttons.length ? (
								<Box
									width="100%"
									m="2rem 0 0"
									display="flex"
									flexDirection="row"
									justifyContent="flex-end"
									alignItems="center"
								>
									{buttons ? (
										buttons.map(({ id, label, onClick, variant }) => (
											<Button
												m="0 0 0 1rem"
												key={id}
												id={id}
												onClick={onClick ? handleClick : handleClose}
												variant={variant}
											>
												{label}
											</Button>
										))
									) : (
										<Button onClick={handleClose}>Schließen</Button>
									)}
								</Box>
							) : null}
						</Box>
					</ScrollCenterWrapper>
				</AbsoluteWrapper>
			</StyledModal>
		</Box>
	);
};

const dialogStack: string[] = [];
Dialog.render = (props: Omit<DialogProps, 'dismissPortal'>, id?: string): void => {
	if (!id) {
		id = shortid.generate();
		dialogStack.push(id);
	}
	PortalProvider.render(id, Dialog, props);
};
Dialog.unmount = (id?: string) => {
	if (!id) id = dialogStack.pop();
	if (id) PortalProvider.unmount(id);
};

export default Dialog;
