import { parse } from 'rn-markdown-parser';

export type HrNode = { type: 'hr' };
export type BrNode = { type: 'br' };
export type TextNode = { type: 'text', text: string };
export type ImageNode = { type: 'image', href: string, title: string, text: string };
export type LinkNode = { type: 'link', href: string, text: string };
export type StrongNode = { type: 'strong', children: MarkdownNode[] };
export type EmNode = { type: 'em', children: MarkdownNode[] };
export type HeadingNode = { type: 'heading', depth: number, children: MarkdownNode[] };
export type ParagraphNode = { type: 'paragraph', children: MarkdownNode[] };
export type ListNode = { type: 'list', ordered: boolean, children: MarkdownNode[] };
export type ListItemNode = { type: 'list_item', children: MarkdownNode[] };
export type BlockquoteNode = { type: 'blockquote', children: MarkdownNode[] };
export type MarkdownNode =
	| HrNode
	| BrNode
	| TextNode
	| ImageNode
	| LinkNode
	| StrongNode
	| EmNode
	| HeadingNode
	| ParagraphNode
	| ListNode
	| ListItemNode
	| BlockquoteNode;

type ParserOptions = {
	gfm?: boolean,
	tables?: boolean,
	breaks?: boolean,
	pedantic?: boolean,
	sanitize?: boolean,
	smartLists?: boolean,
	smartypants?: boolean,
};

const DEFAULT_OPTIONS = {
	gfm: true,
	breaks: true,
	smartLists: true,
	smartypants: true,
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export default function (text: string, options: ParserOptions = {}): MarkdownNode[] {
	const parsed: { children: MarkdownNode[] } = parse(text, { ...DEFAULT_OPTIONS, ...options });
	const postProcessMarkdownSyntaxTree = (children) => {
		const cleanedChildren = [];
		for (let i = 0; i < children.length; i++) {
			let node = children[i];
			const lastNode = i > 0 ? cleanedChildren[i - 1] : null;
			// if two consecutive text nodes appear, combine them (happened with `!` for some reason)
			if (lastNode && node.type === 'text' && lastNode.type === 'text') {
				lastNode.text += node.text;
			} else {
				// remove space nodes and white space only text nodes
				if (node.children)
					node.children = node.children.reduce((childs, nod) => {
						if (
							(nod.type === 'text' && (!nod.text || nod.text.replace(/\s/g, '').length === 0)) ||
							node.type === 'space'
						) {
							return childs;
						} else {
							return [...childs, nod];
						}
					}, []);
				// transform paragraphs with only an image into image_blocks
				if (
					node.type === 'paragraph' &&
					node.children &&
					node.children.length === 1 &&
					node.children[0].type === 'image'
				) {
					node = node.children[0];
					node.type = 'image_block';
				}
				// transform links that don't have text children i.e. <someLink> to [someLink](someLink)
				if(node.type === 'link' && !node.children) {
					node.children = [{ type: 'text', text: node.href }]
				}
				// transform loose_items to list_items
				if (node.type === 'loose_item') {
					node.type = 'list_item'
				}
				// remove nested paragraphs in blockquotes
				if (
					node.type === 'blockquote' &&
					node.children &&
					node.children.length === 1 &&
					node.children[0].type === 'paragraph'
				) {
					node = node.children[0];
					node.type = 'blockquote';
				}
				if (node.type === 'text') {
					node.text = node.text.replace(/\n/g, '');
				}
				// remove parent reference, which prevent stringifying because of circularity
				node.parent && delete node.parent;
				// recursively apply to all children
				if (node.children) node.children = postProcessMarkdownSyntaxTree(node.children);
				cleanedChildren.push(node);
			}
		}
		return cleanedChildren;
	};
	const markdownSyntaxTree = postProcessMarkdownSyntaxTree(parsed.children);
	return markdownSyntaxTree;
}
