import React from 'react';

import ActiveWrapper from './ActiveWrapper';
import { ClusterPropName } from '../TargetGroupCluster';
import SearchSelectField, {
	SearchSelectFieldRef,
	SelectFieldItem as _SelectFieldItem,
} from '../../../components/SearchSelectField';
import SearchApiSelectField, {
	SearchApiSelectFieldRef,
} from '../../../components/SearchApiSelectField';

export type SelectFieldItem = _SelectFieldItem;

const defaultProps = {
	label: 'Suchen',
	flex: '1',
	multi: true,
};

export type GeneralSearchFilterRef = {
	validate: () => boolean;
};

const SearchSelectFilter = React.forwardRef<
	GeneralSearchFilterRef,
	{
		defaultItems: SelectFieldItem[];
		allItems?: SelectFieldItem[];
		active?: boolean;
		onChange: (clusterPropName: ClusterPropName, selectedItems: SelectFieldItem[]) => void;
		showFilter: boolean;
		clusterPropName: ClusterPropName;
		onChangeActivity: (clusterPropName: ClusterPropName, value: boolean) => void;
		label: string;
		type?: 'MOVIE' | 'CAST_OR_CREW_MEMBER';
	}
>(
	(
		{
			defaultItems,
			allItems,
			active,
			onChange,
			showFilter,
			clusterPropName,
			onChangeActivity,
			label,
			type,
		},
		ref
	): JSX.Element | null => {
		const handleChange = React.useCallback(
			(selectedItems: SelectFieldItem[]) => {
				onChange?.(clusterPropName, selectedItems);
			},
			[clusterPropName, onChange]
		);

		const handleToggleActivity = React.useCallback(() => {
			onChangeActivity(clusterPropName, !active);
		}, [onChangeActivity, clusterPropName, active]);

		const disabled = !active;

		const searchSelectFieldRef = React.useRef<SearchSelectFieldRef>(null);
		const searchApiSelectFieldRef = React.useRef<SearchApiSelectFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			const errors = !active
				? []
				: [searchSelectFieldRef, searchApiSelectFieldRef].map((r) => r.current?.validate());
			const invalid = errors.some(Boolean);
			return invalid;
		}, [active]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return showFilter ? (
			<ActiveWrapper
				label={label}
				active={active}
				onHandleToggleActivity={handleToggleActivity}
				p="2rem 2rem 1rem"
			>
				{!type ? (
					<SearchSelectField
						allItems={allItems}
						disabled={disabled}
						onChange={handleChange}
						defaultItems={defaultItems}
						ref={searchSelectFieldRef}
						{...defaultProps}
					/>
				) : type === 'CAST_OR_CREW_MEMBER' || type === 'MOVIE' ? (
					<SearchApiSelectField
						disabled={disabled}
						onChange={handleChange}
						defaultItems={defaultItems}
						type={type}
						ref={searchApiSelectFieldRef}
						{...defaultProps}
					/>
				) : null}
			</ActiveWrapper>
		) : null;
	}
);

export default React.memo(SearchSelectFilter);
