import React, { Component } from 'react';
import styled from 'styled-native-components';

import { Button, Paragraph } from '@cinuru/components';

import { default as gql } from 'graphql-tag';
import { withApollo } from 'react-apollo';
import WithData from '../../components/WithData';
import CinemaSelectField from '../../components/CinemaSelectField';

import { EditCinemaForm } from './EditCinemaForm';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
`;

const cinemaDetailsFragmentOld = gql`
	fragment CinemaDetailsOld on Cinema {
		id
		name
		logo
		claim
		hasBonusProgram
		headerImage
		imprint
		technologies
		history
		currentInformation
		specialAboutUs
		onlineTicketingBaseUrl
		street
		houseNumber
		zipCode
		city
		parkingDescription
		locationDescription
		latitude
		longitude
		googleMapsLink
		appleMapsLink
		phone
		facebook
		twitter
		website
		email
		instagram
		barrierFree
		barrierFreeText
		hearingImpaired
		hearingImpairedText
		brands
		blind
		blindText
		openingHoursDisplay {
			weekdays
			hours
		}
		pricesDisplay {
			title
			price
			description
		}
		specialOffersDisplay {
			title
			image
			description
			price
		}
		giftVouchersDisplay {
			title
			image
			description
			price
		}
		sneaksDisplay {
			description
			title
			price
			image
		}
		accessRestrictedTo
	}
`;
const cinemaQuery = gql`
	${cinemaDetailsFragmentOld}
	query CinemaDetail($cinemaId: ID!) {
		cinema(id: $cinemaId) {
			...CinemaDetailsOld
		}
	}
`;
export class EditCinema extends Component {
	state = { cinemaId: null, forceRerenderKey: 0 };

	forceRerender = () => {
		this.setState({ forceRerenderKey: 'r' + Math.round(Math.random() * 10000) });
	};
	handleSelectCinemas = (cinemaId) => {
		if (cinemaId && cinemaId !== this.state.cinemaId && typeof cinemaId === 'string') {
			this.setState({ cinemaId });
			this.forceRerender();
		}
	};

	saveCinema = async ({ variables }) => {
		return await this.props.client.mutate({
			mutation: gql`
				mutation updateCinema(
					$id: ID!
					$name: String!
					$logo: String
					$claim: String
					$hasBonusProgram: Boolean
					$headerImage: String
					$imprint: String
					$technologies: String
					$history: String
					$specialAboutUs: String
					$currentInformation: String
					$onlineTicketingBaseUrl: String
					$street: String
					$houseNumber: String
					$zipCode: String
					$city: String
					$parkingDescription: String
					$locationDescription: String
					$latitude: String
					$longitude: String
					$googleMapsLink: String
					$appleMapsLink: String
					$phone: String
					$facebook: String
					$twitter: String
					$instagram: String
					$website: String
					$email: String
					$barrierFree: Boolean
					$barrierFreeText: String
					$hearingImpaired: Boolean
					$hearingImpairedText: String
					$blind: Boolean
					$blindText: String
					$openingHoursDisplay: [CinemaOpeningHoursInput!]!
					$pricesDisplay: [CinemaInformationParagraphInput!]!
					$specialOffersDisplay: [CinemaInformationParagraphInput!]!
					$giftVouchersDisplay: [CinemaInformationParagraphInput!]!
					$sneaksDisplay: [CinemaInformationParagraphInput!]!
					$brands: [Brand!]!
					$accessRestrictedTo: AccessRestriction!
				) {
					updateCinema(
						cinema: {
							id: $id
							name: $name
							logo: $logo
							claim: $claim
							hasBonusProgram: $hasBonusProgram
							headerImage: $headerImage
							imprint: $imprint
							technologies: $technologies
							history: $history
							specialAboutUs: $specialAboutUs
							currentInformation: $currentInformation
							onlineTicketingBaseUrl: $onlineTicketingBaseUrl
							street: $street
							houseNumber: $houseNumber
							zipCode: $zipCode
							city: $city
							parkingDescription: $parkingDescription
							locationDescription: $locationDescription
							latitude: $latitude
							longitude: $longitude
							googleMapsLink: $googleMapsLink
							appleMapsLink: $appleMapsLink
							phone: $phone
							facebook: $facebook
							twitter: $twitter
							instagram: $instagram
							website: $website
							email: $email
							barrierFree: $barrierFree
							barrierFreeText: $barrierFreeText
							hearingImpaired: $hearingImpaired
							hearingImpairedText: $hearingImpairedText
							blind: $blind
							blindText: $blindText
							openingHoursDisplay: $openingHoursDisplay
							pricesDisplay: $pricesDisplay
							specialOffersDisplay: $specialOffersDisplay
							giftVouchersDisplay: $giftVouchersDisplay
							sneaksDisplay: $sneaksDisplay
							brands: $brands
							accessRestrictedTo: $accessRestrictedTo
						}
					) {
						success
						cinema {
							...CinemaDetailsOld
						}
					}
				}
				${cinemaDetailsFragmentOld}
			`,
			variables,
		});
	};

	createCinema = async () => {
		const { data, error } = await this.props.client.mutate({
			mutation: gql`
				${cinemaDetailsFragmentOld}
				mutation {
					createCinema {
						success
						cinema {
							...CinemaDetailsOld
						}
					}
				}
			`,
		});

		if (!error && data) {
			this.handleSelectCinemas(data.createCinema.cinema.id);
		}
	};
	render = () => (
		<Wrapper>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<Button label="Neues Kino erstellen" onPress={this.createCinema} margin="0.5rem 0rem" />
				<Paragraph margin="0.5rem 8rem 0.5rem 4rem">Oder:</Paragraph>
				<CinemaSelectField onChange={this.handleSelectCinemas} />
			</div>
			{this.state.cinemaId && (
				<WithData
					query={cinemaQuery}
					// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
					variables={{
						cinemaId: this.state.cinemaId,
					}}
				>
					{({ cinema }) => (
						<EditCinemaForm
							initialData={cinema}
							saveCinema={this.saveCinema}
							//We add the cinema.id key here to rerender also if the cinemaid changed. Otherwise, after creating
							// a cinema this does not reload, because the query component fires first with the "old" cinema once
							key={this.state.forceRerenderKey + cinema.id}
						/>
					)}
				</WithData>
			)}
		</Wrapper>
	);
}
export default withApollo(EditCinema);
