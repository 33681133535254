import React from 'react';
import { useSnackbar } from 'notistack';
import { Box, Input, Button } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import styledWeb from 'styled-components';

import { uploadImageToFileServer, Image } from '../utils/image';

const UploadButtonWrapper = styledWeb(Box)`
	margin: 0;
	> .UploadButton-input {
		display: none;
	};
`;

const UploadImageButton = ({
	onUploadStart,
	onUploaded,
	disabled,
}: {
	onUploadStart: () => void;
	onUploaded: (image: Image) => void;
	disabled?: boolean;
}): JSX.Element => {
	// const { enqueueSnackbar } = useSnackbar();

	const handleImageUpload = React.useCallback(
		async ({
			target: {
				validity,
				files: [file],
			},
		}) => {
			if (validity.valid) {
				onUploadStart();
				const { success, uploadedImage, error } = await uploadImageToFileServer({
					file,
				});
				if (success && uploadedImage) {
					// enqueueSnackbar(`Erfolgreicher Upload`, {
					// 	variant: 'success',
					// });
					onUploaded(uploadedImage);
					return;
				} else {
					// enqueueSnackbar(`Fehler beim Speichern. ${error}`, {
					// 	variant: 'error',
					// });
				}
			}
		},
		[onUploadStart, onUploaded]
	);

	return (
		<UploadButtonWrapper>
			<Input
				className="UploadButton-input"
				id="contained-button-file"
				type="file"
				onChange={handleImageUpload}
				disabled={disabled}
			/>

			<label htmlFor="contained-button-file">
				<Button component="span" endIcon={<CloudUpload />} disabled={disabled}>
					Upload
				</Button>
			</label>
		</UploadButtonWrapper>
	);
};

export default UploadImageButton;
