/** 
 This component is pretty old and only used from PortalTriggerWeb to support preventEventBubbling, which is only used in the EditorFields Link component, which seems to be broken currently. Since react native pose was removed from the PortalProvider this will not support all the same components that use a PortalTrigger like Dropdowns, but those are only used on the web at the moment. 
 
 In short this is not used on native and would need a rewrite completely to be used on native.
*/

// @flow
import * as React from 'react';
import { Platform } from 'react-native';
import shortid from 'shortid';
import styled, { useLengthAttribute } from 'styled-native-components';

import PortalProvider from './PortalProvider';
import shallowEqual from 'shallowequal';

const Wrapper = styled.View`
	margin: ${(p) => p.margin};
	${(p) => (p.stretch ? 'align-self: stretch' : '')};
	${(p) => (p.flexBase ? `flex: 1 0 ${p.flexBase}` : '')};
`;

const OverlayWrapper = styled.TouchableOpacity.attrs((p) => ({
	pointerEvents: p.onPress ? 'auto' : 'box-none',
	activeOpacity: 1,
}))`
	position: absolute;
	width: ${(p) => p.portalLayout.width}px;
	height: ${(p) => p.portalLayout.height}px;
	${Platform.OS === 'web' ? 'cursor: auto' : ''};
`;

const CONTENT_PADDING = 10;
const getTop = (p) => p.measures.top + p.measures.height + p.topMargin;
const OverlayContentWrapper = styled.View`
	position: absolute;
	top: ${getTop}px;
	left: ${(p) => p.measures.left - CONTENT_PADDING}px;
	width: ${(p) => p.measures.width + 2 * CONTENT_PADDING}px;
	height: ${(p) => p.portalHeight - getTop(p)}px;
	padding: 0 ${CONTENT_PADDING}px;
	${(p) => (p.allowOverflow ? '' : 'overflow: hidden')};
`;

const PortalContent = React.memo(
	({
		contentTopMargin,
		allowOverflow,
		renderOverlay,
		measures,
		portalLayout,
		dismissPortal,
		onClose,
	}) => {
		const [topMargin] = useLengthAttribute(contentTopMargin);
		return measures ? (
			<OverlayWrapper
				portalLayout={portalLayout}
				onPress={() => {
					dismissPortal();
					onClose && onClose();
				}}
			>
				<OverlayContentWrapper
					measures={measures}
					portalHeight={portalLayout.height}
					topMargin={topMargin}
					allowOverflow={allowOverflow}
				>
					{renderOverlay()}
				</OverlayContentWrapper>
			</OverlayWrapper>
		) : null;
	}
);

type Props = {
	active: boolean,
	onClose: () => any,
	children: React.Node,
	renderOverlay: () => React.Node,
	renderTrigger: () => React.Node,
	contentTopMargin: string,
	margin?: string,
	stretch?: boolean,
	flexBase?: string,
};
type State = {
	measures?: {
		width: number,
		height: number,
		top: number,
		left: number,
		windowHeight: number,
		windowWidth: number,
	},
};
export default class PortalTrigger extends React.Component<Props, State> {
	static defaultProps = { margin: '0', contentTopMargin: '0' };
	state = { measures: null };
	wrapperRef = React.createRef();

	id = shortid.generate();

	shouldComponentUpdate = (nextProps, nextState) =>
		!shallowEqual(nextProps, this.props) ||
		(!shallowEqual(nextState.measures, this.state.measures) && nextProps.active);

	componentDidUpdate = () => {
		// measure component position
		this.wrapperRef.current.measure((x, y, width, height, left, top) => {
			top += (window && window.scrollY) || 0;
			left += (window && window.scrollX) || 0;
			this.setState({ measures: { width, height, top, left } });
		});
		// activate portal if active
		if (this.props.active) {
			const { contentTopMargin, allowOverflow, renderOverlay, onClose } = this.props;
			const { measures } = this.state;
			PortalProvider.render(this.id, PortalTrigger, {
				contentTopMargin,
				allowOverflow,
				renderOverlay,
				measures,
				onClose,
			});
		} else {
			PortalProvider.unmount(this.id);
		}
	};

	render = () => {
		// console.log('render native portal');
		const { children, margin, flexBase, renderTrigger, stretch, ...props } = this.props;
		return (
			<Wrapper
				ref={this.wrapperRef}
				margin={margin}
				flexBase={flexBase}
				stretch={stretch}
				onLayout={this.componentDidUpdate}
				{...props}
			>
				{children || (renderTrigger && renderTrigger())}
			</Wrapper>
		);
	};
}
