import React from 'react';
import styled from 'styled-components';
import templateData from '../json/templateData.json';
import { Brand } from '@cinuru/utils/theme';
import { FilmSeriesProps } from '@cinuru/emails/src/components/DashboardEmailComponents/FilmSeries';

import {
	stripValueFromDimensionObject,
	transformElement,
} from '../utils/emailConfigurationHelpers';
import ApiRenderedComponent from './ApiRenderedComponent';
import {
	ColorController,
	FilmSeriesController,
	TextEditorController,
} from '../utils/allFormControlComponents';
import { useElementsWithUpdatedFilmSeries } from '../utils/updateTrueElements';
import { Campaign } from '../../../utils/campaign';
import { EmailElement, FilmSeriesData } from '../../../utils/campaignEmailContent';

import SizeController from './FormControls/SizeController';
import SelectController from './FormControls/SelectController';
import CheckBoxController from './FormControls/CheckBoxController';
import ExpandableContents from '../../../components/ExpandableContents';

const { filmSeriesTemplate } = templateData;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
`;

const FilmSeriesPreview = ({
	id,
	element,
	campaign,
	brand,
	elements,
}: {
	id: string;
	element: FilmSeriesData;
	campaign: Campaign;
	brand: Brand;
	elements: EmailElement[];
}) => {
	const elementWithNewFilmSeries = useElementsWithUpdatedFilmSeries({
		elements,
		campaign,
		selectedFilmSeries: element.selectedFilmSeries,
		timeFrame: element.timeFrame,
	}).filter((el) => el.key === element.key)[0] as FilmSeriesData;

	const componentProps: FilmSeriesProps = React.useMemo(
		() => ({
			...element,
			movieConfiguration: stripValueFromDimensionObject(
				element.movieConfiguration
			) as FilmSeriesProps['movieConfiguration'],
			filmSeries: elementWithNewFilmSeries.filmSeries,
		}),
		[element, elementWithNewFilmSeries.filmSeries]
	);

	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="FilmSeries"
			componentProps={componentProps}
		/>
	);
};

export const FilmSeriesEditor = React.memo(
	({
		element,
		updateElement,
		brand,
	}: {
		element: FilmSeriesData;
		updateElement: (filmSeriesData: FilmSeriesData) => void;
		brand: Brand;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement(newElement as FilmSeriesData);
			},
			[element, updateElement]
		);

		const contents = React.useMemo(
			() => [
				{
					label: 'Allgemeine Einstellungen',
					items: [
						<FilmSeriesController
							value={element}
							onChange={handleChangeController}
							path="selectedFilmSeries"
							key="1"
						/>,
						<CheckBoxController
							type="PROGRAM_DETAILS"
							value={element.details}
							onChange={handleChangeController}
							path="details"
							key="2"
						/>,
						<SelectController
							type="NUMBER_OF_WEEKS"
							value={element.timeFrame}
							onChange={handleChangeController}
							path="timeFrame"
							key="3"
						/>,
						<ColorController
							value={element.backgroundColor}
							brand={brand}
							onChange={handleChangeController}
							path="backgroundColor"
							label="Hintergrundfarbe"
							fallBackColor="background0"
							key="4"
						/>,
					],
				},
				{
					label: 'Hauptüberschrift und -beschreibung',
					items: [
						<TextEditorController
							id={`filmSeries_header_${element.key}`}
							value={element.description?.text}
							onChange={handleChangeController}
							path="description/text"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.description?.family}
							onChange={handleChangeController}
							path="description/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.description?.size}
							onChange={handleChangeController}
							path="description/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.description?.align}
							onChange={handleChangeController}
							path="description/align"
							key="4"
						/>,
						<ColorController
							value={element.description?.color}
							brand={brand}
							onChange={handleChangeController}
							path="description/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
					],
				},
				{
					label: 'Filmreihen-Titel',
					items: [
						<SelectController
							type="MARKDOWN_TYPE"
							value={element.filmSeriesConfiguration?.filmSeriesTitle?.textType}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesTitle/textType"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.filmSeriesConfiguration?.filmSeriesTitle?.family}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesTitle/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.filmSeriesConfiguration?.filmSeriesTitle?.size}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesTitle/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.filmSeriesConfiguration?.filmSeriesTitle?.align}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesTitle/align"
							key="4"
						/>,
						<ColorController
							value={element.filmSeriesConfiguration?.filmSeriesTitle?.color}
							brand={brand}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesTitle/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
						<CheckBoxController
							value={element.filmSeriesConfiguration?.filmSeriesTitle?.bold}
							type="BOLDNESS"
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesTitle/bold"
							key="6"
						/>,
					],
				},
				{
					label: 'Filmreihen-Untertitel',
					items: [
						<SelectController
							type="MARKDOWN_TYPE"
							value={element.filmSeriesConfiguration?.filmSeriesSubTitle?.textType}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesSubTitle/textType"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.filmSeriesConfiguration?.filmSeriesSubTitle?.family}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesSubTitle/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.filmSeriesConfiguration?.filmSeriesSubTitle?.size}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesSubTitle/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.filmSeriesConfiguration?.filmSeriesSubTitle?.align}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesSubTitle/align"
							key="4"
						/>,
						<ColorController
							value={element.filmSeriesConfiguration?.filmSeriesSubTitle?.color}
							brand={brand}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesSubTitle/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
						<CheckBoxController
							type="BOLDNESS"
							value={element.filmSeriesConfiguration?.filmSeriesSubTitle?.bold}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesSubTitle/bold"
							key="6"
						/>,
					],
				},
				{
					label: 'Filmreihen-Beschreibung',
					items: [
						<SelectController
							type="MARKDOWN_TYPE"
							value={element.filmSeriesConfiguration?.filmSeriesDescription?.textType}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesDescription/textType"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.filmSeriesConfiguration?.filmSeriesDescription?.family}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesDescription/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.filmSeriesConfiguration?.filmSeriesDescription?.size}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesDescription/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.filmSeriesConfiguration?.filmSeriesDescription?.align}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesDescription/align"
							key="4"
						/>,
						<ColorController
							value={element.filmSeriesConfiguration?.filmSeriesDescription?.color}
							brand={brand}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesDescription/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
						<CheckBoxController
							type="BOLDNESS"
							value={element.filmSeriesConfiguration?.filmSeriesDescription?.bold}
							onChange={handleChangeController}
							path="filmSeriesConfiguration/filmSeriesDescription/bold"
							key="6"
						/>,
					],
				},
				{
					label: 'Filmplakat',
					items: [
						<SizeController
							type="WIDTH"
							value={element.movieConfiguration?.poster?.width}
							onChange={handleChangeController}
							path="movieConfiguration/poster/width"
							key="1"
						/>,
						<SizeController
							type="BORDER_RADIUS"
							value={element.movieConfiguration?.poster?.borderRadius}
							onChange={handleChangeController}
							path="movieConfiguration/poster/borderRadius"
							key="2"
						/>,
						<CheckBoxController
							type="SHADOW"
							value={element.movieConfiguration?.poster?.shadow}
							onChange={handleChangeController}
							path="movieConfiguration/poster/shadow"
							key="3"
						/>,
					],
				},
				{
					label: 'Filmtitel',
					items: [
						<SelectController
							type="MARKDOWN_TYPE"
							value={element.movieConfiguration?.movieTitle?.textType}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/textType"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.movieConfiguration?.movieTitle?.family}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.movieConfiguration?.movieTitle?.size}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.movieConfiguration?.movieTitle?.align}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/align"
							key="4"
						/>,
						<ColorController
							value={element.movieConfiguration?.movieTitle?.color}
							brand={brand}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
						<CheckBoxController
							type="BOLDNESS"
							value={element.movieConfiguration?.movieTitle?.bold}
							onChange={handleChangeController}
							path="movieConfiguration/movieTitle/bold"
							key="6"
						/>,
					],
				},
				{
					label: 'Weitere Filminfos',
					items: [
						<SelectController
							type="MARKDOWN_TYPE"
							value={element.movieConfiguration?.movieData?.textType}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/textType"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.movieConfiguration?.movieData?.family}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.movieConfiguration?.movieData?.size}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.movieConfiguration?.movieData?.align}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/align"
							key="4"
						/>,
						<ColorController
							value={element.movieConfiguration?.movieData?.color}
							brand={brand}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
						<CheckBoxController
							type="BOLDNESS"
							value={element.movieConfiguration?.movieData?.bold}
							onChange={handleChangeController}
							path="movieConfiguration/movieData/bold"
							key="6"
						/>,
					],
				},
				{
					label: 'Filmsynopsis',
					items: [
						<SelectController
							type="MARKDOWN_TYPE"
							value={element.movieConfiguration?.movieSynopsis?.textType}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/textType"
							key="1"
						/>,
						<SelectController
							type="FONT_FAMILY"
							value={element.movieConfiguration?.movieSynopsis?.family}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/family"
							key="2"
						/>,
						<SelectController
							type="FONT_SIZE"
							value={element.movieConfiguration?.movieSynopsis?.size}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/size"
							key="3"
						/>,
						<SelectController
							type="FONT_ALIGN"
							value={element.movieConfiguration?.movieSynopsis?.align}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/align"
							key="4"
						/>,
						<ColorController
							value={element.movieConfiguration?.movieSynopsis?.color}
							brand={brand}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
							key="5"
						/>,
						<CheckBoxController
							type="BOLDNESS"
							value={element.movieConfiguration?.movieSynopsis?.bold}
							onChange={handleChangeController}
							path="movieConfiguration/movieSynopsis/bold"
							key="6"
						/>,
					],
				},
			],
			[brand, element, handleChangeController]
		);

		return (
			<Wrapper>
				<ExpandableContents
					width="100%"
					contents={contents}
					itemMargin="2rem 0 1rem"
					expandableMargin="1rem 0"
				/>
			</Wrapper>
		);
	}
);

export const FilmSeriesDefaultParams: FilmSeriesData = {
	type: 'SERIES',
	timeFrame: 1,
	filmSeries: filmSeriesTemplate,
	selectedFilmSeries: ['ALL'],
	details: true,
	description: {
		textType: 'PARAGRAPH',
		text: '# Alle Filmreihen:',
	},
	filmSeriesConfiguration: {
		filmSeriesTitle: {
			textType: 'HEADING',
			color: 'accent0',
			bold: true,
		},
		filmSeriesSubTitle: {
			textType: 'PARAGRAPH',
		},
		filmSeriesDescription: {
			textType: 'PARAGRAPH',
		},
	},
	movieConfiguration: {
		poster: { shadow: true },
		movieTitle: {
			textType: 'PARAGRAPH',
			color: 'accent0',
			bold: true,
		},
		movieData: {
			textType: 'PARAGRAPH',
			bold: true,
		},
		movieSynopsis: {
			textType: 'PARAGRAPH',
		},
	},
	index: 0,
	borderRadiusWrapper: { top: true, bottom: true },
};

export default React.memo(FilmSeriesPreview);
