import * as React from 'react';
import { BoxProps } from '@mui/material/Box';

import { FormLabel, Box, Card } from '@mui/material';

interface ExtendedBoxProps extends BoxProps {
	label?: string;
	m?: string;
}

const SectionWrapper2 = ({ label, m, children, ...rest }: ExtendedBoxProps): JSX.Element => {
	return (
		<Box m={m}>
			<Card variant="outlined">
				<Box display="flex" {...rest}>
					{label ? (
						<Box>
							<FormLabel>{label}</FormLabel>
						</Box>
					) : null}
					{children}
				</Box>
			</Card>
		</Box>
	);
};

export default SectionWrapper2;
