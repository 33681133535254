import * as React from 'react';

import { Grid } from '@mui/material';
import TimeSelectField from './TimeSelectField';
import type { TimeSelectFieldRef } from './TimeSelectField';
import DateSelectField from './DateSelectField';
import type { DateSelectFieldRef } from './DateSelectField';
import SectionWrapper from './SectionWrapper';

export type DatetimeSelectFormRef = {
	validate: () => boolean;
};

const DatetimeSelectForm = React.forwardRef<
	DatetimeSelectFormRef,
	{
		label?: string;
		margin?: string;
		padding?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		disabled?: boolean;
		// date
		dateLabel?: string;
		defaultDate?: string;
		onChangeDate?: (startDate?: string) => void;
		// time
		timeLabel?: string;
		defaultTime?: string;
		onChangeTime?: (startTime?: string) => void;
	}
>(
	(
		{
			label,
			margin = '2rem 0 1rem 0',
			variant = 'standard',
			padding = '2rem',
			disabled,
			// date
			dateLabel = 'Datum',
			defaultDate,
			onChangeDate,
			// time
			timeLabel = 'Zeit',
			defaultTime,
			onChangeTime,
		},
		ref
	): JSX.Element => {
		const dateSelectFieldRef = React.useRef<DateSelectFieldRef>(null);
		const timeSelectFieldRef = React.useRef<TimeSelectFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			const invalid = [dateSelectFieldRef, timeSelectFieldRef]
				.map((r) => r.current?.validate())
				.some(Boolean);
			return invalid;
		}, []);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<SectionWrapper margin={margin} padding={padding} label={label}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<DateSelectField
							label={dateLabel}
							defaultDate={defaultDate}
							onChange={onChangeDate}
							variant={variant}
							flex
							ref={dateSelectFieldRef}
							disabled={disabled}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TimeSelectField
							label={timeLabel}
							defaultValue={defaultTime}
							onChange={onChangeTime}
							variant={variant}
							flex
							ref={timeSelectFieldRef}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</SectionWrapper>
		);
	}
);

export default React.memo(DatetimeSelectForm);
