import * as React from 'react';
import styled, { useTheme, useLengthAttribute, useColorAttribute } from 'styled-native-components';
import Svg, { Path } from 'svgs';
import { Platform } from 'react-native';

import { GradientDef } from './Gradient';
import Touchable from './Touchable';

import type { ViewStyle, AccessibilityRole } from 'react-native';
import type { IconName } from '@cinuru/utils/theme';
import type { TouchableRef, TouchableProps } from './Touchable';
import type { Pressable } from './Button';

const MaybeTouchableOpacity = React.forwardRef<
	TouchableRef,
	Omit<TouchableProps, 'onPress' | 'id'> & Pressable & { onDoublePress?: () => unknown }
>(({ id, onPress, onDoublePress, ...props }, ref) => {
	const lastPress = React.useRef<number | undefined>();
	const timeout = React.useRef<number | undefined>();
	const handlePress = React.useMemo(() => {
		if (!onPress && !onDoublePress) return undefined;
		if (onPress && !onDoublePress) return () => onPress(id!);
		return () => {
			if (Date.now() - lastPress.current! < 300 && onDoublePress) {
				if (timeout.current) clearTimeout(timeout.current);
				onDoublePress();
			} else {
				lastPress.current = Date.now();
				timeout.current = setTimeout(() => {
					lastPress.current = undefined;
					if (onPress) onPress(id!);
				}, 300);
			}
		};
	}, [onDoublePress, onPress, id]);
	return <Touchable ref={ref} onPress={handlePress} {...props} />;
});

// @ts-ignore not sure what typescript is having a problem with
const Wrapper = styled(MaybeTouchableOpacity).attrs<
	{
		extendTouchableArea?: number;
		opacity: number;
		pixelSize: number;
		pixelPadding: [number, number, number, number];
		pixelMargin: [number, number, number, number];
		backgroundColor: string;
		el: number;
	},
	{ hitSlop?: { top: number; right: number; bottom: number; left: number } }
>((p) => ({
	hitSlop: p.extendTouchableArea
		? {
				top: p.extendTouchableArea,
				right: p.extendTouchableArea,
				bottom: p.extendTouchableArea,
				left: p.extendTouchableArea,
		  }
		: undefined,
}))`
	width: ${(p) => p.pixelSize + p.pixelPadding[0] * 2}px;
	height: ${(p) => p.pixelSize + p.pixelPadding[0] * 2}px;
	padding: ${(p) => p.pixelPadding.join('px ')}px;
	margin: ${(p) => p.pixelMargin.join('px ')}px;
	elevation: ${(p) => p.el};
	background-color: ${(p) => p.backgroundColor};
	opacity: ${(p) => p.opacity};
`;

const SVGWrapper = styled.View<{ rotation?: number }>`
	${(p) => (p.rotation ? `transform: rotate(${p.rotation}deg)` : '')};
`;

let gradIdCounter = 0;
export type IconProps = Pressable & {
	style?: ViewStyle;
	name: IconName;
	size?: string;
	color?: string;
	opacity?: number;
	gradient?: string;
	onDoublePress?: () => unknown;
	padding?: string;
	margin?: string;
	outline?: boolean;
	strokeWidth?: number;
	strokeLinecap?: 'butt' | 'square' | 'round';
	strokeLinejoin?: 'miter' | 'bevel' | 'round';
	rotation?: number;
	elevation?: number;
	extendTouchableArea?: number;
	backgroundColor?: string;
	testID?: string;
	accessible?: boolean;
	accessibilityRole?: AccessibilityRole;
	accessibilityLabel?: string;
};

const Icon = React.forwardRef<TouchableRef, IconProps>(
	(
		{
			id,
			style,
			name,
			size = '3rem',
			color,
			opacity = 1,
			gradient,
			onPress,
			onDoublePress,
			padding = '0rem',
			margin = '0rem',
			outline,
			strokeWidth = 1,
			strokeLinecap = 'round',
			strokeLinejoin = 'round',
			rotation,
			elevation = 0,
			accessible,
			accessibilityRole,
			accessibilityLabel,
			extendTouchableArea,
			backgroundColor = 'transparent',
			testID,
		}: IconProps,
		ref
	) => {
		const theme = useTheme();

		if (!theme.iconPaths[name]) {
			// eslint-disable-next-line no-console
			console.warn(`missing icon path '${name}'`);
			name = 'missingPicture';
		}
		const pathValue = theme.iconPaths[name];
		let path: string;
		if (typeof pathValue === 'object') {
			path = Platform.OS in pathValue ? pathValue[Platform.OS] : pathValue.default;
			if (!path) throw new Error(`icon ${name} not defined for ${Platform.OS} and no default`);
		} else {
			path = pathValue;
		}

		const gradId = React.useRef(String(gradIdCounter++)).current;

		const pixelSize = useLengthAttribute(size)[0];
		const pixelPadding = useLengthAttribute(padding);
		const pixelMargin = useLengthAttribute(margin);

		color = useColorAttribute(color || '$neutral0');

		return (
			<Wrapper
				ref={ref}
				id={id!}
				pixelMargin={pixelMargin}
				pixelSize={pixelSize}
				pixelPadding={pixelPadding}
				style={style}
				onPress={onPress}
				onDoublePress={onDoublePress}
				accessible={accessible}
				accessibilityRole={accessibilityRole}
				accessibilityLabel={accessibilityLabel}
				extendTouchableArea={extendTouchableArea}
				el={elevation}
				backgroundColor={backgroundColor}
				testID={testID}
				opacity={opacity}
			>
				<SVGWrapper rotation={rotation}>
					<Svg width={pixelSize} height={pixelSize} viewBox="0 0 24 24">
						{gradient ? <GradientDef id={gradId} colors={theme.colors[gradient]} /> : null}
						<Path
							fill={outline ? 'none' : gradient ? `url(#grad${gradId})` : color}
							stroke={outline ? color : 'none'}
							fillRule={outline ? 'nonzero' : 'evenodd'}
							d={path}
							strokeWidth={outline ? strokeWidth : 0}
							strokeLinecap={strokeLinecap}
							strokeLinejoin={strokeLinejoin}
						/>
					</Svg>
				</SVGWrapper>
			</Wrapper>
		);
	}
);

export default React.memo(Icon);
