import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

import useTextFieldContoller from '../utils/useTextFieldController';
import Button from './Button';
import ImageGallery from './ImageGallery';
import ImageBox from './ImageBox';
import SectionWrapper2 from './SectionWrapper2';
import Tabs, { TabItems } from './Tabs';
import CustomTextField from './TextField';
import Dialog from './Dialog';
import ImageGallerySimple from './ImageGallerySimple';
import { FALLBACK_BANNER } from '../utils/constants';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

// TODO: when enqueueeSnackbar workd again: ErrorCases need to be translated into unerstandable outputs for user. The the following tpyes and errorDictionary should be used:

// type DeleteImageError =
// 	| 'NETWORK_ERROR'
// 	| 'UNAUTHORIZED'
// 	| 'IMAGE_DOES_NOT_EXIST'
// 	| 'IMAGE_ID_DUPLICATION'
// 	| 'INVALID_REQUEST';

// const errorDictionary = {
// 	NETWORK_ERROR: 'Netzwerkfehler',
// 	UNAUTHORIZED: 'Sie sind nicht dazu berechtigt, diesen Befehl durchzuführen.',
// 	IMAGE_DOES_NOT_EXIST: 'Dieses Bild existiert nicht in unserer Datenbank.',
// 	IMAGE_ID_DUPLICATION: 'Von diesem Bild existiert ein Duplikat in der Datenbank.',
// 	INVALID_REQUEST: 'Diese Anfrage ist uns nicht bekannt.',
// };

const WebImageSelect = ({
	defaultUrl,
	onChange,
}: {
	defaultUrl?: string;
	onChange?: (value?: string) => void;
}): JSX.Element => {
	const { textInputProps: urlImageProps } = useTextFieldContoller({
		defaultValue: defaultUrl || FALLBACK_BANNER,
		inputLabel: 'Url',
		stateKey: 'urlImage',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
		onChange: (newImage) => {
			onChange?.(newImage || undefined);
		},
	});

	// if the user wants to keep the placeholderImage, onChange would not be called and the parent component would not be notified
	// therefore we need to call onChange with the placeholderImage upon first render
	React.useEffect(() => {
		if (!defaultUrl) {
			onChange?.(FALLBACK_BANNER);
		}
	}, [defaultUrl, onChange]);

	return (
		<Box p="4rem 2rem 2rem">
			<CustomTextField {...urlImageProps} variant="outlined" flex m="0 0 2rem 0" />
		</Box>
	);
};

export type ImageSourceType = 'URL' | 'UPLOADED' | 'PLACEHOLDER' | 'CONTEXT';
export type ImageSelectDialogTab = 'URL' | 'UPLOADED' | 'CONTEXT';

const ImageSelectDialog = ({
	onChange,
	defaultImageUrl,
	defaultTab,
	dismissPortal,
}: {
	onChange?: (imageUrl?: string) => void;
	defaultImageUrl?: string;
	defaultTab?: ImageSelectDialogTab;
	dismissPortal: () => void;
}): JSX.Element => {
	const [imageUrl, setImageUrl] = React.useState<string | undefined>(defaultImageUrl);

	const handleChangeImage = React.useCallback((newImageUrl?: string) => {
		setImageUrl(newImageUrl);
	}, []);

	const handleClose = React.useCallback(() => {
		dismissPortal();
	}, [dismissPortal]);

	const handleSave = React.useCallback(() => {
		onChange?.(imageUrl);
		handleClose();
	}, [onChange, imageUrl, handleClose]);

	const handleSaveOrPrepareSave = React.useCallback(() => {
		Dialog.render({
			title: 'Bildrechte',
			description:
				'Mit der Auswahl eines Bildes bestätigen Sie, dass Sie die Rechte an dem Bild haben.',
			buttons: [
				{
					id: '1',
					label: 'Abbrechen',
				},
				{
					id: '2',
					label: 'Bestätigen',
					onClick: handleSave,
					variant: 'mainButton',
				},
			],
		});
	}, [handleSave, imageUrl]);

	const tabs = React.useMemo(
		() =>
			[
				{
					label: 'WebImage',
					renderComponent: () => (
						<WebImageSelect defaultUrl={defaultImageUrl} onChange={handleChangeImage} />
					),
				},
				{
					label: 'Upload',
					renderComponent: () => (
						<ImageGallery onChange={handleChangeImage} defaultImageUrl={defaultImageUrl} />
					),
				},
			].filter(Boolean) as TabItems,
		[defaultImageUrl, handleChangeImage]
	);

	return (
		<Box minWidth="60rem" p="4rem">
			<SectionWrapper2 flexDirection="row" p="2rem" justifyContent="center" m="0 0 2rem">
				<ImageBox maxWidth="30rem" maxHeight="30rem" objectFit="contain" src={imageUrl} />
			</SectionWrapper2>
			<SectionWrapper2 m="0 0 2rem">
				<Tabs tabs={tabs} />
			</SectionWrapper2>
			<Row justifyContent="flex-end">
				<Button onClick={handleClose} m="0 1rem 0 0">
					Schließen
				</Button>
				<Button variant="mainButton" onClick={handleSaveOrPrepareSave}>
					Speichern
				</Button>
			</Row>
		</Box>
	);
};

export default ImageSelectDialog;
