import * as React from 'react';
import { sumBy } from 'lodash';
import BonusprogramSelectField from '../components/BonusprogramSelectField';
import styled from 'styled-native-components';
import { addMonths, addDays, format } from 'date-fns';

import { DateRangeField, Table, Paragraph, Heading } from '@cinuru/components';

import { default as gql } from 'graphql-tag';

import WithData from '../components/WithData';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
`;

const DescriptionWrapper = styled.View`
	margin-bottom: 4rem;
`;

const SECTION_MARGINS = '4rem 0rem 2rem';
const FIELD_MARGINS = [2, -0.5];
const TABLE_MARGINS = [0, -0.5];

const encodeDate = (date) => format(date, 'yyyy-MM-dd');

export default class StatisticsBonusProgram extends React.Component {
	state = {
		bonusProgramIds: [],
		dateRange: { from: encodeDate(addMonths(new Date(), -1)), to: encodeDate(new Date()) },
	};

	selectDateRange = (dateRange) => this.setState({ dateRange });
	handleSelectBonusprograms = (bonusProgramIds) => this.setState({ bonusProgramIds });

	render = () => (
		<Wrapper>
			<DescriptionWrapper>
				<Heading size="l" margin="4rem 0rem 1rem">
					Statistiken Bonusprogramm
				</Heading>
				<Paragraph>
					Wie viele Punkte wurden in meinem Kino gebucht? Wie viele Prämien eingelöst? Laden Sie
					sich die Bonusprogramm Auswertungen als PDF für Ihre Buchhaltung herunter und behalten Sie
					den Erfolg Ihres Bonusprogramms im Blick.
				</Paragraph>
			</DescriptionWrapper>
			<DateRangeField
				nMargins={FIELD_MARGINS}
				onChange={this.selectDateRange}
				value={this.state.dateRange}
				label="Zeitraum wählen"
			/>
			<BonusprogramSelectField
				onChange={this.handleSelectBonusprograms}
				nMargins={FIELD_MARGINS}
				multi={true}
			/>
			<WithData
				query={gql`
					query BonusProgramStatistics($bonusProgramIds: [ID!]!, $from: Date!, $to: Date!) {
						bonusPrograms(ids: $bonusProgramIds) {
							name
							statistics(from: $from, to: $to) {
								numBpBookingsInInterval
								numBpBookingsTotal
								numNewUsersInInterval
								numNewUsersTotal
								numUsersInInterval
								numUsersTotal
								scannedVouchersInInterval
								scannedVouchersTotal
								sumBonusPointsInInterval
								sumBonusPointsTotal
								sumBonusPointsSupportInInterval
								sumBonusPointsSupportTotal
								numUsersMultipleBookingsTotal
								redeemedVouchersInInterval {
									id
									name
									redeemedAt
									amountPoints
								}
							}
						}
					}
				`}
				// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
				variables={{
					bonusProgramIds: this.state.bonusProgramIds,
					from: this.state.dateRange.from || addDays(new Date(), 1),
					to: this.state.dateRange.to || addDays(new Date(), 1),
				}}
			>
				{({ bonusPrograms }) => {
					const redeemedVouchers = bonusPrograms.reduce(
						(sums, { statistics }) => [
							...sums,
							...((statistics && statistics.redeemedVouchersInInterval) || []),
						],
						[]
					);
					const usageStatistics = [
						{
							id: 1,
							category: 'Anzahl Bonuspunkte-Buchungen',
							categoryTooltip:
								'Anzahl der erfolgten Punkte-Buchungen (mehrfach pro Teilnehmer*in möglich).',
							interval: sumBy(bonusPrograms, 'statistics.numBpBookingsInInterval'),
							overall: sumBy(bonusPrograms, 'statistics.numBpBookingsTotal'),
						},
						{
							id: 2,
							category: 'Aktive Teilnehmer*innen',
							categoryTooltip: 'Anzahl der Teilnehmer*innen, die Bonuspunkte gesammelt haben.',
							interval: sumBy(bonusPrograms, 'statistics.numUsersInInterval'),
							overall: sumBy(bonusPrograms, 'statistics.numUsersTotal'),
						},
						{
							id: 3,
							category: 'Neue Teilnehmer*innen',
							categoryTooltip:
								'Summe der Teilnehmer*innen, die zum ersten Mal Bonuspunkte gesammelt haben.',
							interval: sumBy(bonusPrograms, 'statistics.numNewUsersInInterval'),
							overall: sumBy(bonusPrograms, 'statistics.numNewUsersTotal'),
						},
						{
							id: 4,
							category: 'Wiederkehrende Teilnehmer*innen',
							categoryTooltip:
								'Summe der Teilnehmer*innen, die mindestens ein zweites Mal Bonuspunkte gesammelt haben. Diese Statistik wird nur für den Gesamtzeitraum angezeigt.',
							interval: 'nicht anwendbar',
							overall: sumBy(bonusPrograms, 'statistics.numUsersMultipleBookingsTotal'),
						},
						{
							id: 5,
							category: 'Gescannte Gutscheine',
							categoryTooltip:
								'Wenn etwa 10% der an der Kasse herausgegebenen Gutscheine gescannt werden, ist dies ein guter Richtwert!',
							interval: sumBy(bonusPrograms, 'statistics.scannedVouchersInInterval'),
							overall: sumBy(bonusPrograms, 'statistics.scannedVouchersTotal'),
						},
						{
							id: 6,
							category: 'Insgesamt vergebene Bonuspunkte',
							categoryTooltip:
								'Summe aller sich im Umlauf befindlichen bzw. im gewählten Zeitraum vergebenen Bonuspunkte.',
							interval: sumBy(bonusPrograms, 'statistics.sumBonusPointsInInterval'),
							overall: sumBy(bonusPrograms, 'statistics.sumBonusPointsTotal'),
						},
						{
							id: 7,
							category: 'Im Rahmen des Support vergebene Bonuspunkte',
							categoryTooltip:
								'Der Cinuru Support kann im Einzelfall für wertvolle Fehlermeldungen oder als Entschädigung für Fehlfunktionen Bonuspunkte direkt an Teilnehmer*innen vergeben.',
							interval: sumBy(bonusPrograms, 'statistics.sumBonusPointsSupportInInterval'),
							overall: sumBy(bonusPrograms, 'statistics.sumBonusPointsSupportTotal'),
						},
					];

					return (
						<React.Fragment>
							<Heading size="xs" margin={SECTION_MARGINS}>
								ÜBERSICHT
							</Heading>
							<Table
								nMargins={TABLE_MARGINS}
								// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
								columns={[
									{ label: 'Statistik', key: 'category', tooltipKey: 'categoryTooltip', flex: 4 },
									{ label: 'im Zeitraum', key: 'interval' },
									{ label: 'Gesamt', key: 'overall' },
								]}
								data={usageStatistics}
								pagination={false}
							/>
							<Heading size="s" margin={SECTION_MARGINS}>
								EINGELÖSTE PRÄMIEN
							</Heading>
							{redeemedVouchers && redeemedVouchers.length ? (
								<Table
									nMargins={TABLE_MARGINS}
									// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
									columns={[
										{ label: 'Gutschein', key: 'name', flex: 4 },
										{
											label: 'Eingelöst am',
											key: 'redeemedAt',
											align: 'right',
											format: (d) => d && format(new Date(d), 'dd.MM'),
										},
										{ label: 'Punkte', key: 'amountPoints' },
									]}
									data={redeemedVouchers}
									pagination={false}
								/>
							) : (
								<Paragraph>Es wurden in diesem Zeitraum keine Prämien eingelöst</Paragraph>
							)}
						</React.Fragment>
					);
				}}
			</WithData>
		</Wrapper>
	);
}
