import './polyfill';

import * as React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';

import App from './App';
import client from './apollo';
import * as analytics from './services/analytics';

analytics.setClient(client);
const history = createBrowserHistory();
analytics.connectHistory(history);

ReactDOM.render(
	<ApolloProvider client={client}>
		<Router history={history}>
			<App />
		</Router>
	</ApolloProvider>,
	document.getElementById('root')
);
