import styled from 'styled-native-components';
import { Platform } from 'react-native';

const Heading = styled.Text<{
	color?: string;
	margin?: string;
	size?: 'xs' | 's' | 'm' | 'l' | 'xl';
	align?: 'center' | 'left' | 'right';
	textDecoration?: 'underline' | 'line-through' | 'none';
	flex?: boolean;
	condensed?: boolean;
}>`
	color: ${(p) => p.color || '$neutral0'};
	margin: ${(p) => p.margin || '0'};
	font: ${(p) => p.theme.typography.heading(p.size || 'm', p.condensed).css};
	${(p) => (p.flex ? 'flex: 1' : '')};
	text-align: ${(p) => p.align || 'left'};
	text-align-vertical: center;
	${Platform.OS === 'android' ? 'include-font-padding: false' : ''};
	text-decoration: ${(p) =>
		p.textDecoration ? `${p.textDecoration} ${p.color || '$neutral0'}` : 'none'};
	letter-spacing: ${(p) =>
		p.condensed && Platform.OS === 'ios'
			? { xs: '-0.0px', s: '-0.2px', m: '-0.4px' }[p.size || 'm']
			: 0};
`;

export default Heading;
