import * as React from 'react';
import { Typography, Select, MenuItem } from '@mui/material';
import { times } from 'lodash';
import FormControlWrapper from './FormControlWrapper';

type ItemType = { label: string; value: string };

const decodePropsValue = (propsValue?: number): string | '' =>
	typeof propsValue === 'number' ? propsValue.toString() : '';

const encodeStateValue = (stateValue: string | ''): number | undefined =>
	stateValue === '' ? undefined : Number(stateValue);

export type IntegerSelectFieldRef = {
	validate: () => boolean;
};

const IntegerSelectField = React.forwardRef<
	IntegerSelectFieldRef,
	{
		label: string;
		disabled?: boolean;
		onChange?: (value?: number) => void;
		defaultValue?: number;
		margin?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		flex?: boolean;
		nothingSelectedLabel?: string;
		width?: string;
	}
>(
	(
		{
			label = 'No label provided',
			disabled,
			onChange,
			defaultValue,
			margin,
			variant,
			flex,
			nothingSelectedLabel = '-',
			width,
		},
		ref
	): JSX.Element => {
		const items: ItemType[] = React.useMemo(
			() => [
				{ label: nothingSelectedLabel, value: '' },
				...times(100).map((n) => ({ label: String(n), value: String(n) })),
			],
			[nothingSelectedLabel]
		);
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();

		const [selectedValue, setSelectedValue] = React.useState<string | ''>(
			decodePropsValue(defaultValue)
		);

		const handleChange = React.useCallback(
			(event) => {
				setErrorMessage(undefined);
				const newValue = event?.target?.value;
				const sanitizedNewValue = typeof newValue === 'string' ? newValue : '';
				setSelectedValue(sanitizedNewValue);
				onChange?.(encodeStateValue(sanitizedNewValue));
			},
			[onChange]
		);

		const handleValidate = React.useCallback(() => {
			let invalid = false;
			if (!disabled && !selectedValue) {
				invalid = true;
				setErrorMessage('Bitte wählen');
			}

			return invalid;
		}, [disabled, selectedValue]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		const renderValue = React.useCallback(
			(value: string | '') => {
				const newLabel = items.find((item) => item.value === value)?.label;
				return <Typography color="textPrimary">{newLabel}</Typography>;
			},
			[items]
		);

		React.useEffect(() => {
			if (disabled) {
				setErrorMessage(undefined);
			}
		}, [disabled]);

		return (
			<FormControlWrapper
				flex={flex}
				m={margin}
				errorMessage={errorMessage}
				variant={variant}
				label={label}
				disabled={disabled}
				width={width}
			>
				<Select
					value={selectedValue}
					onChange={handleChange}
					renderValue={renderValue}
					disabled={disabled}
					variant={variant}
					label={label}
				>
					{items.map((item) => (
						<MenuItem key={item.value} value={item.value}>
							{item.label}
						</MenuItem>
					))}
				</Select>
			</FormControlWrapper>
		);
	}
);

export default React.memo(IntegerSelectField);
