import * as React from 'react';
import styled from 'styled-components';
import { Box, FormControl, InputLabel as MuiInputLabel } from '@mui/material';
const InputLabel = styled(MuiInputLabel)<{ $error?: boolean; $isHighlighted?: boolean }>`
	color: ${(p) =>
		p.$error ? p.theme.palette.error.main : p.$isHighlighted ? p.theme.palette.primary.main : ''};
	background-color: ${(p) => p.theme.palette.common.white};
	padding: 0 0.5rem;
`;

const Wrapper = styled(Box)`
	display: flex;
	width: 100%;
`;

const ChildrenWrapper = styled(Box)<{ $isHighlighted?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	border: 1px solid
		${(p) => (p.$isHighlighted ? p.theme.palette.primary.main : p.theme.palette.grey[400])};
	border-radius: 5px;
	min-height: 60px;
`;

// a component that mimics the style of an outlined Material-UI TextField
const PseudoOutlinedTextFieldWrapper = ({
	label,
	disabled,
	children,
	p,
	m,
	justifyContent,
	isHighlighted,
}: {
	label?: string;
	disabled?: boolean;
	children: JSX.Element;
	p?: string;
	m?: string;
	justifyContent?: string;
	isHighlighted?: boolean;
}): JSX.Element => {
	return (
		<Wrapper m={m}>
			<FormControl variant="outlined" fullWidth disabled={disabled}>
				{label ? (
					<InputLabel shrink $isHighlighted={!disabled && isHighlighted}>
						{label}
					</InputLabel>
				) : null}

				<ChildrenWrapper
					p={p}
					$isHighlighted={!disabled && isHighlighted}
					justifyContent={justifyContent}
				>
					{children}
				</ChildrenWrapper>
			</FormControl>
		</Wrapper>
	);
};

export default React.memo(PseudoOutlinedTextFieldWrapper);
