import React from 'react';
import styled from 'styled-components';
import { Brand } from '@cinuru/utils/theme';
import { MarkdownTextWithImageProps } from '@cinuru/emails/src/components/DashboardEmailComponents/MarkdownTextWithImage';

import {
	stripValueFromDimensionObject,
	transformElement,
} from '../utils/emailConfigurationHelpers';
import ApiRenderedComponent from './ApiRenderedComponent';
import { ColorController, TextEditorController } from '../utils/allFormControlComponents';
import { MarkdownTextWithImageData } from '../../../utils/campaignEmailContent';

import TextController from './FormControls/TextController';
import SelectController from './FormControls/SelectController';
import CheckBoxController from './FormControls/CheckBoxController';
import SizeController from './FormControls/SizeController';
import ImageSelectButton from '../../../components/ImageSelectButton';
import ExpandableContents from '../../../components/ExpandableContents';

const Wrapper = styled.div`
	display: flex;
	width: 100%;
`;

const MarkdownTextWithImagePreview = ({
	id,
	element,
	brand,
}: {
	id: string;
	element: MarkdownTextWithImageData;
	brand: Brand;
}) => {
	const componentProps: MarkdownTextWithImageProps = React.useMemo(
		() => stripValueFromDimensionObject(element) as MarkdownTextWithImageProps,
		[element]
	);
	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="MarkdownTextWithImage"
			componentProps={componentProps}
		/>
	);
};

export const MarkdownTextWithImageEditor = React.memo(
	({
		element,
		updateElement,
		brand,
	}: {
		element: MarkdownTextWithImageData;
		updateElement: (markdownTextWithImageData) => void;
		brand: Brand;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement(newElement as MarkdownTextWithImageData);
			},
			[element, updateElement]
		);

		const handleChangeImage = React.useCallback(
			(imageUrl?: string | null) => {
				updateElement({ ...element, image: { ...element.image, imageUrl } });
			},
			[element, updateElement]
		);

		const contents = React.useMemo(
			() => [
				{
					label: 'Allgemeine Einstellungen',
					items: [
						<SelectController
							key="1"
							type="IMAGE_ORIENTATION"
							value={element.orientation}
							onChange={handleChangeController}
							path="orientation"
						/>,
						<ColorController
							key="2"
							value={element.backgroundColor}
							brand={brand}
							onChange={handleChangeController}
							path="backgroundColor"
							label="Hintergrundfarbe"
							fallBackColor="background0"
						/>,
					],
				},
				{
					label: 'Bildeinstellungen',
					items: [
						<ImageSelectButton
							key="3"
							onChange={handleChangeImage}
							defaultImageUrl={element.image.imageUrl}
						/>,
						<SizeController
							key="4"
							type="WIDTH"
							value={element.image.width}
							onChange={handleChangeController}
							path="image/width"
						/>,
						<SizeController
							key="5"
							type="BORDER_RADIUS"
							value={element.image.borderRadius}
							onChange={handleChangeController}
							path="image/borderRadius"
						/>,
						<CheckBoxController
							key="6"
							type="SHADOW"
							value={element.image.shadow}
							onChange={handleChangeController}
							path="image/shadow"
						/>,
						<TextController
							key="7"
							type="ALT"
							value={element.image.altText}
							onChange={handleChangeController}
							path="image/altText"
							toolTip="Wird innerhalb der Mail nicht angzeigt, hilft Endgeräten jedoch, den Inhalt der Bildes barrierefrei wiederzugeben (z.B. Sprachausgabe für Nutzer mit Sehschwäche)."
						/>,
					],
				},
				{
					label: 'Texteinstellungen',
					items: [
						<TextEditorController
							key="8"
							id={`markdown_text_with_image_${element.key}`}
							value={element?.text?.text || ''}
							onChange={handleChangeController}
							path="text/text"
						/>,
						<SelectController
							key="9"
							type="FONT_FAMILY"
							value={element.text.family}
							onChange={handleChangeController}
							path="text/family"
						/>,
						<SelectController
							key="10"
							type="FONT_SIZE"
							value={element.text.size}
							onChange={handleChangeController}
							path="text/size"
						/>,
						<SelectController
							key="11"
							type="FONT_ALIGN"
							value={element.text.align}
							onChange={handleChangeController}
							path="text/align"
						/>,
						<ColorController
							key="12"
							value={element.text.color}
							brand={brand}
							onChange={handleChangeController}
							path="text/color"
							label="Schriftfarbe"
							fallBackColor="neutral0"
						/>,
					],
				},
			],
			[
				element.backgroundColor,
				element.image.altText,
				element.image.borderRadius,
				element.image.imageUrl,
				element.image.shadow,
				element.image.width,
				element.orientation,
				handleChangeController,
				handleChangeImage,
				element.key,
				element.text.align,
				element.text.color,
				element.text.family,
				element.text.size,
				element.text?.text,
				brand,
			]
		);

		return (
			<Wrapper>
				<ExpandableContents
					width="100%"
					contents={contents}
					itemMargin="2rem 0 1rem"
					expandableMargin="1rem 0"
				/>
			</Wrapper>
		);
	}
);

export const MarkdownTextWithImageDefaultParams: MarkdownTextWithImageData = {
	type: 'TEXTWITHIMAGE',
	orientation: 'left',
	image: {
		imageUrl: 'https://static.cinuru.com/public/dashboard/placeholder.jpg',
		width: '300px',
		shadow: true,
	},
	text: {
		textType: 'PARAGRAPH',
		text:
			'(Platzhalter) Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis quam nec velit scelerisque placerat. Aliquam blandit erat in felis malesuada sagittis. Fusce gravida purus sed maximus imperdiet. Donec pellentesque, diam quis dictum interdum, mi mi volutpat tortor, quis sodales sapien nulla vel neque. Etiam volutpat, elit laoreet mattis porta, diam diam aliquet massa, vel finibus nisi tellus a massa. Nullam ornare porta magna et sagittis. Donec tempus, sem a tincidunt ornare, diam dolor porta odio, id maximus ex massa non tellus. Nulla id nibh odio. Vestibulum fermentum vestibulum magna ac laoreet. Duis at faucibus augue, a consequat lorem. Nulla non leo odio. Donec risus enim, auctor ut pharetra at, facilisis ac neque.',
	},
	index: 0,
	borderRadiusWrapper: { top: true, bottom: true },
};

export default React.memo(MarkdownTextWithImagePreview);
