import React from 'react';
import { Row, Col, Input } from '../../components/AntDReplacements';

const styles = {
	wrapper: {
		marginTop: '20px',
		marginBottom: '40px',
	},
};

// TODO: Cleanup this styles mess
export const ContactsSection = ({ cinema, updateContact }) => (
	<div style={styles.wrapper}>
		<Row>
			<Col>Kontakt + Social Media</Col>
		</Row>
		<Row style={{ margin: '10px 0' }}>
			<Col span={8}>Facebook</Col>
			<Col span={14}>
				<Input
					value={cinema.facebook}
					placeholder="Facebook..."
					onChange={(event) => updateContact('facebook', event.target.value)}
				/>
			</Col>
			<Col span={8}>Twitter</Col>
			<Col span={14}>
				<Input
					value={cinema.twitter}
					placeholder="Twitter."
					onChange={(event) => updateContact('twitter', event.target.value)}
				/>
			</Col>
			<Col span={8}>Website</Col>
			<Col span={14}>
				<Input
					value={cinema.website}
					placeholder="Wesite..."
					onChange={(event) => updateContact('website', event.target.value)}
				/>
			</Col>
			<Col span={8}>Telefon</Col>
			<Col span={14}>
				<Input
					value={cinema.phone}
					placeholder="Telefonnummer..."
					onChange={(event) => updateContact('phone', event.target.value)}
				/>
			</Col>
			<Col span={8}>E-mail</Col>
			<Col span={14}>
				<Input
					value={cinema.email}
					placeholder="E-mail..."
					onChange={(event) => updateContact('email', event.target.value)}
				/>
			</Col>
			<Col span={8}>Instagram</Col>
			<Col span={14}>
				<Input
					value={cinema.instagram}
					placeholder="Instagram..."
					onChange={(event) => updateContact('instagram', event.target.value)}
				/>
			</Col>
		</Row>
	</div>
);
