type ApiEndPoint =
	| 'http://localhost:3000'
	| 'https://api.cinuru.com'
	| 'https://devapi.cinuru.com/dev';
type FileServerEndPoint = 'http://localhost:3006' | 'https://static.cinuru.com';
type Feature = 'NEW_CAMPAIGNING' | 'NEW_STATISTICS' | 'NEW_EDIT_CINEMA' | 'TARGETGROUP_EDIT';

export const API_ENDPOINT: ApiEndPoint = 'https://api.cinuru.com';
export const FILE_SERVER_ENDPOINT: FileServerEndPoint = 'https://static.cinuru.com';
//export const FEATURES: Feature[] = ['NEW_EDIT_CINEMA'];

//export const API_ENDPOINT: ApiEndPoint = 'http://localhost:3000';
// export const FILE_SERVER_ENDPOINT: FileServerEndPoint = 'http://localhost:3006';
 export const FEATURES: Feature[] = ['NEW_EDIT_CINEMA', 'NEW_CAMPAIGNING', 'TARGETGROUP_EDIT'];

console.log('API_ENDPOINT', API_ENDPOINT);
