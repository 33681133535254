import styled from 'styled-native-components';
import { Platform } from 'react-native';

const Paragraph = styled.Text<{
	color?: string;
	margin?: string;
	size?: 's' | 'm' | 'l';
	align?: 'center' | 'left' | 'right';
	textDecoration?: 'underline' | 'line-through' | 'none';
	flex?: boolean;
	bold?: boolean;
	width?: string;
	height?: string;
}>`
	font: ${(p) => p.theme.typography.paragraph(p.size || 'm', p.bold).css};
	color: ${(p) => p.color || '$neutral0'};
	margin: ${(p) => p.margin || '0'};
	text-align: ${(p) => p.align || 'left'};
	${(p) => (p.width ? `width: ${p.width}` : '')};
	${(p) => (p.height ? `height: ${p.height}` : '')};
	${(p) => (p.flex ? 'flex: 1' : '')};
`;

export const ParagraphInput = styled.TextInput.attrs<
	{
		color?: string;
		size?: 's' | 'm' | 'l';
		align?: 'center' | 'left' | 'right';
		flex?: boolean;
	},
	{ underlineColorAndroid: string }
>({
	underlineColorAndroid: 'transparent',
})`
	font-size: ${(p) => p.theme.typography.fontSizes[p.size === 'l' ? 5 : 3]}rem;
	${Platform.OS === 'ios' ? '' : 'line-height: 3rem'};
	color: ${(p) => p.color || '$neutral0'};
	text-align: ${(p) => p.align || 'left'};
	${(p) => (p.flex ? 'flex: 1' : '')};
`;

export default Paragraph;
