import React, { Component } from 'react';
import styled from 'styled-native-components';
import { addMonths, addDays, format } from 'date-fns';

import { DateRangeField, Table, Article, Heading, Button } from '@cinuru/components';

import CinemaSelectField from '../components/CinemaSelectField';

import { default as gql } from 'graphql-tag';

import WithData from '../components/WithData';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
`;

const DescriptionWrapper = styled.View`
	margin-bottom: 4rem;
`;

const FIELD_MARGINS = [2, -0.5];
const TABLE_MARGINS = [0, -0.5, 3, -0.5];

const encodeDate = (date) => format(date, 'yyyy-MM-dd');

export default class StatisticsUsage extends Component {
	state = {
		cinemaIds: [],
		dateRange: { from: encodeDate(addMonths(new Date(), -1)), to: encodeDate(new Date()) },
	};

	selectDateRange = (dateRange) => this.setState({ dateRange });
	handleSelectCinemas = (cinemaIds) => this.setState({ cinemaIds });

	render = () => (
		<Wrapper>
			<DescriptionWrapper>
				<Heading size="l" margin="4rem 0rem 1rem">
					Statistiken App-Nutzung
				</Heading>
			</DescriptionWrapper>
			<DateRangeField
				nMargins={FIELD_MARGINS}
				onChange={this.selectDateRange}
				value={this.state.dateRange}
				label="Zeitraum wählen"
			/>
			<CinemaSelectField nMargins={FIELD_MARGINS} onChange={this.handleSelectCinemas} multi />
			<WithData
				query={gql`
					query usageStatistics($cinemaIds: [ID!]!, $from: Date!, $to: Date!) {
						usageStatistics(cinemaIds: $cinemaIds) {
							id
							sentMessagesInterval: sentMessagesInInterval(from: $from, to: $to) {
								numberOfCountedMessages
								numberOfFreeMessages
							}
							totalSentMessages: sentMessagesInInterval(to: $to) {
								numberOfCountedMessages
								numberOfFreeMessages
							}
							installsInterval: installationsInInterval(from: $from, to: $to)
							totalInstalls: installationsInInterval(to: $to)
							activeInInterval: activeUsersInInterval(from: $from, to: $to)
							activeTotal: activeUsersInInterval(to: $to)
							watchlistUsedInInterval: watchlistUsedInInterval(from: $from, to: $to)
							watchllistUsedTotal: watchlistUsedInInterval(to: $to)
						}
						cinemas(ids: $cinemaIds) {
							hasDynamicPricing
						}
					}
				`}
				// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
				variables={{
					cinemaIds: this.state.cinemaIds,
					from: this.state.dateRange.from || addDays(new Date(), 1),
					to: this.state.dateRange.to || addDays(new Date(), 1),
				}}
			>
				{({ usageStatistics, cinemas }) => {
					// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
					const statistics = [
						{
							id: 1,
							category: 'Neue Nutzer*innen',
							categoryTooltip:
								'Anzahl der Nutzer*innen, die die App im gewählten Zeitraum erstmalig geöffnet haben.',
							interval: usageStatistics.installsInterval,
							overall: usageStatistics.totalInstalls,
						},
						{
							id: 2,
							category: 'Aktive Nutzer*innen',
							categoryTooltip:
								'Anzahl aller Nutzer*innen, die die App im gewählten Zeitraum geöffnet haben.',
							interval: usageStatistics.activeInInterval,
							overall: usageStatistics.activeTotal,
						},
						{
							id: 3,
							category: 'Merklisten-Nutzer*innen',
							categoryTooltip:
								'Anzahl der Nutzer*innen, die im gewählten Zeitraum mindestens einen Film auf die Merkliste gesetzt haben.',
							interval: usageStatistics.watchlistUsedInInterval,
							overall: usageStatistics.watchllistUsedTotal,
						},
						{
							id: 4,
							category: 'Vom Kino versandte Nachrichten',
							categoryTooltip: `Automatisch zum Filmstart versandte Erinnerungen werden nicht mitgezählt`,
							interval: usageStatistics.sentMessagesInterval.numberOfCountedMessages,
							overall: usageStatistics.totalSentMessages.numberOfCountedMessages,
						},
						{
							id: 5,
							category: 'Automatisch zum Filmstart versandte Erinnerungen',
							categoryTooltip: `Werden unter "Vom Kino versandte Nachrichten" nicht mitgezählt`,
							interval: usageStatistics.sentMessagesInterval.numberOfFreeMessages,
							overall: usageStatistics.totalSentMessages.numberOfFreeMessages,
						},
					];
					return (
						<React.Fragment>
							<Heading size="s" margin="4rem 0rem 2rem">
								ÜBERSICHT
							</Heading>
							<Table
								nMargins={TABLE_MARGINS}
								// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
								columns={[
									{ label: 'Statistik', key: 'category', tooltipKey: 'categoryTooltip', flex: 4 },
									{ label: 'im Zeitraum', key: 'interval' },
									{ label: 'Gesamt', key: 'overall' },
								]}
								data={statistics}
								pagination={false}
							/>
							{cinemas && cinemas.some((cinema) => cinema.hasDynamicPricing) ? (
								<Button
									href="https://static.cinuru.com/public/assets/qipnhOknlJvw0aNQL7IZ.pdf"
									label="Preisliste öffnen"
									link
								/>
							) : null}
						</React.Fragment>
					);
				}}
			</WithData>
		</Wrapper>
	);
}
