import Row_ from 'antd/lib/row';
import 'antd/lib/row/style/css';

import Col_ from 'antd/lib/col';
import 'antd/lib/col/style/css';

import Radio_ from 'antd/lib/radio';
import 'antd/lib/radio/style/css';

import Card_ from 'antd/lib/card';
import 'antd/lib/card/style/css';

import Input_ from 'antd/lib/input';
import 'antd/lib/input/style/css';

import Checkbox_ from 'antd/lib/checkbox';
import 'antd/lib/checkbox/style/css';

import message_ from 'antd/lib/message';
import 'antd/lib/message/style/css';

import Button_ from 'antd/lib/button';
import 'antd/lib/button/style/css';

export const Row = Row_;
export const Col = Col_;
export const Radio = Radio_;
export const Card = Card_;
export const Input = Input_;
export const Checkbox = Checkbox_;
export const message = message_;
export const Button = Button_;
