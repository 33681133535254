import React from 'react';
import styled from 'styled-components';

import { Box, FormControl, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { hexToRgba } from '../utils/color';
import { deleteImage, Image, useUploadedImages } from '../utils/image';

import Divider from './Divider';
import CustomizedButton from './Button';
import LoadingIndicator from './LoadingIndicator';
import { ID } from 'utils/types';
import UploadImageButton from './UploadImageButton';
import LoadingBox from './LoadingBox';

const StyledImageListItem = styled(ImageListItem)`
	flex-wrap: wrap;
	justify-content: space-evenly;
	height: unset !important;

	&:hover {
		> div.imageButtonBox {
			border-color: ${(props) => props.theme.palette.primary.light};
			transition-duration: 0.7s;
		}
	}
`;

const StyledImageList = styled(ImageList)`
	justify-content: space-between;
	max-height: 50rem;
	overflow: auto;
`;

const StyledImageListItemBar = styled(ImageListItemBar)`
	background: linear-gradient(
		to bottom,
		${(props) => {
			const black = props.theme.palette.common.black;
			return `${hexToRgba(black, 0.5)} 0%, ${hexToRgba(black, 0.8)} 70%, ${hexToRgba(
				black,
				0
			)} 100%`;
		}}
	);
	border-radius: 1.5rem 1.5rem 0 0;
	padding: 0.5rem 0.5rem 0 0.5rem;
	border: 0.5rem solid ${(props) => props.theme.palette.primary.light};
	border-bottom: none;
	opacity: 0;
	transition-duration: 0.7s;
	&:hover {
		opacity: 1;
	}
`;

const StyledFormControl = styled(FormControl)`
	width: 100%;
`;

const HEIGHT = 20;
const BORDER_STYLE = '0.5rem solid';
const BORDER_RADIUS = 1.5;

const ImageButtonBox = styled(Box)<{ $url?: string; $selected?: boolean }>`
	height: ${HEIGHT}rem;
	border: ${(props) =>
		props.$selected
			? `${BORDER_STYLE} ${props.theme.palette.primary.light}`
			: `${BORDER_STYLE}  ${props.theme.palette.grey[200]}`};
	background-image: url(${(props) => props.$url}), linear-gradient(to bottom, #ffffffcc, #ffffffcc),
		url(${(props) => props.$url});
	background-repeat: no-repeat;
	background-size: contain, contain, 300%;
	background-position: center, center, center;
	border-radius: ${BORDER_RADIUS}rem;
	&:active {
		opacity: 0.5;
	}
`;

const StyledBox = styled(Box)`
	border: ${BORDER_STYLE} ${(p) => p.theme.customColors.lightGrey};
	border-radius: ${BORDER_RADIUS}rem;
	height: ${HEIGHT}rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const PseudoImagePlaceHolder = ({ children }: { children?: JSX.Element | null }) => {
	return <StyledBox>{children}</StyledBox>;
};

const ImageInnerWrapper = ({
	children,
	image,
	imageSelected,
	onClick,
}: {
	children?: JSX.Element | JSX.Element[] | undefined;
	image: Image;
	imageSelected: boolean;
	onClick: (image: Image) => void;
}) => {
	const handleSelect = React.useCallback(() => {
		onClick(image);
	}, [onClick, image]);

	return (
		<ImageButtonBox
			className="imageButtonBox"
			$url={image.url}
			$selected={imageSelected}
			onClick={handleSelect}
		>
			{children}
		</ImageButtonBox>
	);
};

const StyledTrashbinButton = styled(CustomizedButton)`
	color: ${(props) => props.theme.palette.common.white};
	margin-bottom: 2rem;
	&:hover {
		color: ${(props) => props.theme.palette.primary.light};
	}
`;

const CircularProgressItem = () => {
	return (
		<StyledImageListItem className="imageListItem" key="CircularInProgress">
			<LoadingIndicator />
		</StyledImageListItem>
	);
};

const ImageGallery = ({
	onChange,
	defaultImageUrl,
}: {
	onChange: (url?: string) => void;
	defaultImageUrl?: string;
}): JSX.Element | null => {
	const uploadedImages = useUploadedImages();

	const [selectedImageId, setSelectedImageId] = React.useState<ID>(
		uploadedImages?.find((i) => i.url === defaultImageUrl)?.id || ''
	);
	const [imageUploadInitialized, setImageUploadInitialized] = React.useState<boolean>(false);
	const [imageAboutToBeDeleted, setImageAboutToBeDeleted] = React.useState<ID | undefined>(
		undefined
	);

	const handleImageUploadStart = React.useCallback(() => {
		setImageUploadInitialized(true);
	}, []);

	const handleSelectImage = React.useCallback(
		(image: Image) => {
			setSelectedImageId(image.id);
			onChange(image.url);
			setImageUploadInitialized(false);
		},
		[onChange]
	);

	const handleImageUpload = React.useCallback(
		(image: Image) => {
			handleSelectImage(image);
		},
		[handleSelectImage]
	);

	const handleDelete = React.useCallback(
		async (id: ID) => {
			setImageAboutToBeDeleted(id);
			// TODO: lock dialog or somehow prevent memory leak when user closes dialog before deletion has completed -> issue
			const { error } = await deleteImage({ id });
			if (!error) {
				// enqueueSnackbar('Bild gelöscht.', { variant: 'success' });
				setImageAboutToBeDeleted(undefined);
				if (id === selectedImageId) {
					onChange && onChange();
				}
			} else {
				// enqueueSnackbar(`Fehler beim Speichern. ${errorDictionary[error]}`, { variant: 'error' });
			}
		},
		[selectedImageId, onChange]
	);

	return uploadedImages ? (
		<>
			<Divider m="0" />
			<Box margin="2rem">
				<StyledFormControl>
					<StyledImageList cols={3} rowHeight={172}>
						{imageUploadInitialized ? <CircularProgressItem key={selectedImageId} /> : null}

						<PseudoImagePlaceHolder>
							<UploadImageButton
								onUploadStart={handleImageUploadStart}
								onUploaded={handleImageUpload}
							/>
						</PseudoImagePlaceHolder>
						{uploadedImages.map((image, i) =>
							imageAboutToBeDeleted === image.id ? (
								<CircularProgressItem key={image.id} />
							) : (
								<StyledImageListItem className="imageListItem" key={image.id}>
									<ImageInnerWrapper
										onClick={handleSelectImage}
										image={image}
										imageSelected={image.id === selectedImageId}
									/>
									<StyledImageListItemBar
										className="imageListItemBar"
										position="top"
										actionIcon={
											<StyledTrashbinButton
												aria-label="delete"
												onClick={handleDelete}
												id={image.id as string}
												startIcon={<DeleteIcon />}
											/>
										}
										actionPosition="left"
									/>
								</StyledImageListItem>
							)
						)}
					</StyledImageList>
				</StyledFormControl>
			</Box>
		</>
	) : (
		<LoadingBox />
	);
};

export default ImageGallery;
