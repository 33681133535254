import * as React from 'react';
import styled from 'styled-native-components';
import { debounce } from 'lodash';

import type { TextInput, TextInputProps } from 'react-native';
import type { IconName } from '@cinuru/utils/theme';
import type { BaseFieldProps } from './BaseField';

import Icon from './Icon';
import WrappedImage from './WrappedImage';
import BaseField from './BaseField';
import { ParagraphInput } from './Paragraph';
import Label from './Label';

const Input = styled(ParagraphInput).attrs((p) => ({
	inlineImagePadding: 0,
	selectionColor:
		!p.theme.colors.darkMode && p.theme.colors.isLight(p.theme.colors.accent0)
			? p.theme.colors.neutral0
			: p.theme.colors.accent0,
	keyboardAppearance: p.theme.colors.darkMode ? 'dark' : 'light',
	underlineColorAndroid: 'transparent',
}))`
	height: ${(p) => (p.numberOfLines ? p.numberOfLines * 3 : p.multiline ? 6 : 3)}rem;
	flex: 1 1;
	padding: 0;
`;

const Thumbnail = styled(WrappedImage).attrs({
	resizeMode: 'contain',
})`
	height: 2.5rem;
	width: ${(2.5 / 3) * 2}rem;
	border-radius: ${(p) => p.theme.borderRadius[0]};
	margin-right: 0.75rem;
`;

const TagWrapper = styled.View`
	background-color: $border0;
	padding: 0.25rem;
	margin: 0.25rem 0;
	margin-right: 1rem;
	border-radius: ${(p) => p.theme.borderRadius[1]};
	flex-direction: row;
	align-items: center;
	width: ${(p) => (p.width ? `${p.width}rem` : 'none')};
	justify-content: ${(p) => (p.width ? 'space-between' : 'flex-start')};
`;

const TagImage = styled(WrappedImage)`
	height: 2rem;
	width: 1.5rem;
	border-radius: ${(p) => p.theme.borderRadius[0]};
`;

class Tag extends React.PureComponent {
	handleRemove = () => this.props.onRemove(this.props.index);

	render = () => {
		const { label, iconName, image, onRemove, tagWidth } = this.props;
		return (
			<TagWrapper width={tagWidth}>
				{image ? <TagImage src={image} /> : null}
				{iconName ? (
					<Icon name={iconName} size="2rem" color="$neutral0" margin="0rem 0rem 0rem 0.75rem" />
				) : null}
				<Label size="xs" margin="0 0.75rem" numberOfLines={tagWidth ? 1 : null}>
					{label}
				</Label>
				{onRemove && (
					<Icon
						name="close"
						color="$neutral0"
						size="1.5rem"
						margin="0.25rem 0.75rem 0.25rem 0rem"
						accessible={false}
						onPress={this.handleRemove}
					/>
				)}
			</TagWrapper>
		);
	};
}

type CommonProps = BaseFieldProps &
	Omit<
		TextInputProps,
		| 'value'
		| 'onChangeText'
		| 'onSubmitEditing'
		| 'onFocus'
		| 'onChange'
		| 'onBlur'
		| 'multiline'
		| 'onKeyPress'
		| 'editable'
		| 'secureTextEntry'
		| 'defaultValue'
	> & {
		valueIcon?: IconName;
		valueImage?: string;
		hideTagRemove?: boolean;
		editOnBackspace?: boolean;
		onChangeText?: (val: string) => unknown;
		onChangeTags?: (val: [{ key: string; label: string }]) => unknown;
		inputChangeDebounce?: number;
		toggleVisibility?: boolean;
		secureTextEntry?: boolean;
		keyboardType?: 'email-address' | 'number-pad' | 'phone-pad' | 'url' | 'numbers-and-punctuation';
		returnKeyType?: 'done' | 'go' | 'next' | 'search' | 'send';
		selectTextOnFocus?: boolean;
		editable?: boolean;
		selected?: boolean;
		tagWidth?: number;
		cutTags?: boolean;
		onKeyPress?: ({ nativeEvent: { key } }: { nativeEvent: { key: any } }) => void;
		valueType?: 'string' | 'tags';
	};

export interface StringInputProps extends CommonProps {
	valueType: 'string';
	numberType?: undefined;
	value?: string;
	defaultValue?: string;
	tagSeparator?: undefined;
	maxTags?: undefined;
	onChange?: (val: string) => unknown;
	onFocus?: (val: string) => unknown;
	onBlur?: (val: string) => unknown;
	onSubmit?: (val: string) => unknown;
}

interface NumberInputProps extends CommonProps {
	valueType: 'number';
	numberType?: 'int' | 'float';
	value?: number;
	defaultValue?: number;
	tagSeparator?: undefined;
	maxTags?: undefined;
	onChange?: (val: number) => unknown;
	onFocus?: (val: number) => unknown;
	onBlur?: (val: number) => unknown;
	onSubmit?: (val: number) => unknown;
}

interface TagInputProps extends CommonProps {
	valueType: 'tags';
	numberType?: undefined;
	value?: string[];
	defaultValue?: string[];
	tagSeparator?: string;
	maxTags?: number;
	onChange?: (val: string[]) => unknown;
	onFocus?: (val: string[]) => unknown;
	onBlur?: (val: string[]) => unknown;
	onSubmit?: (val: string[]) => unknown;
}
export type TextFieldProps = StringInputProps | NumberInputProps | TagInputProps;
type State = {
	selected: boolean;
	value: number | string | string[];
	textValue: string | string[];
};
export default class TextField extends React.PureComponent<TextFieldProps, State> {
	state = {
		selected: false,
		value:
			this.props.value || this.props.defaultValue || (this.props.valueType === 'tags' ? [] : ''),
		textValue:
			this.props.valueType === 'number' && (this.props.value || this.props.defaultValue)
				? String(this.props.value || this.props.defaultValue)
				: '',
		visibilityHidden: true,
	};

	static defaultProps = {
		nMargins: [1, 0],
		tagSeparator: ' ',
		editOnBackspace: true,
		inputChangeDebounce: 167,
		autoCorrect: false,
		autoCapitalize: 'none',
		valueType: 'string',
	};

	constructor(props: TextFieldProps) {
		super(props);
		this.debouncedHandleChange = debounce(this.handleChange, props.inputChangeDebounce);
		this.debouncedHandleChangeText = debounce(this.handleChangeText, props.inputChangeDebounce);
	}

	inputRef = React.createRef<TextInput>();

	setValue = (value): void => this.setState({ value });

	handleSelect = (): void => (this.inputRef.current && this.inputRef.current.focus()) || undefined;

	handleFocus = (): void =>
		this.setState(
			{ selected: true },
			() => this.props.onFocus && this.props.onFocus(this.state.value)
		);
	handleBlur = (): void => {
		this.setState(
			{ selected: false },
			() => this.props.onBlur && this.props.onBlur(this.state.value)
		);
		this.handleChangeText(this.state.textValue);
		this.handleChange(this.state.value);
	};

	handleSubmit = (): void => {
		this.handleChangeText(this.state.textValue);
		this.handleChange(this.state.value);
		this.props.onSubmit && this.props.onSubmit(this.state.value);
	};

	componentDidUpdate = (prevProps): void => {
		if (prevProps.selected && !this.props.selected) {
			this.blur();
		}
	};

	componentWillUnmount = () => {
		this.debouncedHandleChangeText.cancel();
		this.debouncedHandleChange.cancel();
	};

	handleInput = (value) => {
		if (this.props.valueType === 'tags') {
			// split current textValue on tagSeparator
			const tags = value.split(this.props.tagSeparator);
			if (tags.length > 1) {
				// if value was split, tag[0] is new tag and tag[1] is remaining textValue, usually ''
				this.setState(
					(state) => ({ value: [...state.value, { label: tags[0] }], textValue: tags[1] }),
					() => {
						// no need to debounce here
						this.handleChangeText(this.state.textValue);
						this.handleChange(this.state.value);
						this.handleChangeTags(this.state.value);
					}
				);
			} else {
				this.setState({ textValue: value }, () => this.debouncedHandleChangeText(value));
			}
		} else if (this.props.valueType === 'number') {
			let numValue = Number(value);
			// give back NaN if float entered in int input
			if (this.props.numberType === 'int') numValue = Number.isInteger(numValue) ? numValue : NaN;
			this.setState({ textValue: value, value: numValue }, () => {
				this.debouncedHandleChange(this.state.value);
				this.debouncedHandleChangeText(this.state.textValue);
			});
		} else {
			this.setState({ value }, () => {
				this.debouncedHandleChange(value);
				this.debouncedHandleChangeText(value);
			});
		}
	};

	handleChangeText = (value) => this.props.onChangeText && this.props.onChangeText(value);
	handleChangeTags = (value) => this.props.onChangeTags && this.props.onChangeTags(value);
	handleChange = (value) => {
		if (this.props.onChange) {
			if (this.props.valueType === 'tags') value = value.map((tag) => tag.label);
			this.props.onChange(value);
		}
	};

	handleRemoveTag = (index) =>
		this.setState(
			(state) => ({ value: state.value.filter((_, i) => i !== index) }),
			() => {
				this.handleChangeTags(this.state.value);
				this.handleChange(this.state.value);
			}
		);

	handleKeyPress = (e) => {
		const { value, textValue } = this.state;
		if (this.props.valueType === 'number') {
			const keyValues = { ArrowDown: -1, ArrowUp: +1 };
			if (e.nativeEvent.key in keyValues) {
				const isValidNumber = typeof value === 'number' && !isNaN(value);
				const newValue = isValidNumber ? value + keyValues[e.nativeEvent.key] : 0;
				this.setState({ value: newValue, textValue: String(newValue) }, () => {
					this.handleChange(this.state.value);
					this.handleChangeText(this.state.textValue);
				});
			}
		}
		if (
			this.props.valueType === 'tags' &&
			e.nativeEvent.key === 'Backspace' &&
			textValue.length === 0
		) {
			const lastTag = value.splice(value.length - 1, 1)[0];
			this.setState(
				{ textValue: this.props.editOnBackspace ? lastTag.label + ' ' : '', value },
				() => {
					this.handleChangeTags(this.state.value);
					this.handleChange(this.state.value);
					this.handleChangeText(this.state.textValue);
				}
			);
		}
		if (this.props.onKeyPress) this.props.onKeyPress(e);
	};

	handleClear = () =>
		this.setState({ value: this.props.valueType === 'tags' ? [] : '', textValue: '' }, () => {
			this.handleChangeTags(this.state.value);
			this.handleChange(this.state.value);
			this.handleChangeText(this.state.textValue);
		});

	handleToggleVisibility = () =>
		this.setState((state) => ({ visibilityHidden: !state.visibilityHidden }));

	blur = (): void => this.inputRef.current?.blur();
	focus = (): void => this.inputRef.current?.focus();
	clear = (): void => this.inputRef.current?.clear();

	clearText = (): void =>
		this.setState({ textValue: '' }, () => this.debouncedHandleChangeText(''));

	render = () => {
		const {
			valueType,
			label,
			nMargins,
			iconName,
			iconRightAligned,
			altBackground,
			stretch,
			flex,
			numberOfLines,
			multiline,
			characterLimit,
			hint,
			error,
			maxTags,
			valueIcon,
			valueImage,
			hideTagRemove,
			toggleVisibility,
			secureTextEntry,
			keyboardType,
			numberType,
			testID,
			errorTestID,
			hintTestID,
			disabled,
			editable,
			noClear,
			margin,
			width,
			borderRadius,
			...otherProps
		} = this.props;
		const {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onChange, // eslint-disable-next-line @typescript-eslint/no-unused-vars
			tagSeparator, // eslint-disable-next-line @typescript-eslint/no-unused-vars
			editOnBackspace, // eslint-disable-next-line @typescript-eslint/no-unused-vars
			inputChangeDebounce, // eslint-disable-next-line @typescript-eslint/no-unused-vars
			onChangeTags, // eslint-disable-next-line @typescript-eslint/no-unused-vars
			defaultValue,
			...rest
		} = otherProps;
		const { value, textValue, selected, visibilityHidden } = this.state;
		const errorValue =
			valueType === 'number' && isNaN(value)
				? error || `Bitte gib eine ${numberType === 'int' ? 'ganze ' : ' '}Zahl an`
				: error;
		return (
			<BaseField
				error={errorValue}
				errorTestID={errorTestID}
				hintTestID={hintTestID}
				label={label}
				nMargins={nMargins}
				iconName={iconName}
				iconRightAligned={iconRightAligned}
				altBackground={altBackground}
				flex={flex}
				stretch={stretch}
				width={width}
				noClear={noClear}
				margin={margin}
				borderRadius={borderRadius}
				multiline={multiline || valueType === 'tags'}
				characterLimit={characterLimit}
				hint={hint}
				disabled={disabled}
				inputLength={value.length}
				textValue={textValue}
				selected={selected}
				onClear={this.handleClear}
				onPress={this.handleSelect}
				hasContent={valueType !== 'string' ? textValue || value.length > 0 : value}
				toggleVisibility={toggleVisibility}
				visibilityHidden={visibilityHidden}
				onToggleVisibility={this.handleToggleVisibility}
			>
				{valueType === 'tags'
					? value.map((tag, i) => {
							// TODO: types
							return (this.props.cutTags && i < this.props.cutTags) || !this.props.cutTags ? (
								<Tag
									key={tag.label + i}
									{...tag}
									index={i}
									onRemove={!hideTagRemove && this.handleRemoveTag}
									tagWidth={this.props.tagWidth}
								/>
							) : this.props.cutTags && i === this.props.cutTags ? (
								<Tag key="EndTag" label={`... ${value.length - i} weitere`} index={i} />
							) : null;
					  })
					: null}
				{value && valueIcon ? (
					<Icon name={valueIcon} size="2rem" margin="0.5rem 1rem 0.5rem 0rem" />
				) : null}
				{value && valueImage ? <Thumbnail src={valueImage} /> : null}
				<Input
					{...rest}
					testID={testID}
					keyboardType={valueType === 'number' ? 'phone-pad' : keyboardType}
					ref={this.inputRef}
					value={String(valueType !== 'string' ? textValue : value)}
					onChangeText={this.handleInput}
					onSubmitEditing={this.handleSubmit}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					numberOfLines={numberOfLines}
					multiline={multiline}
					onKeyPress={this.handleKeyPress}
					editable={
						editable !== undefined
							? editable
							: valueType !== 'tags' || !maxTags || value.length < maxTags
					}
					secureTextEntry={secureTextEntry && visibilityHidden}
				/>
			</BaseField>
		);
	};
}
