import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import QRCodeOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MovieFilterOutlinedIcon from '@mui/icons-material/MovieFilterOutlined';

import { ButtonDefaultParams } from './ContentTypes/Button';
import { ImageDefaultParams } from './ContentTypes/Image';
import { SpacerDefaultParams } from './ContentTypes/Spacer';
import { MarkdownTextDefaultParams } from './ContentTypes/MarkdownText';
import { MarkdownTextWithImageDefaultParams } from './ContentTypes/MarkdownTextWithImage';
import { ProgramDefaultParams } from './ContentTypes/Program';
import { RecommendationsDefaultParams } from './ContentTypes/Recommendations';
import { QRCodeDefaultParams } from './ContentTypes/QRCode';
import { FilmSeriesDefaultParams } from './ContentTypes/FilmSeries';

import type { EmailElement, EmailEditorComponentType } from '../../utils/campaignEmailContent';
import Tooltip from '../../components/Tooltip';
import useDivDimensions, { useGetPixelFromRem } from '../../utils/dimensions';

const PADDING = 1;
const ITEM_WIDTH = 12;
const ITEM_MARGIN = 1;

const ItemWrapper = styled(Box)`
	height: ${ITEM_WIDTH}rem;
	width: 12rem;
	background-color: ${(p) => p.theme.customColors.lightGrey};
	padding: 1rem;
	margin: ${ITEM_MARGIN}rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
`;

const ProviderComponent = React.memo(
	({
		children,
		type,
		onDragAndDrop,
		defaultParams,
	}: {
		children: JSX.Element | JSX.Element[] | undefined;
		type: EmailEditorComponentType;
		onDragAndDrop: (arg: boolean) => void;
		defaultParams: EmailElement;
	}) => {
		const handleDragStart = React.useCallback(
			(event) => {
				event.dataTransfer.setData(
					'text',
					JSON.stringify({ action: 'ADD', itemType: type, defaultValues: defaultParams })
				);
				onDragAndDrop(true);
			},
			[defaultParams, onDragAndDrop, type]
		);
		const handleDragEnd = React.useCallback(() => {
			onDragAndDrop(false);
		}, [onDragAndDrop]);

		return (
			<div draggable onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
				<Tooltip text="Zum Einfügen muss das Element angeklickt und per Drag and in das Email-Feld gezogen werden.">
					<ItemWrapper>{children}</ItemWrapper>
				</Tooltip>
			</div>
		);
	}
);

// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
const iconStyle = { fontSize: 30 };

const providerItems: {
	type: EmailEditorComponentType;
	label: string;
	defaultParams: EmailElement;
	icon: JSX.Element;
}[] = [
	{
		type: 'RECOMMENDATIONS',
		label: 'Film-empfehlungen',
		defaultParams: RecommendationsDefaultParams,
		icon: <AccountCircleOutlinedIcon style={iconStyle} />,
	},
	{
		type: 'PROGRAM',
		label: 'Programm',
		defaultParams: ProgramDefaultParams,
		icon: <CalendarTodayOutlinedIcon style={iconStyle} />,
	},
	{
		type: 'TEXT',
		label: 'Text',
		defaultParams: MarkdownTextDefaultParams,
		icon: <TextFieldsOutlinedIcon style={iconStyle} />,
	},
	{
		type: 'IMAGE',
		label: 'Bild',
		defaultParams: ImageDefaultParams,
		icon: <ImageOutlinedIcon style={iconStyle} />,
	},
	{
		type: 'TEXTWITHIMAGE',
		label: 'Text mit Bild',
		defaultParams: MarkdownTextWithImageDefaultParams,
		icon: <VerticalSplitIcon style={iconStyle} />,
	},
	{
		type: 'BUTTON',
		label: 'Button',
		defaultParams: ButtonDefaultParams,
		icon: <LinkOutlinedIcon style={iconStyle} />,
	},
	{
		type: 'QRCODE',
		label: 'QR-Code',
		defaultParams: QRCodeDefaultParams,
		icon: <QRCodeOutlinedIcon style={iconStyle} />,
	},

	{
		type: 'SPACER',
		label: 'Spacer',
		defaultParams: SpacerDefaultParams,
		icon: <RemoveOutlinedIcon style={iconStyle} />,
	},
	{
		type: 'SERIES',
		label: 'Filmreihen',
		defaultParams: FilmSeriesDefaultParams,
		icon: <MovieFilterOutlinedIcon style={iconStyle} />,
	},
];

const RowWrap = styled(Box)`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 auto;
`;

const EmailComponentProviderPanel = ({
	onDragAndDrop,
}: {
	onDragAndDrop: (arg: boolean) => void;
}): JSX.Element => {
	const ref = React.useRef(null);
	const { width: availableWidth } = useDivDimensions(ref);
	const itemWidthRem = ITEM_WIDTH + 2 * ITEM_MARGIN;
	const itemWidthPx = useGetPixelFromRem(itemWidthRem);
	const numberOfItemsPerRow = Math.floor(availableWidth / itemWidthPx);
	const wrapperPaddingRem = 2 * PADDING;
	const wrapperPaddingPx = useGetPixelFromRem(wrapperPaddingRem);
	const wrapperWidth = numberOfItemsPerRow * itemWidthPx + wrapperPaddingPx + 'px';

	return (
		<Paper>
			<Box width="100%" ref={ref}>
				<RowWrap width={wrapperWidth} p={PADDING}>
					{providerItems.map(({ type, label, defaultParams, icon }) => (
						<ProviderComponent
							key={type}
							onDragAndDrop={onDragAndDrop}
							type={type}
							defaultParams={defaultParams}
						>
							{icon}
							<Typography align="center" fontSize="12px">
								{label}
							</Typography>
						</ProviderComponent>
					))}
				</RowWrap>
			</Box>
		</Paper>
	);
};

export default React.memo(EmailComponentProviderPanel);
