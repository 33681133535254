import * as React from 'react';
import styled from 'styled-native-components';

import Paragraph from './Paragraph';

export const parseLinks = (label = ''): { text: string; link?: string }[] => {
	const [mdLink] = label.match(/\[[^\]]+\]\([^)]*\)/) || [];
	if (mdLink) {
		const [, text, , link] = mdLink.split(/[[\]()]/);
		const [before, after] = label.split(mdLink);
		label = [before, text, after].join('');
		return [{ text: before }, { text, link }, ...parseLinks(after)];
	} else {
		return [{ text: label }];
	}
};

const Wrapper = styled.View<{ margin: string }>`
	flex-flow: row wrap;
	flex: 1;
	align-items: center;
	margin: ${(p) => p.margin};
`;

const TextWithLink = ({
	children,
	color = '$neutral0',
	size,
	margin = '0',
	onLinkPress,
	onTextPress,
}: {
	children: string;
	color?: string;
	size?: string;
	margin?: string;
	onLinkPress?: (link: string) => void;
	onTextPress?: () => void;
}) => {
	const Link = React.useMemo(
		() => styled(Paragraph).attrs(({ href }) => ({
			accessibilityRole: 'link',
			onPress: () => onLinkPress && onLinkPress(href),
		}))`
			text-decoration: underline;
			text-decoration-color: ${color};
		`,
		[color, onLinkPress]
	);

	return (
		<Wrapper margin={margin}>
			{parseLinks(children).map(({ text, link }, i) =>
				link ? (
					<Link key={`${i}`} href={link} size={size} color={color} onLinkPress={onLinkPress}>
						{text}
					</Link>
				) : (
					text.split(' ').map((w, j, ws) => (
						<Paragraph key={`${i}${j}`} size={size} color={color} onPress={onTextPress}>
							{w + (j === ws.length - 1 ? '' : ' ')}
						</Paragraph>
					))
				)
			)}
		</Wrapper>
	);
};

export default React.memo(TextWithLink);
