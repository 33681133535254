import React from 'react';
import styled from 'styled-native-components';

import { Table, Heading, Paragraph, MoviePoster } from '@cinuru/components';

import { orderBy } from 'lodash';
import { useQuery } from 'react-apollo';
import { default as gql } from 'graphql-tag';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
	min-width: 80rem;
`;
const TableWrapper = styled.View`
	max-width: 150rem;
`;

const MovieTitleWrapper = styled.View`
	flex-direction: row;
	align-items: center;
`;

const TABLE_MARGINS = [0, -0.5, 3, -0.5];

const MovieTitle = ({ poster, title }) => {
	return (
		<MovieTitleWrapper>
			<MoviePoster poster={poster} margin="0em 2em"></MoviePoster>
			<Paragraph bold={true} align="left">
				{title}
			</Paragraph>
		</MovieTitleWrapper>
	);
};

const StatisticsMovie = () => {
	const movies = useQuery(
		gql`
			{
				movieStatistics {
					id
					movie {
						title
						poster
						averageRating
					}
					numWatchlistings
					numInteractions
				}
			}
		`,
		{ fetchPolicy: 'network-only' }
	);
	const tableData = orderBy(
		movies.data && movies.data.movieStatistics
			? movies.data.movieStatistics.map((m) => ({
					id: m.id,
					movie: MovieTitle({ title: m.movie.title, poster: m.movie.poster }),
					watchlist: m.numWatchlistings || '-',
					ratingAvg: m.movie.averageRating || '-',
					interactions: m.numInteractions || '-',
			  }))
			: [],
		['watchlist'],
		['desc']
	);

	return (
		<Wrapper>
			<Heading size="s" margin="4rem 0rem 2rem">
				Statistiken der aktuelle Filme
			</Heading>
			<Paragraph>Es werden Filme angezeigt, die aktuell in der App sichtbar sind.</Paragraph>
			<TableWrapper>
				<Table
					nMargins={TABLE_MARGINS}
					// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
					columns={[
						{ label: 'Film', key: 'movie' },
						{ label: 'Merkliste', key: 'watchlist', nWidth: 15, flex: [0, 0] },
						{ label: 'Bewertung', key: 'ratingAvg', nWidth: 15, flex: [0, 0] },
						{ label: 'Interaktionen', key: 'interactions', nWidth: 15, flex: [0, 0] },
					]}
					data={tableData}
					pagination={false}
				/>
			</TableWrapper>
		</Wrapper>
	);
};

export default StatisticsMovie;
