import * as React from 'react';

import ActiveWrapper from './ActiveWrapper';
import { ClusterPropName, ClusterProps } from '../TargetGroupCluster';
import IntegerRangeSelectForm from '../../../components/IntegerRangeSelectForm';

const optionLabel = {
	statusLevelFilter: {
		label: 'Empfänger müssen entsprechend viele Statuslevel besitzen:',
		minimumOnChangePropertyName: 'minStatusLevel',
		maximumOnChangePropertyName: 'maxStatusLevel',
	},
	statusPointsFilter: {
		label: 'Empfänger müssen entsprechend viele Statuspunkte besitzen:',
		minimumOnChangePropertyName: 'minStatusPoints',
		maximumOnChangePropertyName: 'maxStatusPoints',
	},
	bonusPointsFilter: {
		label: 'Empfänger müssen entsprechend viele Bonuspunkte besitzen:',
		minimumOnChangePropertyName: 'minBonusPoints',
		maximumOnChangePropertyName: 'maxBonusPoints',
	},
};

export type ValueRangeFilterRef = {
	validate: () => boolean;
};

const ValueRangeFilter = React.forwardRef<
	ValueRangeFilterRef,
	{
		active?: boolean;
		defaultMinimum: number;
		defaultMaximum?: number;
		onChange: ({ clusterPropName, clusterPropValue }: ClusterProps) => void;
		showFilter: boolean;
		clusterPropName: ClusterPropName;
	}
>(({ active, defaultMinimum, defaultMaximum, onChange, showFilter, clusterPropName }, ref) => {
	const { label, minimumOnChangePropertyName, maximumOnChangePropertyName } = optionLabel[
		clusterPropName
	];

	const handleChange = React.useCallback(
		([min, max]: [number | undefined, number | undefined]) => {
			onChange({
				clusterPropName,
				clusterPropValue: {
					[minimumOnChangePropertyName]: min,
					[maximumOnChangePropertyName]: max,
				},
			});
		},
		[clusterPropName, maximumOnChangePropertyName, minimumOnChangePropertyName, onChange]
	);

	const handleToggleActivity = React.useCallback(() => {
		onChange({
			clusterPropName,
			clusterPropValue: {
				active: !active,
			},
		});
	}, [onChange, clusterPropName, active]);

	const disabled = !active;

	return showFilter ? (
		<ActiveWrapper label={label} active={active} onHandleToggleActivity={handleToggleActivity}>
			<IntegerRangeSelectForm
				variant="outlined"
				disabled={disabled}
				flex
				onChange={handleChange}
				defaultFrom={defaultMinimum}
				defaultTo={defaultMaximum}
				ref={ref}
			/>
		</ActiveWrapper>
	) : null;
});

export default React.memo(ValueRangeFilter);
