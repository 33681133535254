import * as React from 'react';
import { Grid } from '@mui/material';

import Slider from './Slider';
import ActiveWrapper from './ActiveWrapper';
import { ClusterPropName, ClusterProps } from '../TargetGroupCluster';
import TimeUnitSelectField, {
	TimeUnit as _TimeUnit,
} from '../../../components/TimeUnitSelectField';

export type TimeUnit = _TimeUnit;

const clusterPropNameDict = {
	averageCinemaVisitsFilter: 'Visits',
	ageFilter: 'Age',
};

const SliderFilter = ({
	active,
	defaultTimePeriod,
	onChange,
	showFilter,
	clusterPropName,
	enabledTimeUnits,
	label,
	hideTimeUnit,
	minMark,
	maxMark,
	markStep,
	defaultMin,
	defaultMax,
	fallbackMin,
	fallbackMax,
}: {
	active?: boolean;
	defaultTimePeriod?: TimeUnit;
	onChange: ({ clusterPropName, clusterPropValue }: ClusterProps) => void;
	showFilter: boolean;
	clusterPropName: ClusterPropName;
	enabledTimeUnits?: TimeUnit[];
	label: string;
	hideTimeUnit?: boolean;
	minMark: number;
	maxMark: number;
	markStep: number;
	defaultMin?: number;
	defaultMax?: number;
	fallbackMin?: number;
	fallbackMax?: number;
}): JSX.Element | null => {
	const handleChange = React.useCallback(
		([newMin, newMax]: [number, number]) => {
			onChange({
				clusterPropName,
				clusterPropValue: {
					[`max${clusterPropNameDict[clusterPropName]}`]: newMax,
					[`min${clusterPropNameDict[clusterPropName]}`]: newMin,
				},
			});
		},
		[onChange, clusterPropName]
	);

	const handleChangeTimeUnit = React.useCallback(
		(timeUnit?: TimeUnit) => {
			onChange({
				clusterPropName,
				clusterPropValue: {
					timePeriod: timeUnit,
				},
			});
		},
		[onChange, clusterPropName]
	);

	const handleToggleActivity = React.useCallback(() => {
		onChange({
			clusterPropName,
			clusterPropValue: {
				active: !active,
			},
		});
	}, [onChange, clusterPropName, active]);

	return showFilter ? (
		<ActiveWrapper label={label} active={active} onHandleToggleActivity={handleToggleActivity}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={hideTimeUnit ? 12 : 6}>
					<Slider
						flex="1"
						disabled={!active}
						onChange={handleChange}
						minMark={minMark}
						maxMark={maxMark}
						markStep={markStep}
						defaultMin={defaultMin}
						defaultMax={defaultMax}
						fallbackMin={fallbackMin}
						fallbackMax={fallbackMax}
					/>
				</Grid>
				{hideTimeUnit ? null : (
					<Grid item xs={12} sm={6}>
						<TimeUnitSelectField
							defaultValue={defaultTimePeriod}
							disabled={!active}
							flex
							onChange={handleChangeTimeUnit}
							enabledTimeUnits={enabledTimeUnits}
							singular
						/>
					</Grid>
				)}
			</Grid>
		</ActiveWrapper>
	) : null;
};

export default React.memo(SliderFilter);
