import * as React from 'react';
import styled from 'styled-native-components';
import { format } from 'date-fns';

import { Checkbox, Paragraph } from '@cinuru/components';

const MovieCard = styled.View`
	width: 100%;
	flex-direction: row;
	align-items: center;
	background-color: ${(p) => (p.error ? '$error' : '$background1')};
	border-radius: ${(p) => p.theme.borderRadius[1]};
	margin: 1rem 0;
	overflow: hidden;
	elevation: 1;
`;

const MoviePoster = styled.View`
	height: ${(p) => p.width * 1.5}px;
	width: ${(p) => p.width}px;
	margin-right: 2rem;
	background-image: url(${(p) => p.poster});
	background-color: ${(p) => (p.poster ? 'none' : p.theme.colors.neutral2)}
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;

const MovieInfos = styled.View`
	display: flex;
	flex-direction: column;
	flex: 10;
	margin-right: 1rem;
`;

const MovieActions = styled.View`
	width: 200px;
	height: 70px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
`;

const LastChanceMovieCard = ({
	loading,
	id,
	poster,
	title,
	datetime,
	marked,
	onChangeLastChance,
	error,
}) => {
	const hanldeSetLastChance = React.useCallback(
		(value) => onChangeLastChance({ id, marked: value }),
		[id, onChangeLastChance]
	);
	return (
		<MovieCard error={error}>
			<MoviePoster poster={poster} width={70} />
			<MovieInfos>
				<Paragraph bold>{title}</Paragraph>
				<Paragraph>{format(datetime, 'dd.MM.yyyy HH:mm')}</Paragraph>
				{error ? <Paragraph>Es existiert eine aktuellere Spielzeit!</Paragraph> : null}
			</MovieInfos>

			<MovieActions>
				<Paragraph>Benachrichtigen: Letzte Chance!</Paragraph>
				<Checkbox
					altBackground
					error={error}
					value={marked}
					loading={loading}
					onChange={hanldeSetLastChance}
				/>
			</MovieActions>
		</MovieCard>
	);
};

export default LastChanceMovieCard;
