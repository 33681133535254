/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import { DateRangeField, Heading, Paragraph } from '@cinuru/components';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-native-components';

import { addMonths, format } from 'date-fns';
import SplitCinemaSelectField from '../../../components/SplitCinemaSelectField';
import { useQuery } from 'react-apollo';
import { default as gql } from 'graphql-tag';
import {
	DemographicsBarChart,
	VisitFrequencyBarChart,
	PlusVisitFrequencyBarChart,
} from './BarChart';
import { generateData } from './generateMockData';
import { MainGraph } from './LineChart';
import styledWeb from 'styled-components';

const Page = styledWeb.div`
page-break-after: always;
`;

const Card = styled.View`
	background-color: $background0;
	width: 100%;
	min-width: 80rem;
	padding: 2rem 5rem;
	elevation: 2;
	margin-bottom: 2rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	align-items: center;
`;
const MainGraphWrapper = styled.View`
	height: 50rem;
	width: 100%;
`;
const MetricsWrapper = styled.View`
	min-width: 95rem;
`;
const CardHeading = styled(Heading)`
	text-align: center;
`;
const InfoParagraph = styled(Paragraph)`
	width: 80%;
`;
const Seperator = styled.View`
	background-color: $background1;
	width: 100%;
	height: 0.2rem;
	margin: 5rem;
	border-radius: 3rem;
`;

const FIELD_MARGINS = [0.5, 1];

const encodeDate = (date) => format(date, 'yyyy-MM-dd');
export const CinemaComparison = () => {
	const [dateRange, setDateRange] = useState({
		from: encodeDate(addMonths(new Date(), -1)),
		to: encodeDate(new Date()),
	});
	const [cinemaIds1, setCinemaIds1] = useState(['Q2luZW1hOjI1', 'Q2luZW1hOjIz', 'Q2luZW1hOjIx']);

	const [cinemaIds2, setCinemaIds2] = useState(['Q2luZW1hOjI1MA==']);
	const [cinemaIds3, setCinemaIds3] = useState(['Q2luZW1hOjEwNw==', 'Q2luZW1hOjI0MA==']);
	const cinemaNamesRes = useQuery(
		gql`
			{
				currentUser {
					id
					privileges {
						adminForCinemas {
							id
							name
						}
					}
				}
			}
		`,
		{ fetchPolicy: 'cache-first' }
	);
	const cinemaNameMap = new Map(
		cinemaNamesRes.data.currentUser.privileges.adminForCinemas.map((r) => [r.id, r.name])
	);
	const cinemaNames = [
		cinemaIds1.map((id) => cinemaNameMap.get(id)),
		cinemaIds2.map((id) => cinemaNameMap.get(id)),
		cinemaIds3.map((id) => cinemaNameMap.get(id)),
	];
	return (
		<>
			<Page>
				<Card>
					<CardHeading>Auswahl</CardHeading>
					<MetricsWrapper>
						<DateRangeField
							nMargins={FIELD_MARGINS}
							onChange={setDateRange}
							value={dateRange}
							label="Zeitraum"
						/>
						<SplitCinemaSelectField
							onChange={setCinemaIds1}
							nMargins={[1]}
							multi
							value={cinemaIds1}
							label="Kinos in Vergleichsgruppe 1"
						/>
						<SplitCinemaSelectField
							onChange={setCinemaIds2}
							nMargins={[1]}
							multi
							value={cinemaIds2}
							label="Kinos in Vergleichsgruppe 2"
						/>
						<SplitCinemaSelectField
							onChange={setCinemaIds3}
							nMargins={[1]}
							multi
							value={cinemaIds3}
							label="Kinos in Vergleichsgruppe 3"
						/>
					</MetricsWrapper>
				</Card>
			</Page>
			<Page>
				<Card>
					<CardHeading>Neue Nutzer</CardHeading>
					<Seperator />
					<Heading size="s">Absolut</Heading>
					<MainGraphWrapper>
						<MainGraph
							data={generateData(
								dateRange.from,
								dateRange.to,
								100,
								30,
								cinemaIds1,
								cinemaIds2,
								cinemaIds3,
								false
							)}
							ylabel="Neue Nutzer"
							xlabel="Datum"
							cinemaNames={cinemaNames}
						/>
					</MainGraphWrapper>
					<Seperator />
					<Heading size="s">Relativ</Heading>
					<InfoParagraph>
						Normiert nach Kinogröße. Zum besseren Vergleich von unterschiedlich großen Kinos/Gruppen
						wurde die Anzahl der neuen Nutzer durch die Anzahl der im Vorjahr verkauften Tickets
						dividiert.
					</InfoParagraph>
					<MainGraphWrapper>
						<MainGraph
							data={generateData(
								dateRange.from,
								dateRange.to,
								100,
								30,
								cinemaIds1,
								cinemaIds2,
								cinemaIds3,
								true
							)}
							cinemaNames={cinemaNames}
						/>
					</MainGraphWrapper>
				</Card>
			</Page>
			<Page>
				<Card>
					<CardHeading>Aktive Nutzer</CardHeading>
					<Seperator />

					<MainGraphWrapper>
						<MainGraph
							data={generateData(
								dateRange.from,
								dateRange.to,
								100,
								30,
								cinemaIds1,
								cinemaIds2,
								cinemaIds3,
								false
							)}
							cinemaNames={cinemaNames}
						/>
					</MainGraphWrapper>
					<Seperator />
					<Heading size="s">Relativ</Heading>
					<InfoParagraph>
						Normiert nach Kinogröße. Zum besseren Vergleich von unterschiedlich großen Kinos/Gruppen
						wurde die Anzahl der aktiven Nutzer durch die Anzahl der im Vorjahr verkauften Tickets
						dividiert.
					</InfoParagraph>
					<MainGraphWrapper>
						<MainGraph
							data={generateData(
								dateRange.from,
								dateRange.to,
								100,
								30,
								cinemaIds1,
								cinemaIds2,
								cinemaIds3,
								true
							)}
							cinemaNames={cinemaNames}
						/>
					</MainGraphWrapper>
				</Card>
			</Page>
			<Page>
				<Card>
					<CardHeading>Verkaufte Tickets</CardHeading>
					<InfoParagraph>
						Normiert nach Kinogröße. Zum besseren Vergleich von unterschiedlich großen Kinos/Gruppen
						wurde die Anzahl der aktiven Nutzer durch die Anzahl der im Vorjahr verkauften Tickets
						dividiert.
					</InfoParagraph>
					<MainGraphWrapper>
						<MainGraph
							data={generateData(
								dateRange.from,
								dateRange.to,
								10000,
								300,
								cinemaIds1,
								cinemaIds2,
								cinemaIds3,
								false
							)}
							cinemaNames={cinemaNames}
						/>
					</MainGraphWrapper>
				</Card>
				<Card>
					<CardHeading>Anteil Plus-Mitglieder </CardHeading>
					<MainGraphWrapper>
						<MainGraph
							data={generateData(
								dateRange.from,
								dateRange.to,
								70,
								10,
								cinemaIds1,
								cinemaIds2,
								cinemaIds3,
								true
							)}
							cinemaNames={cinemaNames}
							formatYAxisLabel={(value) => `${value} %`}
						/>
					</MainGraphWrapper>
				</Card>
			</Page>
			<Page>
				<Card>
					<CardHeading>Demografie </CardHeading>
					<MainGraphWrapper>
						<DemographicsBarChart cinemaNames={cinemaNames} ylabel="Nutzer" />
					</MainGraphWrapper>
				</Card>
			</Page>
			<Page>
				<Card>
					<MainGraphWrapper>
						<CardHeading>Besuchshäufigkeiten </CardHeading>
						<VisitFrequencyBarChart
							cinemaNames={cinemaNames}
							ylabel="%"
							formatYAxisLabel={(data) => `${data}%`}
						/>
					</MainGraphWrapper>
					<Seperator />

					<MainGraphWrapper>
						<CardHeading>Besuchshäufigkeiten Plus-Mitglieder </CardHeading>
						<PlusVisitFrequencyBarChart
							cinemaNames={cinemaNames}
							ylabel="%"
							formatYAxisLabel={(data) => `${data}%`}
						/>
					</MainGraphWrapper>
				</Card>
			</Page>
			<Page>
				<Card>
					<CardHeading>Versendete Push-Nachrichten</CardHeading>
					<Seperator />

					<MainGraphWrapper>
						<MainGraph
							data={generateData(
								dateRange.from,
								dateRange.to,
								2000,
								1000,
								cinemaIds1,
								cinemaIds2,
								cinemaIds3,
								false
							)}
							cinemaNames={cinemaNames}
						/>
					</MainGraphWrapper>
					<Seperator />
					<Heading size="s">Relativ</Heading>
					<InfoParagraph>
						Normiert nach Kinogröße. Zum besseren Vergleich von unterschiedlich großen Kinos/Gruppen
						wurde die Anzahl der Nachrichten durch die Anzahl der im Vorjahr verkauften Tickets
						dividiert.
					</InfoParagraph>
					<MainGraphWrapper>
						<MainGraph
							data={generateData(
								dateRange.from,
								dateRange.to,
								200,
								100,
								cinemaIds1,
								cinemaIds2,
								cinemaIds3,
								true
							)}
							cinemaNames={cinemaNames}
						/>
					</MainGraphWrapper>
				</Card>
			</Page>
		</>
	);
};
