/** may need some refactoring, no native support */

// @flow
import * as React from 'react';
import styled, { useColorAttribute, useTheme } from 'styled-native-components';

import Paragraph from './Paragraph';
import Icon from './Icon';
import Button from './Button';
import PortalTrigger from './PortalTrigger';
import DropDown from './DropDown';

const ItemWrapper = styled.TouchableOpacity`
	padding: 0.5rem 1.5rem;
	flex-direction: row;
	align-self: stretch;
	background-color: ${(p) => p.backgroundColor};
`;

type ItemType = {
	value: string,
	label: string,
	iconName?: string,
	image?: string,
	active?: boolean,
};

const Item = ({
	index,
	value,
	label,
	iconName,
	image,
	color,
	textColor,
	active,
	onPress,
	onHover,
}: {
	...ItemType,
	index: number,
	color?: string,
	textColor?: string,
	onPress: (item: ItemType) => any,
	onHover?: (index: number) => any,
}) => {
	const handlePress = React.useCallback(() => {
		if (onPress) onPress({ value, label, iconName, image });
	}, [iconName, image, label, onPress, value]);
	const handleHover = React.useCallback(() => onHover && onHover(index), [onHover, index]);

	const theme = useTheme();
	const activeColor = theme.colors.transparentize(0.9, useColorAttribute(textColor));

	return (
		<ItemWrapper
			active={active}
			onPress={handlePress}
			onMouseEnter={handleHover}
			color={color}
			backgroundColor={active ? activeColor : 'transparent'}
		>
			{iconName ? (
				<Icon color="$neutral2" size="2rem" margin="0.5rem 1rem 0.5rem 0rem" name={iconName} />
			) : null}
			<Paragraph color={textColor}>{label}</Paragraph>
		</ItemWrapper>
	);
};

type Props = {};
type State = {
	selected: boolean,
	value: ItemType[],
	activeIndex?: number,
	query: string,
};
class DropDownButton extends React.PureComponent<Props, State> {
	state = { selected: false, activeIndex: -1 };
	listRef = React.createRef();

	handleFocus = () => this.setState({ selected: true });
	handleBlur = () => this.setState({ selected: false });

	handleKeyPress = ({ nativeEvent: { key } }) => {
		const { items, multi } = this.props;
		if ((key === 'Tab' || key === 'Enter') && this.state.activeIndex >= 0) {
			const { value, query } = this.state;
			const filteredItems = this.filter(query, items, multi ? value : []);
			this.handleSelect(filteredItems[this.state.activeIndex]);
		}
		this.setState((state) => {
			const filteredItems = this.filter(state.query, items, multi ? state.value : []);
			if (key === 'ArrowDown') {
				const activeIndex =
					state.activeIndex >= filteredItems.length - 1 ? 0 : state.activeIndex + 1;
				this.listRef.current.scrollToIndex({ index: activeIndex, viewPosition: 0.55 });
				return { activeIndex };
			}
			if (key === 'ArrowUp') {
				const activeIndex =
					state.activeIndex <= 0 ? filteredItems.length - 1 : state.activeIndex - 1;
				this.listRef.current.scrollToIndex({ index: activeIndex, viewPosition: 0.55 });
				return { activeIndex };
			}
			return {};
		});
		if (key === 'Enter' && this.inputRef.current.blur) {
			this.inputRef.current.blur();
		}
	};

	handleItemHovered = (index) => this.setState({ activeIndex: index });

	handleSelect = ({ value }) => {
		this.handleBlur();
		this.props.onSelect && this.props.onSelect(value);
	};

	renderOverlay = () => {
		const { items, color, textColor } = this.props;
		const { activeIndex } = this.state;
		return (
			<DropDown thinLine marginTop="1rem" lineColor={textColor} color={color}>
				{items.map((item, index) => (
					<Item
						{...item}
						key={item.value}
						onPress={this.handleSelect}
						onHover={this.handleItemHovered}
						index={index}
						active={activeIndex === index}
						color={color}
						textColor={textColor}
					/>
				))}
			</DropDown>
		);
	};

	render = () => {
		const { color, textColor, ...rest } = this.props;
		const { selected } = this.state;
		return (
			<PortalTrigger
				margin="1rem 0"
				contentTopMargin="-1rem"
				active={selected}
				renderOverlay={this.renderOverlay}
			>
				<Button
					{...rest}
					noHover={selected}
					margin="0rem"
					onPress={this.handleFocus}
					onBlur={this.handleBlur}
					color={color}
					textColor={textColor}
					onKeyDown={this.handleKeyPress}
				/>
			</PortalTrigger>
		);
	};
}

export default DropDownButton;
