import * as React from 'react';

import { Dialog } from '@cinuru/components';

import CinemaSelectField from '../../components/CinemaSelectField';
import AddMovie from './AddMovie';
import ShowMovies from './ShowMovies';
import EditMovie from './EditMovie';
import { Wrapper, Content } from './LocalComponents';

const EditSoonInCinema = () => {
	const [cinemaId, setCinemaId] = React.useState(null);

	const openAddDialog = React.useCallback(() => {
		Dialog.render({
			noDismiss:true,
			renderContent: ({ dismissPortal }) => (
				<AddMovie dismissPortal={dismissPortal} cinemaId={cinemaId} />
			),
		});
	}, [cinemaId]);

	const openEditDialog = React.useCallback(
		(item) => {
			Dialog.render({
				renderContent: ({ dismissPortal }) => (
					<EditMovie dismissPortal={dismissPortal} cinemaId={cinemaId} item={item} />
				),
			});
		},
		[cinemaId]
	);

	return (
		<React.Fragment>
			<Wrapper>
				<Content>
					<CinemaSelectField onChange={setCinemaId} />
				</Content>
			</Wrapper>
			{cinemaId ? (
				<ShowMovies
					cinemaId={cinemaId}
					openAddDialog={openAddDialog}
					openEditDialog={openEditDialog}
				/>
			) : null}
		</React.Fragment>
	);
};

export default EditSoonInCinema;
