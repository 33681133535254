import { format } from 'date-fns';
import { de } from 'date-fns/locale';

import { ColorScheme } from '@cinuru/utils/theme/createColorScheme';

export const colorsToChooseFrom = (colors: ColorScheme): { [keys: string]: string } => {
	const allPossibleColors = {};
	const keyNames = Object.keys(colors);
	keyNames.map((name) => {
		if (
			typeof colors[name] === 'string' &&
			!Object.values(allPossibleColors).includes(colors[name])
		) {
			const colorNamePartsToInclude = ['neutral', 'background', 'accent'];
			colorNamePartsToInclude.map((part) => {
				if (name.includes(part) && !Object.values(allPossibleColors).includes(colors[name])) {
					allPossibleColors[name] = colors[name];
				}
			});
		}
	});

	return allPossibleColors;
};

export const gradientsToChooseFrom = (colors: ColorScheme): { [keys: string]: string[] } => {
	const allPossibleGradients = {};
	const keyNames = Object.keys(colors);

	keyNames.map((name) => {
		if (
			typeof colors[name] !== 'string' &&
			!Object.values(allPossibleGradients).includes(colors[name])
		) {
			const colorNamePartsToInclude = ['accentGradient'];
			colorNamePartsToInclude.map((part) => {
				if (name.includes(part)) {
					allPossibleGradients[name] = colors[name];
				}
			});
		}
	});

	return allPossibleGradients;
};

export const stripValueFromDimension = (value?: string): string => {
	const valueArray = value ? value.match(/\d+/) : [];
	return valueArray?.length ? valueArray[0] : '';
};

const keysToTransform = [
	'width',
	'borderRadius',
	'height',
	'margin',
	'padding',
	'marginTop',
	'marginLeft',
	'marginRight',
	'marginBottom',
	'paddingTop',
	'paddingLeft',
	'paddingRight',
	'paddingBottom',
];

export const stripValueFromDimensionObject = (obj: {
	[key: string]: any;
}): { [key: string]: any } => {
	const newObject = {};
	for (const key in obj) {
		const value = obj[key];
		let newValue;
		if (typeof value === 'object') {
			newValue = stripValueFromDimensionObject(value);
		} else {
			if (keysToTransform.includes(key)) {
				newValue = value ? `${stripValueFromDimension(value)}px` : undefined;
			} else if (key === 'qrCodeSize') {
				newValue = value || undefined;
			} else {
				newValue = value;
			}
		}
		newObject[key] = newValue;
	}
	return newObject;
};

export const transformElement = (
	element: { [key: string]: any },
	keyArray: string[],
	newValue: any
): { [key: string]: any } => {
	const newObject = { ...element };
	if (keyArray.length) {
		const oldValue = newObject[keyArray[0]];
		if (typeof oldValue === 'object') {
			newObject[keyArray[0]] = transformElement(oldValue, keyArray.slice(1), newValue);
		}
		if (keyArray.length === 1) {
			newObject[keyArray[0]] = newValue;
		}
	}
	return newObject;
};

export const createDefaultHeaderText = (): string => {
	return format(new Date(), 'dd MMM yyyy', { locale: de });
};
