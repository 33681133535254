import React from 'react';

import { Brand } from '@cinuru/utils/theme';

import { transformElement } from '../utils/emailConfigurationHelpers';
import { Wrapper } from '../utils/reusableStyledComponents';
import ApiRenderedComponent from './ApiRenderedComponent';
import { ColorController } from '../utils/allFormControlComponents';
import { FooterData } from '../../../utils/campaignEmailContent';

const FooterPreview = ({
	id,
	element,
	brand,
}: {
	id: string;
	element: FooterData;
	brand: Brand;
}) => {
	return (
		<ApiRenderedComponent
			id={id}
			brand={brand}
			componentName="DashboardEmailFooter"
			componentProps={element}
		/>
	);
};

export const FooterEditor = React.memo(
	({
		element,
		updateElement,
		brand,
	}: {
		element: FooterData;
		updateElement: (footerData) => void;
		brand: Brand;
	}) => {
		const handleChangeController = React.useCallback(
			(path: string, newValue: any) => {
				const pathArray = path.split('/');
				const newElement = transformElement(element, pathArray, newValue);
				updateElement(newElement as FooterData);
			},
			[element, updateElement]
		);

		return (
			<Wrapper>
				<Wrapper className="wrapper">
					<ColorController
						value={element.backgroundColor}
						brand={brand}
						onChange={handleChangeController}
						path="backgroundColor"
						label="Hintergrundfarbe"
						fallBackColor="background0"
					/>
				</Wrapper>
			</Wrapper>
		);
	}
);

export const FooterDefaultParams: FooterData = {
	type: 'FOOTER',
	backgroundColor: 'transparent',
	index: 1000,
};

export default React.memo(FooterPreview);
