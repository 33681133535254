import chroma from 'chroma-js';

export const transparentize = (val: number, color: string) => {
	const col = chroma(color);
	return col.alpha(col.alpha() - val).hex();
};

export const hexToRgba = (hex: string, opacity: number) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	const [r, g, b] = result
		? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
		: [0, 0, 0];
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
