import React from 'react';
import { Row, Col, Input, Button } from '../../components/AntDReplacements';

const TextArea = Input.TextArea;

const styles = {
	wrapper: {
		marginTop: '20px',
		marginBottom: '40px',
		borderTop: '1px',
	},
};

//TODO: cleanup this styles mess
export const SneakPreviewSection = ({ updateSneak, addSneak, removeSneak, sneaks }) => (
	<div style={styles.wrapper}>
		<Row>
			<Col>Sneak Previews</Col>
		</Row>
		<Row style={{ margin: '10px 0' }}>
			{Object.entries(sneaks).map(
				([key, { playtimeDescription, description, title, price, image }]) => (
					<Col span={7} key={key} style={{ marginBottom: '10px', marginLeft: '10px' }}>
						<Input
							value={title}
							placeholder="Titel..."
							onChange={(event) => updateSneak([key, 'title'], event.target.value)}
						/>
						<Input
							value={price}
							placeholder="Preis..."
							onChange={(event) => updateSneak([key, 'price'], event.target.value)}
						/>
						<TextArea
							value={description}
							placeholder="Beschreibung der Sneak..."
							onChange={(event) => updateSneak([key, 'description'], event.target.value)}
						/>
						<Input
							value={image}
							placeholder="Bild (Url)..."
							onChange={(event) => updateSneak([key, 'image'], event.target.value)}
						/>
						<Button type="danger" onClick={() => removeSneak(key)}>
							Löschen
						</Button>
					</Col>
				)
			)}
		</Row>
		<Row>
			<Col>
				<Button type="dashed" icon="plus-circle-o" onClick={() => addSneak({})} />
			</Col>
		</Row>
	</div>
);
