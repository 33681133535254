import * as React from 'react';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';
import { ThemeProvider as StyledNativeComponentsThemeProvider } from 'styled-native-components';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import { createTheme } from '@cinuru/utils/theme';
import { PortalProvider } from '@cinuru/components';
import muiTheme from '../utils/theme';

const ThemeProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
	const theme = React.useMemo(() => {
		return createTheme('CINURU', { darkMode: false });
	}, []);

	return (
		<StyledNativeComponentsThemeProvider
			theme={theme}
			rootBackgroundColor={muiTheme.palette.grey[100]}
		>
			<StyledComponentThemeProvider theme={muiTheme}>
				<MUIThemeProvider theme={muiTheme}>
					<PortalProvider>{children}</PortalProvider>
				</MUIThemeProvider>
			</StyledComponentThemeProvider>
		</StyledNativeComponentsThemeProvider>
	);
};

export default ThemeProvider;
