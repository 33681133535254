import * as React from 'react';
import { Grid, Box } from '@mui/material';
import { BoxProps as MuiBoxProps } from '@mui/material/Box';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import TimeUnitSelectField from './TimeUnitSelectField';
import type { TimeUnit as _TimeUnit, TimeUnitSelectFieldRef } from './TimeUnitSelectField';
import IntegerSelectField from './IntegerSelectField';
import type { IntegerSelectFieldRef } from './IntegerSelectField';

export type TimePeriodSelectFormSimpleRef = {
	validate: () => boolean;
};

export type TimeUnit = _TimeUnit;

interface TimePeriodSelectFormProps extends MuiBoxProps {
	variant?: MuiTextFieldProps['variant'];
	defaultTimeUnit?: TimeUnit;
	onChangeTimeUnit?: (value?: TimeUnit) => void;
	defaultTimeUnitFactor?: number;
	onChangeTimeUnitFactor?: (value?: number) => void;
	disabled?: boolean;
	enabledTimeUnits?: TimeUnit[];
}

const TimePeriodSelectForm = React.forwardRef<
	TimePeriodSelectFormSimpleRef,
	TimePeriodSelectFormProps
>(
	(
		{
			variant,
			defaultTimeUnit,
			onChangeTimeUnit,
			defaultTimeUnitFactor,
			onChangeTimeUnitFactor,
			disabled,
			enabledTimeUnits,
			...rest
		},
		ref
	): JSX.Element => {
		const integerSelectFieldRef = React.useRef<IntegerSelectFieldRef>(null);
		const timeUnitSelectFieldRef = React.useRef<TimeUnitSelectFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			const errors = [
				...[integerSelectFieldRef, timeUnitSelectFieldRef].map((r) => r.current?.validate()),
			];
			const invalid = errors.some(Boolean);
			return invalid;
		}, []);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<Box {...rest}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<IntegerSelectField
							label="Anzahl"
							defaultValue={defaultTimeUnitFactor}
							onChange={onChangeTimeUnitFactor}
							variant={variant}
							ref={integerSelectFieldRef}
							disabled={disabled}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TimeUnitSelectField
							onChange={onChangeTimeUnit}
							defaultValue={defaultTimeUnit}
							variant={variant}
							ref={timeUnitSelectFieldRef}
							disabled={disabled}
							enabledTimeUnits={enabledTimeUnits}
						/>
					</Grid>
				</Grid>
			</Box>
		);
	}
);

export default TimePeriodSelectForm;
