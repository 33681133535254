import * as React from 'react';
import { ID } from '@cinuru/utils/types';

import SearchSelectField, { SelectFieldItem } from './SearchSelectField';
import TextField from './TextField';
import { TextFieldProps } from '@mui/material/TextField';

import { useAllMovieLists, MovieList as _MovieList } from '../utils/movieList';

export type MovieList = _MovieList;

export type FilmSeriesSelectFieldRef = {
	validate: () => boolean;
};

const FilmSeriesSelectField = React.forwardRef<
	FilmSeriesSelectFieldRef,
	{
		label: string;
		onChange?: (movies: MovieList[]) => void;
		multi?: boolean;
		variant?: TextFieldProps['variant'];
		defaultItems?: { value: string; label: string }[] | null;
		defaultFilmSeriesId?: ID;
		m?: string;
		disabled?: boolean;
		cinemaIds?: ID[];
	}
>(
	(
		{
			label,
			onChange,
			multi,
			variant = 'outlined',
			defaultItems,
			m,
			disabled,
			defaultFilmSeriesId,
			cinemaIds,
		},
		ref
	): JSX.Element => {
		const allCinemaFilmSeries = useAllMovieLists(cinemaIds);

		const handleChange = React.useCallback(
			(items: SelectFieldItem[]) => {
				const populated: MovieList[] = allCinemaFilmSeries!.filter(({ id }) =>
					items.some(({ value }) => value === id)
				);
				onChange && onChange(populated);
			},
			[allCinemaFilmSeries, onChange]
		);

		const transformed = React.useMemo(
			() =>
				allCinemaFilmSeries?.map((filmSerie) => ({
					label: filmSerie.title!,
					value: filmSerie.id as string,
				})),
			[allCinemaFilmSeries]
		);

		const defaultItemsFromId = React.useMemo(
			() =>
				defaultFilmSeriesId
					? allCinemaFilmSeries
							?.filter((fs) => fs.id === defaultFilmSeriesId)
							?.map(({ id, title }) => ({ value: id as string, label: title! }))
					: undefined,
			[allCinemaFilmSeries, defaultFilmSeriesId]
		);

		return !allCinemaFilmSeries ? (
			<TextField label={label} variant={variant} m={m} isLoading />
		) : (
			<SearchSelectField
				allItems={transformed}
				label={label}
				onChange={handleChange}
				multi={multi}
				variant={variant}
				defaultItems={defaultItems || defaultItemsFromId}
				m={m}
				ref={ref}
				disabled={disabled}
			/>
		);
	}
);

export default FilmSeriesSelectField;
