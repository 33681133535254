import React from 'react';
import styledWeb from 'styled-components';
import {
	TextField,
	Typography,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Box,
	Grid,
	Card,
	CardContent,
	IconButton,
	Button,
} from '@mui/material';

import { Delete, Add } from '@mui/icons-material';

import Expandable from '../../components/Expandable';

const keyDictionary = {
	weekdays: 'Wochentage',
	hours: 'Zeit',
	title: 'Bezeichnung',
	description: 'Beschreibung',
	price: 'Preis',
	image: 'Bild',
};

export const CheckboxSection = ({
	label,
	options,
	defaultValue,
	onChange,
	margin,
}: {
	label: string;
	options: { label: string; value: string | boolean }[];
	defaultValue: string | boolean;
	onChange: (optionId: string | boolean) => void;
	margin?: string;
}): JSX.Element => {
	const [selected, setSelected] = React.useState(defaultValue);

	const handleSelect = React.useCallback(
		(optionId) => {
			setSelected(optionId);
			onChange && onChange(optionId);
		},
		[onChange]
	);

	return (
		<Box m={margin}>
			<Typography>{label}</Typography>
			<FormGroup>
				{options.map((option, index) => (
					<FormControlLabel
						key={index}
						control={
							<Checkbox
								checked={selected === option.value}
								// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
								onChange={() => handleSelect(option.value)}
							/>
						}
						label={option.label}
					/>
				))}
			</FormGroup>
		</Box>
	);
};

const StyledBox = styledWeb(Box)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

const SectionItem = React.memo(
	({
		item,
		index,
		onHandleChange,
		onHandleAddItem,
		onHandleDeleteItem,
		itemKeys,
		margin,
	}: {
		item: { [key: string]: string | undefined };
		index: number;
		onHandleChange: (value: string, index: number, key: string) => void;
		onHandleAddItem: (index?: number) => void;
		onHandleDeleteItem: (index: number) => void;
		itemKeys: string[];
		margin?: string;
	}): JSX.Element => {
		const handleAddItem = React.useCallback(() => {
			onHandleAddItem && onHandleAddItem(index);
		}, [index, onHandleAddItem]);

		const handleDeleteItem = React.useCallback(() => {
			onHandleDeleteItem && onHandleDeleteItem(index);
		}, [index, onHandleDeleteItem]);

		const handleChange = React.useCallback(
			(event, key) => {
				const newValue = event?.target?.value;
				onHandleChange && onHandleChange(newValue, index, key);
			},
			[index, onHandleChange]
		);

		return (
			<Box m={margin}>
				<Card variant="outlined">
					<CardContent>
						<Grid container spacing={2}>
							{itemKeys.map((key) => (
								<Grid key={key} item xs={12}>
									<TextField
										value={item[key] || ''}
										label={keyDictionary[key]}
										fullWidth={true}
										maxRows={5}
										// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
										onChange={(event) => handleChange(event, key)}
										multiline
										variant="standard"
									/>
								</Grid>
							))}
						</Grid>
					</CardContent>
					<StyledBox m="0 1rem 0">
						<IconButton onClick={handleDeleteItem} size="large">
							<Delete />
						</IconButton>
						<IconButton onClick={handleAddItem} size="large">
							<Add />
						</IconButton>
					</StyledBox>
				</Card>
			</Box>
		);
	}
);

export const TextfieldSection = React.memo(
	({
		label,
		handleChange,
		handleAddItem,
		handleDeleteItem,
		items,
		itemKeys,
		margin,
		itemMargin,
		itemPadding,
	}: {
		label: string;
		handleChange: (value: string, index: number, key: string) => void;
		handleAddItem: (index?: number) => void;
		handleDeleteItem: (index: number) => void;
		items: { [key: string]: string | undefined }[];
		itemKeys: string[];
		margin?: string;
		itemMargin?: string;
		itemPadding?: string;
	}): JSX.Element => {
		return (
			<Box m={margin}>
				<Expandable label={label}>
					<Box m="2rem 0 1rem">
						{items?.map((item, i) => (
							<SectionItem
								key={i}
								item={item}
								index={i}
								onHandleChange={handleChange}
								onHandleAddItem={handleAddItem}
								onHandleDeleteItem={handleDeleteItem}
								itemKeys={itemKeys}
								margin={itemMargin}
							/>
						))}
						{items.length === 0 ? (
							<Box m="0 0.5rem 0 0">
								<Button color="primary" variant="outlined" onClick={handleAddItem}>
									Hinzufügen
								</Button>
							</Box>
						) : null}
					</Box>
				</Expandable>
			</Box>
		);
	}
);
