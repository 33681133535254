import React from 'react';
import MuiAlert from '@mui/material/Alert';
import type { Color } from '@mui/material';
import { Snackbar } from '@mui/material';

const AlertSnackBar = ({
	severity,
	show,
	handleClose,
	message,
}: {
	severity?: string;
	show?: boolean;
	handleClose?: () => void;
	message: string;
}): JSX.Element => {
	return (
		<Snackbar open={show} autoHideDuration={6000} onClose={handleClose}>
			<MuiAlert elevation={6} variant="filled" severity={severity}>
				{message}
			</MuiAlert>
		</Snackbar>
	);
};

export default AlertSnackBar;
