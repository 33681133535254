import _ColorController from '../ContentTypes/FormControls/ColorController'; // keep
import Cinemas from '../ContentTypes/FormControls/CinemasController'; // keep
import FilmSeries from '../ContentTypes/FormControls/FilmSeriesController'; // keep
import InfoPopper from '../ContentTypes/FormControls/InfoPopper'; // keep
import TextEditor from '../ContentTypes/FormControls/TextEditorController'; // keep

import CheckBox from '../ContentTypes/FormControls/CheckBoxController';
import Select from '../ContentTypes/FormControls/SelectController';

export const ColorController = _ColorController;
export const CinemasController = Cinemas;
export const FilmSeriesController = FilmSeries;
export const InfoPopperController = InfoPopper;
export const TextEditorController = TextEditor;
export const CheckBoxController = CheckBox;
export const SelectController = Select;
