/** may need some refactoring, no native support */

// @flow
import * as React from 'react';
import { debounce } from 'lodash';
import { Platform } from 'react-native';
import styled from 'styled-native-components';

import Icon from './Icon';
import Label from './Label';

export type Time = { days?: number, hours?: number, minutes?: number, seconds?: number };

const Wrapper = styled.View`
	${(p) => (p.nWidth ? `width: ${p.nWidth}rem` : '')};
	flex-direction: row;
	justify-content: space-between;
	background-color: ${(p) => (p.altBackground ? '$background0' : '$background1')};
`;

const getColor = (p) => {
	let background = p.theme.colors[p.altBackground ? 'background0' : 'background1'];
	if (p.active) background = p.theme.colors.blend(0.15, background, p.theme.colors.neutral3);
	return background;
};

const Column = styled.View`
	flex: 1;
	align-items: center;
	margin: 0 1rem;
	padding: 1rem 0;
	background-color: ${getColor};
`;

const Separator = styled(Number)`
	margin-top: 5.25rem;
	font-weight: 700;
`;

const ChangeButton = styled(Icon).attrs((p) => ({
	name: 'chevron',
	rotation: p.down ? -180 : 0,
	size: '2.5rem',
	color: p.disabled ? 'border0' : '$neutral2',
}))`
	${(p) => (Platform.OS === 'web' && p.disabled ? 'cursor: not-allowed' : '')};
`;

class ValuePicker extends React.PureComponent {
	handleIncrease = () => this.props.onIncrease && this.props.onIncrease(this.props.name);
	handleDecrease = () => this.props.onDecrease && this.props.onDecrease(this.props.name);

	render = () => {
		const { label, value, disableDecrease, disableIncrease, altBackground, active } = this.props;
		return (
			<Column altBackground={altBackground} active={active}>
				<Label size="s" align="center" color="$neutral2">
					{label}
				</Label>
				<ChangeButton onPress={this.handleIncrease} disabled={disableIncrease} />
				<Label align="center" light>
					{value}
				</Label>
				<ChangeButton
					onPress={!disableDecrease && this.handleDecrease}
					down
					disabled={disableDecrease}
				/>
			</Column>
		);
	};
}

export const secondValues = { days: 24 * 60 * 60, hours: 60 * 60, minutes: 60, seconds: 1 };

const divRemainder = (a, b) => [(a - (a % b)) / b, a % b];
export const secondsToTimeObject = (seconds) =>
	Object.keys(secondValues).reduce((timeFormat, name) => {
		let value = 0;
		[value, seconds] = divRemainder(seconds, secondValues[name]);
		return { ...timeFormat, [name]: value };
	}, {});

export const timeObjectToSeconds = (timeObject: ?Object): number =>
	Object.keys(timeObject || {}).reduce(
		(seconds, name) => seconds + timeObject[name] * secondValues[name],
		0
	);

type Props = {};
type State = { value?: Time, active: number | null };
export default class TimePicker extends React.PureComponent<Props, State> {
	state = { value: this.props.value || 0, active: null };

	static defaultProps = {
		changeInterval: { minutes: 5, seconds: 10 },
		showValues: ['days', 'hours', 'minutes', 'seconds'],
		valueLabels: { days: 'TAG', hours: 'STD', minutes: 'MIN', seconds: 'SEK' },
		inputChangeDebounce: 167,
	};

	constructor(props) {
		super(props);
		this.debouncedHandleChange = debounce(this.handleChange, props.inputChangeDebounce);
	}

	componentWillUnmount = () => this.debouncedHandleChange.cancel();

	handleChange = (value) => this.props.onChange && this.props.onChange(value);

	setValue = (value) => this.setState({ value });

	setNextActive = () =>
		this.setState((state) => ({
			active:
				state.active === null
					? 0
					: state.active + 1 < this.props.showValues.length
					? state.active + 1
					: null,
		}));

	setPrevActive = () =>
		this.setState((state) => ({
			active:
				state.active === null
					? this.props.showValues.length - 1
					: state.active - 1 >= 0
					? state.active - 1
					: null,
		}));

	increaseActive = () =>
		this.state.active >= 0 && this.handleIncrease(this.props.showValues[this.state.active]);
	decreaseActive = () =>
		this.state.active >= 0 && this.handleDecrease(this.props.showValues[this.state.active]);

	canDecrease = (name) =>
		this.state.value - (this.props.changeInterval[name] || 1) * secondValues[name] >= 0;
	canIncrease = (name) =>
		this.props.interval ||
		this.state.value + (this.props.changeInterval[name] || 1) * secondValues[name] <
			secondValues.days;

	handleChangeValue = (name, direction) =>
		this.setState(
			(state) => {
				if (direction < 0 ? !this.canDecrease(name) : !this.canIncrease(name)) return {};
				const newValue =
					state.value + direction * (this.props.changeInterval[name] || 1) * secondValues[name];
				return { value: newValue };
			},
			() => this.debouncedHandleChange(this.state.value)
		);

	handleIncrease = (name) => this.handleChangeValue(name, +1);
	handleDecrease = (name) => this.handleChangeValue(name, -1);

	render = () => {
		const { altBackground, nWidth, showValues, valueLabels } = this.props;
		const { value, active } = this.state;
		const timeObject = secondsToTimeObject(value);
		return (
			<Wrapper onMouseLeave={this.handleResetActive} altBackground={altBackground} nWidth={nWidth}>
				{showValues.map((name, i, arr) => [
					<ValuePicker
						altBackground={altBackground}
						active={showValues[active] === name}
						key={name}
						label={valueLabels[name]}
						name={name}
						value={timeObject[name]}
						onIncrease={this.handleIncrease}
						onDecrease={this.handleDecrease}
						disableDecrease={!this.canDecrease(name)}
						disableIncrease={!this.canIncrease(name)}
					/>,
					i !== arr.length - 1 ? <Separator key={i}>:</Separator> : null,
				])}
			</Wrapper>
		);
	};
}
