import * as React from 'react';
import { ID } from '@cinuru/utils/types';

import SearchSelectField, { SelectFieldItem, SearchSelectFieldRef } from './SearchSelectField';
import { useAllMovies, Movie as _Movie } from '../utils/movie';
import { TextFieldProps } from '@mui/material/TextField';
import TextField from '../components/TextField';

export type Movie = _Movie;
export type MovieSelectFieldRef = SearchSelectFieldRef;

const MovieSelectField = React.forwardRef<
	MovieSelectFieldRef,
	{
		label: string;
		onChange?: (movies: Movie[] | []) => void;
		multi?: boolean;
		variant?: TextFieldProps['variant'];
		defaultItems?: { value: string; label: string }[] | null;
		defaultMovieId?: ID;
		m?: string;
		disabled?: boolean;
	}
>(
	(
		{ label, onChange, multi, variant = 'outlined', defaultItems, m, disabled, defaultMovieId },
		ref
	): JSX.Element => {
		const allMovies = useAllMovies();

		const handleChange = React.useCallback(
			(items: SelectFieldItem[]) => {
				const populatedMovies: Movie[] = allMovies!.filter(({ id }) =>
					items.some(({ value }) => value === id)
				);
				onChange && onChange(populatedMovies);
			},
			[allMovies, onChange]
		);

		const transformed = React.useMemo(
			() => allMovies?.map((movie) => ({ label: movie.title, value: movie.id as string })),
			[allMovies]
		);

		const defaultItemsFromMovieId = React.useMemo(
			() =>
				defaultMovieId
					? allMovies
							?.filter((m) => m.id === defaultMovieId)
							?.map(({ id, title }) => ({ value: id as string, label: title }))
					: undefined,
			[allMovies, defaultMovieId]
		);

		return !allMovies ? (
			<TextField label={label} variant={variant} m={m} isLoading />
		) : (
			<SearchSelectField
				allItems={transformed}
				label={label}
				onChange={handleChange}
				multi={multi}
				variant={variant}
				defaultItems={defaultItems || defaultItemsFromMovieId}
				m={m}
				ref={ref}
				disabled={disabled}
			/>
		);
	}
);

export default MovieSelectField;
