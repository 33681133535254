import React from 'react';
import { Row, Col, Input } from '../../components/AntDReplacements';

const TextArea = Input.TextArea;
const styles = {
	wrapper: {
		marginTop: '20px',
		marginBottom: '40px',
	},
};

//TODO: Cleanup this style mess

export const AdressSection = ({ cinema, updateAddress }) => (
	<div style={styles.wrapper}>
		<Row>
			<Col>Adresse / Anfahrt</Col>
		</Row>
		<Row style={{ margin: '10px 0' }}>
			<Col>
				<Row style={{ margin: '10px 0' }}>
					<Col>Straße</Col>
					<Col>
						<TextArea
							value={cinema.street}
							autosize
							onChange={(event) => updateAddress('street', event.target.value)}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '10px 0' }}>
					<Col>Hausnummer</Col>
					<Col>
						<TextArea
							value={cinema.houseNumber}
							autosize
							onChange={(event) => updateAddress('houseNumber', event.target.value)}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '10px 0' }}>
					<Col>Postleitzahl</Col>
					<Col>
						<TextArea
							value={cinema.zipCode}
							autosize
							onChange={(event) => updateAddress('zipCode', event.target.value)}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '10px 0' }}>
					<Col>Stadt</Col>
					<Col>
						<TextArea
							value={cinema.city}
							autosize
							onChange={(event) => updateAddress('city', event.target.value)}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '10px 0' }}>
					<Col>Beschreibung Parken</Col>
					<Col>
						<TextArea
							value={cinema.parkingDescription}
							autosize
							onChange={(event) => updateAddress('parkingDescription', event.target.value)}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '10px 0' }}>
					<Col>Beschreibung Lage</Col>
					<Col>
						<TextArea
							value={cinema.locationDescription}
							autosize
							onChange={(event) => updateAddress('locationDescription', event.target.value)}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '10px 0' }}>
					<Col>Google Maps Link</Col>
					<Col>
						<TextArea
							value={cinema.googleMapsLink}
							autosize
							onChange={(event) => updateAddress('googleMapsLink', event.target.value)}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '10px 0' }}>
					<Col>Apple Maps Link</Col>
					<Col>
						<TextArea
							value={cinema.appleMapsLink}
							autosize
							onChange={(event) => updateAddress('appleMapsLink', event.target.value)}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '10px 0' }}>
					<Col>Coordinaten</Col>
					<Col span={11}>
						<Input
							value={cinema.latitude}
							onChange={(event) => updateAddress('latitude', event.target.value)}
						/>
					</Col>
					<Col span={11} offset={2}>
						<Input
							value={cinema.longitude}
							onChange={(event) => updateAddress('longitude', event.target.value)}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	</div>
);
