import { Dialog } from '@cinuru/components';

export const showErrorDialog = (title) =>
	Dialog.render({
		title,
		textColor: '$background0',
		color: '$error',
		buttons: [
			{
				label: 'SUPPORT KONTAKTIEREN',
				onPress: () => {
					document.location = `mailto:support@cinuru.com?body=${encodeURIComponent(title)}`;
				},
				link: false,
				color: '$background0',
				textColor: '$error',
			},
		],
	});
