import * as React from 'react';
import styled, { useTheme } from 'styled-native-components';
import { Platform, Animated, View } from 'react-native';

import Icon from './Icon';
import LoadingIndicator from './LoadingIndicator';
import TextWithLink from './TextWithLink';
import Touchable from './Touchable';

import type { TouchableRef } from './Touchable';

const Wrapper = styled.View<{ margin: string }>`
	padding: 1rem 0;
	flex-flow: row;
	align-items: center;
	margin: ${(p) => p.margin};
`;

const Box = styled(Touchable).attrs<
	{ small?: boolean; altBackground?: boolean; error?: string; altBorderColor?: string },
	{ hitSlop?: { top: number; left: number; bottom: number; right: number }; activeOpacity: number }
>((p) => ({
	activeOpacity: 1,
	hitSlop: p.small
		? { top: p.theme.rem, right: p.theme.rem, bottom: p.theme.rem, left: p.theme.rem }
		: undefined,
}))`
	justify-content: center;
	align-items: center;
	width: ${(p) => (p.small ? 3.5 : 5)}rem;
	height: ${(p) => (p.small ? 3.5 : 5)}rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	background-color: ${(p) => (p.altBackground ? '$background0' : '$background1')};
	border-width: 1px;
	border-color: ${(p) => (p.error ? '$error' : p.altBorderColor || '$border0')};
	margin-right: ${(p) => (p.small ? 1.5 : 1)}rem;
`;

const Checkbox = React.forwardRef<
	TouchableRef,
	{
		loading?: boolean;
		label: string;
		error?: string;
		onLinkPress?: (link: string) => void;
		altBackground?: boolean;
		checkmarkColor?: string;
		labelColor?: string;
		small?: boolean;
		margin?: string;
		testID?: string;
		altBorderColor?: string;
	} & (
		| {
				defaultValue?: boolean;
				value?: undefined;
				onChange?: undefined;
		  }
		| {
				defaultValue?: undefined;
				value: boolean;
				onChange: (val: boolean) => void;
		  }
	)
>(
	(
		{
			label,
			loading,
			defaultValue,
			value,
			error,
			onChange,
			altBackground,
			onLinkPress,
			checkmarkColor = '$neutral0',
			labelColor = '$neutral2',
			small,
			margin = '0',
			testID,
			altBorderColor,
		},
		ref
	) => {
		const controlled = typeof value === 'boolean';
		const [checked, setChecked] = React.useState(defaultValue || value || false);
		const checkedValue = controlled ? value : checked;
		const handlePress = React.useCallback(
			() => (controlled ? onChange && onChange(!value) : setChecked((v) => !v)),
			[value, onChange, controlled]
		);
		const animation = React.useRef(new Animated.Value(checkedValue ? 1 : 0));
		React.useEffect(() => {
			Animated.timing(animation.current, {
				toValue: checkedValue ? 1 : 0,
				duration: 100,
				useNativeDriver: Platform.OS !== 'web',
			}).start();
		}, [checkedValue]);
		const theme = useTheme();
		const style = React.useMemo(
			() => ({
				position: 'absolute',
				width: (small ? 3.5 : 5) * theme.rem,
				height: (small ? 3.5 : 5) * theme.rem,
				left: (small ? 0.5 : 0.75) * theme.rem,
				opacity: animation.current,
				transform: [
					{
						scale: animation.current.interpolate({
							inputRange: [0, 1],
							outputRange: [0.5, 1],
						}),
					},
					{
						rotate: animation.current.interpolate({
							inputRange: [0, 1],
							outputRange: ['30deg', '0deg'],
						}),
					},
					{
						translateY: animation.current.interpolate({
							inputRange: [0, 1],
							outputRange: [(small ? 1 : 2) * theme.rem, 0],
						}),
					},
				],
			}),
			[small, theme.rem]
		);
		return (
			<Wrapper margin={margin}>
				{loading ? (
					<LoadingIndicator />
				) : (
					<View>
						<Box
							testID={testID}
							error={error}
							onPress={handlePress}
							altBackground={altBackground}
							small={small}
							ref={ref}
							altBorderColor={altBorderColor}
						/>
						<Animated.View style={style} pointerEvents="none">
							<Icon name="checkmark" size={(small ? 3.5 : 5) + 'rem'} color={checkmarkColor} />
						</Animated.View>
					</View>
				)}
				<TextWithLink
					onTextPress={handlePress}
					onLinkPress={onLinkPress}
					size="s"
					margin={small ? '0' : '0.5rem 0'}
					color={error ? '$error' : labelColor}
				>
					{label}
				</TextWithLink>
			</Wrapper>
		);
	}
);

export default React.memo(Checkbox);
