import * as React from 'react';
import shortid from 'shortid';
import styled from 'styled-native-components';
import { Platform, Animated, Easing } from 'react-native';

import type { GestureResponderEvent, LayoutChangeEvent, LayoutRectangle } from 'react-native';

const Wrapper = styled.View`
	flex: 1;
`;

const WebPortalTarget = () => {
	const webStyles = React.useMemo(
		() => ({
			position: 'absolute' as const,
			width: '100%',
			height: '100%',
			zIndex: 100,
			pointerEvents: 'none' as const,
		}),
		[]
	);
	return <div id="web-portal-target" style={webStyles} />;
};

const globalTouchResponders = new Map();
/**
 * Register a responder to global touch events on the PortalProvider
 *
 * @remarks
 * This function is build specifically for PickerKeyboard.ios to enable dismissing the
 * PickerKeyboard when tapping outside of the Keyboard area while allowing for the same
 * touch events to also bubble through to the other components under it. For example given
 * you have a TextField and a SelectField and the SelectField is focused and the PickerKeyboard
 * for it displayed, when you tap into a TextField then both the onFocus of the TextField
 * needs to be triggered and the PickerKeyboard needs to be dismissed. To achieve that
 * onStartShouldSetResponderCapture is used to capture the touch events.
 *
 * @param onTouch - the touch handler
 */
export const useGlobalTouchResponder = (
	onTouch: (e: {
		xOffset: number;
		yOffset: number;
		windowHeight: number;
		windowWidth: number;
	}) => void
): void => {
	React.useEffect(() => {
		const key = shortid.generate();
		globalTouchResponders.set(key, onTouch);
		return () => {
			globalTouchResponders.delete(key);
		};
	}, [onTouch]);
};

const PortalWrapper = styled.View.attrs({
	pointerEvents: 'box-none',
})`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 50;
`;

type AnimationConfig = {
	type?: 'spring' | 'easeOut' | 'easeIn' | 'easeInOut';
	speed?: number;
	bounciness?: number;
	duration?: number;
};
const animateValue = (value: Animated.Value, toValue: number, config: AnimationConfig) => {
	if (config.type === 'spring') {
		return Animated.spring(value, {
			toValue,
			useNativeDriver: true,
			speed: config.speed || 20,
			bounciness: config.bounciness,
		}).start;
	} else {
		return Animated.timing(value, {
			toValue,
			useNativeDriver: true,
			easing:
				config.type === 'easeOut'
					? Easing.out(Easing.ease)
					: config.type === 'easeIn'
					? Easing.in(Easing.ease)
					: Easing.inOut(Easing.ease),
			duration: config.duration || 200,
		}).start;
	}
};

const PortalContent = React.memo(
	({
		id,
		componentName,
		portalLayout,
		animationConfig,
		onRegisterTransitionOut,
		...props
	}: {
		id: string;
		componentName: string;
		portalLayout?: LayoutRectangle;
		animationConfig?: [AnimationConfig | undefined, AnimationConfig | undefined];
		onRegisterTransitionOut: (
			id: string,
			transitionOut: (onTransionFinished: () => void) => void
		) => void;
	} & unknown) => {
		const transition = React.useRef(new Animated.Value(0));
		React.useLayoutEffect(() => {
			const inConfig = animationConfig?.[0] || { type: 'spring' };
			animateValue(transition.current, 1, inConfig)();
			onRegisterTransitionOut(id, (onTransionFinished) => {
				const outConfig = animationConfig?.[1] || { type: 'easeOut', duration: 200 };
				animateValue(transition.current, 0, outConfig)(onTransionFinished);
			});
		}, [animationConfig, id, onRegisterTransitionOut]);

		const handleDismiss = React.useCallback(() => globalThis.portalRef?.unmount(id), [id]);
		if (!(globalThis.portalRef && componentName in globalThis.portalRef.components)) {
			throw new Error(`trying to render unknown portal ${componentName}`);
		}
		const Component = globalThis.portalRef.components[componentName];
		return (
			<PortalWrapper>
				<Component
					{...props}
					dismissPortal={handleDismiss}
					portalLayout={portalLayout}
					transition={transition.current}
				/>
			</PortalWrapper>
		);
	}
);

/**
 * Provider that portal instances are rendered into, should live right under the ThemeProvider.
 *
 * @remarks
 * ReactNatives Modal has been very buggy, this is a replacement for it that allows a more
 * imperative way of rendering components. This also fullfills a very specific use case for
 * web, to prevent event bubbling through ReactDOM Portals (see PortalTrigger). Preventing
 * event bubbling through web portals is needed in the EditorField where a dropdown is nested
 * in another dorpdown and the dismissal of the inner dropdown should not also close the outer
 * dropdown.
 *
 * The implementation is relatively simple, the provider holds a list of portal instances in
 * it's state that are rendered in an absolutely positioned layer over the main app layer that
 * is passed through the children prop. To render a component into the portal layer, there are
 * static methods render and unmount on the provider that allow to imperatively mount/rerender
 * and unmount a component with given props identified by a unique id. This allows imperatively
 * rendering something into this portal, that can happen either from imperative code outside
 * of react, for example to render a Dialog, or inside another react component in an effect hook
 */
const PortalProvider = ({
	children,
}: {
	/** The main app layer over which portals are rendered */
	children: React.ReactNode;
}): JSX.Element => {
	const [contents, setContents] = React.useState<
		{ id: string; componentName: string; props: unknown }[]
	>([]);

	const contentTransitions = React.useRef<{
		[id: string]: (onTransionFinished: () => void) => void;
	}>({});
	const handleRegisterTransition = React.useCallback((id, transitionOut) => {
		contentTransitions.current[id] = transitionOut;
	}, []);

	// register the global portal ref, using globalThis ensures fast refresh works
	React.useLayoutEffect(() => {
		globalThis.portalRef = {
			components: {},
			render: (id: string, componentName: string, props: unknown): void => {
				setContents((cs) => {
					const contentIndex = cs.findIndex((c) => c.id === id);
					if (contentIndex >= 0) {
						const newContents = [...cs];
						newContents[contentIndex] = { id, componentName, props };
						return newContents;
					} else {
						return [...cs, { id, componentName, props }];
					}
				});
			},
			unmount: (id: string): void => {
				contentTransitions.current[id]?.(() => {
					setContents((cs) => cs.filter((c) => c.id !== id));
				});
			},
		};
		// reset global portal ref on unmount
		return () => {
			globalThis.portalRef = null;
		};
	}, []);

	const [portalLayout, setPortalLayout] = React.useState<LayoutRectangle | undefined>();
	const handleMeasureWrapper = React.useCallback(
		({ nativeEvent: { layout } }: LayoutChangeEvent) => setPortalLayout(layout),
		[]
	);

	const handleGlobalTouches = React.useCallback(
		({ nativeEvent }: GestureResponderEvent) => {
			const { pageX, pageY } = nativeEvent;
			globalTouchResponders.forEach((responder) =>
				responder({
					xOffset: pageX,
					yOffset: pageY,
					windowHeight: portalLayout?.height,
					windowWidth: portalLayout?.width,
				})
			);
			return false;
		},
		[portalLayout]
	);

	return (
		<Wrapper onLayout={handleMeasureWrapper} onStartShouldSetResponderCapture={handleGlobalTouches}>
			{children}
			{contents.map(({ id, componentName, props }) => (
				<PortalContent
					onRegisterTransitionOut={handleRegisterTransition}
					key={id}
					id={id}
					componentName={componentName}
					portalLayout={portalLayout}
					{...props}
				/>
			))}
			{Platform.OS === 'web' ? <WebPortalTarget /> : null}
		</Wrapper>
	);
};

/**
 * Render a given component inside the PortalProvider
 *
 * @param id - A unique id for the portal instance, use a constant or shortid.generate()
 * @param Component - The component to use for rendering the portal instance
 * @param props - The props for this portal instance that will be passed to the provided component
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
PortalProvider.render = function <P>(
	id: string,
	Component: React.ComponentType<P>,
	props: Omit<P, 'dismissPortal' | 'portalLayout' | 'transition'> & {
		animationConfig?: [AnimationConfig | undefined, AnimationConfig | undefined];
	}
): void {
	const componentName = Component.name;
	if (!componentName) throw new Error('Portal components need a name attribute');
	if (!globalThis.portalRef) throw new Error('No PortalProvider present');
	// register component if it doesn't exist yet
	if (!(componentName in globalThis.portalRef.components)) {
		globalThis.portalRef.components[componentName] = Component;
	}
	globalThis.portalRef.render(id, componentName, props);
};

/**
 * Transitions a specific portal instace out and then unmounts it from the PortalProvider
 *
 * @param id - The unique id of portal instance that should be unmounted
 */
PortalProvider.unmount = (id: string): void => globalThis.portalRef?.unmount(id);

export default PortalProvider;
