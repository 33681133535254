import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { ID } from '@cinuru/utils/types';

import { MovieLinkSelectFieldRef } from './MovieLinkSelectField';
import SelectField, { SelectFieldRef } from '../../components/SelectField';
import { Movie } from '../../utils/movie';
import { CampaignLink, CampaignLinkType, getCampaignLinkTypeAndValue } from '../../utils/campaign';
import MovieSelectField from '../../components/MovieSelectField';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import { CustomAppViewEditor } from './CustomAppViewEditor';
import Tooltip from '../../components/Tooltip';
import CheckBoxSection from '../../components/CheckBoxSection';
import FilmSeriesSelectField, {
	FilmSeriesSelectFieldRef,
} from '../../components/FilmSeriesSelectField';

export type PushLinkEditorRef = {
	validate: () => boolean;
};

const linkOptions: { label: string; value: CampaignLinkType }[] = [
	{ label: 'Keine Verlinkung', value: 'NONE' },
	{ label: 'Film-Details', value: 'APP_VIEW_MOVIE_DETAIL' },
	{ label: 'Programm', value: 'APP_VIEW_PROGRAM' },
	{ label: 'Merkliste', value: 'APP_VIEW_WATCHLIST' },
	{ label: 'Profil', value: 'APP_VIEW_PROFILE' },

	{ label: 'Bonusprogramm', value: 'APP_VIEW_BONUSPROGRAM' },
	{ label: 'Gutscheinübersicht', value: 'APP_VIEW_VOUCHER' },
	{ label: 'Vorteile', value: 'APP_VIEW_PERKS' },
	{ label: 'Stickerübersicht', value: 'APP_VIEW_STICKER' },

	{ label: 'Webansicht', value: 'WEB_VIEW' },
	{ label: 'App-Deeplink', value: 'DEEPLINK' },

	/*{ label: 'App-Ansicht Filmreihen-Details', value: 'APP_VIEW_FILM_SERIES_DETAIL' },
	{ label: 'App-Ansicht Sticker-Details', value: 'APP_VIEW_STICKER_DETAIL' },
	{ label: 'App-Ansicht Event-Details', value: 'APP_VIEW_EVENT' },*/
];

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
	align-self: stretch;
`;

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const options = [
	{
		label: 'Detailseite mit weiteren Infos',
		value: 'CUSTOM_APP_VIEW',
	},
	{
		label: 'Link auf bestehende App-Ansicht',
		value: 'PREDEFINED_APP_VIEW',
	},
];

const tooltip1 =
	'In diesem Abschnitt wird definiert welche App-Ansicht angezeigt wird, nachdem der Nutzer die Push-Nachricht angeklickt hat. Es besteht die Möglichkeit eine eigene App-Ansicht oder eine der vordefinierten App-Ansichten anzuzeigen.';
const tooltip2 =
	'Hier wird definiert welche App-Ansicht angezeigt wird, nachdem der Nutzer auf den Link in der angezeigten App-Ansicht geklickt hat.';

const PushLinkEditor = React.forwardRef<
	PushLinkEditorRef,
	{
		defaultLink?: CampaignLink;
		disabled?: boolean;
		onChange: (newLink?: CampaignLink) => void;
		hasNewsItem?: boolean;
		onSaveCampaign?: (campaignLink?: CampaignLink) => void;
		campaignCinemaIds?: ID[];
	}
>(
	(
		{ defaultLink, disabled, onChange, onSaveCampaign, hasNewsItem, campaignCinemaIds },
		ref
	): JSX.Element => {
		const [defaultLinkType, defaultLinkValue] = getCampaignLinkTypeAndValue(defaultLink);

		const [linkType, setLinkType] = React.useState<CampaignLinkType | undefined>(defaultLinkType);
		const [linkValue, setLinkValue] = React.useState<string | undefined>(defaultLinkValue);

		const handleChangeLinkType = React.useCallback(
			(newLinkType: string | null) => {
				const sanitzedNewLinkType = newLinkType ? (newLinkType as CampaignLinkType) : undefined;
				const newLink = sanitzedNewLinkType
					? (`${sanitzedNewLinkType}:` as CampaignLink)
					: undefined;
				setLinkType(sanitzedNewLinkType);
				setLinkValue(undefined);
				onChange(newLink);
			},
			[onChange]
		);

		const handleChangeLinkValue = React.useCallback(
			(newLinkValue: string | null) => {
				const sanitizedNewLinkValue = newLinkValue || undefined;
				const newLink: CampaignLink = `${linkType}:${sanitizedNewLinkValue}`;
				setLinkValue(sanitizedNewLinkValue);
				onChange(newLink);
			},
			[linkType, onChange]
		);

		const handleSelectFieldChange = React.useCallback(
			(newSelectedItems: (Movie | any)[]) => {
				// TODO: any
				const newSelectedItem = newSelectedItems[0];
				handleChangeLinkValue(newSelectedItem ? (newSelectedItem?.id as string) : null);
			},
			[handleChangeLinkValue]
		);

		const handleSaveNewsItem = React.useCallback(
			(newsItemId) => {
				handleChangeLinkValue(newsItemId);
				onSaveCampaign?.(`CUSTOM_APP_VIEW:${newsItemId}`);
				Dialog.unmount('CustomAppView');
			},
			[handleChangeLinkValue, onSaveCampaign]
		);

		const renderContent = React.useCallback(
			({ dismissPortal }: { dismissPortal: () => void }) => (
				<CustomAppViewEditor
					newsItemId={linkValue}
					disabled={disabled}
					onSave={handleSaveNewsItem}
					dismissPortal={dismissPortal}
					campaignCinemaIds={campaignCinemaIds}
				/>
			),
			[campaignCinemaIds, defaultLinkType, disabled, handleSaveNewsItem, linkValue]
		);

		const handleOpenNewsEditor = React.useCallback(() => {
			Dialog.render(
				{
					renderContent,
					buttons: [],
					isLocked: true,
				},
				'CustomAppView'
			);
		}, [renderContent]);

		const linkTypeSelectFieldRef = React.useRef<SelectFieldRef>(null);
		const movieLinkSelectFieldRef = React.useRef<MovieLinkSelectFieldRef>(null);
		const filmSeriesSelectFieldRef = React.useRef<FilmSeriesSelectFieldRef>(null);

		const handleChangeHasCustomAppView = React.useCallback(
			(value) => {
				const val = value as 'CUSTOM_APP_VIEW' | 'PREDEFINED_APP_VIEW';
				if (val === 'CUSTOM_APP_VIEW') {
					handleChangeLinkType('CUSTOM_APP_VIEW');
				} else {
					handleChangeLinkType(null);
				}
			},
			[handleChangeLinkType]
		);

		// validation
		const handleValidation = React.useCallback(() => {
			const invalid = [linkTypeSelectFieldRef, movieLinkSelectFieldRef, filmSeriesSelectFieldRef]
				.map((r) => r?.current?.validate())
				.some(Boolean);
			return invalid;
		}, []);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidation,
			}),
			[handleValidation]
		);

		return (
			<Column>
				<Row alignItems="center" m="2rem 0 2rem 0">
					<Typography variant="h6">{hasNewsItem ? 'Push-Verlinkgung' : 'Link'}</Typography>
					<Tooltip text={hasNewsItem ? tooltip1 : tooltip2} />
				</Row>
				{hasNewsItem ? (
					<CheckBoxSection
						options={options}
						onChange={handleChangeHasCustomAppView}
						defaultValue={
							linkType
								? linkType === 'CUSTOM_APP_VIEW'
									? 'CUSTOM_APP_VIEW'
									: 'PREDEFINED_APP_VIEW'
								: undefined
						}
						margin="0 0 2rem"
					/>
				) : null}
				{linkType === 'CUSTOM_APP_VIEW' ? (
					<Button m="2rem 0 1rem" variant="mainButton" onClick={handleOpenNewsEditor} fullWidth>
						Detail-Seite editieren
					</Button>
				) : (
					<>
						<SelectField
							margin="0 0 1rem"
							label="Link Ziel"
							items={linkOptions}
							onChange={handleChangeLinkType}
							defaultValue={linkType}
							ref={linkTypeSelectFieldRef}
							disabled={disabled}
							hideEmptySelectOption
						/>
					</>
				)}
				{linkType === 'APP_VIEW_MOVIE_DETAIL' ? (
					<MovieSelectField
						label="Film"
						ref={movieLinkSelectFieldRef}
						onChange={handleSelectFieldChange}
						defaultMovieId={
							defaultLinkType === 'APP_VIEW_MOVIE_DETAIL' ? defaultLinkValue : undefined
						}
						disabled={disabled}
						m="2rem 0 1rem"
					/>
				) : null}
				{linkType === 'APP_VIEW_FILM_SERIES_DETAIL' ? (
					<FilmSeriesSelectField
						label="Filmreihe"
						ref={movieLinkSelectFieldRef}
						onChange={handleSelectFieldChange}
						defaultFilmSeriesId={
							defaultLinkType === 'APP_VIEW_FILM_SERIES_DETAIL' ? defaultLinkValue : undefined
						}
						disabled={disabled}
						m="2rem 0 1rem"
						cinemaIds={campaignCinemaIds}
					/>
				) : null}
			</Column>
		);
	}
);

export default PushLinkEditor;
