/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-native-components';
import { useTheme } from 'styled-native-components';
import chroma from 'chroma-js';

const data2 = [
	{
		'id': '<18',
		'Weiblich': 53001,
		'Männlich': 57455,
		'Weiblich (Plus-Mitglied)': 44044,
		'Männlich (Plus-Mitglied)': 42011,
	},
	{
		'id': '18-25',
		'Weiblich': 73001,
		'Männlich': 77455,
		'Weiblich (Plus-Mitglied)': 64044,
		'Männlich (Plus-Mitglied)': 62011,
	},
	{
		'id': '25-35',
		'Weiblich': 93001,
		'Männlich': 97455,
		'Weiblich (Plus-Mitglied)': 84044,
		'Männlich (Plus-Mitglied)': 82011,
	},
	{
		'id': '35-49',
		'Weiblich': 73001,
		'Männlich': 77455,
		'Weiblich (Plus-Mitglied)': 54044,
		'Männlich (Plus-Mitglied)': 52011,
	},
	{
		'id': '49-65',
		'Weiblich': 53001,
		'Männlich': 57455,
		'Weiblich (Plus-Mitglied)': 44044,
		'Männlich (Plus-Mitglied)': 42011,
	},
	{
		'id': '65+',
		'Weiblich': 33001,
		'Männlich': 27455,
		'Weiblich (Plus-Mitglied)': 31044,
		'Männlich (Plus-Mitglied)': 20011,
	},
];
const Wrapper = styled.View`
	height: 100%;
	width: 100%;
`;
export const DemographicsBarChart = () => {
	const theme = useTheme();
	const colorscale = chroma.scale(theme.colors.accentGradient0).colors(4);
	return (
		<Wrapper>
			<ResponsiveBar
				data={data2}
				groupMode="grouped"
				keys={['Weiblich', 'Männlich', 'Weiblich (Plus-Mitglied)', 'Männlich (Plus-Mitglied)']}
				margin={{ top: 25, right: 160, bottom: 30, left: 65 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={colorscale}
				borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 0,
					tickPadding: 0,
					tickRotation: 0,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Nutzer',
					legendPosition: 'middle',
					legendOffset: -45,
					format: (value) => `${Math.round(Number(value) / 1000).toLocaleString('de-DE')}k`,
					tickValues: 5,
				}}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'right',
						direction: 'column',
						justify: false,
						translateY: 20,
						translateX: 85,
						itemsSpacing: 2,
						itemWidth: 70,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 10,
					},
				]}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				animate={true}
				motionStiffness={90}
				motionDamping={15}
			/>
		</Wrapper>
	);
};
