import * as React from 'react';
import styled, { filterProps } from 'styled-native-components';
import { Animated, View, TouchableOpacity } from 'react-native';
import { State, TapGestureHandler } from 'react-native-gesture-handler';

import type { ViewStyle, AccessibilityRole } from 'react-native';
import type { MouseEvent } from 'react';

const FakeTouchable = styled.View.attrs<
	{ noCursor?: boolean; onPress?: (e: unknown) => unknown },
	{ onMouseDown: (e: MouseEvent) => unknown }
>((p) => ({
	onMouseDown: (e) => {
		e.preventDefault();
		p.onPress && p.onPress(e);
	},
}))`
	cursor: ${(p) => (p.noCursor ? 'auto' : 'pointer')};
`;

export type TouchableProps = {
	opacity?: number;
	activeOpacity?: number;
	stopPropagation?: boolean;
	noCursor?: boolean;
	hitSlop?: { top: number; right: number; bottom: number; left: number };
	testID?: string;
	nativeID?: string;
	disabled?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	simultaneousHandlers?: React.Ref<any> | React.Ref<any>[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	waitFor?: React.Ref<any> | React.Ref<any>[];
	children?: React.ReactNode;
	onPress?: () => unknown;
	style?: ViewStyle;
	accessible?: boolean;
	accessibilityLabel?: string;
	accessibilityRole?: AccessibilityRole;
	discouragedTouchableOpacity?: boolean;
};

export type TouchableRef = { props: { onPress?: () => unknown } };

const Touchable = React.forwardRef<TouchableRef, TouchableProps>(
	(
		{
			opacity = 1,
			activeOpacity = 0.3,
			stopPropagation,
			noCursor,
			hitSlop,
			testID,
			nativeID,
			accessible,
			accessibilityLabel,
			disabled,
			simultaneousHandlers,
			waitFor,
			children,
			onPress,
			style,
			discouragedTouchableOpacity, // Should only be used as workaround for https://github.com/software-mansion/react-native-gesture-handler/issues/1255
			...props
		},
		ref
	) => {
		// this is for cavy
		React.useImperativeHandle(ref, () => ({ props: { onPress } }), [onPress]);

		const progress = React.useRef(new Animated.Value(0));
		const wrapperStyle = React.useMemo(
			() => [
				style,
				{
					cursor: noCursor ? 'auto' : 'pointer',
					opacity: progress.current.interpolate({
						inputRange: [0, 1],
						outputRange: [opacity, activeOpacity],
					}),
				},
			],
			[style, noCursor, opacity, activeOpacity]
		);

		const handleGesture = React.useCallback(
			(e) => {
				switch (e.nativeEvent.state) {
					case State.BEGAN:
						Animated.timing(progress.current, {
							toValue: 1,
							duration: 100,
							useNativeDriver: true,
						}).start();
						break;
					case State.FAILED:
					case State.CANCELLED:
					case State.END:
						Animated.timing(progress.current, {
							toValue: 0,
							duration: 100,
							useNativeDriver: true,
						}).start();
						if (e.nativeEvent.state === State.END && onPress) onPress(e);
						break;
					default:
						break;
				}
			},
			[onPress]
		);

		return stopPropagation ? (
			<FakeTouchable
				{...filterProps(props)}
				onPress={onPress}
				style={style}
				accessible={accessible}
				accessibilityLabel={accessibilityLabel}
			>
				{children}
			</FakeTouchable>
		) : discouragedTouchableOpacity ? (
			<TouchableOpacity
				activeOpacity={activeOpacity}
				onPress={onPress}
				style={style}
				disabled={disabled}
			>
				{children}
			</TouchableOpacity>
		) : onPress ? (
			<TapGestureHandler
				onHandlerStateChange={handleGesture}
				testID={testID}
				nativeID={nativeID}
				simultaneousHandlers={simultaneousHandlers}
				enabled={!disabled}
				waitFor={waitFor}
			>
				<Animated.View
					{...filterProps(props)}
					style={wrapperStyle}
					hitSlop={hitSlop}
					accessible={accessible}
					accessibilityLabel={accessibilityLabel}
				>
					{children}
				</Animated.View>
			</TapGestureHandler>
		) : (
			<View
				{...filterProps(props)}
				style={style}
				accessible={accessible}
				accessibilityLabel={accessibilityLabel}
			>
				{children}
			</View>
		);
	}
);

export default React.memo(Touchable);
