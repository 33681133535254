import { addDays, format, parseISO } from 'date-fns';

export const stringToDate = (string?: string /* e.g. 2021-10-05 */): Date | undefined =>
	string ? parseISO(string.split('T')[0]) : undefined;

export const dateToString = (date?: Date): string | undefined =>
	date ? format(date, 'yyyy-MM-dd') : undefined;

export const addDayToDateString = (
	inputDateString?: string /* e.g. 2021-12-23 */
): string | undefined => {
	try {
		if (!inputDateString) return undefined;
		const inputDateDate = stringToDate(inputDateString);
		const dayAfterDateDate = addDays(inputDateDate as Date, 1);
		const dayAfterDateString = dateToString(dayAfterDateDate);
		return dayAfterDateString;
		// eslint-disable-next-line no-catch-all/no-catch-all
	} catch (e) {
		console.log(`addDayToDateString: error: for ${inputDateString}`, e);
	}
};
