import type { ColorName, GradientName, ColorScheme } from './createColorScheme';
import type { TypographyStyles } from './createTypographyStyles';
import type { Brand, IconPaths, IconName } from './createWhitelabelConfig';

import createTypographyStyles from './createTypographyStyles';
import createColorScheme from './createColorScheme';
import createWhitelabelConfig from './createWhitelabelConfig';

type ShadowStyle = {
	elevation: number;
	shadowColor?: string;
	shadowOffset?: { width: number; height: number };
	shadowRadius?: number;
	shadowOpacity?: number;
	zIndex?: number;
};
export interface Theme {
	brand: Brand;
	/** current scale as passed to createTheme  */
	scale: number;
	/** the value of the rem unit (8*scale) */
	rem: number;
	/** colors, gradients, and color helpers */
	colors: ColorScheme;
	/** typography functions, should only be used in Paragraph, Heading, Label */
	typography: TypographyStyles;
	/** the SVG paths for icons, should only be used in Icon */
	iconPaths: IconPaths;
	/** urls for branding images like logos */
	imageUrls: { [name: string]: string };
	/** function that returns elevation styles for styled-native-components, don't invoke directly */
	elevation: (value: number) => ShadowStyle;
	/** array of values to be used for border radius styles */
	borderRadius: [string, string, string, string];
}

/**
 * Generates a theme for the given brand that has to be defined in the createWhitelabelConfig
 *
 * @param brand - the brand name
 * @param options - make sure to pass all options unless you are on web and don't need darkMode
 */
export const createTheme = (
	brand: Brand,
	{
		UNIT = 8,
		/** operating system, make sure to pass Platfrom.OS unless you are on web */
		os = 'web',
		/** scaling factor for accessibility use useWindowDimensions().fontScale */
		scale = 1,
		/** whether or not to use the dark theme */
		darkMode,
	}: { UNIT?: number; os?: 'ios' | 'web' | 'android'; scale?: number; darkMode?: boolean } = {}
): Theme => {
	const {
		font,
		noShadow,
		iconPaths,
		imageUrls,
		baseBorderRadius = 0.25,
		...colorConfig
	} = createWhitelabelConfig(brand, darkMode);
	return {
		brand,
		// length units
		scale: scale,
		rem: UNIT * scale,
		// colors
		colors: createColorScheme({ darkMode, ...colorConfig }),
		// typography
		typography: createTypographyStyles({ rem: UNIT, os, font }),
		// icons and images
		iconPaths,
		imageUrls: imageUrls || {},
		// shadow functions
		elevation: (value) => {
			if (value === 0 || noShadow) return { elevation: 0 };
			return {
				shadowColor: 'black',
				shadowOffset: { width: 0, height: value / 2 },
				shadowRadius: value * 2,
				shadowOpacity: darkMode ? 0.6 : 0.2,
				elevation: value,
				zIndex: value,
			};
		},
		borderRadius: [
			baseBorderRadius * Math.pow(2, 0) + 'rem',
			baseBorderRadius * Math.pow(2, 1) + 'rem',
			baseBorderRadius * Math.pow(2, 2) + 'rem',
			baseBorderRadius * Math.pow(2, 3) + 'rem',
		],
	};
};

export type { ColorName, GradientName, Brand, IconName };
