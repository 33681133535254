import * as React from 'react';
import styled from 'styled-components';

import {
	Table as MuiTable,
	TableBody,
	TableCell,
	TableContainer,
	TableHead as MuiTableHead,
	TablePagination,
	TableRow as MuiTableRow,
	Checkbox,
	Chip,
	Box,
} from '@mui/material';

import Tooltip from './Tooltip';

import fuzzySearch from '@cinuru/utils/fuzzySearch';
import type { ID } from '@cinuru/utils/types';

import Button from './Button';
import SearchField from './SearchField';

const Row = styled(Box)`
	display: flex;
	align-items: center;
	flex-direction: row;
`;

const fuseSearchOptions = {
	shouldSort: true,
	includeScore: true,
	threshold: 0.2,
};

export type Actions<ActionType> = {
	allActions: {
		type: ActionType;
		label: string;
	}[];
	getPossibleActionTypes: (rowIds?: ID[], rowItems?: RowItem[]) => string[];
};

type ItemControlProps<ActionType extends string> = {
	selectedItemIds: ID[];
	items: RowItem[];
	onHandleAction: (rowAction: ActionType, itemIds: ID[]) => void;
	onHandleSearchItem?: (query: string) => void;
	label?: string;
	actions?: Actions<ActionType>;
};

const TableControl = <ActionType extends string>({
	selectedItemIds,
	items,
	onHandleAction,
	onHandleSearchItem,
	label,
	actions,
}: ItemControlProps<ActionType>): JSX.Element => {
	const handleRowAction = React.useCallback(
		(rowAction: ActionType) => {
			onHandleAction?.(rowAction, selectedItemIds);
		},
		[onHandleAction, selectedItemIds]
	);

	const possibleActionTypes = React.useMemo(
		() => actions?.getPossibleActionTypes?.(selectedItemIds, items),
		[actions, items, selectedItemIds]
	);

	return (
		<Row justifyContent="space-between" m="0rem 0rem 3rem">
			<SearchField onChange={onHandleSearchItem} />
			<Row>
				{actions?.allActions.map(({ label: actionLabel, type }) => (
					<Button
						m="0 0.5rem 0 0"
						key={type}
						onClick={handleRowAction}
						id={type}
						variant="outlined"
						disabled={!selectedItemIds.length || !possibleActionTypes?.includes(type)}
					>
						{actionLabel}
					</Button>
				))}
			</Row>
		</Row>
	);
};

const TableHead = ({
	onSelectAllClick,
	numSelected,
	rowCount,
	columns,
}: {
	onSelectAllClick: (event: any) => void;
	numSelected: number;
	rowCount: number;
	columns: ColumnItem[];
}) => {
	return (
		<MuiTableHead>
			<MuiTableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
					/>
				</TableCell>
				{columns.map((column) => (
					<TableCell key={column.id} align="left" padding="none">
						<Box display="flex" alignItems="center">
							{column.label}
							{column.toolTip ? <Tooltip text={column.toolTip} /> : null}
						</Box>
					</TableCell>
				))}
			</MuiTableRow>
		</MuiTableHead>
	);
};

const StyledChip = styled(Chip).attrs((p) => ({
	size: 'small',
	color: p.color || 'primary',
}))<{ color?: string }>`
	margin-right: 0.5rem;
`;

export type ColumnItem = {
	id: number;
	label: string;
	toolTip?: string;
};

export type RowItem = {
	id: ID;
	data: {
		text?: string;
		chips?: string[];
		buttonLabel?: string;
		onPress?: () => void;
	}[];
	rawData: any;
};

const TableRow = ({
	item,
	selected,
	onSelectItem,
}: {
	item: RowItem;
	selected?: boolean;
	onSelectItem: (itemId: ID) => void;
}) => {
	const handleSelectItem = React.useCallback(() => {
		onSelectItem?.(item.id);
	}, [onSelectItem, item.id]);

	return (
		<MuiTableRow hover role="checkbox" aria-checked={selected} tabIndex={-1} key={item.id}>
			<TableCell padding="checkbox">
				<Checkbox onClick={handleSelectItem} checked={selected} color="primary" />
			</TableCell>
			{item.data.map(({ text, chips, buttonLabel, onPress }, index) => {
				return text && !chips ? (
					<TableCell key={index}>{text}</TableCell>
				) : text && chips ? (
					<TableCell key={index} padding="none">
						<Row>
							<Box display="flex" flexDirection="column">
								<Row>{text}</Row>
								<Row>
									{chips!.map((chipLabel) => (
										<StyledChip key={chipLabel} label={chipLabel} color={'primary'} />
									))}
								</Row>
							</Box>
						</Row>
					</TableCell>
				) : buttonLabel && onPress ? (
					<TableCell align="left" padding="none" key={index}>
						<Button variant="outlined" onClick={onPress}>
							{buttonLabel}
						</Button>
					</TableCell>
				) : (
					<TableCell key={index} />
				);
			})}
		</MuiTableRow>
	);
};

const MinWidthTable = styled(MuiTable)`
	min-width: 750px;
`;

const defaultRowsPerPageOptions = [5, 10, 25];

const StyledTableRow = styled(MuiTableRow)<{ $emptyRows: number }>`
	height: ${(p) => p.$emptyRows * 53}px;
`;

type TableProps<ActionType extends string> = {
	label: string;
	allRows: RowItem[];
	columns: ColumnItem[];
	rowsPerPageOptions?: number[];
	onHandleAction: (value: string, itemIds: ID[]) => void;
	searchPath?: string;
	actions?: Actions<ActionType>;
};

const StyledTablePagination = styled(TablePagination).attrs({
	component: 'div',
})`
	.MuiTablePagination-selectLabel {
		margin: 0;
	}
	.MuiTablePagination-displayedRows {
		margin: 0;
	}
`;

const labelDisplayedRows = ({ from, to, count }) =>
	`${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`;

// renders tablecontrols, tablehead, tablebody and tablepagination
const Table = <ActionType extends string>({
	label,
	allRows,
	columns,
	rowsPerPageOptions = defaultRowsPerPageOptions,
	onHandleAction,
	searchPath = 'data.text',
	actions,
}: TableProps<ActionType>): JSX.Element => {
	const [rows, setRows] = React.useState<RowItem[]>(allRows);

	const [selectedRowIds, setSelectedRowIds] = React.useState<ID[]>([]);
	const [searchedRows, setSearchedRows] = React.useState<RowItem[] | undefined>(undefined);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const currentRows = React.useMemo(() => searchedRows || rows, [rows, searchedRows]);

	const handleSearchItem = React.useCallback(
		(query) => {
			const sanitizedQuery = query.trim();
			if (sanitizedQuery === '') {
				setSearchedRows(undefined);
			} else {
				const searchResults = fuzzySearch(
					allRows,
					sanitizedQuery,
					searchPath,
					fuseSearchOptions,
					true
				).map(({ item }) => item);
				setSearchedRows(searchResults);
			}
		},
		[allRows, searchPath]
	);

	const handleSelectAllRows = React.useCallback(
		(event) => {
			const checked = event.target.checked;
			checked ? setSelectedRowIds(currentRows.map((n) => n.id)) : setSelectedRowIds([]);
		},
		[currentRows]
	);

	const handleSelectItem = React.useCallback((id: ID) => {
		setSelectedRowIds((b) => {
			return b.includes(id) ? b.filter((i) => i !== id) : [...b, id];
		});
	}, []);

	const handleChangePage = React.useCallback((event, newPage) => {
		setPage(newPage);
	}, []);

	const handleChangeRowsPerPage = React.useCallback((event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}, []);

	const emptyRows = React.useMemo(
		() => rowsPerPage - Math.min(rowsPerPage, currentRows.length - page * rowsPerPage),
		[currentRows.length, page, rowsPerPage]
	);

	React.useEffect(() => {
		setRows(allRows);
	}, [allRows]);

	return (
		<Box width="100%">
			<TableControl
				label={label}
				selectedItemIds={selectedRowIds}
				items={currentRows}
				onHandleAction={onHandleAction}
				onHandleSearchItem={handleSearchItem}
				actions={actions}
			/>
			<TableContainer>
				<MinWidthTable size="medium">
					<TableHead
						numSelected={selectedRowIds.length}
						onSelectAllClick={handleSelectAllRows}
						rowCount={currentRows.length}
						columns={columns}
					/>
					<TableBody>
						{currentRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
							<TableRow
								key={item.id}
								item={item}
								selected={selectedRowIds.includes(item.id)}
								onSelectItem={handleSelectItem}
							/>
						))}
						{emptyRows > 0 && (
							<StyledTableRow $emptyRows={emptyRows}>
								<TableCell colSpan={6} />
							</StyledTableRow>
						)}
					</TableBody>
				</MinWidthTable>
			</TableContainer>
			<StyledTablePagination
				rowsPerPageOptions={rowsPerPageOptions}
				count={currentRows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage="Zeilen pro Seite"
				labelDisplayedRows={labelDisplayedRows}
			/>
		</Box>
	);
};

export default Table;
