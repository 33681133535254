import gql from 'graphql-tag';
import shortid from 'shortid';
import userAgentParser from 'ua-parser-js';
import { Dimensions } from 'react-native';

import * as paths from '@cinuru/utils/paths';

import { routes } from '../routes';
import packageInfo from '../../package.json';

const APP_ID_KEY = 'cinuru:appid';
export let appId = localStorage.getItem(APP_ID_KEY);
if (!appId) {
	appId = shortid.generate();
	localStorage.setItem(APP_ID_KEY, appId);
}

export const session: string = shortid.generate();

export const appVersion = `v${packageInfo.version}`;

const { browser, os } = userAgentParser(navigator.userAgent);
const { width, height } = Dimensions.get('window');
export const device = [
	`${browser.name} ${browser.version}`,
	`(${os.name} ${os.version})`,
	`${width}x${height}`,
].join(' ');

const container = {};
export const setClient = (client) => {
	container.client = client;
};

const options = { appVersion, appId, device, session, source: 'DASHBOARD' };
const __DEV__ =
	location.origin.includes('localhost') || location.origin.includes('http://127.0.0.1');

export const log = async ({ type, value }) => {
	// eslint-disable-next-line no-console
	if (__DEV__) return console.log('| logging', type, value);
	const datetime = new Date().toISOString();
	const logItem = { type, value: value ? JSON.stringify(value) : null, datetime };
	// save events to backend
	await container.client.mutate({
		mutation: gql`
			mutation logAnalyticsItems($items: [LogItem], $options: LogOptions) {
				logItems(items: $items, options: $options) {
					success
				}
			}
		`,
		variables: { items: [logItem], options },
		errorPolicy: 'all',
	});
};

let activeRouteName;

const findRoute = (link) => {
	let params;
	const matchedRoute = routes.find(({ path }) => {
		params = paths.match(link, path);
		return Boolean(params);
	});
	if (!matchedRoute) return null;
	return { ...matchedRoute, params };
};

export const connectHistory = (history) => {
	let lastLink;
	const logRouteOpened = (location) => {
		const link = location.pathname + location.search + location.hash;
		// only log if route has changed
		if (link !== lastLink) {
			lastLink = link;
			const { routeName, params } = findRoute(link);
			activeRouteName = routeName;
			// log screen view
			log({ type: 'SCREEN_VIEWED', value: { key: routeName, params, link } });
		}
	};

	// track initial location
	logRouteOpened(history.location);
	// subsribce to route changes
	history.listen((location) => logRouteOpened(location));
};
