import * as React from 'react';
import styled from 'styled-components';
import cookies from 'js-cookie';
import { gql } from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { Box } from '@mui/material';

import { TextField, Button, Heading, Paragraph } from '@cinuru/components';
import { PRIVILEGES_QUERY } from '../utils/user';

const Wrapper = styled(Box)`
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	background: ${(p) => p.theme.customColors.accentGradient0}; ;
`;

const FormWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	width: 45rem;
	align-items: stretch;
	padding: 4rem;
	border-radius: 2rem;
	background-color: ${(p) => p.theme.customColors.white};
`;

class Login extends React.Component {
	state = { email: '', password: '', emailError: '', passwordError: '', loading: false };

	setEmail = (email) => this.setState({ email, emailError: '' });
	setPassword = (password) => this.setState({ password, passwordError: '' });

	// to trigger password autosave, we need a submit button, so the actual button simply clicks a hiddem submit
	submitRef = React.createRef();
	handleClickSubmit = () => this.submitRef.current.click();
	handleLogin = async (e) => {
		if (e && e.preventDefault) e.preventDefault();
		const { email, password } = this.state;
		if (!email || !password) {
			if (!email) this.setState({ emailError: 'Bitte Email angeben' });
			if (!password) this.setState({ passwordError: 'Bitte Passwort angeben' });
			return;
		}
		try {
			this.setState({ loading: true });
			const { data, errors } = await this.props.client.mutate({
				mutation: gql`
					mutation Login($email: String!, $password: String!) {
						login(email: $email, password: $password, privileged: true) {
							user {
								id
							}
							csrf
							fileServerToken
						}
					}
				`,
				variables: { email, password },
				errorPolicy: 'all',
				fetchPolicy: 'no-cache',
			});
			if (errors) {
				this.setState({ loading: false });
				switch (errors[0].message) {
					case 'WRONG_PASSWORD':
						this.setState({ passwordError: 'Passwort inkorrekt.' });
						break;
					case 'USER_INVALID_OR_BLOCKED':
						this.setState({ emailError: 'Nutzer gesperrt.' });
						break;
					case 'WRONG_EMAIL_OR_ID':
						this.setState({ emailError: 'Unbekannte Email.' });
						break;
					case 'NO_PRIVILEGES':
						this.setState({ emailError: 'Keine Zugriffsberechtigung.' });
						break;
					default:
						throw new Error(errors[0].message);
				}
			} else {
				// Set csrf cookie
				cookies.set('_csrf', data.login.csrf, { expires: 1 });
				cookies.set('_fileServerToken', data.login.fileServerToken, { expires: 1 });
				// refetch privileges after cookie has been set
				await this.props.client.query({
					query: PRIVILEGES_QUERY,
					fetchPolicy: 'network-only',
					errorPolicy: 'ignore',
				});
				this.setState({
					topError:
						'Ein unerwarteter Fehler ist aufgetreten, bitte laden Sie die Seite neu und versuchen es noch einmal',
					loading: false,
				});
			}
		} catch (e) {
			if (e.networkError) this.setState({ topError: 'Keine Internetverbindung.', loading: false });
			else throw e;
		}
	};

	render = () => {
		return (
			<Wrapper>
				<FormWrapper>
					<Heading size="l" align="center" margin="2rem 0rem">
						ANMELDUNG
					</Heading>
					<form autoComplete="on" onSubmit={this.handleLogin}>
						<TextField
							label="Email"
							flex
							value={this.state.email}
							error={this.state.emailError}
							onChange={this.setEmail}
							iconName="email"
						/>
						<TextField
							label="Passwort"
							flex
							value={this.state.password}
							error={this.state.passwordError}
							onChange={this.setPassword}
							onSubmit={this.handleClickSubmit}
							iconName="key"
							secureTextEntry
						/>
						{this.state.topError ? (
							<Paragraph color="$error" align="center">
								{this.state.topError}
							</Paragraph>
						) : null}
						<input type="submit" ref={this.submitRef} hidden />
						<Button
							margin="4rem 0rem 0rem"
							center
							label="ANMELDEN"
							gradient="accentGradient0"
							textColor="$background0"
							onPress={this.handleClickSubmit}
							loading={this.state.loading}
						/>
					</form>
				</FormWrapper>
				<Paragraph width="42rem" margin="6rem 0rem 0rem" align="center" color="$background0">
					Nutzen Sie dieselbe Kombination aus Email und Passwort, wie für die Kino-App.
				</Paragraph>
				<Paragraph width="42rem" margin="1.5rem 0rem 0rem" align="center" color="$background0">
					Sollten Sie ihr Passwort vergessen haben, können Sie dieses über die App zurücksetzen.
				</Paragraph>
				<Paragraph width="42rem" margin="1.5rem 0rem 0rem" align="center" color="$background0">
					Zur Freischaltung Ihres Accounts für das Dashboard wenden Sie sich bitte an{' '}
					<a
						href="mailto:support@cinuru.com"
						style={{ color: 'inherit', textDecoration: 'underline' }}
					>
						unseren Support
					</a>
					.
				</Paragraph>
			</Wrapper>
		);
	};
}

export default withApollo(Login);
