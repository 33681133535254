import React from 'react';
import { Slider as MuiSlider, Box } from '@mui/material';
import { BoxProps as MuiBoxProps } from '@mui/material/Box';
import styled from 'styled-components';

const StyledSlider = styled(MuiSlider)<{ $disabled: number }>`
	color: ${(p) => p.theme.palette.primary.main};
	height: 8px;
	.MuiSlider-track {
		border: none;
	}
	.MuiSlider-thumb {
		height: 12px;
		width: 12px;
		margin-bottom: 30px;
		background-color: ${(p) => p.theme.palette.common.white};
		border: 2px solid currentColor;
		:focus,
		:hover,
		.Mui-active,
		.Mui-focusVisible {
			box-shadow: inherit;
		}
		:before {
			display: none;
		}
	}
	.MuiSlider-valueLabel {
		line-height: 1.2px;
		font-size: 12px;
		background: unset;
		padding: 0;
		width: 25px;
		height: 25px;
		border-radius: 50% 50% 50% 0;
		background-color: ${(p) => p.theme.palette.primary.main};
		transform-origin: bottom left;
		transform: translate(50%, -100%) rotate(-45deg) scale(0);
		:before {
			display: none;
		}
		&.MuiSlider-valueLabelOpen {
			transform: translate(50%, -70%) rotate(-45deg) scale(1);
		}
		& > * {
			transform: rotate(45deg);
		}
	}
	.MuiSlider-markLabel {
		opacity: ${(p) => (p.$disabled ? '0.5' : '1')};
	}
`;

const PositionCorrectionWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 2rem;
`;

interface ExtendedBoxProps extends Omit<MuiBoxProps, 'onChange'> {
	disabled?: boolean;
	minMark: number;
	maxMark: number;
	markStep: number;
	defaultMin?: number;
	defaultMax?: number;
	fallbackMin?: number;
	fallbackMax?: number;
	onChange: (newRange: [number, number]) => void;
}

const Slider = ({
	disabled,
	minMark,
	maxMark,
	markStep,
	defaultMin,
	defaultMax,
	fallbackMin = 10,
	fallbackMax = 20,
	onChange,
	...rest
}: ExtendedBoxProps): JSX.Element => {
	const marks = React.useMemo(() => {
		const marksArray: { label: string; value: number }[] = [];
		for (let i = minMark; i <= maxMark; i = i + markStep) {
			marksArray.push({ value: i, label: i === maxMark ? `>${maxMark}` : i.toString() });
		}
		return marksArray;
	}, [markStep, maxMark, minMark]);

	const [min, setMin] = React.useState(defaultMin || fallbackMin);
	const [max, setMax] = React.useState(defaultMax || fallbackMax);

	const handleChange = React.useCallback(
		(event) => {
			const [newMin, newMax] = event.target.value;
			setMin(newMin);
			setMax(newMax);
		},
		[setMin, setMax]
	);

	const handleCommittChange = React.useCallback(() => {
		onChange?.([min, max]);
	}, [onChange, min, max]);

	const range = React.useMemo(() => [min, max], [max, min]);

	return (
		<Box {...rest}>
			<PositionCorrectionWrapper>
				<StyledSlider
					disabled={disabled}
					$disabled={disabled ? 1 : 0}
					valueLabelDisplay="auto"
					value={range}
					onChange={handleChange}
					onChangeCommitted={handleCommittChange}
					marks={marks}
					max={maxMark}
				/>
			</PositionCorrectionWrapper>
		</Box>
	);
};

export default Slider;
