import * as React from 'react';
import styled from 'styled-native-components';
import { sortBy } from 'lodash';
import { useApolloClient } from 'react-apollo';
import { default as gql } from 'graphql-tag';
import { Heading, Paragraph } from '@cinuru/components';

import { WithData } from '../../components/WithData';
import LastChanceMovieCard from './LastChanceMovieCard';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
	margin-bottom: 4rem;
	min-width: 500px;
`;

const Row = styled.View`
	width: 100%;
	padding: 1rem 0;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const LastChanceCardsForMovie = ({ lastKnownScreening, screeningsMarkedAsLastChance, movie }) => {
	const [loading, setLoading] = React.useState(false);
	const client = useApolloClient();
	const handleChangeLastChance = React.useCallback(
		async ({ id, marked }) => {
			setLoading(true);
			// remove last chance flag from all other screenings before marking new screening
			if (marked) {
				await Promise.all([
					...screeningsMarkedAsLastChance.map(
						async (screening) =>
							await client.mutate({
								mutation: gql`
									mutation updateScreening($id: ID!, $marked: Boolean!) {
										updateScreening(id: $id, lastChance: $marked) {
											success
										}
									}
								`,
								variables: { id: screening.id, marked: false },
							})
					),
				]);
			}
			await client.mutate({
				mutation: gql`
					mutation updateScreening($id: ID!, $marked: Boolean!) {
						updateScreening(id: $id, lastChance: $marked) {
							success
						}
					}
				`,
				variables: { id, marked },
				refetchQueries: ['getMoviesWithLastChanceInfo'],
			});
			setLoading(false);
		},
		[client, screeningsMarkedAsLastChance]
	);

	const lastKnownIsMarked = Boolean(
		screeningsMarkedAsLastChance.find(({ id }) => lastKnownScreening.id === id)
	);
	return sortBy(
		lastKnownIsMarked
			? screeningsMarkedAsLastChance
			: [lastKnownScreening, ...screeningsMarkedAsLastChance],
		['datetime']
	).map(({ id, datetime }) => (
		<LastChanceMovieCard
			key={id}
			loading={loading}
			id={id}
			datetime={datetime && new Date(datetime)}
			title={movie.title}
			poster={movie.poster}
			marked={Boolean(screeningsMarkedAsLastChance.find((screening) => screening.id === id))}
			onChangeLastChance={handleChangeLastChance}
		/>
	));
};

const LastMovies = ({ cinemaId }) => {
	return (
		<Wrapper>
			<Row>
				<Heading>Letzte in der Datenbank erfasste Vorstellungen</Heading>
			</Row>
			<Row>
				<Paragraph color="secondary">{`Hier markierte Filme erscheinen im Entdecken-Tab der App in der Kategorie 'Letzte Chance'. Bei rechtzeitiger Eintragung wird auch die entsprechende Kampagne verschickt.`}</Paragraph>
			</Row>
			<WithData
				query={gql`
					query getMoviesWithLastChanceInfo($id: ID!) {
						cinema(id: $id) {
							id
							moviesWithLastChanceInfo(includeOutdated: false) {
								movie {
									id
									title
									poster
									nonMovieEvent
								}
								lastKnownScreening {
									id
									datetime
								}
								screeningsMarkedAsLastChance {
									id
									datetime
								}
							}
						}
					}
				`}
				// eslint-disable-next-line
				variables={{ id: cinemaId }}
			>
				{({ cinema: { moviesWithLastChanceInfo } }) => {
					const relevantMovies = moviesWithLastChanceInfo.filter(
						({ movie, lastKnownScreening }) =>
							movie.nonMovieEvent === false && lastKnownScreening !== null
					);
					const movies = sortBy(relevantMovies, ['lastKnownScreening.datetime']);

					return movies.length === 0 ? (
						<Paragraph>Derzeit sind keine Filme vorhanden</Paragraph>
					) : (
						movies.map(({ lastKnownScreening, screeningsMarkedAsLastChance, movie }) => (
							<LastChanceCardsForMovie
								key={movie.id}
								lastKnownScreening={lastKnownScreening}
								screeningsMarkedAsLastChance={screeningsMarkedAsLastChance}
								movie={movie}
							/>
						))
					);
				}}
			</WithData>
		</Wrapper>
	);
};

export default LastMovies;
