/** old and needs a rewrite, used to work on native but because react-native-pose was removed from native the native PortalTrigger doesn't work with this anymore, to make it work again on native, this and the PortalTrigger.web.js would need to be rewritten to work without pose  */

// @flow
import * as React from 'react';
import styled, { useLengthAttribute } from 'styled-native-components';
import posed from 'react-native-pose';

import Touchable from './Touchable';

const ANIMATION_TOP_OVERSCROLL = 20;
const transition = { type: 'spring', stiffness: 500, damping: 32 };
const DropDownAnimation = styled(
	posed.View({
		enter: { y: -ANIMATION_TOP_OVERSCROLL, opacity: 1, transition },
		exit: { y: -300, opacity: 0, transition },
	})
)`
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
	background-color: ${(p) => p.color || '$background0'};
	border-color: ${(p) => p.borderColor || 'transparent'};
	border-width: ${(p) => (p.borderColor ? '1px' : 0)};
	padding-top: ${ANIMATION_TOP_OVERSCROLL}px;
	overflow: hidden;
	min-height: ${(p) => 2 * p.theme.rem + ANIMATION_TOP_OVERSCROLL}px;
	elevation: 2;
`;

export const FocusLine = styled(
	posed.View({
		enter: { opacity: 1, scaleY: 1 },
		exit: { opacity: 0, scaleY: 0 },
	})
)`
	background-color: ${(p) => (p.hasError ? '$error' : p.color || '$accent0')};
	width: 100%;
	margin-top: ${(p) => p.marginTop};
	height: ${(p) => (p.thinLine ? 1 : 2)}px;
	margin-bottom: ${(p) => (p.thinLine ? -1 : -2)}px;
	border-radius: ${(p) => (p.thinLine ? 1 : 2)}px;
	z-index: 3;
`;

const ContentWrapper = styled(Touchable).attrs({
	stopPropagation: true,
	noCursor: true,
})`
	align-items: ${(p) => (p.center ? 'center' : 'flex-start')};
	justify-content: ${(p) => (p.altCenter ? 'center' : 'flex-start')};
	flex-flow: ${(p) => p.flexFlow || 'column'};
	padding: ${(p) => p.padding};
	${(p) =>
		p.pixelMaxHeight ? `max-height: ${p.pixelMaxHeight + ANIMATION_TOP_OVERSCROLL}px` : ''};
`;

const DropDown = ({
	children,
	hasError,
	noLine,
	color,
	borderColor,
	lineColor,
	marginTop = 0,
	thinLine,
	maxHeight,
	center,
	altCenter,
	flexFlow,
	padding = '0',
}: {
	children: React.Node | React.Node[],
	hasError?: boolean,
	noLine?: boolean,
	color?: string,
	borderColor?: string,
	lineColor?: string,
	marginTop?: number,
	thinLine?: boolean,
	maxHeight?: number,
	center?: boolean,
	altCenter?: boolean,
	flexFlow?: string,
	padding?: number[],
}) => {
	const [pixelMaxHeight] = useLengthAttribute(maxHeight || '0');
	return [
		noLine ? null : (
			<FocusLine
				key={1}
				hasError={hasError}
				color={lineColor}
				marginTop={marginTop}
				thinLine={thinLine}
			/>
		),
		<DropDownAnimation key={2} color={color} borderColor={borderColor}>
			<ContentWrapper
				center={center}
				altCenter={altCenter}
				flexFlow={flexFlow}
				padding={padding}
				pixelMaxHeight={maxHeight && pixelMaxHeight}
			>
				{children}
			</ContentWrapper>
		</DropDownAnimation>,
	];
};

export default DropDown;
