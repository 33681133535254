import React from 'react';
import styled from 'styled-native-components';
import { Row, Col } from '../../components/AntDReplacements';
import { Checkbox } from '@cinuru/components';

const Wrapper = styled.View`
	margin-top: 4rem;
	display: flex;
	flex-direction: column;
`;

const CheckboxWrapper = styled.View`
	display: flex;
	flex-direction: row;
`;

export const AccessRestrictionSection = ({ selectedAccessRestrictedTo, update }) => {
	const handleSelect = React.useCallback(
		(newAccessRestriction) => {
			update(newAccessRestriction);
		},
		[update]
	);
	return (
		<Wrapper>
			<Row>
				<Col>Zugriffsbeschränkung</Col>
			</Row>
			<CheckboxWrapper>
				{['PRODUCTION', 'STAGING', 'DEVELOPMENT'].map((accessRestriction) => (
					<Checkbox
						key={accessRestriction}
						value={selectedAccessRestrictedTo === accessRestriction}
						label={accessRestriction}
						onChange={() => handleSelect(accessRestriction)}
					/>
				))}
			</CheckboxWrapper>
		</Wrapper>
	);
};
