import * as React from 'react';

import { TextField, SelectField, EditorField, LoadingIndicator } from '@cinuru/components';

import ElevatedCard, { INPUT_FIELD_MARGINS } from '../../components/ElevatedCard';
import { ApolloConsumer } from 'react-apollo';
import { default as gql } from 'graphql-tag';
import WithData from '../../components/WithData';
import { showErrorDialog } from '../../services/report-error';

class CampaignLinkSelect extends React.PureComponent {
	state = {
		linkItem: this.props.value,
		newsItem: {},
		newsItemError: {},
		newsItemLoading: undefined,
	};

	componentDidMount = async () => {
		const [linkItemType, linkItemValue] = (this.props.value || '').split(':');
		if (linkItemType === 'NEWSITEM' && linkItemValue) {
			this.setState({ newsItemLoading: true });
			const { data } = await this.props.client.query({
				query: gql`
					query NewsItem($newsItemId: ID!) {
						newsItem(id: $newsItemId) {
							id
							title
							body
							movie {
								id
							}
						}
					}
				`,
				variables: { newsItemId: linkItemValue },
			});
			if (data.newsItem) {
				const newsItem = { ...data.newsItem, movie: data.newsItem.movie && data.newsItem.movie.id };
				this.setState({ newsItem });
			} else {
				showErrorDialog('Unerwarteter Fehler in News Item query');
			}
			this.setState({ newsItemLoading: false });
		}
	};

	handleSelect = (linkItem) => {
		this.setState({ linkItem }, () => this.props.onChange && this.props.onChange(linkItem));
	};

	handleMovieSelect = (movieId) =>
		this.setState(
			{ linkItem: `MOVIE:${movieId || ''}` },
			() => this.props.onChange && this.props.onChange(this.state.linkItem)
		);

	setNewsItemValue = (name) => (value) =>
		this.setState((state) => ({
			newsItem: { ...state.newsItem, [name]: value },
			newsItemError: { ...state.newsItemError, [name]: null },
		}));

	saveNewsItem = async () => {
		const [linkItemType, linkItemValue] = (this.state.linkItem || '').split(':');
		const { title, body, movie } = this.state.newsItem;
		if (linkItemType === 'NEWSITEM') {
			const { data } = await this.props.client.mutate({
				mutation: gql`
					mutation UpsertNewsItem($id: ID, $title: String, $body: String, $movieId: ID) {
						result: upsertNewsItem(id: $id, title: $title, body: $body, movieId: $movieId) {
							newsItem {
								id
								title
								body
								movie {
									id
								}
							}
						}
					}
				`,
				variables: { title, body, movieId: movie || null, id: linkItemValue },
			});
			if (data && data.result && data.result.newsItem) {
				const newsItemId = data.result.newsItem.id;
				if (linkItemValue !== newsItemId) {
					const linkItem = `NEWSITEM:${newsItemId || ''}`;
					this.setState({ linkItem });
					this.props.onChange && this.props.onChange(linkItem);
				}
			} else {
				showErrorDialog('Unerwarteter Fehler in News Item upsert');
			}
		}
	};

	validate = async () => {
		const [linkItemType, linkItemValue] = (this.state.linkItem || '').split(':');
		switch (linkItemType) {
			case 'NEWSITEM': {
				let { title, body, movie } = this.state.newsItem;
				title = title && title.trim();
				body = body && body.trim();
				let valid = true;
				if (!title) {
					this.setState((state) => ({
						newsItemError: { ...state.newsItemError, title: 'Bitte ausfüllen' },
					}));
					valid = false;
				}
				if (!body) {
					this.setState((state) => ({
						newsItemError: { ...state.newsItemError, body: 'Bitte ausfüllen' },
					}));
					valid = false;
				}
				if (!movie) {
					this.setState((state) => ({
						newsItemError: { ...state.newsItemError, movie: 'Bitte auswählen' },
					}));
					valid = false;
				}
				if (valid) {
					await this.saveNewsItem();
				}
				return valid;
			}
			case 'VARIABLE':
				if (!linkItemValue) {
					this.setState({ linkItemError: 'Bitte auswählen' });
				}
				return Boolean(linkItemValue);
			case 'MOVIE': {
				if (!linkItemValue) {
					this.setState({ linkItemError: 'Bitte auswählen' });
				}
				return Boolean(linkItemValue);
			}
			default:
				return false;
		}
	};

	render = () => {
		const { campaignType, variables, error } = this.props;
		const { linkItem, newsItem, newsItemError, newsItemLoading, linkItemError } = this.state;
		const [linkItemType, linkItemValue] = (linkItem || '').split(':');
		const linkItems = [
			...(campaignType === 'FIXED_DATE'
				? [
						{ label: 'Film Information', value: 'MOVIE' },
						{ label: 'Nachrichten-Details (öffnet neuen Editor)', value: 'NEWSITEM' },
				  ]
				: []),
			...variables.map((v) => ({ label: v.label, value: `VARIABLE:${v.name || ''}` })),
		];
		return (
			<WithData
				query={gql`
					query Movies {
						movies: allMovies {
							id
							title
							poster
						}
					}
				`}
			>
				{({ movies }) => {
					const movieItems = movies.map(({ id, title, releaseDate, poster }) => ({
						value: id,
						label: title + (releaseDate ? ` (${new Date(releaseDate).getFullYear()})` : ''),
						image: poster,
					}));
					return (
						<React.Fragment>
							<SelectField
								label="Verlinkung"
								hint="Wird bei tippen der Benachrichtigung geöffnet"
								items={linkItems}
								onChange={this.handleSelect}
								value={linkItemType === 'VARIABLE' ? linkItem : linkItemType}
								error={error}
								width="100%"
							/>
							{linkItemType === 'NEWSITEM' ? (
								<ElevatedCard title="Verlinkte Nachrichten-Details">
									{newsItemLoading ? (
										<LoadingIndicator />
									) : (
										<React.Fragment>
											<TextField
												label="Titel"
												value={newsItem.title}
												error={newsItemError.title}
												onChange={this.setNewsItemValue('title')}
												altBackground
												nMargins={INPUT_FIELD_MARGINS}
												width="100%"
											/>
											<EditorField
												label="Text"
												value={newsItem.body}
												error={newsItemError.body}
												onChange={this.setNewsItemValue('body')}
												markdown
												altBackground
												nMargins={INPUT_FIELD_MARGINS}
												width="100%"
											/>
											<SelectField
												label="Film Link"
												searchable
												items={movieItems}
												value={newsItem.movie}
												error={newsItemError.movie}
												onChange={this.setNewsItemValue('movie')}
												altBackground
												nMargins={INPUT_FIELD_MARGINS}
												width="100%"
											/>
										</React.Fragment>
									)}
								</ElevatedCard>
							) : null}
							{linkItemType === 'MOVIE' ? (
								<ElevatedCard title="Verlinkter Film">
									<SelectField
										label="Film"
										searchable
										value={linkItemValue}
										error={linkItemError}
										onChange={this.handleMovieSelect}
										items={movieItems}
										altBackground
										nMargins={INPUT_FIELD_MARGINS}
										width="100%"
									/>
								</ElevatedCard>
							) : null}
						</React.Fragment>
					);
				}}
			</WithData>
		);
	};
}

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => (
	<ApolloConsumer>
		{(client) => <CampaignLinkSelect {...props} client={client} ref={ref} />}
	</ApolloConsumer>
));
