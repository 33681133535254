// @flow
import * as React from 'react';
import styled from 'styled-native-components';

import { SelectField, TextField, SwitchField } from '@cinuru/components';
import ElevatedCard, { INPUT_FIELD_MARGINS } from '../../components/ElevatedCard';

const InputWrapper = styled.View`
	flex-flow: row wrap;
`;

type InputProps = {
	...FilterInput,
	movieItems: { label: string, value: string }[],
	value: Value,
	onChange: (value: Value, name?: string) => any,
};
class InputField extends React.PureComponent<InputProps> {
	handleInput = (value) => {
		const { invertValue, name, onChange } = this.props;
		if (onChange) onChange(value ? (invertValue ? !value : value) : null, name);
	};

	render = () => {
		const { type, movieItems, itemType, value, invertValue, ...inputProps } = this.props;
		const Component = {
			SELECT: SelectField,
			STRING: TextField,
			NUMBER: TextField,
			INTEGER: TextField,
			BOOLEAN: SwitchField,
		}[type];
		if (type === 'SELECT') inputProps.items = { MOVIES: movieItems }[itemType];
		if (type === 'NUMBER' || type === 'INTEGER') inputProps.valueType = 'number';
		if (type === 'INTEGER') inputProps.numberType = 'int';
		return (
			<Component
				value={invertValue && value !== null ? !value : value}
				{...inputProps}
				altBackground
				onChange={this.handleInput}
				nMargins={INPUT_FIELD_MARGINS}
				width="100%"
			/>
		);
	};
}

type Value = string | boolean | number | string[] | null;
type InputType = 'SELECT' | 'STRING' | 'NUMBER' | 'INTEGER' | 'BOOLEAN';
type FilterInput = {
	type: InputType,
	label: string,
	hint?: string,
	multi?: true,
	itemType?: 'MOVIES',
	name?: string,
	invertValue?: boolean,
};
type Props = {|
	type: string,
	name: string,
	movieItems: { label: string, value: string }[],
	onSetValue: ({ type: string, value: Value, name: string }) => any,
	onRemove: (type: string) => any,
	value: Value | { [name: string]: Value },
	input: FilterInput[],
|};
export default class UserGroupFilter extends React.Component<Props> {
	handleSetValue = (value: Value, name?: string) =>
		this.props.onSetValue && this.props.onSetValue({ type: this.props.type, value, name });

	handleRemove = () => this.props.onRemove && this.props.onRemove(this.props.type);

	render = () => {
		const { name, value, input, movieItems } = this.props;
		return (
			<ElevatedCard title={name} onRemove={this.handleRemove}>
				<InputWrapper>
					{input.map((props, index) => (
						<InputField
							key={index}
							{...props}
							// flow-disable-next-line
							value={props.name ? (value ? value[props.name] : null) : value}
							onChange={this.handleSetValue}
							movieItems={movieItems}
						/>
					))}
				</InputWrapper>
			</ElevatedCard>
		);
	};
}
