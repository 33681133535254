import * as React from 'react';
import { times } from 'lodash';
import {
	addDays,
	addWeeks,
	isSameMonth,
	startOfMonth,
	startOfWeek,
	isSameDay,
	addMonths,
	isFuture,
	isAfter,
	isBefore,
	format,
} from 'date-fns';
import { de as locale } from 'date-fns/locale';
import styled from 'styled-native-components';

import CalendarDate from './CalendarDate';
import Icon from './Icon';
import Label from './Label';

const Wrapper = styled.View`
	width: ${7 * 4.5}rem;
	margin: 1rem 2rem;
`;

const Row = styled.View`
	flex-direction: row;
	justify-content: ${(p) => p.justification || 'flex-start'};
	padding: 0 ${(p) => p.nSidePadding || 0}rem;
`;

const CalendarDatePlaceholder = styled.View`
	width: 4.5rem;
	height: 4.5rem;
`;

const ChangeMonthButton = styled(Icon).attrs((p) => ({
	name: 'chevron',
	rotation: p.left ? -90 : 90,
	margin: '1.25rem 0rem',
	size: '2.5rem',
	color: 'neutral0',
}))``;

const getWeeksOfMonth = (date = Date.now()) => {
	const first = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
	return times(6).map((week) => times(7).map((day) => addDays(addWeeks(first, week), day)));
};

const getWeekDays = () =>
	times(7).map((i) => format(addDays(startOfWeek(new Date(0), { weekStartsOn: 1 }), i), 'EEEEEE'));

type Props = (
	| { rangeSelect: false; value?: Date }
	| { rangeSelect: true; value?: { from: Date; to: Date } }
) & {
	month?: Date;
	futureOnly?: boolean;
	nTodayColor?: string;
};
type State = {
	active?: Date;
	month: Date;
	value?: Date | { from?: Date; to?: Date };
};
export default class CalendarSheet extends React.Component<Props, State> {
	state = {
		month: startOfMonth(
			this.props.month ||
				(this.props.rangeSelect && this.props.value
					? this.props.value.from
					: this.props.value || Date.now())
		),
		value: this.props.rangeSelect ? this.props.value || {} : this.props.value,
	};

	static defaultProps = {
		nTodayColor: 'accent0',
	};

	shouldComponentUpdate = (_, { month, value, active }) =>
		!isSameMonth(month, this.state.month) ||
		!isSameDay(value, this.state.value) ||
		!isSameDay(active, this.state.active);

	setMonth = (month) => month && this.setState({ month });
	getMonth = () => this.state.month;
	setActive = (active, keepMonth) => {
		this.setState({ active });
		if (
			active &&
			!keepMonth &&
			(this.props.rangeSelect
				? !isSameMonth(active, this.state.moneht)
				: isAfter(active, getWeeksOfMonth(this.state.month)[5][6]) ||
				  isBefore(active, getWeeksOfMonth(this.state.month)[0][0]))
		) {
			this.setState({ month: active });
		}
	};
	setValue = (value) => this.setState({ value });

	handleSelectNextMonth = () =>
		this.setState(
			(state) => ({ month: addMonths(state.month, 1) }),
			() => this.props.onMonthChange && this.props.onMonthChange(this.state.month)
		);
	handleSelectPrevMonth = () =>
		this.setState(
			(state) => ({ month: addMonths(state.month, -1) }),
			() => this.props.onMonthChange && this.props.onMonthChange(this.state.month)
		);

	handleSelectDate = (selectedValue) => {
		let newValue = selectedValue;
		if (this.props.rangeSelect) {
			if (this.state.value.from && !this.state.value.to) {
				newValue = isBefore(selectedValue, this.state.value.from)
					? { from: selectedValue, to: this.state.value.from }
					: { from: this.state.value.from, to: selectedValue };
			} else {
				newValue = { from: selectedValue };
			}
		}
		this.props.onChange && this.props.onChange(newValue);
		this.setState({ value: newValue });
		if (selectedValue) this.setState({ month: selectedValue });
	};

	handleSetActive = (active) => {
		this.props.onSetActive && this.props.onSetActive(active);
		this.setActive(active);
	};
	handleResetActive = () => this.setState({ active: null });

	render = () => {
		const { futureOnly, nTodayColor, rangeSelect } = this.props;
		const { month, active, value } = this.state;
		return (
			<Wrapper onMouseLeave={this.handleResetActive}>
				<Row justification="space-between" nSidePadding={0.75}>
					<Label size="xl" margin="1rem 0">
						{format(month, 'MMMM yyyy', { locale })}
					</Label>
					<Row>
						<ChangeMonthButton left onPress={this.handleSelectPrevMonth} />
						<ChangeMonthButton onPress={this.handleSelectNextMonth} />
					</Row>
				</Row>
				<Row>
					{getWeekDays().map((weekDay) => (
						<Label
							size="s"
							width="4.5rem"
							margin="0.25rem 0"
							align="center"
							color="$neutral2"
							key={weekDay}
						>
							{weekDay}
						</Label>
					))}
				</Row>
				{getWeeksOfMonth(month).map((week) => (
					<Row key={week[0]}>
						{week.map((date) => {
							const isFrom = rangeSelect && isSameDay(date, value.from);
							const isTo = rangeSelect && isSameDay(date, value.to);
							const isActive = isSameDay(date, active);
							const isSelected = isSameDay(date, value);
							const disabledFuture = futureOnly && !(isSameDay(date, new Date()) || isFuture(date));
							return rangeSelect && !isSameMonth(month, date) ? (
								<CalendarDatePlaceholder key={date} />
							) : (
								<CalendarDate
									nPadding={0.25}
									key={date}
									hideWeekDay
									date={date}
									formatDate={(date) => format(date, 'd')}
									formatWeekday={(date) => format(date, 'EEEEEE')}
									selected={rangeSelect ? isFrom || isTo : isSelected}
									startPoint={rangeSelect && (isFrom || ((!value.from || value.to) && isActive))}
									endPoint={rangeSelect && (isTo || (value.from && !value.to && isActive))}
									inRange={
										rangeSelect &&
										isAfter(date, value.from) &&
										!isFrom &&
										!isActive &&
										(value.to
											? isBefore(date, value.to) && !isTo
											: isBefore(date, active) && !isActive)
									}
									disabled={!isSameMonth(month, date) || disabledFuture}
									error={!rangeSelect && isSelected && disabledFuture}
									onPress={(!disabledFuture && this.handleSelectDate) || undefined}
									onHover={(!disabledFuture && this.handleSetActive) || undefined}
									hovered={isActive}
									nTodayColor={nTodayColor}
								/>
							);
						})}
					</Row>
				))}
			</Wrapper>
		);
	};
}
