import React from 'react';
import { MoviePoster, Paragraph } from '@cinuru/components';
import styled from 'styled-native-components';

const MovieWrapper = styled.View`
	width: ${(p) => (p.width ? p.width : '12rem')};
	height: ${(p) => (p.height ? p.height : '23rem')};
	overflow: hidden;
	align-items: center;
	jusitfy-content: center;
`;
const MoviesWrapper = styled.View`
	align-items: center;
	flex-direction: row;
	width: ${(p) => (p.width ? p.width : '100%')};
	margin-top: 4rem;
	justify-content: center;
`;
const SmallText = styled(Paragraph)`
	font-size: ${(p) => p.fontSize};
	margin: 0;
	padding: 0;
	line-height: ${(p) => p.fontSize};
`;
const BlueText = styled(Paragraph)`
	font-size: 2rem;
	font-color:$accent0
	margin: 0;
	padding: 1rem 0 0 0;
`;

export const PosterRanking = ({ movies, description, width }) => (
	<MoviesWrapper width>
		<MovieWrapper width="14rem">
			<MoviePoster poster={movies[0].poster} />
			<BlueText color="$accent0">{movies[0].kpiValue} </BlueText>
			<SmallText fontSize="1.7rem">{description}</SmallText>
		</MovieWrapper>
		<MovieWrapper height="20rem" width="12rem">
			<MoviePoster poster={movies[1].poster} width="8rem" height={`${(8 / 2) * 3}rem`} />
			<BlueText color="$accent0">{movies[1].kpiValue} </BlueText>
			<SmallText fontSize="1.5rem">{description}</SmallText>
		</MovieWrapper>
		<MovieWrapper height="18rem" width="10rem">
			<MoviePoster poster={movies[2].poster} width="6rem" height={`${(6 / 2) * 3}rem`} />
			<BlueText color="$accent0">{movies[2].kpiValue} </BlueText>
			<SmallText fontSize="1.3rem">{description}</SmallText>
		</MovieWrapper>
	</MoviesWrapper>
);
