import React from 'react';

import ImageSelectButton from '../../components/ImageSelectButton';

export const ImagePicker = ({
	imageUrl,
	onChange,
	path,
}: {
	imageUrl?: string;
	path: string;
	onChange: (path: string, newValue: any) => void;
}): JSX.Element => {
	const handleChange = React.useCallback(
		(updatedImageUrl) => {
			onChange(path, updatedImageUrl);
		},
		[onChange, path]
	);

	return <ImageSelectButton defaultImageUrl={imageUrl} onChange={handleChange} />;
};
