import React from 'react';
import { Row, Col, Input, Button } from '../../components/AntDReplacements';

const styles = {
	wrapper: {
		marginTop: '20px',
		marginBottom: '40px',
	},
};

//Todo: Cleanup this styles mess

export const OpeningHoursSection = ({
	openingHours,
	updateOpeningHours,
	addOpeningHours,
	deleteOpeningHours,
}) => (
	<div style={styles.wrapper}>
		<Row>
			<Col>Öffnungszeiten</Col>
		</Row>
		{Object.entries(openingHours).map(([key, { weekdays, hours }]) => (
			<Row key={key} style={{ margin: '10px 0' }}>
				<Col span={7}>
					<Input
						value={weekdays}
						placeholder="Wochentag(e)..."
						onChange={(event) => updateOpeningHours([key, 'weekdays'], event.target.value)}
					/>
				</Col>
				<Col span={7}>
					<Input
						value={hours}
						placeholder="Zeiten..."
						onChange={(event) => updateOpeningHours([key, 'hours'], event.target.value)}
					/>
				</Col>
				<Col span={3} offset={5}>
					<Button type="danger" onClick={() => deleteOpeningHours(key)}>
						Löschen
					</Button>
				</Col>
			</Row>
		))}
		<Row>
			<Col>
				<Button type="dashed" icon="plus-circle-o" onClick={() => addOpeningHours({})} />
			</Col>
		</Row>
	</div>
);
