import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { ID } from '@cinuru/utils/types';

export type Genre = {
	id: ID;
	name: string;
};

export const useAllGenres = (): undefined | Genre[] => {
	const { data } = useQuery(
		gql`
			query AllGenres {
				allGenres {
					id
					name
				}
			}
		`
	);

	return React.useMemo(() => data?.allGenres, [data?.allGenres]);
};
