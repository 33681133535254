import * as React from 'react';
import { SelectField } from '@cinuru/components';
import { default as gql } from 'graphql-tag';
import { withApollo } from 'react-apollo';
const { useState, useEffect } = React;

const MovieSelectField = ({ client, onChange, disabled, error, label, hint, multi }) => {
	const [dbMovies, setDBMovies] = useState([]);

	// on Mount: get available dbMovies in DB and setDBMovies(result)
	useEffect(() => {
		const getDBMovies = async () => {
			const { data } = await client.query({
				query: gql`
					query allMovies {
						allMovies {
							title
							id
							poster
						}
					}
				`,
			});
			const result = data.allMovies.map((m) => ({ label: m.title, value: m.id, image: m.poster }));
			setDBMovies(result);
		};
		getDBMovies();
	}, [client]);

	return (
		<SelectField
			// props ====================================
			error={error}
			disabled={disabled}
			onChange={onChange}
			label={label || 'Film suchen'}
			hint={hint || 'Film in der Datenbank suchen'}
			multi={multi || false}
			// ==========================================
			items={dbMovies}
			searchable
			width={'100%'}
		/>
	);
};

export default withApollo(MovieSelectField);
