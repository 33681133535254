import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { ID } from '@cinuru/utils/types';

export type Achievement = {
	id: ID;
	title: string;
	description: string;
};

export const useAllAchievements = (): undefined | Achievement[] => {
	const { data } = useQuery(
		gql`
			query AllAchievements {
				allAchievements {
					id
					title
					description
				}
			}
		`
	);

	return React.useMemo(() => data?.allAchievements, [data?.allAchievements]);
};
