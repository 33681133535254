import styledWeb from 'styled-components';
import {
	InputLabel,
	TextField,
	Box,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Select,
	MenuItem,
} from '@mui/material';

export const Wrapper = styledWeb.div`
	display:flex;
	flex-direction:column;
	align-items:center;
	width: 100%;
	`;

export const StyledAccordion = styledWeb(Accordion)`
	padding: 0;
	width: 100%;
	box-shadow: none;
`;
export const StyledAccordionDetails = styledWeb(AccordionDetails)`
	padding: 0 2rem;
	margin: 0;
	box-shadow: none;
`;
export const StyledAccordionSummary = styledWeb(AccordionSummary)`
	background-color: ${(props) => props.theme.palette.grey[200]};
`;

export const CustomInputLabel = styledWeb(InputLabel)({
	position: 'relative',
	transform: 'translate(0px, 0px) scale(0.75)',
	webkitTransform: 'unset',
	transformOrigin: 'left',
	pointerEvents: 'unset',
	display: 'flex',
	alignItems: 'center',
});

export const StyledInputLabel = styledWeb(InputLabel)`
	background-color: ${(props) => props.theme.palette.common.white};
	pointer-events: unset;
	display: flex;
	align-items: center;
`;

export const StyledTextField = styledWeb(TextField)`
	border-radius: none;
	padding-left: unset;
`;

export const Row = styledWeb(Box)`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	width: 100%;
	padding-left: 1rem;
`;

export const StyledSelect = styledWeb(Select)<{ $fontFamily?: string }>`
	width: 100%;
	border-radius: 0;
	font-family: ${(props) => props.$fontFamily || 'unset'}
`;

export const StyledMenuItem = styledWeb(MenuItem)<{ $bold?: boolean; $fontFamily?: string }>`
	font-family: ${(props) => props.$fontFamily || 'currentValue'};
	font-weight: ${(p) => (p.$bold ? '500' : '400')};
`;
