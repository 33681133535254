import * as React from 'react';
import { flatten } from 'lodash';
import styled from 'styled-native-components';
import { stripIndent } from 'common-tags';

import {
	Paragraph,
	Heading,
	ButtonTile,
	DateField,
	TextField,
	SelectField,
	DropDownButton,
	Button,
	EditorField,
	TimeField,
} from '@cinuru/components';
import resolveVariables from '@cinuru/utils/resolveVariables';

import UserGroupFilter from './UserGroupFilter';
import CampaignLinkSelect from './CampaignLinkSelect';

import { default as gql } from 'graphql-tag';
import { WithData } from '../../components/WithData';

import { usergroupFilterTypes, variableTypeProps } from './config';

const TEXT_FIELD_MARGINS = [2, -0.5];
const BUTTON_TILE_MARGINS = [1, 0, 2];
const SUBTITLE_MARGINS = '1rem';

const Wrapper = styled.View`
	background-color: $background0;
	padding: 3rem 4rem;
	border-radius: ${(p) => p.theme.borderRadius[2]};
	elevation: 2;
`;

const EditWrapper = styled.View.attrs((p) => ({
	pointerEvents: p.disabled ? 'none' : 'auto',
}))`
	opacity: ${(p) => (p.disabled ? (p.theme.colors.darkMode ? 0.4 : 0.7) : 1)};
	filter: ${(p) => (p.disabled ? 'saturate(60%)' : 'none')};
`;

const ButtonWrapper = styled.View`
	margin: ${(p) => (p.nVerticalMargin !== undefined ? p.nVerticalMargin : 1)}rem 0;
	flex-direction: row;
	justify-content: ${(p) => p.justify || 'flex-end'};
	align-items: center;
	align-self: stretch;
`;

const Border = styled.View`
	width: 100%;
	margin: 2rem -0.5rem;
	border-color: $border0;
	border-bottom-width: 1px;
`;

const filterAlreadySet = (userGroupFilters) => ({ type }) =>
	userGroupFilters.findIndex((setFilter) => setFilter.type === type) < 0;

const resolveTemplate = (name, campaignType) =>
	resolveVariables(
		name,
		Object.assign({}, ...(campaignType.variables || []).map((v) => ({ [v.name]: v.label })))
	);

const getVariables = (campaignType, userGroupFilters, other = []) => [
	...other,
	...(campaignType.variables || []),
	...flatten(
		userGroupFilters.map(
			({ type }) => usergroupFilterTypes.find((filter) => filter.type === type).variables || []
		)
	),
];

const getVariableProps = (variables) =>
	flatten(
		variables.map((v) =>
			variableTypeProps[v.type].map((p) => ({
				name: `${v.name}.${p.name}`,
				label: `${v.label} - ${p.label}`,
				inputLength: p.inputLength,
			}))
		)
	);

const CHANNEL_OPTIONS = [
	{ label: 'Neuigkeiten-Liste', value: 'APP' },
	{ label: 'Push-Benachrichtigung', value: 'PUSH' },
];

const MINUTES_INTERVAL_SENDING_TIME = { minutes: 15 };

class EditCampaignRender extends React.PureComponent {
	linkSelectRef = React.createRef();
	validateLink = () => this.linkSelectRef.current.validate();
	saveLinkedNewsItem = async () => {
		await this.linkSelectRef.current.saveNewsItem();
	};

	render = () => {
		const {
			name,
			id,
			campaignType,
			channels,
			message,
			messageTitle,
			link,
			status,
			sendingTime,
			sendingDate,
			sendOnDayXAfterTrigger,
			sendExactlyMinutesAfterTrigger,
			userGroupFilters,
			onSetName,
			onSetChannels,
			onSetMessage,
			onSetMessageTitle,
			onSetLink,
			onSetSendingDate,
			onSetSendingTime,
			onSetSendOnDayXAfterTrigger,
			onSetSendOnDayXBeforeTrigger,
			onSetSendExactlyMinutesAfterTrigger,
			onAddUserGroupFilter,
			onRemoveUserGroupFilter,
			onSetUserGroupFilterValue,
			onActivate,
			onMakeEditable,
			onDelete,
			onSave,
			saveLoading,
			nameError,
			sendingDateError,
			sendOnDayXAfterTriggerError,
			sendingTimeError,
			sendExactlyMinutesAfterTriggerError,
			channelsError,
			messageTitleError,
			messageError,
			linkError,
		} = this.props;
		return (
			<Wrapper>
				<Heading size="l" margin="0rem 0rem 2rem 0rem">
					{name} Kampagne
				</Heading>
				<TextField
					value={name}
					error={nameError}
					onChange={onSetName}
					nMargins={TEXT_FIELD_MARGINS}
					label="Interne Kampagnen-Bezeichnnung"
					width="100%"
				/>
				{status === 'FAILED' ? (
					<React.Fragment>
						<Paragraph margin="1rem" color="$neutral2">
							Beim Versand dieser Kampagne ist etwas schief gelaufen.
						</Paragraph>
						<ButtonWrapper>
							<Button onPress={onMakeEditable} label="BEARBEITEN" link />
							<Button label="LÖSCHEN" onPress={onDelete} link />
						</ButtonWrapper>
					</React.Fragment>
				) : null}
				{status === 'SENT' ? (
					<React.Fragment>
						<Paragraph margin="1rem" color="$neutral2">
							Diese Kampagne wurde bereits gesendet, sie kann daher nicht mehr bearbeitet werden.
						</Paragraph>
						<ButtonWrapper>
							<Button label="LÖSCHEN" onPress={onDelete} link />
						</ButtonWrapper>
					</React.Fragment>
				) : null}
				{status === 'ACTIVE' ? (
					<React.Fragment>
						<Paragraph margin="1rem" color="$neutral2">
							Diese Kampagne ist bereits aktiv. Nach einer erneuten Bearbeitung muss sie ein
							weiteres Mal aktiviert werden, damit sie verschickt wird.
						</Paragraph>
						<ButtonWrapper>
							<Button label="LÖSCHEN" onPress={onDelete} link />
							<Button onPress={onMakeEditable} label="BEARBEITEN" link />
						</ButtonWrapper>
					</React.Fragment>
				) : null}
				<EditWrapper disabled={status !== 'EDITING'}>
					<Border />
					<Heading margin={SUBTITLE_MARGINS}>Auslöser</Heading>
					<Paragraph margin="0rem 1rem" bold color="$neutral2">
						{campaignType.group === 'FIXED' ? 'Einmalige Kampagne' : 'Wiederholende Kampagne'}
						{' wird ausgelöst durch:'}
					</Paragraph>
					<ButtonTile
						nWidth={40}
						{...campaignType}
						label={campaignType.labelLong || campaignType.label}
						elevation={0}
						nMargins={BUTTON_TILE_MARGINS}
					/>
					{campaignType.fields.includes('SENDING_DATE') ? (
						<DateField
							value={sendingDate}
							error={sendingDateError}
							onChange={onSetSendingDate}
							nMargins={TEXT_FIELD_MARGINS}
							label="Zustellungsdatum"
							hint="Tag, an dem die Kampagne verschickt wird"
							futureOnly
						/>
					) : null}
					{campaignType.fields.includes('SEND_ON_DAY_X_BEFORE_TRIGGER') ? (
						<TextField
							valueType="number"
							numberType="int"
							label="Zustellungstag"
							hint="Maximal wieviele Tage vor dem Ereignis soll die Nachricht verschickt werden?"
							value={-sendOnDayXAfterTrigger}
							error={sendOnDayXAfterTriggerError}
							onChange={onSetSendOnDayXBeforeTrigger}
							nMargins={TEXT_FIELD_MARGINS}
							width="100%"
						/>
					) : null}
					{campaignType.fields.includes('SEND_ON_DAY_X_AFTER_TRIGGER') ? (
						<TextField
							valueType="number"
							numberType="int"
							label="Zustellungstag"
							hint="Wieviele Tage nach dem Ereignis soll die Nachricht verschickt werden?"
							value={sendOnDayXAfterTrigger}
							error={sendOnDayXAfterTriggerError}
							onChange={onSetSendOnDayXAfterTrigger}
							nMargins={TEXT_FIELD_MARGINS}
							width="100%"
						/>
					) : null}
					{campaignType.fields.includes('SENDING_TIME') ? (
						<TimeField
							label="Zustellungszeit"
							hint="Uhrzeit, zu der die Kampagne verschickt wird"
							changeInterval={MINUTES_INTERVAL_SENDING_TIME}
							value={sendingTime}
							error={sendingTimeError}
							onChange={onSetSendingTime}
							nMargins={TEXT_FIELD_MARGINS}
							width="100%"
						/>
					) : null}
					{campaignType.fields.includes('SEND_EXACTLY_MINUTES_AFTER_TRIGGER') ? (
						<TimeField
							interval
							valueUnit="minutes"
							label="Zustellungszeit"
							hint="Wie lange nach dem Ereignis soll die Nachricht verschickt werden?"
							value={sendExactlyMinutesAfterTrigger}
							error={sendExactlyMinutesAfterTriggerError}
							onChange={onSetSendExactlyMinutesAfterTrigger}
							nMargins={TEXT_FIELD_MARGINS}
							width="100%"
						/>
					) : null}
					<SelectField
						label="Zustellungskanal"
						hint="Ort, an dem die Kampagnen-Nachricht erscheint"
						items={CHANNEL_OPTIONS}
						value={channels}
						error={channelsError}
						onChange={onSetChannels}
						multi
						nMargins={TEXT_FIELD_MARGINS}
						width="100%"
					/>
					<Border />
					<Heading margin={SUBTITLE_MARGINS}>Zielgruppe</Heading>
					{userGroupFilters.length === 0 ? (
						<Paragraph margin="1rem">
							{stripIndent`
					Eigenschaften der Empfänger festlegen. Die Zielgruppengröße errechnet sich automatisch nach Speicherung der Kampagne.`}
						</Paragraph>
					) : (
						<WithData
							query={gql`
								query Movies {
									movies: allMovies {
										id
										title
										poster
									}
								}
							`}
						>
							{({ movies }) =>
								userGroupFilters.map(({ type, value }, index) => {
									const filterType = usergroupFilterTypes.find((filter) => filter.type === type);
									return [
										<UserGroupFilter
											key={type}
											{...filterType}
											name={resolveTemplate(filterType.name, campaignType)}
											value={value}
											onRemove={onRemoveUserGroupFilter}
											onSetValue={onSetUserGroupFilterValue}
											movieItems={movies.map(({ id, title, releaseDate, poster }) => ({
												value: id,
												label:
													title + (releaseDate ? ` (${new Date(releaseDate).getFullYear()})` : ''),
												image: poster,
											}))}
										/>,
										index === userGroupFilters.length - 1 ? null : (
											<Paragraph align="center" size="s" color="$neutral2" bold key={index}>
												UND
											</Paragraph>
										),
									];
								})
							}
						</WithData>
					)}
					<ButtonWrapper justify="space-between">
						<DropDownButton
							color="$neutral0"
							textColor="$background0"
							label="       FILTER HINZUFÜGEN       "
							onSelect={onAddUserGroupFilter}
							items={usergroupFilterTypes
								.filter(filterAlreadySet(userGroupFilters))
								.filter(({ type }) => campaignType.filter.includes(type))
								.map((filter) => ({
									label: resolveTemplate(filter.name, campaignType),
									value: filter.type,
								}))}
						/>
					</ButtonWrapper>
					<WithData
						query={gql`
							query EstimateSize($id: ID!) {
								size: estimateCampaignSize(campaignId: $id) {
									userGroupSize
									# exampleReferenceMovie {
									# 	userGroupSize
									# 	movie {
									# 		title
									# 	}
									# }
								}
							}
						`}
						variables={{ id }}
					>
						{({ size }) => (
							<Paragraph margin="1rem" bold size="m">
								ZIELGRUPPENGRÖSSE:{' '}
								{
									size.userGroupSize
									// + (campaignType.filter.includes('REFERENCED_MOVIE')
									// 	? '≈' + size.exampleReferenceMovie.userGroupSize
									// 	: size.userGroupSize) +
									// ' ' +
									// 'NUTZER' +
									// ' ' +
									// (campaignType.filter.includes('REFERENCED_MOVIE')
									// 	? `(Beispielfilm: ${size.exampleReferenceMovie.movie.title})`
									// 	: '')
								}
							</Paragraph>
						)}
					</WithData>
					<Border />
					<Heading margin={SUBTITLE_MARGINS}>Inhalt der Nachricht</Heading>
					<EditorField
						label="Titel"
						hint=" "
						value={messageTitle}
						error={messageTitleError}
						onChange={onSetMessageTitle}
						numberOfLines={1}
						characterLimit={35}
						variables={getVariableProps(
							getVariables(campaignType, userGroupFilters, [
								{ name: 'user', label: 'Empfänger', type: 'User' },
							])
						)}
						width="100%"
					/>
					<EditorField
						label="Text"
						hint="Textlänge wird je nach Handymodell unterschiedlich angezeigt"
						value={message}
						error={messageError}
						onChange={onSetMessage}
						numberOfLines={2}
						characterLimit={120}
						variables={getVariableProps(
							getVariables(campaignType, userGroupFilters, [
								{ name: 'user', label: 'Empfänger', type: 'User' },
							])
						)}
						width="100%"
					/>
					<CampaignLinkSelect
						ref={this.linkSelectRef}
						value={link}
						error={linkError}
						onChange={onSetLink}
						campaignType={campaignType.type}
						variables={getVariables(campaignType, userGroupFilters)}
					/>
					{status === 'EDITING' ? (
						<React.Fragment>
							<Border />
							<ButtonWrapper justify="space-between">
								<ButtonWrapper nVerticalMargin={0}>
									<Button label="LÖSCHEN" onPress={onDelete} link />
								</ButtonWrapper>
								<ButtonWrapper nVerticalMargin={0}>
									<Button loading={saveLoading} label="SPEICHERN" onPress={onSave} link />
									<Button
										label="AKTIVIEREN"
										onPress={onActivate}
										gradient="accentGradient0"
										textColor="$background0"
									/>
								</ButtonWrapper>
							</ButtonWrapper>
						</React.Fragment>
					) : null}
				</EditWrapper>
			</Wrapper>
		);
	};
}

export default EditCampaignRender;
