/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import styled from 'styled-components';
import { Box, Button, ButtonGroup } from '@mui/material';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/lib/css/marvel-devices.min.css';

import { useDivDimensions } from '../../utils/dimensions';

const baseDimensions = {
	ios: [427, 864],
	android: [420, 904],
};

const Background = styled.div`
	background-color: ${(p) => p.theme.palette.grey[200]};
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -100;
`;

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	max-width: 100%;
`;

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	overflow: hidden;
`;

const DeviceFrame = ({
	margin,
	defaultOs = 'android',
	renderContent,
	heightFactor = 1,
	calculateHeightFactor,
}: {
	margin?: string;
	defaultOs?: 'ios' | 'android';
	renderContent: (args: { os: 'android' | 'ios' }) => JSX.Element;
	heightFactor?: number;
	calculateHeightFactor?: (os: 'android' | 'ios') => number;
}) => {
	const [os, setOs] = React.useState<'ios' | 'android'>(defaultOs);
	const handleSetAndroid = React.useCallback(() => {
		setOs('android');
	}, []);
	const handleSetIos = React.useCallback(() => {
		setOs('ios');
	}, []);

	const containerRef = React.useRef(null);
	const containerDimensions = useDivDimensions(containerRef);

	const deviceWidth = baseDimensions[os][0];
	const deviceHeight = baseDimensions[os][1];
	const zoom = containerDimensions?.width / deviceWidth;
	const leftOffset = (-1 * (1 - zoom) * deviceWidth) / 2;
	const topOffset = (-1 * (1 - zoom) * deviceHeight) / 2;
	const wrapperHeight = calculateHeightFactor
		? calculateHeightFactor(os) * zoom * deviceHeight
		: heightFactor * zoom * deviceHeight;

	return (
		<Box m={margin} ref={containerRef}>
			<Column>
				<Box position="relative" height={wrapperHeight} width="100%" overflow="hidden">
					<Box position="absolute" left={leftOffset} top={topOffset}>
						<DeviceFrameset
							device={os === 'ios' ? 'iPhone X' : 'Galaxy Note 8'}
							color={os === 'android' ? 'black' : undefined}
							zoom={zoom}
						>
							<Background />

							{renderContent && renderContent({ os: os })}
						</DeviceFrameset>
					</Box>
				</Box>
				<Row justifyContent="center" m="1rem">
					<ButtonGroup color="primary" disableElevation>
						<Button variant={os === 'android' ? 'outlined' : 'contained'} onClick={handleSetIos}>
							iOS
						</Button>
						<Button variant={os === 'ios' ? 'outlined' : 'contained'} onClick={handleSetAndroid}>
							Android
						</Button>
					</ButtonGroup>
				</Row>
			</Column>
		</Box>
	);
};

export default DeviceFrame;
