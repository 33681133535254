import * as React from 'react';
import shortid from 'shortid';
import { State, TapGestureHandler } from 'react-native-gesture-handler';
import { useStyle, filterProps } from 'styled-native-components';
import { Animated, View } from 'react-native';

import type { TouchableRef } from './Touchable';
import type { LayoutChangeEvent, LayoutRectangle, ViewStyle } from 'react-native';
import type { TapGestureHandlerStateChangeEvent } from 'react-native-gesture-handler';

const RippleAnimation = ({ id, progress, endRadius, rippleColor, x, y }) => {
	const startRadius = 10;
	const baseStyle = useStyle(`
		width: ${startRadius * 2}px;
		height: ${startRadius * 2}px;
		border-radius: ${startRadius}px;
		overflow: hidden;
		position: absolute;
		top: ${y - startRadius}px;
		left: ${x - startRadius}px;
		background-color: ${rippleColor};
	`);
	const rippleStyle = React.useMemo(
		() => [
			baseStyle,
			{
				transform: [
					{
						scale: progress.interpolate({
							inputRange: [0, 1],
							outputRange: [1, endRadius / startRadius],
						}),
					},
				],
				opacity: progress.interpolate({
					inputRange: [0, 1],
					outputRange: [0.3, 0],
				}),
			},
		],
		[baseStyle, progress, endRadius]
	);
	return <Animated.View style={rippleStyle} key={id} />;
};

type RippleProps = {
	rippleColor?: string;
	disabled?: boolean;
	children?: React.ReactNode;
	onPress?: () => unknown;
	onLayout?: (e: LayoutChangeEvent) => unknown;
	style?: ViewStyle;
	testID?: string;
	nativeID?: string;
	hitSlop?: { top: number; right: number; bottom: number; left: number };
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	simultaneousHandlers?: React.Ref<any> | React.Ref<any>[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	waitFor?: React.Ref<any> | React.Ref<any>[];
	accessible?: boolean;
	accessibilityLabel?: string;
	accessibilityRole?: string;
};

export type { TouchableRef };

const Ripple = React.forwardRef<TouchableRef, RippleProps>(
	(
		{
			rippleColor = '$overlayText',
			disabled,
			hitSlop,
			testID,
			nativeID,
			accessible,
			accessibilityLabel,
			simultaneousHandlers,
			waitFor,
			onLayout,
			onPress,
			children,
			style,
			...props
		},
		ref
	) => {
		const mounted = React.useRef(true);
		React.useEffect(
			() => () => {
				mounted.current = false;
			},
			[]
		);

		// this is for cavy
		React.useImperativeHandle(ref, () => ({ props: { onPress } }), [onPress]);

		const layout = React.useRef<LayoutRectangle>({ x: 0, y: 0, width: 0, height: 0 });
		const handleLayout = React.useCallback(
			(e: LayoutChangeEvent) => {
				if (onLayout) onLayout(e);
				layout.current = e.nativeEvent.layout;
			},
			[onLayout]
		);

		const [ripples, setRipples] = React.useState<
			{ id: string; progress: Animated.Value; endRadius: number; x: number; y: number }[]
		>([]);
		const handleStartRipple = React.useCallback(({ nativeEvent }) => {
			const id = shortid.generate();
			const progress = new Animated.Value(0);
			setRipples((v) => [
				...v,
				{
					id,
					progress,
					endRadius: Math.max(layout.current.width, layout.current.height),
					x: nativeEvent.x || layout.current.width / 2,
					y: nativeEvent.y || layout.current.height / 2,
				},
			]);

			Animated.timing(progress, {
				toValue: 1,
				duration: 400,
				useNativeDriver: true,
			}).start(() => {
				// remove ripple when completed
				if (mounted.current) setRipples((v) => v.filter((ripple) => ripple.id !== id));
			});
		}, []);

		const handleGesture = React.useCallback(
			(e: TapGestureHandlerStateChangeEvent) => {
				switch (e.nativeEvent.state) {
					case State.BEGAN:
						handleStartRipple(e);
						break;
					case State.CANCELLED:
					case State.FAILED:
						setRipples([]);
						break;
					case State.END:
						onPress && onPress();
						break;
					default:
						break;
				}
			},
			[handleStartRipple, onPress]
		);

		const wrapperStyle = useStyle(`
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			overflow: hidden;
			padding: 0;
			margin: 0;
			background-color: transparent;
			opacity: 1;
			elevation: 0;
			border-width: 0;
		`);

		return onPress ? (
			<TapGestureHandler
				onHandlerStateChange={handleGesture}
				hitSlop={hitSlop}
				testID={testID}
				nativeID={nativeID}
				simultaneousHandlers={simultaneousHandlers}
				enabled={!disabled}
				waitFor={waitFor}
			>
				<Animated.View
					{...filterProps(props)}
					onLayout={handleLayout}
					style={style}
					accessible={accessible}
					accessibilityLabel={accessibilityLabel}
				>
					{/* eslint-disable-next-line react-perf/jsx-no-new-array-as-prop */}
					<View style={[style, wrapperStyle]} pointerEvents="box-none">
						{ripples.map((ripple) => (
							<RippleAnimation key={ripple.id} rippleColor={rippleColor} {...ripple} />
						))}
					</View>
					{children}
				</Animated.View>
			</TapGestureHandler>
		) : (
			<View
				ref={ref}
				style={style}
				accessible={accessible}
				accessibilityLabel={accessibilityLabel}
				testID={testID}
				nativeID={nativeID}
				onLayout={handleLayout}
				{...filterProps(props)}
			>
				{children}
			</View>
		);
	}
);

export default React.memo(Ripple);
