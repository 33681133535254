// @flow
import * as React from 'react';
import styledWeb from 'styled-components';
import styled from 'styled-native-components';
import { Switch as RNSwitch } from 'react-native';

const WebWrapper = styledWeb.span`
	& > div > div > div:nth-child(2) {
		border: 1px solid ${(p) => p.theme.colors.border0};
		box-shadow: 1px 2px 5px rgba(0, 0, 0, ${(p) => (p.theme.colors.darkMode ? 0.6 : 0.2)});
		z-index: 2;
	}
`;

const Wrapper = styled.TouchableOpacity.attrs({
	activeOpacity: 1,
})`
	margin: ${(p) => p.nMargins.reduce((str, curr) => `${str} ${curr * p.theme.rem}px`, '')};
	width: ${(p) => p.theme.rem * 5}px;
	justify-content: center;
	align-items: center;
`;

const ScalableSwitcher = styled(RNSwitch).attrs((p) => ({
	// web properties
	activeThumbColor: p.theme.colors[p.nThumbColor],
	tintColor: p.theme.colors[p.nColor],
	onTintColor: p.theme.colors[p.nActiveColor],
}))``;

type Props = {
	value?: boolean,
	onValueChange?: (boolean) => any,
	nColor?: string,
	nActiveColor?: string,
};
export default class Switch extends React.Component<Props> {
	state = { value: false };

	static defaultProps = {
		nMargins: [0],
		nColor: 'border0',
		nActiveColor: 'accent0',
		nThumbColor: '$accentText0',
	};

	inputRef = React.createRef();

	handleWrapperPress = () => {
		if (this.inputRef.current && this.inputRef.current.blur) this.inputRef.current.blur();
		if (this.props.value === undefined) this.setState((state) => ({ value: !state.value }));
		if (this.props.onValueChange) this.props.onValueChange(!this.props.value);
	};

	render = () => {
		return (
			<WebWrapper>
				<Wrapper nMargins={this.props.nMargins} onPress={this.handleWrapperPress}>
					<ScalableSwitcher
						ref={this.inputRef}
						{...this.props}
						value={this.props.value !== undefined ? this.props.value : this.state.value}
					/>
				</Wrapper>
			</WebWrapper>
		);
	};
}
