import * as React from 'react';
import styled from 'styled-components';
import { Box, Typography, ButtonGroup } from '@mui/material';

import Button from '../../components/Button';
import Txt from '../../components/Txt';

import CakeRoundedIcon from '@mui/icons-material/CakeRounded';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded';
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';
import DraftsRoundedIcon from '@mui/icons-material/DraftsRounded';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const Column = styled(Box)`
	height: 14rem;
	width: 14rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

export type Trigger =
	| 'SINGLE_EVENT'
	| 'REGULAR'
	| 'BIRTHDAY'
	| 'VISIT'
	| 'FILM_START'
	| 'BOUGHT_TICKET'
	| 'BOUGHT_CONCESSIONS'
	| 'NEWSLETTER_SUBSCRIBED'
	| 'CREATED_ACCOUNT'
	| 'APP_INSTALL'
	| 'JOIN_BONUSPROGRAM'
	| 'POINTS_REACHED'
	| 'STATUSLEVEL_REACHED'
	| 'NO_VISIT';
type Triggers = { trigger: Trigger; label: string; icon?: JSX.Element; disabled?: boolean }[];
export const triggers: Triggers = [
	{
		trigger: 'SINGLE_EVENT',
		label: 'Einmalig',
		icon: undefined,
	},
	{
		trigger: 'REGULAR',
		label: 'Regelmäßig',
		icon: <AllInclusiveIcon />,
	},
	{
		trigger: 'BIRTHDAY',
		label: 'Geburtstag',
		icon: <CakeRoundedIcon />,
	},
	{
		trigger: 'VISIT',
		label: 'Kinobesuch',
		icon: <DateRangeRoundedIcon />,
	},
	{
		trigger: 'FILM_START',
		label: 'Filmstart Merkliste',
		icon: <AddAlertRoundedIcon />,
	},
	{
		trigger: 'BOUGHT_TICKET',
		label: 'Ticket erworben',
		icon: <ConfirmationNumberRoundedIcon />,
	},
	{
		trigger: 'BOUGHT_CONCESSIONS',
		label: 'Concessions erworben',
		icon: <FastfoodRoundedIcon />,
	},
	{
		trigger: 'NEWSLETTER_SUBSCRIBED',
		label: 'Newsletter Anmeldung',
		icon: <DraftsRoundedIcon />,
	},
	{
		trigger: 'CREATED_ACCOUNT',
		label: 'Account Erstellt',
		icon: <AccountCircleRoundedIcon />,
	},
	{
		trigger: 'APP_INSTALL',
		label: 'App Installiert',
		icon: <PhoneIphoneRoundedIcon />,
	},
	{
		trigger: 'JOIN_BONUSPROGRAM',
		label: 'Beitritt Bonusprogramm',
		icon: <LoyaltyRoundedIcon />,
	},
	{
		trigger: 'POINTS_REACHED',
		label: 'Punktestand erreicht',
		icon: <ControlPointDuplicateRoundedIcon />,
		disabled: true,
	},
	{
		trigger: 'STATUSLEVEL_REACHED',
		label: 'Statuslevel erreicht',
		icon: <AddBoxRoundedIcon />,
		disabled: true,
	},
	{
		trigger: 'NO_VISIT',
		label: 'Kein Kinobesuch in Zeitraum',
		icon: <EmojiPeopleRoundedIcon />,
		disabled: false,
	},
];

const CenterWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0.5rem;
`;

const TriggerButton = ({
	onClick,
	trigger,
	label,
	active,
	icon,
	disabled,
}: {
	onClick: (trigger: Trigger) => void;
	trigger: Trigger;
	label: string;
	active: boolean;
	icon?: JSX.Element;
	disabled?: boolean;
}): JSX.Element => {
	const handleClick = React.useCallback(() => {
		onClick?.(trigger);
	}, [onClick, trigger]);
	return (
		<Button
			variant={active ? 'selectButtonActive' : 'selectButton'}
			disabled={disabled}
			onClick={handleClick}
			m="0.5rem"
		>
			<Column>
				<CenterWrapper flex="0.8" alignItems="flex-end">
					{icon}
				</CenterWrapper>
				<CenterWrapper flex="1" alignItems="flex-start" textAlign="center">
					<Txt variant="caption">{label}</Txt>
				</CenterWrapper>
			</Column>
		</Button>
	);
};

const CampaignTriggerSelector = ({
	p,
	m,
	onChange,
	defaultValue,
	disabled,
}: {
	p?: string;
	m?: string;
	onChange: (campaignKey: string, value: Trigger) => void;
	defaultValue?: Trigger;
	disabled?: boolean;
}): JSX.Element => {
	const handleChange = React.useCallback(
		(newTrigger: Trigger) => {
			setTrigger(newTrigger);
			onChange?.('trigger', newTrigger);
		},
		[onChange]
	);

	const [trigger, setTrigger] = React.useState<Trigger | undefined>(defaultValue || 'SINGLE_EVENT');
	const [lastAutoTrigger, setLastAutoTrigger] = React.useState(
		!defaultValue || defaultValue === 'SINGLE_EVENT' ? 'REGULAR' : defaultValue
	);
	const isAutmaticCampaign = React.useMemo(() => trigger !== 'SINGLE_EVENT', [trigger]);

	const toggleCampaignTrigger = React.useCallback(() => {
		if (trigger === 'SINGLE_EVENT') {
			handleChange(lastAutoTrigger);
		} else {
			trigger ? setLastAutoTrigger(trigger) : null;
			handleChange('SINGLE_EVENT');
		}
	}, [lastAutoTrigger, handleChange, trigger]);

	return (
		<>
			<Box p="0.7rem 0rem">
				<Txt p="0 0 1rem 0" color="textSecondary">
					Kampagnen Typ
				</Txt>
				<ButtonGroup color="primary" disableElevation>
					<Button
						disabled={disabled}
						variant={isAutmaticCampaign ? 'outlined' : 'contained'}
						onClick={toggleCampaignTrigger}
					>
						Einmalig
					</Button>
					<Button
						disabled={disabled}
						variant={isAutmaticCampaign ? 'contained' : 'outlined'}
						onClick={toggleCampaignTrigger}
					>
						Automatisch
					</Button>
				</ButtonGroup>
			</Box>
			{isAutmaticCampaign ? (
				<Box p={p} m={m}>
					<Box pb="1rem">
						<Typography color="textSecondary">Auslöser</Typography>
					</Box>
					<Row>
						{triggers.map(
							({ trigger: itemTrigger, label, icon, disabled: triggerButtonDisabled }) =>
								itemTrigger !== 'SINGLE_EVENT' ? (
									<TriggerButton
										key={itemTrigger}
										trigger={itemTrigger}
										label={label}
										active={trigger === itemTrigger}
										onClick={handleChange}
										disabled={triggerButtonDisabled || disabled}
										icon={icon}
									/>
								) : null
						)}
					</Row>
				</Box>
			) : null}
		</>
	);
};

export default CampaignTriggerSelector;
