import * as React from 'react';
import { Route, Redirect } from 'react-router';
import styled from 'styled-components';
import { SnackbarProvider, SnackbarOrigin } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import deLocale from 'date-fns/locale/de';

import { LoadingIndicator, Heading } from '@cinuru/components';

import Login from './screens/Login';
import { logout, useUserPrivileges } from './utils/user';
import ThemeProvider from './components/ThemeProvider';
import PortalProvider from './components/PortalProvider';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';
import { getNavTreeForPrivileges, hasPrivilegesForPath, routes } from './routes';

const Wrapper = styled.div`
	flex-direction: row;
	align-items: stretch;
	width: 100vw;
	min-height: 100vh;
`;

const ContentWrapper = styled.div`
	flex: 1;
	padding: 2rem;
	background-color: ${(p) => p.theme.palette.grey[100]};
	margin-left: 22rem;
	@media print {
		margin-left: 1rem;
	}
`;

const Card = styled.div`
	background-color: ${(p) => p.theme.palette.grey[100]};
	padding: 3rem 4rem;
	border-radius: 1rem;
	elevation: 2;
`;

const snackBarProviderProps = {
	maxSnack: 3,
	anchorOrigin: {
		horizontal: 'center',
		vertical: 'bottom',
	} as SnackbarOrigin,
};

const Navigator = () => {
	const privileges = useUserPrivileges();
	return privileges === undefined ? (
		<LoadingIndicator />
	) : privileges ? (
		<React.Fragment>
			<NavMenu links={getNavTreeForPrivileges(privileges)} onLogout={logout} />
			<ContentWrapper>
				{routes.map((route) => {
					const { path, exact, redirect, Component } = route;
					return (
						<Route
							key={path}
							exact={Boolean(exact || redirect)}
							path={path}
							// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
							render={(props) =>
								redirect ? (
									<Redirect to={redirect} />
								) : hasPrivilegesForPath(route, privileges) ? (
									<Component {...props} privileges={privileges} />
								) : (
									<Card>
										<Heading size="xl" align="center">
											{'Sie haben keine Berechtigung\n diese Seite zu öffnen'}
										</Heading>
									</Card>
								)
							}
						/>
					);
				})}
				{/* <Footer /> */}
			</ContentWrapper>
		</React.Fragment>
	) : (
		<Login />
	);
};

const App = (): JSX.Element => {
	return (
		<>
			<CssBaseline />
			<ThemeProvider>
				<SnackbarProvider {...snackBarProviderProps}>
					<PortalProvider>
						<LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
							<Wrapper>
								<Navigator />
							</Wrapper>
						</LocalizationProvider>
					</PortalProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</>
	);
};

export default App;
