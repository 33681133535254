import * as React from 'react';
import styled from 'styled-native-components';

import type { ViewStyle } from 'react-native';
import type { ID } from '@cinuru/utils/types';

import WrappedImage from './WrappedImage';
import Ripple from './Ripple';
import Bookmark from './Bookmark';

const Wrapper = styled(Ripple).attrs<
	{
		borderRadius?: string;
		margin: string;
		width: string;
		height: string;
		elevation: number;
	},
	{ rippleColor: string }
>({
	rippleColor: '$overlayText',
})`
	border-radius: ${(p) => p.borderRadius || p.theme.borderRadius[1]};
	margin: ${(p) => p.margin};
	width: ${(p) => p.width};
	height: ${(p) => p.height};
	background-color: $neutral1;
	elevation: ${(p) => p.elevation};
`;

const Poster = styled(WrappedImage)<{ width: string; height: string; borderRadius?: string }>`
	width: ${(p) => p.width};
	height: ${(p) => p.height};
	border-radius: ${(p) => p.borderRadius || p.theme.borderRadius[1]};
`;

const PositionedBookmark = styled(Bookmark)`
	position: absolute;
	top: 0;
	right: 0;
`;

const MoviePoster = ({
	id,
	poster,
	width = '10rem',
	height = '15rem',
	margin = '0',
	bookmarkSize,
	interest,
	rating,
	onPress,
	onBookmarkPress,
	style,
	borderRadius,
	elevation = 2,
}: {
	id: ID;
	poster?: string;
	width?: string;
	height?: string;
	margin?: string;
	bookmarkSize?: 's' | 'm' | 'l';
	interest?: number;
	rating?: number;
	onPress?: (id: ID) => void;
	onBookmarkPress?: (id: ID) => unknown;
	style?: ViewStyle;
	borderRadius?: string;
	elevation?: number;
}) => {
	const handlePress = React.useMemo(() => onPress && (() => onPress(id)), [id, onPress]);
	const handleBookmarkPress = React.useMemo(() => onBookmarkPress && (() => onBookmarkPress(id)), [
		id,
		onBookmarkPress,
	]);
	return (
		<Wrapper
			margin={margin}
			width={width}
			height={height}
			onPress={handlePress}
			style={style}
			borderRadius={borderRadius}
			elevation={elevation}
		>
			<Poster width={width} height={height} src={poster} borderRadius={borderRadius} />
			{(onBookmarkPress || interest || rating) && (
				<PositionedBookmark
					onPress={handleBookmarkPress}
					active={Boolean(interest || rating)}
					interest={interest}
					rating={rating}
					size={bookmarkSize}
				/>
			)}
		</Wrapper>
	);
};

export default React.memo(MoviePoster);
