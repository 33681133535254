import React from 'react';
import { stripValueFromDimension } from '../../utils/emailConfigurationHelpers';
import IntegerTextField from '../../../../components/IntegerTextField';

type SizeControllerType = 'HEIGHT' | 'WIDTH' | 'BORDER_RADIUS';

const labelDict: { [key in SizeControllerType]: string } = {
	HEIGHT: 'Bildhöhe',
	WIDTH: 'Bildbreite',
	BORDER_RADIUS: 'Eckenrundung',
};

const SizeController = ({
	label,
	value,
	onChange,
	path,
	type,
}: {
	label?: string;
	value?: string;
	path: string;
	onChange: (path: string, newValue?: string) => void;
	type: SizeControllerType;
}): JSX.Element => {
	const handleChange = React.useCallback(
		(newValue?: number) => {
			onChange(path, newValue ? `${newValue}px` : undefined);
		},
		[onChange, path]
	);

	const defaultValue = value ? Number(stripValueFromDimension(value.toString())) : undefined;

	return (
		<IntegerTextField
			label={label || labelDict[type]}
			onChange={handleChange}
			width="100%"
			defaultValue={defaultValue}
		/>
	);
};

export default React.memo(SizeController);
