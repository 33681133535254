import * as React from 'react';
import { Typography, Select, MenuItem } from '@mui/material';
import FormControlWrapper from './FormControlWrapper';

export type TimeUnit = 'MINUTE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

const defaultTimeUnitsPlural: { label: string; value: TimeUnit | '' }[] = [
	{ label: 'Minuten', value: 'MINUTE' },
	{ label: 'Stunden', value: 'HOUR' },
	{ label: 'Tage', value: 'DAY' },
	{ label: 'Wochen', value: 'WEEK' },
	{ label: 'Monate', value: 'MONTH' },
	{ label: 'Jahre', value: 'YEAR' },
];

const defaultTimeUnitsSingular: { label: string; value: TimeUnit | '' }[] = [
	{ label: 'Minute', value: 'MINUTE' },
	{ label: 'Stunde', value: 'HOUR' },
	{ label: 'Tag', value: 'DAY' },
	{ label: 'Woche', value: 'WEEK' },
	{ label: 'Monat', value: 'MONTH' },
	{ label: 'Jahr', value: 'YEAR' },
];

const decodePropsValue = (propsValue?: TimeUnit): TimeUnit | '' => propsValue || '';

const encodeStateValue = (stateValue: TimeUnit | ''): TimeUnit | undefined =>
	stateValue || undefined;

export type TimeUnitSelectFieldRef = {
	validate: () => boolean;
};

const TimeUnitSelectField = React.forwardRef<
	TimeUnitSelectFieldRef,
	{
		label?: string;
		disabled?: boolean;
		onChange?: (output?: TimeUnit) => void;
		defaultValue?: TimeUnit;
		margin?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		flex?: boolean;
		enabledTimeUnits?: TimeUnit[];
		singular?: boolean;
	}
>(
	(
		{
			label = 'Zeiteinheit',
			disabled,
			onChange,
			defaultValue,
			margin,
			variant,
			flex,
			enabledTimeUnits,
			singular,
		},
		ref
	): JSX.Element => {
		const [selectedValue, setSelectedValue] = React.useState<TimeUnit | ''>(
			decodePropsValue(defaultValue)
		);
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>();

		const availableTimeUnits = React.useMemo(() => {
			const defaultTimeUnits = singular ? defaultTimeUnitsSingular : defaultTimeUnitsPlural;
			return !enabledTimeUnits
				? defaultTimeUnits
				: defaultTimeUnits.filter(({ value }) =>
						value === '' ? true : enabledTimeUnits?.includes(value)
				  );
		}, [singular, enabledTimeUnits]);

		const handleChange = React.useCallback(
			(event) => {
				setErrorMessage(undefined);
				const newValue = event.target.value as TimeUnit | '';
				setSelectedValue(newValue);
				onChange?.(encodeStateValue(newValue));
			},
			[onChange]
		);

		const handleValidate = React.useCallback(() => {
			let error = false;
			if (!disabled && !selectedValue) {
				setErrorMessage('Bitte wählen');
				error = true;
			} else {
				error = false;
			}
			return error;
		}, [disabled, selectedValue]);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		React.useEffect(() => {
			if (disabled) {
				setErrorMessage(undefined);
			}
		}, [disabled]);

		const renderValue = React.useCallback(
			(value: string | /* e.g. 10:00 */ '') => {
				const newLabel = availableTimeUnits.find(
					(timePeriodTypeItem) => timePeriodTypeItem.value === value
				)?.label;
				return <Typography color="textPrimary">{newLabel}</Typography>;
			},
			[availableTimeUnits]
		);

		return (
			<FormControlWrapper
				flex={flex}
				m={margin}
				errorMessage={errorMessage}
				variant={variant}
				label={label}
				disabled={disabled}
			>
				<Select
					value={selectedValue}
					onChange={handleChange}
					renderValue={renderValue}
					disabled={disabled}
					variant={variant}
					label={label}
				>
					{availableTimeUnits.map((item) => (
						<MenuItem key={item.value} value={item.value}>
							{item.label}
						</MenuItem>
					))}
				</Select>
			</FormControlWrapper>
		);
	}
);

export default TimeUnitSelectField;
