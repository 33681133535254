import { isToday, isDate } from 'date-fns';
import * as React from 'react';
import styled, { useTheme } from 'styled-native-components';
import { View } from 'react-native';

import Label from './Label';
import Heading from './Heading';
import Gradient from './Gradient';
import Touchable from './Touchable';

import type { DefaultTheme } from 'styled-native-components';
import type { ViewStyle } from 'react-native';
import type { GradientName } from '@cinuru/utils/theme';

const Wrapper = styled(Touchable).attrs<
	{ allowed?: boolean; color: string; nPadding: number },
	{ activeOpacity: number }
>((p) => ({
	activeOpacity: p.allowed ? 0.2 : 1,
}))`
	padding: ${(p) => p.nPadding}rem 0;
	cursor: ${(p) => (p.allowed ? 'pointer' : 'not-allowed')};
	background-color: ${(p) => p.color};
`;

const Weekday = styled.View<{ nPadding: number }>`
	width: 4rem;
	height: 2rem;
	margin: 0 ${(p) => p.nPadding}rem;
`;

const getItemColor = (p: {
	today?: boolean;
	error?: boolean;
	theme: DefaultTheme;
	nTodayColor?: string;
	disabled?: boolean;
	inRange?: boolean;
	hovered?: boolean;
	selected?: boolean;
}) => {
	let color: string;
	if (p.error) color = p.theme.colors.error;
	else if (p.today && p.nTodayColor) color = p.theme.colors[p.nTodayColor];
	else if (p.disabled) color = p.theme.colors.neutral4;
	else color = p.theme.colors.neutral3;
	if (p.selected) {
		return color;
	} else if (p.inRange || p.hovered) {
		return p.theme.colors.transparentize(p.today && p.nTodayColor ? 0.6 : 0.8, color);
	} else return 'transparent';
};

const MaybeGradient = ({
	selectedGradient,
	selected,
	borderRadius,
	style,
	children,
}: {
	selectedGradient: GradientName;
	selected?: boolean;
	borderRadius?: string;
	style?: ViewStyle;
	children?: React.ReactNode;
}) => {
	const theme = useTheme();
	return selected && selectedGradient ? (
		<Gradient borderRadius={borderRadius} style={style} colors={theme.colors[selectedGradient]}>
			{children}
		</Gradient>
	) : (
		<View style={style}>{children}</View>
	);
};
const hasMargin = (p: { inRange?: boolean; startPoint?: boolean; endPoint?: boolean }) =>
	!(p.inRange || p.endPoint || p.startPoint);
const Monthday = styled(MaybeGradient)<{
	nPadding: number;
	inRange?: boolean;
	startPoint?: boolean;
	endPoint?: boolean;
	nTodayColor?: string;
	disabled?: boolean;
	hovered?: boolean;
	selected?: boolean;
	today?: boolean;
	error?: boolean;
}>`
	min-width: ${(p) => 4 + (hasMargin(p) ? 0 : 2 * p.nPadding)}rem;
	margin-left: ${(p) => (hasMargin(p) ? p.nPadding : 0)}rem;
	margin-right: ${(p) => (hasMargin(p) ? p.nPadding : 0)}rem;
	height: ${(p) => (p.inRange ? 3 : 4)}rem;
	margin-top: ${(p) => (p.inRange ? 0.5 : 0)}rem;
	margin-bottom: ${(p) => (p.inRange ? 0.5 : 0)}rem;
	background-color: ${(p) => getItemColor(p)};
	border-radius: ${(p) => p.borderRadius};
	align-items: center;
	justify-content: center;
	elevation: ${(p) => (p.selected ? 2 : 0)};
`;

const CalendarDate = ({
	date,
	selected,
	error,
	inRange,
	startPoint,
	endPoint,
	hovered,
	disabled,
	hideWeekDay,
	nPadding = 1,
	nTodayColor,
	selectedGradient = 'accentGradient0',
	selectedBackgroundColor = '$background0',
	selectedTextColor,
	backgroundSelectionStyle,
	onPress,
	onHover,
	formatDate,
	formatWeekday,
}: {
	date: Date | 'ALL' | 'LATER';
	selected?: boolean;
	error?: boolean;
	inRange?: boolean;
	startPoint?: boolean;
	endPoint?: boolean;
	disabled?: boolean;
	hovered?: boolean;
	hideWeekDay?: boolean;
	nPadding?: number;
	nTodayColor?: string;
	selectedBackgroundColor?: string;
	backgroundSelectionStyle?: boolean;
	selectedGradient?: GradientName;
	selectedTextColor?: string;
	onPress?: (date: Date | 'ALL' | 'LATER') => void;
	onHover?: (date: Date | 'ALL' | 'LATER') => void;
	formatDate: (date: Date | 'ALL' | 'LATER') => string;
	formatWeekday: (date: Date | 'ALL' | 'LATER') => string;
}) => {
	const handlePress = React.useMemo(() => (onPress ? () => onPress(date) : undefined), [
		onPress,
		date,
	]);
	const handleHover = React.useMemo(() => (onHover ? () => onHover(date) : undefined), [
		onHover,
		date,
	]);
	const today = isDate(date) && isToday(date as Date);
	const theme = useTheme();
	return (
		<Wrapper
			onPress={handlePress}
			allowed={Boolean(onPress)}
			onMouseEnter={handleHover}
			nPadding={nPadding}
			color={backgroundSelectionStyle && selected ? selectedBackgroundColor : 'transparent'}
		>
			{!hideWeekDay ? (
				<Weekday nPadding={nPadding}>
					<Label
						size="xs"
						align="center"
						light={!backgroundSelectionStyle}
						color={
							selected && backgroundSelectionStyle
								? selectedTextColor
								: disabled
								? '$neutral3'
								: '$neutral0'
						}
					>
						{formatWeekday(date)}
					</Label>
				</Weekday>
			) : null}
			<Monthday
				selectedGradient={selectedGradient}
				startPoint={startPoint}
				endPoint={endPoint}
				nPadding={nPadding}
				nTodayColor={nTodayColor}
				selected={!backgroundSelectionStyle && selected}
				error={error}
				inRange={inRange}
				hovered={hovered}
				today={today}
				disabled={disabled}
				borderRadius={
					[
						inRange ? 0 : endPoint ? 0.5 : 2,
						inRange ? 0 : startPoint ? 0.5 : 2,
						inRange ? 0 : startPoint ? 0.5 : 2,
						inRange ? 0 : endPoint ? 0.5 : 2,
					].join('rem ') + 'rem'
				}
			>
				<Heading
					size="xs"
					color={
						disabled
							? selected && selectedGradient && selectedTextColor
								? theme.colors.transparentize(
										0.25,
										theme.colors[selectedTextColor.replace('$', '')]
								  )
								: '$neutral3'
							: !selected && !hovered && !inRange && today && nTodayColor
							? nTodayColor
							: selected
							? selectedTextColor
							: '$neutral0'
					}
					margin={!isDate(date) ? '0rem 1rem' : undefined}
					condensed={!isDate(date)}
				>
					{formatDate(date)}
				</Heading>
			</Monthday>
		</Wrapper>
	);
};

export default React.memo(CalendarDate);
