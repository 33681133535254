/** may need some refactoring, no native support */

// @flow
import * as React from 'react';
import { padStart } from 'lodash';

import TextField from './TextField';
import PortalTrigger from './PortalTrigger';
import DropDown from './DropDown';
import TimePicker, { secondsToTimeObject, timeObjectToSeconds, secondValues } from './TimePicker';

const TEXT_FIELD_MARGINS = [0];

const SHOW_VALUES = {
	default: ['hours', 'minutes'],
	seconds: ['hours', 'minutes', 'seconds'],
};
const SHOW_VALUES_INTERVAL = {
	default: ['days', 'hours', 'minutes'],
	seconds: ['days', 'hours', 'minutes', 'seconds'],
};

const formatTime = ({ days, hours, minutes, seconds }): string =>
	(days ? `${days}T ` : '') +
	[hours || 0, minutes || 0, seconds || null]
		.filter((n) => n !== null)
		.map((n) => padStart(n, 2, '0'))
		.join(':');

const parseTime = (string: string): Object => {
	let [days, hours, minutes, seconds] = [0, 0, 0, 0];
	string = string.toLowerCase();
	if (string.includes('t')) {
		[days, string] = string.split('t');
		days = Number(days.trim());
	}
	[hours, minutes, seconds] = string.trim().split(':');
	hours = hours ? Number(hours.trim()) : 0;
	minutes = minutes ? Number(minutes.trim()) : 0;
	seconds = seconds ? Number(seconds.split(/[+.]/)[0].trim()) : 0;
	return Number.isNaN(days) || Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds)
		? null
		: { days, hours, minutes, seconds };
};

const timeObjectToUTCString = ({ hours, minutes, seconds }) =>
	new Date(Date.UTC(0, 0, 0, hours, minutes, seconds)).toISOString().split('T')[1];

// time is given as string, otherwise intervals have number values in a specified unit
const decodePropsValue = ({ value, valueUnit, interval }) =>
	value && (interval ? value * secondValues[valueUnit] : timeObjectToSeconds(parseTime(value)));
const encodeStateValue = (value, { valueUnit, interval }) =>
	value
		? interval
			? Math.round(value / secondValues[valueUnit])
			: timeObjectToUTCString(secondsToTimeObject(value))
		: null;

type CommonProps = { label: string, hint?: string };
type Props =
	| { interval: true, value?: number, ...CommonProps }
	| { interval?: false, value?: string, ...CommonProps };
type State = {
	selected: boolean,
	value?: Date,
};
export default class TimeField extends React.PureComponent<Props, State> {
	state = { selected: false, value: decodePropsValue(this.props) };

	static defaultProps = {
		nMargins: [1, 0],
		altBackground: false,
		valueUnit: 'seconds',
		errorMessages: { INVALID_TIME: 'Ungültige Zeitangabe' },
	};

	inputRef = React.createRef();
	timeRef = React.createRef();

	handleFocus = () =>
		this.setState({ selected: true }, () => this.props.onFocus && this.props.onFocus());
	handleBlur = () =>
		this.setState({ selected: false }, () => this.props.onBlur && this.props.onBlur());

	handleKeyPress = ({ nativeEvent: { key } }) => {
		if (key === 'ArrowRight') this.timeRef.current.setNextActive();
		if (key === 'ArrowLeft') this.timeRef.current.setPrevActive();
		if (key === 'ArrowUp') this.timeRef.current.increaseActive();
		if (key === 'ArrowDown') this.timeRef.current.decreaseActive();
	};

	handleTextInput = (text) => {
		this.setState({ error: null });
		if (!text) {
			this.handleSelectTime(null);
			this.timeRef.current && this.timeRef.current.setValue(null);
		} else {
			const timeObject = parseTime(text);
			if (!timeObject) {
				this.timeRef.current && this.timeRef.current.setValue(null);
				this.setState({ error: this.props.errorMessages.INVALID_TIME });
			} else {
				const value = timeObjectToSeconds(timeObject);
				this.handleSelectTime(value);
				this.timeRef.current && this.timeRef.current.setValue(value);
			}
		}
	};

	handleSelectTime = (value) => {
		if (this.props.onChange) this.props.onChange(encodeStateValue(value, this.props));
		this.inputRef.current.setValue(value ? formatTime(secondsToTimeObject(value)) : '');
		this.setState({ value, error: null });
	};

	renderOverlay = () => {
		const { altBackground, interval, showSeconds, showValues, changeInterval } = this.props;
		const error = this.state.error || this.props.error;
		const finalShowValues =
			showValues ||
			(interval ? SHOW_VALUES_INTERVAL : SHOW_VALUES)[showSeconds ? 'seconds' : 'default'];
		return (
			<DropDown
				focusLine
				hasError={Boolean(error)}
				color={altBackground ? '$background0' : '$background1'}
				borderColor="$border0"
				center
			>
				<TimePicker
					ref={this.timeRef}
					value={this.state.value}
					onChange={this.handleSelectTime}
					altBackground={altBackground}
					nWidth={showValues ? showValues.length * 10 : 40}
					changeInterval={changeInterval}
					showValues={finalShowValues}
					interval={interval}
				/>
			</DropDown>
		);
	};

	render = () => {
		const error = this.state.error || this.props.error;
		const { nMargins, altBackground, flex, hint, ...props } = this.props;
		// eslint-disable-next-line no-unused-vars
		const { changeInterval, showSeconds, valueUnit, interval, ...rest } = props;
		const { selected, value } = this.state;
		return (
			<PortalTrigger
				margin={nMargins.join('rem ') + 'rem'}
				flexBase={flex && '30rem'}
				active={selected}
				renderOverlay={this.renderOverlay}
				contentTopMargin={hint || error ? '-3.25rem' : '-0.25rem'}
				stretch
			>
				<TextField
					{...rest}
					nMargins={TEXT_FIELD_MARGINS}
					ref={this.inputRef}
					altBackground={altBackground}
					value={value && formatTime(secondsToTimeObject(value))}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					selected={selected}
					hint={hint}
					autoCorrect={false}
					onKeyPress={this.handleKeyPress}
					onChange={this.handleTextInput}
					blurOnSubmit={false}
					inputChangeDebounce={500}
					error={error}
				/>
			</PortalTrigger>
		);
	};
}
