import * as React from 'react';

import { Box, Paper } from '@mui/material';

const ContentWrapper = ({
	m = '0 0 2rem 0',
	p = '2rem',
	children,
}: {
	m?: string;
	p?: string;
	children: JSX.Element | (null | JSX.Element)[];
}): JSX.Element => {
	return (
		<Box m={m} width="100%">
			<Paper>
				<Box p={p}>
					<>{children}</>
				</Box>
			</Paper>
		</Box>
	);
};

export default ContentWrapper;
