import * as React from 'react';

import { SelectField } from '@cinuru/components';

import { default as gql } from 'graphql-tag';

import WithData from '../../components/WithData';

const styles = { minWidth: '300px' };
export default class B2BCustomerSelectField extends React.Component {
	initialized = false;

	setInitialValue = (value) => {
		!this.initialized && this.props.onChange && value && this.props.onChange(value);
		this.initialized = true;
	};

	render = () => {
		const { multi, selectedB2BCustomers, forceRerenderKey = 'def', ...otherProps } = this.props;
		return (
			<WithData
				key={forceRerenderKey}
				query={gql`
					query {
						currentUser {
							id
							privileges {
								belongsToB2BCustomers {
									id
									name
								}
							}
						}
					}
				`}
				fetchPolicy="cache-and-network"
			>
				{({ currentUser }) => {
					this.setInitialValue(selectedB2BCustomers);
					return (
						<SelectField
							//Force rerender whenever it is required from the top component and also when the length of the
							//adminForCineas changes (thus a new cinema is created or deleted)
							key={forceRerenderKey + currentUser.privileges.belongsToB2BCustomers.length}
							label={`B2B Kunden auswählen`}
							multi={multi}
							items={currentUser.privileges.belongsToB2BCustomers.map(({ id, name }) => ({
								value: id,
								label: name,
							}))}
							style={styles}
							value={selectedB2BCustomers}
							{...otherProps}
						/>
					);
				}}
			</WithData>
		);
	};
}
