import * as React from 'react';
import { debounce } from 'lodash';
import { TextFieldProps } from '@mui/material/TextField';

import SearchSelectField, { SelectFieldItem as _SelectFieldItem } from './SearchSelectField';
import { useSearchedMovies } from '../utils/movie';
import { useSearchedCastOrCrewMember } from '../utils/castOrCrewMember';

export type SelectFieldItem = _SelectFieldItem;

export type SearchApiSelectFieldRef = {
	validate: () => boolean;
};

const SearchApiSelectField = React.forwardRef<
	SearchApiSelectFieldRef,
	{
		label: string;
		onChange?: (selectedItems: SelectFieldItem[]) => void;
		onBlur?: () => void;
		multi?: boolean;
		variant?: TextFieldProps['variant'];
		defaultItems?: SelectFieldItem[] | null;
		margin?: string;
		disabled?: boolean;
		flex?: string;
		type: 'MOVIE' | 'CAST_OR_CREW_MEMBER'; // | 'GENRE' | 'STICKER' | 'VOUCHER'
	}
>(
	(
		{
			label,
			onChange,
			multi,
			variant = 'outlined',
			defaultItems,
			margin = '2rem 0 1rem',
			disabled,
			flex,
			type,
		},
		ref
	): JSX.Element => {
		const [query, setQuery] = React.useState('');

		const movieSearchResults = useSearchedMovies(type === 'MOVIE' ? query : undefined);
		const castCrewOrMemberSearchResults = useSearchedCastOrCrewMember(
			type === 'CAST_OR_CREW_MEMBER' ? query : undefined
		);

		const handleChangeQuery = debounce(
			React.useCallback((newQuery: string) => {
				setQuery(newQuery);
			}, []),
			500
		);
		const handleChange = React.useCallback(
			(items: SelectFieldItem[]) => {
				onChange?.(items);
			},
			[onChange]
		);

		const searchResults = React.useMemo(
			() =>
				type === 'MOVIE'
					? movieSearchResults
					: type === 'CAST_OR_CREW_MEMBER'
					? castCrewOrMemberSearchResults
					: [],
			[castCrewOrMemberSearchResults, movieSearchResults, type]
		);

		const data = React.useMemo(
			() =>
				searchResults?.map((item) =>
					type === 'MOVIE'
						? { label: item.title, value: item.id as string }
						: type === 'CAST_OR_CREW_MEMBER'
						? { label: item.fullName, value: item.id as string }
						: item
				),
			[searchResults, type]
		);

		return (
			<SearchSelectField
				defaultItems={defaultItems}
				allItems={data}
				label={label}
				onChange={handleChange}
				multi={multi}
				variant={variant}
				m={margin}
				ref={ref}
				disabled={disabled}
				externalSearch
				onChangeQuery={handleChangeQuery}
				flex={flex}
			/>
		);
	}
);

export default SearchApiSelectField;
