import * as React from 'react';

import { Grid } from '@mui/material';
import TimeSelectField, { TimeSelectFieldRef } from './TimeSelectField';
import DateSelectField, { DateSelectFieldRef } from './DateSelectField';
import SectionWrapper from './SectionWrapper';
import { addDayToDateString } from '../utils/time';

export type DatetimeRangeSelectFormRef = {
	validate: () => boolean;
};

const DatetimeRangeSelectForm = React.forwardRef<
	DatetimeRangeSelectFormRef,
	{
		label?: string;
		margin?: string;
		padding?: string;
		variant?: 'outlined' | 'filled' | 'standard';
		disabled?: boolean;
		// date
		defaultStartDate?: string;
		defaultEndDate?: string;
		onChangeStartDate?: (startDate?: string) => void;
		onChangeEndDate?: (endDate?: string) => void;
		// time
		defaultStartTime?: string;
		defaultEndTime?: string;
		onChangeStartTime?: (startTime?: string) => void;
		onChangeEndTime?: (endTime?: string) => void;
	}
>(
	(
		{
			label,
			margin = '2rem 0 1rem 0',
			variant = 'standard',
			padding = '2rem',
			disabled,
			// date
			defaultStartDate,
			defaultEndDate,
			onChangeStartDate,
			onChangeEndDate,
			// time
			defaultStartTime,
			defaultEndTime,
			onChangeStartTime,
			onChangeEndTime,
		},
		ref
	): JSX.Element => {
		// date
		const [startDate, setStartDate] = React.useState<string | undefined>(defaultStartDate);
		const [endDate, setEndDate] = React.useState<string | undefined>(defaultEndDate);
		// time
		const [startTime, setStartTime] = React.useState<string | undefined>(defaultStartTime);

		const handleChangeStartDate = React.useCallback(
			(newDate?: string) => {
				setStartDate(newDate);
				onChangeStartDate?.(newDate);
			},
			[onChangeStartDate]
		);

		const handleChangeEndDate = React.useCallback(
			(newDate?: string) => {
				setEndDate(newDate);
				onChangeEndDate?.(newDate);
			},
			[onChangeEndDate]
		);

		const handleChangeStartTime = React.useCallback(
			(newTime?: string) => {
				setStartTime(newTime);
				onChangeStartTime?.(newTime);
			},
			[onChangeStartTime]
		);

		const dateSelectFieldRef1 = React.useRef<DateSelectFieldRef>(null);
		const timeSelectFieldRef1 = React.useRef<TimeSelectFieldRef>(null);
		const dateSelectFieldRef2 = React.useRef<DateSelectFieldRef>(null);
		const timeSelectFieldRef2 = React.useRef<TimeSelectFieldRef>(null);

		const handleValidate = React.useCallback(() => {
			const errors = [
				dateSelectFieldRef1,
				timeSelectFieldRef1,
				dateSelectFieldRef2,
				timeSelectFieldRef2,
			]
				.map((r) => r.current?.validate())
				.some(Boolean);
			return errors;
		}, []);

		React.useImperativeHandle(
			ref,
			() => ({
				validate: handleValidate,
			}),
			[handleValidate]
		);

		return (
			<SectionWrapper margin={margin} padding={padding} label={label}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<DateSelectField
							label="Startdatum"
							defaultDate={defaultStartDate}
							onChange={handleChangeStartDate}
							variant={variant}
							flex
							ref={dateSelectFieldRef1}
							disabled={disabled}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TimeSelectField
							label="Startzeit"
							defaultValue={defaultStartTime}
							onChange={handleChangeStartTime}
							variant={variant}
							flex
							ref={timeSelectFieldRef1}
							disabled={disabled}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DateSelectField
							label="Enddatum"
							defaultDate={defaultEndDate}
							onChange={handleChangeEndDate}
							minDate={addDayToDateString(startDate)}
							variant={variant}
							flex
							ref={dateSelectFieldRef2}
							disabled={disabled}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TimeSelectField
							label="Endzeit"
							defaultValue={defaultEndTime}
							onChange={onChangeEndTime}
							minTime={
								startDate && endDate
									? startDate === endDate
										? startTime
											? startTime
											: undefined
										: undefined
									: undefined
							}
							variant={variant}
							flex
							ref={timeSelectFieldRef2}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</SectionWrapper>
		);
	}
);

export default React.memo(DatetimeRangeSelectForm);
