import { cloneDeep, sortBy } from 'lodash';
import { useQuery } from 'react-apollo';
import base64 from 'base-64';
import gql from 'graphql-tag';
import {
	isThursday,
	nextThursday,
	nextMonday,
	nextTuesday,
	nextFriday,
	nextWednesday,
	nextSaturday,
	nextSunday,
	startOfDay,
	isMonday,
	isTuesday,
	isWednesday,
	isFriday,
	isSaturday,
	isSunday,
	eachDayOfInterval,
	format,
	isEqual,
	addWeeks,
} from 'date-fns';

import templateData from '../json/templateData.json';
import { EmailElement } from '../../../utils/campaignEmailContent';
import { Campaign } from '../../../utils/campaign';

const { recommendedTemplateMovies, templateCinemas, filmSeriesTemplate } = templateData;

const getIntervalData = (
	weekday: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'
) => {
	const today = new Date();
	const isTodayKeys = {
		MONDAY: isMonday(today),
		TUESDAY: isTuesday(today),
		WEDNESDAY: isWednesday(today),
		THURSDAY: isThursday(today),
		FRIDAY: isFriday(today),
		SATURDAY: isSaturday(today),
		SUNDAY: isSunday(today),
	};
	const startingIntervalKeys = {
		MONDAY: isTodayKeys[weekday] ? startOfDay(today) : startOfDay(nextMonday(today)),
		TUESDAY: isTodayKeys[weekday] ? startOfDay(today) : startOfDay(nextTuesday(today)),
		WEDNESDAY: isTodayKeys[weekday] ? startOfDay(today) : startOfDay(nextWednesday(today)),
		THURSDAY: isTodayKeys[weekday] ? startOfDay(today) : startOfDay(nextThursday(today)),
		FRIDAY: isTodayKeys[weekday] ? startOfDay(today) : startOfDay(nextFriday(today)),
		SATURDAY: isTodayKeys[weekday] ? startOfDay(today) : startOfDay(nextSaturday(today)),
		SUNDAY: isTodayKeys[weekday] ? startOfDay(today) : startOfDay(nextSunday(today)),
	};
	const endingIntervalKeys = {
		MONDAY: startOfDay(nextMonday(startingIntervalKeys[weekday])),
		TUESDAY: startOfDay(nextTuesday(startingIntervalKeys[weekday])),
		WEDNESDAY: startOfDay(nextWednesday(startingIntervalKeys[weekday])),
		THURSDAY: startOfDay(nextThursday(startingIntervalKeys[weekday])),
		FRIDAY: startOfDay(nextFriday(startingIntervalKeys[weekday])),
		SATURDAY: startOfDay(nextSaturday(startingIntervalKeys[weekday])),
		SUNDAY: startOfDay(nextSunday(startingIntervalKeys[weekday])),
	};

	return {
		startingDate: startingIntervalKeys[weekday],
		endingDate: endingIntervalKeys[weekday],
		allDaysOfInterval: eachDayOfInterval({
			start: startOfDay(today),
			end: endingIntervalKeys[weekday],
		}),
	};
};

const intervalData = getIntervalData('THURSDAY');

export const useElementsWithUpdatedProgram = ({
	elements,
	campaign,
	selectedCampaignCinemas,
	timeFrame = 'NEXT_WEEK_ONLY',
}: {
	elements: EmailElement[];
	campaign: Campaign;
	selectedCampaignCinemas?: Campaign['cinemas'];
	timeFrame: 'NEXT_WEEK_ONLY' | 'ALL_UPCOMING_MOVIES';
}): EmailElement[] => {
	const newElements = cloneDeep(elements);
	const updatedProgramMovies = {};
	const { endingDate } = intervalData;

	const { data: cinemasData } = useQuery(
		gql`
			query Cinemas($ids: [ID!], $foreignIdType: String!, $before: DateTime, $after: DateTime) {
				screenings(cinemaIds: $ids, before: $before, after: $after) {
					movie {
						id
						title
						synopsis
						duration
						poster
						ageRating
						foreignMovieId(foreignIdType: $foreignIdType) {
							foreignId
						}
					}
				}
			}
		`,
		{
			variables: {
				ids: selectedCampaignCinemas || campaign?.cinemas?.map((c) => c.id),
				foreignIdType: campaign?.language?.foreignIdType,
				before: timeFrame === 'NEXT_WEEK_ONLY' ? endingDate : null,
				after: null,
			},
			skip: !selectedCampaignCinemas && !campaign?.cinemas?.map((c) => c.id),
		}
	);

	const moviesWithinInterval = [
		...new Set(
			cinemasData?.screenings.reduce((acc, el) => {
				acc.push(el.movie.id);
				return acc;
			}, [])
		),
	];

	newElements.map((el) => {
		if (el.type === 'PROGRAM') {
			!el.fullProgramMovies
				? (el['fullProgramMovies'] = recommendedTemplateMovies.map((m) => {
						return {
							...m,
							programUrl: campaign.language.programUrl,
							cinemaUrl: campaign.language.url,
							trailerAutoplay: campaign.language.trailerAutoplay,
						};
				  }))
				: null;

			cinemasData?.screenings
				?.filter((s) => {
					return timeFrame === 'NEXT_WEEK_ONLY' ? moviesWithinInterval.includes(s.movie.id) : true;
				})
				.map((s) => {
					if (!updatedProgramMovies[s.movie.id]) {
						updatedProgramMovies[s.movie.id] = {
							...s.movie,
							fsk: s.movie.ageRating,
							cinemaUrl: campaign.language.url,
							programUrl: campaign.language.programUrl,
							//make sure to change this as soon a movieDetailUrl is available
							movieDetailUrl: '',
							trailerAutoplay: campaign.language.trailerAutoplay,
						};
					}
				});

			const newProgram = Object.values(updatedProgramMovies) as any;
			if (newProgram?.length) {
				el.fullProgramMovies = newProgram;
			}
		}
	});
	return newElements;
};

export const useElementsWithUpdatedRecommendations = ({
	elements,
	campaign,
}: {
	elements: EmailElement[];
	campaign: Campaign;
}): EmailElement[] => {
	const newElements = cloneDeep(elements);
	const updatedRecommendedMovies = {};

	const queryRecommendationsMovies =
		newElements.filter((el) => el.type === 'RECOMMENDATIONS' && !el.showInitializer).length > 0
			? true
			: false;

	const { data } = useQuery(
		gql`
			query Cinemas($ids: [ID!], $foreignIdType: String!, $before: DateTime, $after: DateTime) {
				cinemas(ids: $ids) {
					id
					name
					movies(futureOnly: true) {
						id
						title
						synopsis
						duration
						poster
						ageRating
						foreignMovieId(foreignIdType: $foreignIdType) {
							foreignId
						}
					}
				}
				screenings(cinemaIds: $ids, before: $before, after: $after) {
					id
					datetime
					cinema {
						name
						id
					}
					movie {
						title
						id
					}
					is3d
					is2d
				}
			}
		`,
		{
			variables: {
				ids: campaign?.cinemas?.map((c) => c.id),
				foreignIdType: campaign?.language?.foreignIdType,
				before: intervalData.endingDate,
				after: null,
			},
			skip: !queryRecommendationsMovies || !campaign?.cinemas?.map((c) => c.id),
		}
	);

	newElements.map((el) => {
		if (el.type === 'RECOMMENDATIONS' && queryRecommendationsMovies) {
			!el.recommendedMovies ? (el['recommendedMovies'] = recommendedTemplateMovies) : null;
			!el.cinemas ? (el['cinemas'] = templateCinemas) : null;

			data?.cinemas?.map((cinema) => {
				if (el.cinemas && JSON.stringify(el.cinemas) === JSON.stringify(templateCinemas)) {
					el.cinemas = [];
				}
				const cinemaObject = {
					id: cinema.id,
					name: cinema.name,
					recommendedMovieScreenings: {},
				};

				cinema.movies.map((m) => {
					cinemaObject.recommendedMovieScreenings[m.id] = [];
					intervalData.allDaysOfInterval.map((d, i) => {
						cinemaObject.recommendedMovieScreenings[m.id].push({
							date: format(d, 'yyyy-MM-dd'),
							screenings: [],
						});
						if (data.screenings) {
							data.screenings.map((s) => {
								if (
									isEqual(
										new Date(format(d, 'yyyy-MM-dd')),
										new Date(format(new Date(s.datetime), 'yyyy-MM-dd'))
									) &&
									s.movie.id === m.id
								) {
									cinemaObject.recommendedMovieScreenings[m.id][i]?.screenings.push({
										title: s.movie.title,
										screeningDbId: s.id,
										datetime: s.datetime,
										attribute: s.is3D ? '3D' : '2D',
									});
								}
							});
							cinemaObject.recommendedMovieScreenings[m.id][i].screenings = sortBy(
								cinemaObject.recommendedMovieScreenings[m.id][i].screenings,
								(s) => s.datetime
							);
						}
					});
				});

				cinema.movies.map((m) => {
					if (!updatedRecommendedMovies[m.id]) {
						updatedRecommendedMovies[m.id] = {
							...m,
							fsk: m.ageRating,
							cinemaUrl: campaign.language.url,
							programUrl: campaign.language.programUrl,
							movieDetailUrl: '',
							trailerAutoplay: campaign.language.trailerAutoplay,
						};
					}
				});
				el.cinemas.push(cinemaObject);
			});

			const newRecommendations = Object.values(updatedRecommendedMovies) as any;

			if (newRecommendations?.length) {
				el.recommendedMovies = [
					newRecommendations[0],
					newRecommendations[1] || recommendedTemplateMovies[0],
					newRecommendations[2] || recommendedTemplateMovies[1],
				];
			}
		}
	});
	return newElements;
};

export const useElementsWithUpdatedFilmSeries = ({
	elements,
	campaign,
	selectedFilmSeries,
	timeFrame = 1,
}: {
	elements: EmailElement[];
	campaign: Campaign;
	selectedFilmSeries?: string[];
	timeFrame?: number;
}): EmailElement[] => {
	const newElements = cloneDeep(elements);
	const updatedFilmSeries = [] as any;
	const { startingDate } = intervalData;
	const endingDate = addWeeks(startingDate, timeFrame);

	const { data } = useQuery(
		gql`
			query MovieLists($ids: [ID!]!, $foreignIdType: String!, $before: DateTime) {
				cinemas(ids: $ids) {
					id
					name
				}
				allCinemaFilmSeries(cinemaIds: $ids, before: $before) {
					id
					title
					subTitle
					description
					items {
						description
						movie {
							id
							title
							synopsis
							duration
							poster
							ageRating
							foreignMovieId(foreignIdType: $foreignIdType) {
								foreignId
							}
						}
					}
				}
			}
		`,
		{
			variables: {
				ids: campaign?.cinemas?.map((c) => c.id),
				foreignIdType: campaign?.language?.foreignIdType,
				before: endingDate,
			},
			skip: !campaign?.cinemas?.map((c) => c.id),
		}
	);

	newElements.map((el) => {
		if (el.type === 'SERIES') {
			!el.filmSeries
				? (el['filmSeries'] = filmSeriesTemplate.map((series) => {
						return {
							...series,
							items: series.items.map((item) => {
								return {
									...item,
									movie: {
										...item.movie,
										programUrl: campaign.language.programUrl,
										cinemaUrl: campaign.language.url,
										trailerAutoplay: campaign.language.trailerAutoplay,
									},
								};
							}),
						};
				  }))
				: null;

			if (!updatedFilmSeries.length) {
				data?.allCinemaFilmSeries?.map((series: any) => {
					if (series?.items?.length > 0 && selectedFilmSeries) {
						const decodedSeriesId = base64.decode(series.id).split('_');
						const modifiedSeriesId =
							decodedSeriesId.length === 3
								? base64.encode(decodedSeriesId[0] + '_' + decodedSeriesId[1])
								: series.id;

						if (
							(selectedFilmSeries && selectedFilmSeries.includes('ALL')) ||
							selectedFilmSeries.includes(modifiedSeriesId)
						) {
							updatedFilmSeries.push({
								...series,
								items: series.items.map((item) => {
									return {
										...item,
										movie: {
											...item.movie,
											fsk: item.movie.ageRating,
											programUrl: campaign.language.programUrl,
											cinemaUrl: campaign.language.url,
											trailerAutoplay: campaign.language.trailerAutoplay,
										},
									};
								}),
							});
						}
					}
				});
			}

			if (updatedFilmSeries?.length) {
				el.filmSeries = updatedFilmSeries;
			}
		}
	});

	return newElements;
};
