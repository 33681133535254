import styled from 'styled-native-components';
import { Platform } from 'react-native';

import type { FontVariant } from 'react-native';

const Label = styled.Text<{
	opacity?: number;
	color?: string;
	margin?: string;
	size?: 'xs' | 's' | 'm' | 'l' | 'xl';
	uppercase?: boolean;
	align?: 'center' | 'left' | 'right';
	textDecoration?: 'underline' | 'line-through' | 'none';
	flex?: boolean;
	light?: boolean;
	width?: string;
	height?: string;
	fontVariant?: FontVariant;
}>`
	opacity: ${(p) => p.opacity || 1};
	color: ${(p) => p.color || '$neutral0'};
	margin: ${(p) => p.margin || '0'};
	font: ${(p) => p.theme.typography.label(p.size || 'm', p.light).css};
	letter-spacing: ${(p) =>
		p.uppercase ? { xs: 0, s: 0.05, m: 0.075, l: 0.1, xl: 0.1 }[p.size || 'm'] + 'rem' : '0'};
	text-transform: ${(p) => (p.uppercase ? 'uppercase' : 'none')};
	text-align: ${(p) => p.align || 'left'};
	text-align-vertical: center;
	text-decoration: ${(p) =>
		p.textDecoration ? `${p.textDecoration} ${p.color || '$neutral0'}` : 'none'};
	${Platform.OS === 'android' ? 'include-font-padding: false' : ''};
	${(p) => (p.flex ? 'flex: 1' : '')};
	${(p) => (p.width ? `width: ${p.width}` : '')};
	${(p) => (p.height ? `height: ${p.height}` : '')};
	${(p) => (p.fontVariant ? `font-variant: ${p.fontVariant}` : '')};
`;

export default Label;
