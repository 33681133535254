import React from 'react';
import styledWeb from 'styled-components';
import { TextField, InputLabel, FormControl, Box } from '@mui/material';

import { EditorField } from '@cinuru/components';
import { useDivDimensions } from '../utils/dimensions';

const StyledInputLabel = styledWeb(InputLabel)<{ $error?: boolean }>`
	color: ${(p) => (p.$error ? p.theme.palette.error.main : '')};
	background-color: ${(p) => p.theme.palette.common.white};
`;

const Edit = React.forwardRef(
	(
		{
			onChange,
			onBlur,
			value,
			label = '',
			markdown = true,
			width = '100%',
			altBackground = true,
			borderWidth = '0',
			padding = 2,
			noClear = true,
			alternativeStyle = true,
			focusStyle = 'disabled',
			hint,
			error,
			numberOfLines,
			characterLimit,
			variables,
		}: {
			onChange: (value: string) => void;
			onBlur?: () => void;
			value: string;
			label?: string;
			markdown?: boolean;
			width?: string;
			altBackground?: boolean;
			borderWidth?: string;
			padding?: number;
			noClear?: boolean;
			alternativeStyle?: boolean;
			focusStyle?: 'underline' | 'outline' | 'disabled';
			hint?: string;
			error?: string;
			numberOfLines?: number;
			characterLimit?: number;
			variables?: { name: string; label: string; inputLength: number }[];
		},
		ref
	) => {
		return (
			<EditorField
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				label={label || ''}
				markdown={markdown}
				width={width}
				altBackground={altBackground}
				borderWidth={borderWidth}
				padding={padding}
				noClear={noClear}
				alternativeStyle={alternativeStyle}
				focusStyle={focusStyle}
				hint={hint}
				error={error}
				numberOfLines={numberOfLines}
				characterLimit={characterLimit}
				variables={variables}
				disableInitialHandleChange
			/>
		);
	}
);

const EditorFieldWrapper = ({
	id,
	value,
	onChange,
	margin,
	variant = 'outlined',
	label,
	markdown,
	width,
	altBackground,
	borderWidth,
	padding,
	noClear,
	alternativeStyle,
	focusStyle,
	hint,
	error,
	helperText,
	numberOfLines,
	characterLimit,
	variables,
	onBlur,
	disabled,
}: {
	id?: string;
	value: string;
	onChange: (value: string) => void;
	margin?: string;
	variant?: 'outlined' | 'filled' | 'standard';
	label?: string;
	markdown?: boolean;
	width?: string;
	altBackground?: boolean;
	borderWidth?: string;
	padding?: number;
	noClear?: boolean;
	alternativeStyle?: boolean;
	focusStyle?: 'underline' | 'outline' | 'disabled';
	hint?: string;
	error?: boolean;
	helperText?: string;
	numberOfLines?: number;
	characterLimit?: number;
	variables?: { name: string; label: string; inputLength: number }[];
	onBlur?: () => void;
	disabled?: boolean;
}): JSX.Element => {
	const ref = React.useRef(null);
	const containerDimensions = useDivDimensions(ref);

	const inputProps = React.useMemo(
		() => ({
			inputComponent: Edit,
			inputProps: {
				markdown,
				width: width || containerDimensions.width + 'px',
				altBackground,
				borderWidth,
				padding,
				noClear,
				alternativeStyle,
				focusStyle,
				hint,
				numberOfLines,
				characterLimit,
				variables,
				onBlur,
			},
		}),
		[
			altBackground,
			alternativeStyle,
			borderWidth,
			characterLimit,
			containerDimensions.width,
			focusStyle,
			hint,
			markdown,
			noClear,
			numberOfLines,
			padding,
			variables,
			width,
			onBlur,
		]
	);

	const handleChange = React.useCallback(
		(val) => {
			onChange && onChange(val);
		},
		[onChange]
	);

	return (
		<Box m={margin}>
			<FormControl variant={variant} fullWidth disabled={disabled}>
				{label ? (
					<StyledInputLabel variant={variant} shrink $error={error}>
						{label}
					</StyledInputLabel>
				) : null}

				<div ref={ref}>
					<TextField
						label=" " // needs to be set in order to set a border on textField
						fullWidth
						// @ts-ignore
						InputProps={disabled ? undefined : inputProps}
						variant="outlined"
						value={value}
						onChange={handleChange}
						error={error}
						helperText={helperText}
						disabled={disabled}
					/>
				</div>
			</FormControl>
		</Box>
	);
};

export default EditorFieldWrapper;
