import React from 'react';

import { ID } from '@cinuru/utils/types';

import CinemaSelectFieldNew from '../../../../components/CinemaSelectFieldNew';

const CinemasController = ({
	onChange,
	path,
	defaultIds,
}: {
	onChange: (path: string, newValue: ID[]) => void;
	path: string;
	defaultIds?: ID[];
}): JSX.Element => {
	const handleChange = React.useCallback(
		(cinemas: { id: ID; name: string }[]) => {
			const cinIds = cinemas.map(({ id }) => id);
			onChange(path, cinIds);
		},
		[onChange, path]
	);

	return (
		<CinemaSelectFieldNew
			width="100%"
			label="Kinoauswahl"
			onChange={handleChange}
			multi
			defaultIds={defaultIds}
		/>
	);
};

export default React.memo(CinemasController);
