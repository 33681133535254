import { Paragraph } from '@cinuru/components';
import React from 'react';

import styled, { useTheme } from 'styled-native-components';
const Wrapper = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;
const OuterWrapper = styled.View`
	margin-top: 1rem;
`;

export const Arrow = ({ percent }) => {
	let rotate = 180;
	if (percent >= -100 && percent <= 100) {
		rotate -= (percent / 100) * 90;
	}
	const theme = useTheme();
	return (
		<OuterWrapper>
			<Wrapper>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					version="1.1"
					x="0px"
					y="0px"
					width="30px"
					height="30px"
					viewBox="0 0 100 100"
					transform={`rotate(${rotate})`}
					stroke={theme.colors.accent0}
					strokeWidth="4"
				>
					<g>
						<path d="M50,17c-18.2,0-33,14.8-33,33s14.8,33,33,33s33-14.8,33-33S68.2,17,50,17z M50,80c-16.5,0-30-13.5-30-30s13.5-30,30-30   s30,13.5,30,30S66.5,80,50,80z"></path>
						<path d="M67,48.5H36.7l14.9-14.4c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L33.1,47.8c-1.2,1.2-1.2,3.1,0,4.3l16.4,15.9   c0.3,0.3,0.7,0.4,1,0.4c0.4,0,0.8-0.2,1.2-0.5c0.5-0.6,0.4-1.6-0.2-2.1L36.7,51.5H67c0.8,0,1.5-0.7,1.5-1.5S67.8,48.5,67,48.5z"></path>
					</g>
				</svg>
				<Paragraph size="l" color="$accent0">
					{percent > 0 ? '+' : ''}
					{percent} %
				</Paragraph>
			</Wrapper>
			<Paragraph size="s">im Vergleich zur Vorwoche</Paragraph>
		</OuterWrapper>
	);
};
